export const getUniqueObjectArrayByProperty = (array: any, property: string) => {
    let map = new Map<string, any>();

    for(const item of array){
        if(!map.get(item[property])){
            map.set(item[property], item);
        }
    }

    return [...map.values()];
}