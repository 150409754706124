import { ProjectType } from "../store/project/project-data";

export function millisecondsToSecondsStr(mills: number, decimal: number = 2, sign: string = "s"): string {
    if (mills) {
        return `${(mills / 1000).toFixed(decimal)}${sign}`;
    }
    return '-';
}

export function truncate(str: string, size: number): string {
    if (size > 3 && str.length > size) {
        return str.slice(0, size - 3) + '...';
    } else if (size < 3 && size > 0) {
        return str.slice(0, size);
    }
    return str;
}

export function lowerCaseProjectType(type: ProjectType): string{
    const tmpType = !type ? ProjectType.Design : type;
    return tmpType.toLowerCase();
}