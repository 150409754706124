import axios from "axios";
import { UiSettingsData } from "../store/uiSettings/UiSettingsContext";

export const UiSettingsService = {
    Get: async (): Promise<UiSettingsData> => {
        try {
            const response = await axios.get('/api/uisettings');
            return response.data;
        } catch {
            throw new Error('Error getting UI settings');
        }
    },
    Set: async (settings: UiSettingsData) => {
        try {
            return await axios.post('/api/uisettings', settings);
        } catch {
            throw new Error('Error setting UI settings');
        }
    }
};