import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Channel } from "../../../store/job/channel";
import { HoopsVisibilityContext } from "../../../store/job/hoops-visibility-context";
import JobData from "../../../store/job/job-data";
import { SelectOperator } from "../operators/SelectOperator";
import { VisibilityStateActionType } from "../../../store/job/hoops-visibility-reducer";

export function useFocusOnChannel(jobContext: JobData, hwv: Communicator.WebViewer) {
    const { updateVisibility } = useContext(HoopsVisibilityContext);
    const [enabled, setEnabled] = useState<boolean>(false);
    const operatorId = useMemo(() => {
        if (hwv && jobContext.IsTreeLoaded) {
            return hwv.registerCustomOperator(new SelectOperator(hwv));
        }
        return Communicator.OperatorId.None;
    }, [jobContext.IsTreeLoaded, hwv]);

    const focusOnChannel = useCallback(async (channel: Channel, closeCb?: Function) => {
        if (operatorId !== Communicator.OperatorId.None) {
            const operator = hwv.operatorManager.getOperator(operatorId) as SelectOperator;

            operator.onClose = () => {
                updateVisibility({
                    type: VisibilityStateActionType.RESTORE_STATE
                });

                hwv.operatorManager.remove(operatorId);
                setEnabled(false);
                closeCb?.();
            }

            hwv.operatorManager.push(operatorId);

            updateVisibility({
                type: VisibilityStateActionType.ISOLATE_CHANNEL,
                items: channel.getVisibleItems()
            });

            setEnabled(true);
        }

    }, [hwv, operatorId]);

    const stop = useCallback(() => {
        if (!hwv || operatorId === Communicator.OperatorId.None) {
            return;
        }

        hwv.operatorManager.remove(operatorId);
        setEnabled(false);
    }, [hwv, operatorId]);

    useEffect(() => {
        return () => {
            hwv && hwv.unregisterCustomOperator(operatorId);
        }
    }, [hwv, operatorId]);


    return {
        focusOnChannel,
        stop,
        enabled
    };
}