import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import theme from "../../styles/main-theme";
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';

export type confirmationModalProps = {
    open: boolean,
    onClose:  (answer: boolean) => void
    title: string,
    content: string
}

const ConfirmationModal = (props: confirmationModalProps) => {
    const { t } = useTranslation();
    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();
    return <>
        <Dialog
            open={props.open}
            onClose={() => { props.onClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
            <span style={{color: theme.palette.primary.main}}>{props.title}</span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose(false) }} variant="text" className={classes.btn}>{t("No")}</Button>
                <Button onClick={() => { props.onClose(true) }} variant="text" className={classes.btn}>{t("Yes")}</Button>
            </DialogActions>
        </Dialog>   
    </>
}

export default ConfirmationModal;