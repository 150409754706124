import { isBlank, writeLine } from "./Utilities";

export enum Directives {
    Comment = '#',
    Document = '',
    Face = 'f',
    Group = 'g',
    Line = 'l',
    NormalizedVertex = 'vn',
    ObjectNode = 'o',
    Vertex = 'v',
    Texture = 'vt'
}

export function composeDirective(directive: Directives, value?: string, allowBlank: boolean = false) : string {
    value = value?.trim();

    return (!allowBlank && isBlank(value))
           ? '' 
           : writeLine(`${directive} ${value}`);
}