import * as React from 'react';
import { useState } from "react";
import { Box, Card, Divider, CircularProgress, FormControlLabel, Fade, Typography, useTheme } from "@mui/material";
import MetricsService from "../../services/MetricsService";
import Layout from "../ui/Layout";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

const MetricsPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const isValid = (dateFrom: Dayjs | null, dateTo: Dayjs | null) => {
        if (dateFrom && dateTo && dateFrom <= dateTo) {
            return true;
        }
        return false;
    }

    const [FilePathIndividual, SetFilePathIndividual] = useState('');
    const [FilePathAggregate, SetFilePathAggregate] = useState('');

    const [valueFrom, setValueFrom] = React.useState<Dayjs | null>(
        dayjs(new Date()),
    );

    const [valueTo, setValueTo] = React.useState<Dayjs | null>(
        dayjs(new Date()),
    );

    const [excludesEmployees, setExcludesEmployees] = React.useState<boolean>(true);

    const handleChangeFrom = (newValue: Dayjs | null) => {
        SetFilePathIndividual('');
        SetFilePathAggregate('');
        setValueFrom(newValue);
    };
    const [loading, setLoading] = useState(false);
    const handleChangeTo = (newValue: Dayjs | null) => {
        SetFilePathIndividual('');
        SetFilePathAggregate('');
        setValueTo(newValue);
    };

    const generateIndividualReport = async () => {
        setLoading(true);
        const responce = await MetricsService.GenerateIndividualUsageReport(
            valueFrom ? valueFrom.toDate() : new Date(),
            valueTo ? valueTo.toDate() : new Date(), excludesEmployees);
        SetFilePathIndividual(responce.filePath);
        setLoading(false);
    }

    const generateAggregateReport = async () => {
        setLoading(true);
        const responce = await MetricsService.GenerateAggregateUsageReport(
            valueFrom ? valueFrom.toDate() : new Date(),
            valueTo ? valueTo.toDate() : new Date(), excludesEmployees);
        SetFilePathAggregate(responce.filePath);
        setLoading(false);
    }

    function handleExcludesEmployees() {
        SetFilePathIndividual('');
        SetFilePathAggregate('');
        setExcludesEmployees(!excludesEmployees);
    }

    const handleClickGenerateIndividualUsage = () => {
        generateIndividualReport();
    };

    const handleClickGenerateAggregateUsage = () => {
        generateAggregateReport();
    };

    return <>
        <Layout>
            <Box sx={{ color: theme.palette.primary.dark, p: 5, minHeight: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Card sx={{ maxWidth: "1200px", padding: "25px", flex: "1 1 0", height: "fit-content", position: "relative" }}>
                    <Fade in={loading} unmountOnExit>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }}>
                            <CircularProgress />
                        </Box>
                    </Fade>
                    <Typography sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }} variant="h5" gutterBottom>{t("Application Usage Reports")}</Typography>
                    <Divider sx={{ mb: "15px" }}></Divider>
                    <div>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Stack >
                                    <DesktopDatePicker
                                        label={t("From")}
                                        inputFormat="MM/DD/YYYY"
                                        value={valueFrom}
                                        onChange={handleChangeFrom}
                                        renderInput={(params) => <TextField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: !isValid(valueFrom, valueTo) ?
                                                        theme.palette.error.main : theme.palette.primary.light,
                                                }
                                            },
                                        }} {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack >
                                    <DesktopDatePicker
                                        label={t("To")}
                                        inputFormat="MM/DD/YYYY"
                                        value={valueTo}
                                        onChange={handleChangeTo}
                                        renderInput={(params) => <TextField {...params} sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: !isValid(valueFrom, valueTo) ?
                                                        theme.palette.error.main : theme.palette.primary.light,
                                                }
                                            },
                                        }} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </FormControl>
                        <FormControlLabel sx={{ margin: '0.5em 0 0 0', padding: '0' }}
                            control={<Checkbox checked={excludesEmployees} onChange={handleExcludesEmployees} />}
                            label={t("Exclude MayaHTT Users")}
                        />
                    </div>
                    <Divider sx={{ mb: "15px" }}></Divider>
                    <div>
                        {!FilePathIndividual && <Button disabled={!isValid(valueFrom, valueTo)}
                            sx={{ fontSize: '16px', textDecoration: 'underline', textTransform: 'none', padding: '10', color: theme.palette.info.light }}
                            onClick={handleClickGenerateIndividualUsage}>
                            {t("Generate Individual Usage Report")}
                        </Button>}
                        {FilePathIndividual && <Typography noWrap>{FilePathIndividual}</Typography>}
                        {!FilePathAggregate && <Button disabled={!isValid(valueFrom, valueTo)}
                            sx={{ fontSize: '16px', textDecoration: 'underline', textTransform: 'none', padding: '10', color: theme.palette.info.light }}
                            onClick={handleClickGenerateAggregateUsage}>
                            {t("Generate Aggregate Usage Report")}
                        </Button>}
                        {FilePathAggregate && <Typography noWrap>{FilePathAggregate}</Typography>}
                    </div>
                </Card>
            </Box>
        </Layout>
    </>
}

export default MetricsPage;