import { useMemo, useRef, useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Box, ButtonGroup, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import useInput from '../ui/hooks/use-input';
import { iterationNameMinimumLengthError } from './ProjectsConfig';
import ModelPicker, { ModelPickerInfo } from './ModelPicker';
import { IterationDialogProps } from './IterationDialogProps';
import { validateJobName } from '../../services/FormsValidation';
import { blobToBase64 } from '../../services/HelperService';
import FileManagementService from '../../services/FileManagementService';
import { SampleJobType, Status } from '../../store/job/job-data';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import theme from '../../styles/main-theme';
import CadInfo, { maxSizeperFile } from '../../store/job/cad-info';
import CachedIcon from '@mui/icons-material/Cached';
import makeStyles from '@mui/styles/makeStyles';
import CreditsAccountContext from '../../store/creditsAccount/CreditsAccountContext';
import { ProjectType } from '../../store/project/project-data';
import { useTranslation } from 'react-i18next';
import JobContext from '../../store/job/job-context';
import AuthContext from '../../auth/AuthenticationContext';

const IterationDialog = (props: IterationDialogProps) => {
    const [cadFilesToAdd, setCadFilesToAdd] = useState<Array<File>>([]);
    const [thumbnailData, setThumbnailData] = useState<string>();
    const descriptionElementRef = useRef<HTMLElement>(null);
    const [cadFiles, setCadFiles] = useState<Array<CadInfo>>([]);
    const [updateOnCadFiles, setUpdateOnCadFiles] = useState(false);
    const creditsAccountContext = useContext(CreditsAccountContext);
    const [feasibilityProject, setFeasibilityProject] = useState<boolean>(false);
    const jobContext = useContext(JobContext);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        setFeasibilityProject(jobContext?.ProjectType == ProjectType.Feasibility);
    }, [jobContext?.ProjectType]);

    const { t } = useTranslation();
    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            flex: "0.2",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            },
        },
        fileDisabledUpload: {
            display: 'inline-block',
            padding: '10px 12px',
            cursor: 'pointer',
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            flex: "0.19",
            borderRadius: "20px",
            margin: "5px",
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",

        },
        fileUpload: {
            display: 'inline-block',
            padding: '10px 12px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            flex: "0.19",
            borderRadius: "20px",
            margin: "5px",
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        input: {
            display: 'none'
        },
    })();

    const { value: name,
        hasError: nameHasError,
        isValid: nameIsValid,
        valueChangedHandler: nameChangedHandler,
        errorMessage: nameErrorMessage,
        inputBlurHandler: nameBlurHandler,
        reset: resetName,
        setValue: setName
    } = useInput(validateJobName, iterationNameMinimumLengthError);

    const { value: description,
        valueChangedHandler: descriptionChangeHandler,
        setValue: setDescription,
        reset: resetDescription } = useInput(() => true, '');

    const { value: customSolverSettings,
        valueChangedHandler: customSolverSettingsChangeHandler,
        setValue: setCustomSolverSettings,
        reset: resetCustomSolverSettings } = useInput(() => true, '');

    useEffect(() => {
        async function fetchThumbnail() {
            if (props.originalJob?.jobId && props.originalJob.thumbnailBlobInfo) {
                let blob = await new FileManagementService().downloadBlob(props.originalJob.thumbnailBlobInfo, props.originalJob.containerName);

                let base64 = await blobToBase64(blob) as string;
                let thumbnailData = base64.split(",")[1];

                setThumbnailData(thumbnailData);
            }
        }

        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            descriptionElement?.focus();

            fetchThumbnail()
        }
        let customSolverSettings = props.originalJob?.customSolverSettings;
        customSolverSettings = customSolverSettings && customSolverSettings.replace(/\s+/g, ' ');
        if (props.open == true && props.edit == true && props.originalJob) {
            //set edited job details
            setName(props.originalJob.name ?? '');
            setDescription(props.originalJob.description ?? '');
            authContext.isSupervisor && setCustomSolverSettings(customSolverSettings ?? '');

            if (props.originalJob.cadInfos)
                setCadFiles(props.originalJob.cadInfos);

        } else if (props.open == true && props.edit == false) {
            //create job based on active iteration
            if (props.originalJob?.cadInfos) {
                setCadFiles(props.originalJob?.cadInfos);
            }
            if (authContext.isSupervisor && props.originalJob?.customSolverSettings) {
                setCustomSolverSettings(props.originalJob?.customSolverSettings);
            }

        } else if (props.open == false) {
            setName(props.name ?? '');
            setDescription(props.description ?? '');
            setCustomSolverSettings(customSolverSettings ?? '');
        }
    }, [props.open]);

    const closeDialog = () => {
        resetForm();
        props.CancelHandler();
    }

    const getCadFiles = () => {
        var cadData = new Array<File>();

        cadFilesToAdd.forEach((file) => {
            cadData.push(file);
        })

        return cadData;
    }

    const submit = () => {
        props.OkHandler();

        const mode = props.edit == true ? "edit" : "create"
        const returnData = {
            id: props.originalJob?.jobId,
            name: name,
            description: description,
            projectId: props.projectId,
            cadFilesToAdd: getCadFiles(),
            existingCadInfos: cadFiles,
            basedOnJobId: props.originalJob?.jobId,
            updateOnCadFiles: updateOnCadFiles,
            containerName: props.originalJob?.containerName!,
            customSolverSettings: customSolverSettings
        }
        props.setDetails(returnData, mode);
        resetForm();
    }

    const resetForm = () => {
        resetName();
        resetDescription();
        resetCustomSolverSettings();
        setCadFiles([]);
        setCadFilesToAdd([]);
    }

    const sizePerFileInRange = () => {
        for (const cadFile of cadFilesToAdd) {
            if (cadFile.size > maxSizeperFile) {
                return false;
            }
        }

        return true;
    }
    const cadValidation = useMemo(() => {
        let errors = [];

        if ((cadFilesToAdd.length + cadFiles.length) == 0) {
            errors.push("You need at least one CAD file for your job");
        }

        // if (!sizePerFileInRange()) {
        //     errors.push("Some of the selected files are too large to use");
        // } 

        let isValid = errors.length == 0 ?? false;

        return {
            isValid: isValid,
            errors: errors
        };
    }, [cadFilesToAdd, cadFiles])

    const cadErrorMessage = useMemo(() => {

        return <>
            {
                !cadValidation.isValid &&
                <Alert severity="error" data-testid="file-errors-list">
                    <>
                        <List>
                            {
                                cadValidation.errors.map((err, index) => {
                                    return <ListItem key={index}><label>{t(err)}</label></ListItem>
                                })
                            }
                        </List>
                    </>
                </Alert>
            }
        </>
    }, [cadValidation.errors])

    const formIsValid = useMemo(() => {
        return cadValidation.errors.length == 0 && nameIsValid
    }, [cadValidation, nameIsValid])

    const getModelPickerInfo = (info: ModelPickerInfo) => {
        if (!info.selected) return;
        setUpdateOnCadFiles(true);

        //Check that no two files have the same name
        const uniqueNameFiles = filterSameNameFiles(info.selected)

        let list = [...cadFilesToAdd, ...uniqueNameFiles];
        setCadFilesToAdd(list);
    }

    const filterSameNameFiles = (selectedFiles: File[]) => {
        const uniqueNameFiles = selectedFiles.filter((f) => {
            const foundInCadToAdd = cadFilesToAdd.find((c) => { return c.name == f.name });
            const foundInExistingCad = cadFiles.find((c) => { return c.Name == f.name });
            return !foundInCadToAdd && !foundInExistingCad;
        });

        return uniqueNameFiles;
    }

    const createJobWithSampleFile = async (sampleJobType: SampleJobType) => {
        if (props.onCreateJobWithSampleFile)
            props.onCreateJobWithSampleFile(sampleJobType)
    }

    const isFreeAccount = () => {
        return creditsAccountContext?.isFree;
    }

    const sampleFileJob = <>
        <Paper sx={{ marginBottom: "10px !important", marginTop: "20px !important", cursor: 'pointer', 'border': '1px solid transparent' }}>
            <Box id="sampleFileButtons" textAlign='center' sx={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                <Typography variant='h6' component='div' color='primary'>{t("Start with a sample model")}</Typography>
                <Typography variant="caption">{t("So you can quickly evaluate SimForm's capabilities")}</Typography>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                    {isFreeAccount() && props.projectType == ProjectType.Feasibility ?
                        <Button id="conventionalSampleFileButton" onClick={() => { createJobWithSampleFile(SampleJobType.MouseShell) }}>{t("Mouse Shell")}</Button> :
                        <Button id="conventionalSampleFileButton" onClick={() => { createJobWithSampleFile(SampleJobType.Conventional) }}>{t("Conventional")}</Button>}
                    {isFreeAccount() && props.projectType == ProjectType.Feasibility ?
                        <Button id="conformalSampleFileButton" onClick={() => { createJobWithSampleFile(SampleJobType.ATVGrill) }}>{t("ATV Grill")}</Button> :
                        <Button id="conformalSampleFileButton" onClick={() => { createJobWithSampleFile(SampleJobType.Conformal) }}>{t("Conformal")}</Button>}
                </ButtonGroup>
            </Box>
        </Paper>
    </>

    const modelPicker = <ModelPicker setModelPickerInfo={getModelPickerInfo} />;

    const canEditCadFile = () => {
        return (props.edit == false || (props.edit == true && props.originalJob?.solveStatus == Status.None));
    }

    const cadFilesList = (displayName: string, onRemove: () => void, icon: JSX.Element | null = null) => {
        return <ListItem key={displayName} sx={{ borderBottom: "1px solid", borderBottomColor: theme.palette.grey[300] }}>
            <ListItemText primary={displayName} />
            {canEditCadFile() && <>
                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                    {icon}
                </ListItemIcon>
                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                    <DeleteForeverIcon onClick={onRemove} color="error" sx={{ fontSize: "1.6em", cursor: "pointer" }} />
                </ListItemIcon>
            </>}
        </ListItem>
    }
    const handleClose = () => {
        resetForm();
        props.CancelHandler();
    }
    const fileReader = new FileReader();
    const handleOnUpload = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            fileReader.onload = async function (event) {
                props.onSelectFileImportJob && props.onSelectFileImportJob(event, name, file, description);
                closeDialog();
            };
            fileReader.readAsDataURL(file);
        }
        // Resets File Input
        $("#zipFileInput").val("");
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => { closeDialog() }}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <form>
                    <input
                        type={"file"}
                        className={classes.input}
                        id={"zipFileInput"}
                        accept={".zip"}
                        onChange={handleOnUpload}
                    />
                </form>

                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                </DialogContentText>
                {props.edit == false &&
                    <>
                        {props.originalJob?.jobId && <DialogContentText sx={{ mb: "10px" }}>{t("Based on")}: {props.originalJob.name}</DialogContentText>}
                    </>
                }

                <Stack spacing={1} sx={{ paddingTop: 1 }} direction='column'>
                    <TextField autoFocus
                        fullWidth
                        required
                        size='small'
                        id='job-name-input-dialog'
                        label={t("Job Name")}
                        variant='outlined'
                        error={nameHasError}
                        helperText={nameErrorMessage}
                        onBlur={nameBlurHandler}
                        onChange={nameChangedHandler}
                        inputProps={{ maxLength: 500 }}
                        value={name}
                        sx={{ paddingBottom: 1 }} />
                    <TextField
                        fullWidth
                        size='small'
                        id='description'
                        label={t("Description")}
                        variant='outlined'
                        inputProps={{ maxLength: 500 }}
                        value={description}
                        onChange={descriptionChangeHandler}
                        sx={{ paddingBottom: 1 }} />
                    {authContext.isSupervisor && <TextField
                        fullWidth
                        multiline
                        disabled={props.originalJob?.solveStatus != undefined && props.originalJob?.solveStatus != Status.None}
                        rows={4}
                        size='small'
                        id='customSolverSettings'
                        label={t("Solver Settings")}
                        variant='outlined'
                        inputProps={{ maxLength: 500 }}
                        value={customSolverSettings}
                        onChange={customSolverSettingsChangeHandler} />}

                    {!props.edit && props.showCreateJobWithSampleFile == true && sampleFileJob}

                    <DialogContentText>{t("Files")}</DialogContentText>
                    {/* Can only add CAD when job has not been submitted */}
                    {canEditCadFile() && modelPicker}
                </Stack>
                {props.edit == true && props.originalJob?.solveStatus != Status.None && <Box sx={{ display: 'flex', alignItems: 'center', mt: "10px" }}>
                    <ErrorOutlineIcon sx={{ mr: '4px' }}></ErrorOutlineIcon>{t("CAD models cannot be changed because the job has been submitted")}
                </Box>}
                {cadErrorMessage}
                <List>
                    {cadFiles && cadFiles.map((cadInfo) => {
                        return cadFilesList(
                            cadInfo.Name,
                            () => {
                                setUpdateOnCadFiles(true);
                                setCadFiles(cadFiles.filter((item) => { return item.Name != cadInfo.Name }))
                            },
                            <Tooltip title={t("Cad file used in job") + " " + props.originalJob?.name}>
                                <CachedIcon color="primary" sx={{ fontSize: "1.3em" }}></CachedIcon>
                            </Tooltip>
                        )
                    })
                    }
                    {cadFilesToAdd.map((file) => {
                        return cadFilesList(
                            file.name,
                            () => {
                                setCadFilesToAdd(cadFilesToAdd.filter((item) => { return item.name != file.name }))
                            }
                        )
                    })
                    }
                </List>
            </DialogContent>
            <DialogActions >
                {authContext.isSupervisor && !feasibilityProject && props.edit == false && <label htmlFor="zipFileInput"
                    className={validateJobName(name) ? classes.fileUpload : classes.fileDisabledUpload}>{t("Import Job")}</label>}
                <Button id='add-job-button-dialog' onClick={submit} className={classes.btn} disabled={!formIsValid}>
                    {props.buttonText ?? t("OK")}
                </Button>
                <Button id='cancel-job-button-dialog' onClick={handleClose} className={classes.btn}>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default IterationDialog;