import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles";
import { JobSolveInputsParameters, getParametersFormatVersion } from "../../../store/job/job-data"
import { ProjectType } from "../../../store/project/project-data"
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset, PartType, VtfxCase, VtfxScalarResults } from "../../../store/job/ceetron-context"
import AuthContext from "../../../auth/AuthenticationContext"
import { useTranslation } from "react-i18next"
import { useContext, useEffect } from "react"
import { createFreezingTimeCaseFromModel, createPlasticVolumeCaseFromModel, createSurfaceTemperatureCaseFromModel, hasDefaultCase, hasFreezeTimeCase, hasFreezeTimeResults, hasPlasticSkinCase, setVisibilityByType, swapModelAndConstantRemoteModel, switchToVtfxCase } from "../utils/ceetron.utils";

interface DisplayPresetControlProps {
    projectType: ProjectType,
    solverInputParameter?: JobSolveInputsParameters
    mainView: cee.View,
    mainModel: cee.ug.RemoteModel,
    constantMainModel?: cee.cug.ConstantRemoteModel,
    comparisonView?: cee.View,
    showCurrentComparisonView: boolean,
    comparisonModel?: cee.ug.RemoteModel,
    constantComparisonModel?: cee.cug.ConstantRemoteModel,
    onHandleDisplayMode: (view: cee.View, model: cee.ug.RemoteModel, displayMode: DisplayMode, isComparisonModel: boolean) => void,
    onRestoreStateIds: (model: cee.ug.RemoteModel, modelIndex: number) => void
}

const useStyles = makeStyles({
    wrapper: {
        padding: "5px 8px 8px 12px",
        borderBottom: "1px solid"
    }
});

export const DisplayPresetControl = (props: DisplayPresetControlProps) => {
    const { projectType, solverInputParameter, mainModel, mainView, constantMainModel, comparisonModel, 
        comparisonView, showCurrentComparisonView, constantComparisonModel, onHandleDisplayMode, onRestoreStateIds } = props;
    const ceetronContext = useContext(CeetronContext);
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const classes = useStyles();

    const getDefaultDisplayModeValue = () => {
        return (projectType == ProjectType.Feasibility) && ceetronContext.ceetronState.displayPreset == DisplayPreset.MOLD ?
            DisplayPreset.FREEZE_TIME : ceetronContext.ceetronState.displayPreset;
    };

    const handlePresetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const preset = parseInt(event.target.value) as DisplayPreset;
        ceetronContext.updateCeetronState({ type: CeetronActionType.SetDisplayPreset, payload: preset });
    };

    
    const handleDisplayPreset = (view: cee.View, model: cee.ug.RemoteModel, displayPreset: DisplayPreset, isComparisonModel = false) => {
        let currentView = view;
        let currentMoldConstantRemoteModel = constantMainModel;
        if (isComparisonModel && comparisonView) {
            currentView = comparisonView;
            currentMoldConstantRemoteModel = constantComparisonModel;
        }
        switch (displayPreset) {
            case DisplayPreset.FREEZE_TIME:
                ceetronContext.ceetronState.scalarResults = VtfxScalarResults.FREEZE_TIME_RESULT;
                ceetronContext.updateCeetronState({ type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: true });
                ceetronContext.updateCeetronState({ type: CeetronActionType.SetDisplayModeEnabled, payload: true });
                // v2: swap cug with ug before proceeding as usual
                if (solverInputParameter && getParametersFormatVersion(solverInputParameter) > 1 && currentView && currentMoldConstantRemoteModel) {
                    swapModelAndConstantRemoteModel(false, view, model, isComparisonModel ? constantComparisonModel : constantMainModel, () => {
                        switchToVtfxCase(model, hasFreezeTimeCase(model.modelDirectory) ? VtfxCase.FREEZE_TIME : VtfxCase.PLASTIC_SKIN, () => {
                            createFreezingTimeCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                            if (!isComparisonModel) {
                                ceetronContext.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                            }
                        });
                        ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.FREEZE_TIME });
                    });
                }
                else if (ceetronContext.ceetronState.vtfxCase === VtfxCase.PLASTIC_SKIN) {
                    createFreezingTimeCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                    onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                    if (!isComparisonModel) {
                        ceetronContext.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                    }
                } else {
                    switchToVtfxCase(model, hasFreezeTimeCase(model.modelDirectory) ? VtfxCase.FREEZE_TIME : VtfxCase.PLASTIC_SKIN, () => {
                        createFreezingTimeCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                        onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                        if (!isComparisonModel) {
                            ceetronContext.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                        }
                    });
                    ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.FREEZE_TIME });
                }
                break;
            case DisplayPreset.PLASTIC_SURFACE:
                ceetronContext.ceetronState.scalarResults = VtfxScalarResults.TEMPERATURE_RESULT;
                ceetronContext.updateCeetronState({ type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: true });
                ceetronContext.updateCeetronState({ type: CeetronActionType.SetDisplayModeEnabled, payload: true });
                // v2: swap cug with ug before proceeding as usual
                if (solverInputParameter && getParametersFormatVersion(solverInputParameter) > 1 && currentView && currentMoldConstantRemoteModel) {
                    swapModelAndConstantRemoteModel(false, view, model, isComparisonModel ? constantComparisonModel : constantMainModel, () => {
                        switchToVtfxCase(model, VtfxCase.PLASTIC_SKIN, () => {
                            createSurfaceTemperatureCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                            onRestoreStateIds(model, isComparisonModel ? 1 : 0);
                        });
                        ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.PLASTIC_SKIN });
                    });
                } else {
                    switchToVtfxCase(model, VtfxCase.PLASTIC_SKIN, () => {
                        createSurfaceTemperatureCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                        onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                        onRestoreStateIds(model, isComparisonModel ? 1 : 0);
                    });
                    ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.PLASTIC_SKIN });
                }
                break;
            case DisplayPreset.PLASTIC_VOLUME:
                ceetronContext.ceetronState.scalarResults = VtfxScalarResults.TEMPERATURE_RESULT;
                ceetronContext.updateCeetronState({ type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: true });
                ceetronContext.updateCeetronState({ type: CeetronActionType.SetDisplayModeEnabled, payload: true });
                // v2: swap cug with ug before proceeding as usual
                if (solverInputParameter && getParametersFormatVersion(solverInputParameter) > 1 && currentView && currentMoldConstantRemoteModel) {
                    swapModelAndConstantRemoteModel(false, view, model, isComparisonModel ? constantComparisonModel : constantMainModel, () => {
                        switchToVtfxCase(model, VtfxCase.DEFAULT_VIEW, () => {
                            const vs = setVisibilityByType(ceetronContext.ceetronState.visibility, PartType.PLASTIC, true);
                            vs.skipAction = true;
                            createPlasticVolumeCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            ceetronContext.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                            model.addNextServerUpdateCompletedCallback(() => {
                                onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                            });
                            if (!isComparisonModel) {
                                ceetronContext.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                            }
                            onRestoreStateIds(model, isComparisonModel ? 1 : 0);
                        });
                        ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.DEFAULT_VIEW });
                    });
                } else {
                    if (ceetronContext.ceetronState.vtfxCase === VtfxCase.DEFAULT_VIEW) {
                        const vs = setVisibilityByType(ceetronContext.ceetronState.visibility, PartType.PLASTIC, true);
                        vs.skipAction = true;
                        createPlasticVolumeCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                        ceetronContext.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                        onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                        if (!isComparisonModel) {
                            ceetronContext.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                        }
                    } else {
                        switchToVtfxCase(model, VtfxCase.DEFAULT_VIEW, () => {
                            const vs = setVisibilityByType(ceetronContext.ceetronState.visibility, PartType.PLASTIC, true);
                            vs.skipAction = true;
                            createPlasticVolumeCaseFromModel(model, ceetronContext.ceetronState.vtfxStates, isComparisonModel ? 1 : 0).applyToRemoteModel(model);
                            ceetronContext.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                            onHandleDisplayMode(view, model, ceetronContext.ceetronState.displayMode, isComparisonModel);
                            if (!isComparisonModel) {
                                ceetronContext.updateCeetronState({ type: CeetronActionType.SetScaleUpToDateState, payload: false });
                            }
                            onRestoreStateIds(model, isComparisonModel ? 1 : 0);
                        });
                        ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.DEFAULT_VIEW });
                    }
                }
                break;
            default: // case DisplayPreset.MOLD
                ceetronContext.ceetronState.scalarResults = VtfxScalarResults.TEMPERATURE_RESULT;
                // v2: use cug
                if (solverInputParameter && getParametersFormatVersion(solverInputParameter) > 1 && currentView && currentMoldConstantRemoteModel) {
                    const vs = setVisibilityByType(ceetronContext.ceetronState.visibility, PartType.MOLD, true);
                    vs.skipAction = false;
                    ceetronContext.updateCeetronState({ type: CeetronActionType.SetDisplayMode, payload: DisplayMode.CUTTING_PLANE });
                    ceetronContext.updateCeetronState({ type: isComparisonModel ? CeetronActionType.SetComparisonTimeStepsEnabled : CeetronActionType.SetMainTimeStepsEnabled, payload: false });
                    ceetronContext.updateCeetronState({ type: CeetronActionType.SetDisplayModeEnabled, payload: false });
                    swapModelAndConstantRemoteModel(true, view, model, isComparisonModel ? constantComparisonModel : constantMainModel, () => {
                        view.clipping.removeAllPlanes();
                        if (!isComparisonModel) {
                            ceetronContext.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
                            ceetronContext.updateCeetronState({ type: CeetronActionType.SetVtfxCase, payload: VtfxCase.DEFAULT_VIEW });
                        }
                    });
                }
        }
    }

    useEffect(() => {
        if (!mainModel || !mainView) {
            return;
        } else {
            handleDisplayPreset(mainView, mainModel, ceetronContext.ceetronState.displayPreset);
            if (comparisonModel && comparisonView) {
                handleDisplayPreset(comparisonView, comparisonModel, ceetronContext.ceetronState.displayPreset, true);
            }
        }
    }, [ceetronContext.ceetronState.displayPreset, mainView, mainModel, comparisonView, comparisonModel]);

    return (
        <Box id="results-display-modes">
            <div className={classes.wrapper}>
                <FormControl>
                    <RadioGroup defaultValue={getDefaultDisplayModeValue()} value={ceetronContext.ceetronState.displayPreset} onChange={handlePresetChange} >
                        {(projectType == ProjectType.Design || (authContext.isSupervisor && projectType == ProjectType.Feasibility)) && !comparisonModel 
                            && <FormControlLabel id="mold-display-mode" value={DisplayPreset.MOLD} control={<Radio />} label={t("Mold")} disabled={!ceetronContext.ceetronState.isViewerReady || !constantMainModel || (showCurrentComparisonView && !constantComparisonModel)}/>}
                        {(hasDefaultCase(mainModel.modelDirectory) || constantMainModel) 
                            && <FormControlLabel id="plastic-volume-display-mode" value={DisplayPreset.PLASTIC_VOLUME} control={<Radio />} label={t("Plastic Volume")} disabled={!ceetronContext.ceetronState.isViewerReady} />}
                        {(hasPlasticSkinCase(mainModel.modelDirectory) || constantMainModel) 
                            && <FormControlLabel id="plastic-surface-display-mode" value={DisplayPreset.PLASTIC_SURFACE} control={<Radio />} label={t("Plastic Surface")} disabled={!ceetronContext.ceetronState.isViewerReady} />}
                        {(hasFreezeTimeResults(mainModel.modelDirectory) || constantMainModel) 
                            && <FormControlLabel id="freeze-time-display-mode" value={DisplayPreset.FREEZE_TIME} control={<Radio />} label={t("Freeze Time")} disabled={!ceetronContext.ceetronState.isViewerReady} />}
                    </RadioGroup>
                </FormControl>
            </div>
        </Box>
    )
}