/// <reference path="../@types/hoops/hoops_web_viewer.d.ts"/>
import { ExportedItemGeometricalData, ExportedJobGeometricalData } from "./ExportedJobGeometricalData";
import { Document } from "./OBJ/Document";
import { Face, Faces } from "./OBJ/Face";
import { Vertex, Vertices } from "./OBJ/Vertex";
import { Part } from "../store/job/job-data";
import { MoldCategory } from "../store/job/mold-categories";
import JobData, { Mold, TreeviewFaceGroup, ChannelEnd, SyntheticMeshInfo } from "../store/job/job-data";
import { PartType } from "../store/job/ceetron-context";
import { Channel } from "../store/job/channel";

const x = 0;
const y = 1;
const z = 2;

///
/// @todo:
///     To confirm with TechSoft3D regarding HOOPS Communicator API.
///
///     Our premise is that faces are composed of triangles/triplets vertices,
///     thus for the OBJ formate we decompose the above face group of elements
///     whenever the face already contains 3 vertices. 
///
const faceVertexCompletionCount = 3;

function computeVertice(vertex: Communicator.MeshDataCopyVertex, itemMatrix: Communicator.Matrix) {
    if (itemMatrix.isIdentity())
        return new Vertex(vertex.position[x], vertex.position[y], vertex.position[z]);

    const point = itemMatrix.transform(new Communicator.Point3(vertex.position[x], vertex.position[y], vertex.position[z]));

    return new Vertex(point.x, point.y, point.z);
}

///
/// When a face has reached a given number of vertices then it's identified as being
/// complete. Therefore adding it to to the collection of faces and begin a new face.
///
function addFaceWhenComplete(faces: Faces, face: Face): Face {
    if (face.length() === faceVertexCompletionCount) {
        faces.add(face);
        return new Face();
    }

    return face;
}

async function exportNodeFace(nodeId: number, faceIndex: number, model: Communicator.Model, vertices: Vertices, faces: Faces) {
    const nodeMeshData = await model.getNodeMeshData(nodeId);
    const itemMatrix = model.getNodeNetMatrix(nodeId);
    const element = nodeMeshData.faces.element(faceIndex);
    const vertexIter = element.iterate();
    let face = new Face();

    while (!vertexIter.done()) {
        face.add(vertices.insert(computeVertice(vertexIter.next(), itemMatrix))!);
        face = addFaceWhenComplete(faces, face);
    }

    faces.add(face);
}

async function exportSyntheticFace(config: SyntheticMeshInfo, vertices: Vertices, faces: Faces) {
    let face = new Face()

    for (let i = 0; i < config.vertexes.length - 2; i += 3) {
        const vertex = new Vertex(config.vertexes[i], config.vertexes[i + 1], config.vertexes[i + 2]);

        face.add(vertices.insert(vertex)!);
        face = addFaceWhenComplete(faces, face);
    }

    faces.add(face);
}

function buildDocumentHeader(document: Document,
    category: MoldCategory,
    prefix: string,
    nodeId?: Communicator.NodeId,
    name?: string,
    fullName?: string) {
    document.addComment(`Category: ${MoldCategory[category]}`);
    document.addComment(`Item#: ${prefix}`);
    if (nodeId) document.addComment("Node.Id: " + nodeId.toFixed(0));
    if (name) document.addComment("Node.Name: " + name);
    if (fullName) document.addComment("Node.FullName: " + fullName);
}

async function exportItemGeometricalData(category: MoldCategory,
    prefix: string,
    nodeId: Communicator.NodeId,
    model: Communicator.Model,
    name?: string,
    fullName?: string,
    materialIndex = 1,
    cadFileName?: string | null): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();

        let exchangeId = model.getNodeExchangeId(nodeId);
        return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, nodeId.toFixed(0), name, fullName, exchangeId, materialIndex, cadFileName);
    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. category = ${MoldCategory[category]}, fullName = ${fullName ?? ''}, name = ${name ?? ''}, nodeId = ${nodeId.toFixed(0)}`;
        throw message;
    }
}

async function exportChannelEndGeometricalData(category: MoldCategory,
    prefix: string,
    end: ChannelEnd,
    model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();
        const faces = document.Faces();
        const vertices = document.Vertices();

        if (Channel.isSyntheticFace(end.face)) {
            await exportSyntheticFace(end.face.config, vertices, faces);

            buildDocumentHeader(document, category, prefix, undefined, end.name);

            return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, end.id, end.name);
        } else {
            await exportNodeFace(end.face.nodeId, end.face.faceIndex, model, vertices, faces);

            buildDocumentHeader(document, category, prefix, end.face.nodeId, end.name);

            let exchangeId = model.getNodeExchangeId(end.face.nodeId);
            return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, end.id, end.name, end.path, exchangeId);
        }

    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. category = ${MoldCategory[category]}, prefix = ${prefix}`;
        throw message;
    }
}

async function exportGenericRectangularMoldGeometricalData(category: MoldCategory, nodeId: number, materialIndex: number, model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();
        const nodeMeshData = await model.getNodeMeshData(nodeId);
        const itemMatrix = model.getNodeNetMatrix(nodeId);
        const vertexIter = nodeMeshData.faces.iterate();
        const faces = document.Faces();
        const vertices = document.Vertices();
        let face = new Face();

        while (!vertexIter.done()) {
            face.add(vertices.insert(computeVertice(vertexIter.next(), itemMatrix))!);
            face = addFaceWhenComplete(faces, face);
        }

        faces.add(face);

        buildDocumentHeader(document, category, '0', nodeId, category === MoldCategory.Cavity ? PartType.CAVITY : PartType.CORE);

        let exchangeId = model.getNodeExchangeId(nodeId);
        return new ExportedItemGeometricalData("OBJ",
            document.export(),
            category === MoldCategory.Cavity ? MoldCategory.Cavity : MoldCategory.Core,
            '0',
            nodeId.toFixed(0),
            category === MoldCategory.Cavity ? PartType.CAVITY : PartType.CORE,
            'auto-' + (category === MoldCategory.Cavity ? PartType.CAVITY : PartType.CORE),
            exchangeId,
            materialIndex
        );
    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. Generic rectangular mold.`;
        throw message;
    }
}

async function exportTreeviewFaceGroupGeometricalData
    (category: MoldCategory, prefix: string, faceGroup: TreeviewFaceGroup, model: Communicator.Model): Promise<ExportedItemGeometricalData> {
    try {
        const document = new Document();
        const faces = document.Faces();
        const vertices = document.Vertices();

        for (const displayGroup of faceGroup.config) {
            if (Channel.isSyntheticFace(displayGroup)) {
                await exportSyntheticFace(displayGroup.config, vertices, faces);
            } else {
                await exportNodeFace(displayGroup.nodeId, displayGroup.faceIndex, model, vertices, faces);
            }
        }

        buildDocumentHeader(document, category, prefix, 0);

        return new ExportedItemGeometricalData("OBJ", document.export(), category, prefix, faceGroup.id, faceGroup.name);
    } catch (error) {
        const message = `Failed to export the geometrical data. Reason: ${error}. category = ${MoldCategory[category]}, prefix = ${prefix}`;
        throw message;
    }
}
//TODO: add naming prefix
async function exportPartGeometricalData(category: MoldCategory, 
    item: Part | Mold,
    model: Communicator.Model,
    prefix: string,
    materialIndex = 1): Promise<ExportedItemGeometricalData[]> {
    const geometricalInfoList: ExportedItemGeometricalData[] = [];

    for (const nodeId of item.nodesIds) {
        const geometricalInfo = await exportItemGeometricalData(category, `${prefix}-${nodeId}`, nodeId, model, item.name, item.path, materialIndex, item.cadFileName);
        geometricalInfoList.push(geometricalInfo);
    }
    
    return geometricalInfoList;
}

async function exportChannelGeometricalData(category: MoldCategory,
    item: Channel,
    model: Communicator.Model,
    prefix: string) {
    const geometricalInfoList: ExportedItemGeometricalData[] = [];

    let bodyIndex = 0;
    for (const body of item.bodies) {
        if (Channel.isTreeviewFaceGroup(body)) {
            const geometricalInfo = await exportTreeviewFaceGroupGeometricalData(category, `${prefix}-body-${bodyIndex}`, body, model);
            geometricalInfoList.push(geometricalInfo);
        }
        if (Channel.isPart(body)) {
            for (const nodeId of body.nodesIds) {
                const geometricalInfo = await exportItemGeometricalData(category, `${prefix}-body-${bodyIndex}`, nodeId, model, body.name, body.path, undefined, body.cadFileName);
                geometricalInfoList.push(geometricalInfo);
            }
        }

        bodyIndex++;
    }

    const inletPromises = item.inlets.map(async (inlet, inletIndex) => {
        const geometricalInfo = await exportChannelEndGeometricalData(category, `${prefix}-inlet-${inletIndex}`, inlet, model);
        geometricalInfoList.push(geometricalInfo);
    });

    const outletPromises = item.outlets.map(async (outlet, outletIndex) => {
        const geometricalInfo = await exportChannelEndGeometricalData(category, `${prefix}-outlet-${outletIndex}`, outlet, model);
        geometricalInfoList.push(geometricalInfo);
    });

    let baffleIndex = 0;
    for (const baffle of item.baffles) {
        const geometricalInfo = await exportTreeviewFaceGroupGeometricalData(category, `${prefix}-baffle-${baffleIndex++}`, baffle, model);
        geometricalInfoList.push(geometricalInfo);
    }

    await Promise.all([...inletPromises, ...outletPromises]);

    return geometricalInfoList;
}

async function exportMoldGeometricalData(category: MoldCategory,
    item: Mold,
    model: Communicator.Model,
    prefix: string) {
    const geometricalInfoList: ExportedItemGeometricalData[] = [];

    const bodiesPromises = item.bodies.map(async (body, bodyIndex) => {
        body.nodesIds.forEach(async (nodeId , nodeIndex) => {
            const geometricalInfo = await exportItemGeometricalData(category, `${prefix}-body-${bodyIndex}-node-${nodeIndex}`, nodeId, model, body.name, body.path, undefined, body.cadFileName);
            geometricalInfoList.push(geometricalInfo);
        });
    });

    await Promise.all([...bodiesPromises]);

    return geometricalInfoList;
}

async function exportSectionGeometricalData(category: MoldCategory,
                                            items: (Part|Channel|Mold)[],
                                            model: Communicator.Model,
                                            materialIndex = 1): Promise<ExportedItemGeometricalData[]> {

    return (await Promise.all(items.map(async (i, index) => {
        if (category == MoldCategory.Channel) {
            return await exportChannelGeometricalData(category, i as Channel, model, index.toFixed(0))
        } else if (category == MoldCategory.Mold) {
            return await exportMoldGeometricalData(category, i as Mold, model, index.toFixed(0))
        } else {
            return await exportPartGeometricalData(category, i, model, index.toFixed(0), materialIndex)
        }
    }))).flat();
}

export async function exportJobGeometricalData(jobContext: JobData,
    model: Communicator.Model): Promise<ExportedJobGeometricalData> {
    const categories = jobContext.Categories;

    let jobGeometricalData = new ExportedJobGeometricalData();

    if (jobContext.basicMold.enabled) {
        jobGeometricalData.Molds = [
            await exportGenericRectangularMoldGeometricalData(MoldCategory.Cavity, jobContext.basicMold.nodesIds[0], 1, model),
            await exportGenericRectangularMoldGeometricalData(MoldCategory.Core, jobContext.basicMold.nodesIds[1], 1, model)
        ];
    } else {
        jobGeometricalData.Molds = await exportSectionGeometricalData(MoldCategory.Mold, categories.Mold, model, 1);
    }
    jobGeometricalData.Channels = await exportSectionGeometricalData(MoldCategory.Channel, categories.Channel, model, 1);
    jobGeometricalData.Parts = await exportSectionGeometricalData(MoldCategory.Part, categories.Part, model, 1);

    if (categories.Runner && categories.Runner.length > 0)
        jobGeometricalData.Parts = jobGeometricalData.Parts.concat(await exportSectionGeometricalData(MoldCategory.Runner, categories.Runner, model, 2));

    return jobGeometricalData;
}