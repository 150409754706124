export const objGeometryScalingFactor = 1/1000; // solver has a built-in 1000x scaling factor.

export interface ObjMeshFace {
    vertices: number[];
    vertexNormals: number[];
}

// This function assumes no UVs data, only parses face as v//vn
export function parseObjToGeometryData(obj: string) {
    const errors: Error[] = [];
    const verticesArray: cee.Vec3Like[] = [];
    const normalsArray: cee.Vec3Like[] = [];
    const meshesArray: ObjMeshFace[][] = [];
    let currentMesh: ObjMeshFace[] = [];
    const gRegexp = /g\s[a-zA-Z0-9]*/;
    const vRegexp = /v\s+(-?[0-9]+\.?[0-9e\-]*)\s(-?[0-9]+\.?[0-9e\-]*)\s(-?[0-9]+\.?[0-9e\-]*)/;
    const vnRegexp = /vn\s(-?[0-9]+\.?[0-9e\-]*)\s(-?[0-9]+\.?[0-9e\-]*)\s(-?[0-9]+\.?[0-9e\-]*)/;
    const fnRegexp = /f\s\s(-?[0-9]+\.?[0-9e\-]*)\/\/(-?[0-9]+\.?[0-9e\-]*)\s(-?[0-9]+\.?[0-9e\-]*)\/\/(-?[0-9]+\.?[0-9]*)\s(-?[0-9]+\.?[0-9]*)\/\/(-?[0-9]+\.?[0-9]*)/;
    const fRegexp = /f\s+(\d+)\s+(\d+)\s+(\d+)/;
    const lines = obj.split('\n');

    lines.forEach((line, lineIndex) => {
        const foundMeshes = line.match(gRegexp);
        const foundVertices = line.match(vRegexp);
        const foundNormals = line.match(vnRegexp);
        const foundFacesWithNormals = line.match(fnRegexp);
        const foundFacesWithoutNormals = line.match(fRegexp);
        if (((foundMeshes && foundMeshes.length === 1) || (lineIndex === lines.length - 1)) && currentMesh.length > 0) {
            meshesArray.push(currentMesh.slice());
            currentMesh = [];
        } else if (foundVertices && foundVertices.length === 4) {
            verticesArray.push({
                x: parseFloat(foundVertices[1]), y: parseFloat(foundVertices[2]), z: parseFloat(foundVertices[3])
            });
        } else if (foundNormals && foundNormals.length === 4) {
            normalsArray.push({
                x: parseFloat(foundNormals[1]), y: parseFloat(foundNormals[2]), z: parseFloat(foundNormals[3])
            });
        } else if (foundFacesWithNormals && foundFacesWithNormals.length === 7) {
            const v1 = verticesArray[parseInt(foundFacesWithNormals[1]) - 1];
            const v2 = verticesArray[parseInt(foundFacesWithNormals[3]) - 1];
            const v3 = verticesArray[parseInt(foundFacesWithNormals[5]) - 1];
            const vn1 = normalsArray[parseInt(foundFacesWithNormals[2]) - 1];
            const vn2 = normalsArray[parseInt(foundFacesWithNormals[4]) - 1];
            const vn3 = normalsArray[parseInt(foundFacesWithNormals[6]) - 1];
            if (v1 && v2 && v3 && vn1 && vn2 && vn3) {
                currentMesh.push({
                    vertices: [v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v3.x, v3.y, v3.z],
                    vertexNormals: [vn1.x, vn1.y, vn1.z, vn2.x, vn2.y, vn2.z, vn3.x, vn3.y, vn3.z]
                });
            } else {
                errors.push(new Error('OBJ parsing error at line: ' + foundFacesWithNormals[0]));
            }
        } else if (foundFacesWithoutNormals && foundFacesWithoutNormals.length === 4) {
            const v1 = verticesArray[parseInt(foundFacesWithoutNormals[1]) - 1];
            const v2 = verticesArray[parseInt(foundFacesWithoutNormals[2]) - 1];
            const v3 = verticesArray[parseInt(foundFacesWithoutNormals[3]) - 1];

            if (v1 && v2 && v3) {
                currentMesh.push({
                    vertices: [v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v3.x, v3.y, v3.z],
                    vertexNormals: []
                });
            } else {
                errors.push(new Error('OBJ parsing error at line: ' + foundFacesWithoutNormals[0]));
            }
        }
    });
    
    return {
        v: verticesArray,
        vn: normalsArray,
        m: meshesArray,
        e: errors
    }
}

