import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

const languages = ["en", "fr"];

i18n.use(initReactI18next).use(LanguageDetector).init({
  detection: { order: ["path", "navigator"] },
  fallbackLng: 'en',
  //lng: 'en',
  whitelist: languages,
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    fr: {
      translations: require('./locales/fr/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'fr'];

export default i18n;