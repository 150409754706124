import axios from "axios";

const PaymentService = {
    CreatePaymentLink: async (priceId: string) => {
        try {
            const response = await axios.post('/api/payment/paymentLink?priceId=' + priceId);
            return response.data;
        } catch {
            throw new Error('Error creating payment link for selected product');
        }
    },
    BuyProduct: async (priceId: string) => {
        try {
            const response = await axios.post('/api/payment/BuyProduct?priceId=' + priceId);
            return response.data;
        } catch {
            throw new Error('Error creating checkout session for selected product');
        }
    },
    SubscribeProduct: async (priceId: string) => {
        try {
            const response = await axios.post('/api/payment/SubscribeProduct?priceId=' + priceId);
            return response.data;
        } catch {
            throw new Error('Error creating checkout session for selected product');
        }
    },
    CreatePortalSession: async () => {
        try {
            const response = await axios.post('/api/payment/CreatePortalSession');
            return response.data;
        } catch {
            throw new Error('Error creating portal session');
        }
    },
    GetActiveSubscriptions: async () => {
        try {
            const response = await axios.get('/api/payment/GetActiveSubscriptions');
            return response.data;
        } catch {
            throw new Error('Error getting subscriptions');
        }
    },
    cancelSubscription: async (subscriptionId: string) => {
        try {
            const config = { headers: {'Content-Type': 'application/json'} };

            const response = await axios.post('/api/payment/CancelSubscription', subscriptionId, config);
            return response.data;
        } catch {
            throw new Error('Error canceling subscription');
        }
    }
}

export default PaymentService;