import axios from "axios";
import { AccountConfiguration, AccountUsersConfiguration, CreditsAccount } from "../store/creditsAccount/CreditsAccountContext";

const CreditsAccountService = {
    GetCreditsAccount: async (): Promise<CreditsAccount> => {
        try {
            const response = await axios.get('/api/CreditsAccount/GetCreditsAccount');

            const account: CreditsAccount = response.data;
            const creditsAccountConfigJson = account.accountConfiguration as unknown as string;
            if(creditsAccountConfigJson){
                const creditsAccountConfig: AccountConfiguration = JSON.parse(creditsAccountConfigJson);
                account.accountConfiguration = creditsAccountConfig;
            }

            return account; 
        } catch(e) {
            throw new Error('Error getting account balance');
        }
    },
    GetAllUsers: async () => {
        try {
            const response = await axios.get('/api/CreditsAccount/Users/');
            return response.data;
        } catch {
            throw new Error('Error getting company users');
        }
    },
    RemoveUserFromCreditsAccount: async (userId: string) => {
        try {
            const response = await axios.delete(`/api/CreditsAccount/Users/${userId}`);
            return response.data;
        } catch {
            throw new Error('Error removing user from company');
        }
    },
    UpdateUsersConfiguration: async (data: AccountUsersConfiguration) => {
        try {
            const response = await axios.put(`/api/CreditsAccount/UsersConfiguration/`, data);
            return response.data;
        } catch {
            throw new Error('Error updating account users configuration');
        }
    }
}

export default CreditsAccountService;