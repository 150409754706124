import React, { PropsWithChildren, useEffect, useReducer, useState } from 'react';
import ConfigurationService, { ApplicationConfiguration } from '../../config/ConfigurationService';
import ConfigurationOptionsService from '../../services/ConfigurationOptionsService';

export type ConfigContextProviderProps = {
    configurationService: ConfigurationService;
}

type GlobalConfig = {
    configLoaded: boolean,
    configuration: ApplicationConfiguration | null,
    supportEmail: string | null
}

type ConfigAction = {
    type: string,
    data: any
}

export const ConfigurationContext = React.createContext<GlobalConfig | undefined>(undefined);

const reducerFn = (state: GlobalConfig, action: ConfigAction) => {

    if (action.type === 'SET_INITIAL_CONFIG_DATA') {

        return {
            ...state,
            supportEmail: action.data.supportEmail,
            configLoaded: true
        };
    }

    return {...state};
}

export const ConfigContextProvider = (props: PropsWithChildren<ConfigContextProviderProps>) => {
    const initialState: GlobalConfig = {
        configLoaded: false,
        configuration: props.configurationService.configuration,
        supportEmail: null
    }

    const [globalConfig, dispatch] = useReducer(reducerFn, initialState);

    const loadInitialConfig = async () => {
        const supportEmailData = await ConfigurationOptionsService.GetSupportEmail();
    
        dispatch({type: "SET_INITIAL_CONFIG_DATA", data: 
        {
            supportEmail: supportEmailData
        }});
    }

    useEffect(() => {
        loadInitialConfig();
    },[])

    return (
        <ConfigurationContext.Provider value={globalConfig}>
            {globalConfig.configLoaded && props.children}
        </ConfigurationContext.Provider>
    );
};

export default ConfigurationContext;