import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { useState, useEffect, useContext } from "react";
import { AxisSelector } from "./AxisSelector";
import makeStyles from '@mui/styles/makeStyles';
import { CeetronActionType, CeetronContext } from "../../store/job/ceetron-context";
import theme from "../../styles/main-theme";
import { createTheme, Slider } from "@mui/material";
import { ThemeProvider } from '@emotion/react';
import { updateClippingPlanePosition } from "../../utils/clippingPlane.utils";
import { ProjectType } from "../../store/project/project-data";

interface CuttingPlaneProps {
    view: cee.View;
    projectType: ProjectType;
    cuttingPlane: cee.ug.CuttingPlane;
    model: cee.ug.RemoteModel;
    comparisonView?: cee.View;
    comparisonModel?: cee.ug.RemoteModel;
    comparisonCuttingPlane?: cee.ug.CuttingPlane;
    updateCuttingPlane: Function;
}

const useStyles = makeStyles({
    headers: {
        color: theme.palette.info.light,
        marginTop: "15px"
    },
    wrapper: {
        padding: "5px 20px 8px 20px",
        borderBottom: "1px solid"
    }
});

const customTheme = createTheme({
    components: {
        MuiSlider: {
            styleOverrides: {
                valueLabel: {
                    backgroundColor: theme.palette.primary.main
                },
                thumb: {
                    color: theme.palette.primary.main,
                    "&:hover, &.Mui-focusVisible": {
                        boxShadow: `0 0 0 8px ${theme.palette.primary.light}`,
                    }
                },
                rail: {
                    color: theme.palette.primary.light
                },
                track: {
                    color: theme.palette.primary.light
                }
            }
        }
    }
});

export const CuttingPlane = (props: CuttingPlaneProps) => {
    const [position, setPosition] = useState<number>(0);
    const [previewCuttingPlaneVisibility, setPreviewCuttingPlaneVisibility] = useState<boolean>(false);
    const context = useContext(CeetronContext);
    const classes = useStyles();
    const { view, cuttingPlane, model, comparisonView, comparisonModel, comparisonCuttingPlane, updateCuttingPlane } = props;
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(0);

    useEffect(() => {
        // Prepare for new cutting plane
        if (!cuttingPlane) return;
        cuttingPlane.computeFromVisibleParts = true;
        cuttingPlane.clipping = true;
        if (!comparisonCuttingPlane) return;
        comparisonCuttingPlane.computeFromVisibleParts = cuttingPlane.computeFromVisibleParts;
        comparisonCuttingPlane.clipping = true;
        // Disable warning that we're missing dependency 'updateRelativePositionFromCuttingPlane'
        // eslint-disable-next-line
    }, [cuttingPlane]);

    useEffect(() => {
        if (!comparisonCuttingPlane) return;
        comparisonCuttingPlane.computeFromVisibleParts = cuttingPlane.computeFromVisibleParts;
        comparisonCuttingPlane.clipping = true;
    }, [comparisonCuttingPlane]);

    useEffect(() => {
        updatePositionFromCuttingPlane(context.ceetronState.cuttingPlanePosition);
    }, [context.ceetronState.cuttingPlanePosition])

    function handlePositionChangeCommitted(position: number) {
        setPreviewCuttingPlaneVisibility(false);
        context.updateCeetronState({ type: CeetronActionType.SetCuttingPlanePosition, payload: position });

        //For channel bodies
        updateClippingPlane(position, context.ceetronState.cuttingPlaneNormal);
    }

    function updateClippingPlane(position: number, normal: cee.Vec3Like){
        //Clipping planes for channel bodies
        updateClippingPlanePosition(view, model, position, normal);

        if(comparisonModel && comparisonView){
            updateClippingPlanePosition(comparisonView, comparisonModel, position, normal);
        }
    }

    function handlePositionChange(position: number) {
        view.clipping.removeAllPlanes();
        if(comparisonView){
            comparisonView.clipping.removeAllPlanes();
        }

        setPosition(position);
        setPreviewCuttingPlaneVisibility(true);
    }

    function updatePositionFromCuttingPlane(position: number) {
        setPosition(context.ceetronState.cuttingPlanePosition);
        cuttingPlane.point = updateCuttingPlane(cuttingPlane, context.ceetronState.cuttingPlaneNormal, position);
        if (comparisonCuttingPlane && comparisonModel) {
            comparisonCuttingPlane.point = updateCuttingPlane(comparisonCuttingPlane, context.ceetronState.cuttingPlaneNormal, position);
        }
    }

    useEffect(() => {
        if (!model) {
            return;
        }
        const modelBoundingBox = model.getBoundingBox();
        const normal = context.ceetronState.cuttingPlaneNormal;
        if (normal.x == 1 || normal.x == -1) {
            setMaxValue(modelBoundingBox.maximum.x);
            setMinValue(modelBoundingBox.minimum.x);
        } else if (normal.y == 1 || normal.y == -1) {
            setMaxValue(modelBoundingBox.maximum.y);
            setMinValue(modelBoundingBox.minimum.y);
        } else {
            setMaxValue(modelBoundingBox.maximum.z);
            setMinValue(modelBoundingBox.minimum.z);
        }
        if (comparisonModel) {
            const comparisonModelBoundingBox = comparisonModel.getBoundingBox();
            if (normal.x == 1 || normal.x == -1) {
                setMaxValue(Math.max(modelBoundingBox.maximum.x, comparisonModelBoundingBox.maximum.x));
                setMinValue(Math.min(modelBoundingBox.minimum.x, comparisonModelBoundingBox.minimum.x));
            } else if (normal.y == 1 || normal.y == -1) {
                setMaxValue(Math.max(modelBoundingBox.maximum.y, comparisonModelBoundingBox.maximum.y));
                setMinValue(Math.min(modelBoundingBox.minimum.y, comparisonModelBoundingBox.minimum.y));
            } else {
                setMaxValue(Math.max(modelBoundingBox.maximum.z, comparisonModelBoundingBox.maximum.z));
                setMinValue(Math.min(modelBoundingBox.minimum.z, comparisonModelBoundingBox.minimum.z));
            }
        }
    }, [context.ceetronState.displayPreset, context.ceetronState.cuttingPlaneNormal, comparisonModel])

    function updatePreviewCuttingPlane(gridModel: cee.usg.UnstructGridModel, view: cee.View, model: cee.ug.RemoteModel, cuttingPlane: cee.ug.CuttingPlane) {
        view.addModel(gridModel);
        let geometry = new cee.usg.Geometry();
        let part = geometry.addPart();

        // modelboundingbox size is used to resize gridModel
        const modelBoundingBox = model.getBoundingBox();

        const absolutePosition = updateCuttingPlane(cuttingPlane, context.ceetronState.cuttingPlaneNormal, position);

        // Create default zplane single quad mesh
        const scalingPreviewPlane = 0.020;
        let nodeValue = scalingPreviewPlane + Math.max(modelBoundingBox.maximum.x, modelBoundingBox.maximum.y, modelBoundingBox.maximum.z); //0;                 
        let nodeArray = [];
        // translationMatrixPerIndex is for in which direction model should move
        let translationMatrixPerIndex;
        if (context.ceetronState.cuttingPlaneNormal.x == 1 || context.ceetronState.cuttingPlaneNormal.x == -1) {
            nodeArray = [0, -nodeValue, nodeValue, 0, -nodeValue, -nodeValue, 0, nodeValue, -nodeValue, 0, nodeValue, nodeValue]
            translationMatrixPerIndex = cee.Mat4.fromTranslation(new cee.Vec3(absolutePosition.x, 0, 0));
        } else if (context.ceetronState.cuttingPlaneNormal.y == 1 || context.ceetronState.cuttingPlaneNormal.y == -1) {
            nodeArray = [-nodeValue, 0, nodeValue, nodeValue, 0, nodeValue, nodeValue, 0, -nodeValue, -nodeValue, 0, -nodeValue]
            translationMatrixPerIndex = cee.Mat4.fromTranslation(new cee.Vec3(0, absolutePosition.y, 0));
        } else {
            nodeArray = [-nodeValue, -nodeValue, 0, nodeValue, -nodeValue, 0, nodeValue, nodeValue, 0, -nodeValue, nodeValue, 0];
            translationMatrixPerIndex = cee.Mat4.fromTranslation(new cee.Vec3(0, 0, absolutePosition.z));
        }
        const elConnArr = [0, 1, 2, 3];
        part.mesh = new cee.usg.Mesh(nodeArray, 4, elConnArr);

        // Configure the visual appearance of the part
        part.settings.color = new cee.Color3(0, 0, 0);
        part.settings.opacity = 0.2;
        part.settings.visible = previewCuttingPlaneVisibility;
        // Create a state, set the geometry and add the scalar result
        let state = gridModel.addState();
        state.geometry = geometry;
        // Transform the part
        state.setPartTransformationAt(0, translationMatrixPerIndex);
    }

    useEffect(() => {
        if (!view || !model) {
            return;
        }
        let mainGridModel = new cee.usg.UnstructGridModel();
        updatePreviewCuttingPlane(mainGridModel, view, model, cuttingPlane);
        let comparisonGridModel = new cee.usg.UnstructGridModel();
        if (comparisonView && comparisonModel && comparisonCuttingPlane) {
            updatePreviewCuttingPlane(comparisonGridModel, comparisonView, comparisonModel, comparisonCuttingPlane);
        }
        return () => {
            view.removeModel(mainGridModel);
            if (comparisonView) {
                comparisonView.removeModel(comparisonGridModel);
            }
        };
    }, [position, updatePositionFromCuttingPlane, context.ceetronState.cuttingPlanePosition, context.ceetronState.displayPreset, context.ceetronState.cuttingPlaneNormal]);

    const valueLabelFormat = (v: number) => {
        if (v === 0 || v % 1 === 0) {
            return v.toFixed(0);
        } else {
            return v.toFixed(3);
        }
    };

    const handleClipPlasticPart = (isClipping: boolean) => {
        updateClippingPlane(context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
    }

    return (
        <div className={classes.wrapper}>
            <AxisSelector cuttingPlane={cuttingPlane} projectType={props.projectType} comparisonCuttingPlane={comparisonCuttingPlane} handleClipPlasticPart={handleClipPlasticPart}/>
            <ThemeProvider theme={customTheme}>
                <Slider
                    id="cutplane-slider"
                    style={{ marginTop: "45px", marginLeft: "15px", width: "19em" }}
                    value={position}
                    valueLabelDisplay="on"
                    aria-labelledby="radius-slider"
                    onChange={(event: any, value: any) => handlePositionChange(value)}
                    onChangeCommitted={(event: any, value: any) => handlePositionChangeCommitted(value)}
                    color="secondary"
                    step={0.001}
                    min={minValue}
                    max={maxValue}
                    disabled={!context.ceetronState.isViewerReady}
                    valueLabelFormat={valueLabelFormat}
                />
            </ThemeProvider>
        </div>
    )
}