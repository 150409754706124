import { Box, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import Iteration from '../../store/project/iteration';
import IterationListItem from './IterationListItem';

export interface IterationsListProps {
    iterations: Iteration[];
    onJobClick: (version: Iteration) => void;
    onJobDetailsClick?: (iteration: Iteration) => void;
    compact: boolean;
    activeIteration?: string
    onJobCompareClick?: (iteration: Iteration) => void;
    onJobCancel: (projectId: string, jobId: string) => void;
}

const IterationsList = (props: IterationsListProps) => {

    const [sortedIterations, setSortedIterations] = useState<Iteration[]>([]);

    useEffect(() => {
        setSortedIterations(props.iterations);
    }, [props.iterations]);

    const onJobCancel = (projectId: string, jobId: string) => {
        props.onJobCancel(projectId, jobId)
    }

    return <CardContent sx={{ padding: '0 !important', margin: 0 }} role='feed' aria-label='iteration-list'>         
        {sortedIterations.map(iteration =>
            <Box key={iteration.id} sx={{ m: 1 }}>
                <IterationListItem
                    iteration={iteration}
                    onClick={props.onJobClick}
                    onDetailsClick={props.onJobDetailsClick}
                    onCompareClick={props.onJobCompareClick}
                    compact={props.compact}
                    active={props.activeIteration === iteration.id}
                    onJobCancel={onJobCancel}
                />
            </Box>
        )}
    </CardContent>
}

export default IterationsList;