export const EOL = '\n';

export function stringify(value?: string) : string {
    return value ?? '';
}

///
/// Determine if the given text is blank removal of whitespaces.
///
export function isBlank(text?: string) : boolean {
    const trimmedText = text?.trim() ?? '';
    
    return (trimmedText === '');
}

export function writeLine(line?: string) : string {
    return isBlank(line) ? '' : `${line?.trimEnd()}${EOL}`;
}

export function round(value: number, precision: number = 10) : number {
    const exponent = Math.pow(10,precision);
    return Math.round((value + Number.EPSILON) * exponent) / exponent;
}

export function toPrecision(value: number, precision: number = 10) : string {
    //return parseFloat(round(value, precision).toPrecision(precision)).toString();
    return value.toFixed(precision).replace(/\.?0+$/,"");
}