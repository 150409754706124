import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material/styles';
import AuthenticationService from './auth/AuthenticationService';
import ConfigurationService from './config/ConfigurationService';
import './i18n/config';

declare global {
    interface Window {
        userGuiding:any;
    }
}

const loadApplication = (authenticationService: AuthenticationService, configurationService: ConfigurationService) => {
    const rootElement = document.getElementById('root');   

    if (!rootElement) {
        throw new Error('Failed to find the root element');
    }
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <StyledEngineProvider injectFirst>
            <App authenticationService={authenticationService} configurationService={configurationService} />
        </StyledEngineProvider>
    );
};

window.onload = async () => {
    try {
        const configurationService = new ConfigurationService()
        await configurationService.setupConfig();

        const authenticationService = new AuthenticationService()
        await authenticationService.setup(configurationService);

        await authenticationService.initInterceptors();
        loadApplication(authenticationService, configurationService);
        
    } catch (error) {
        console.error('Error while loading configuration and initializing authentication');
        console.error(`${(error as { stack: unknown }).stack || error}`);
    }
};
