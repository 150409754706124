import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../styles/main-theme";
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';

type InfoDialogPropsWithMarkup = {
    open: boolean;
    title: string;
    children?: ReactElement
    onClose: () => void
}

type InfoDialogPropsWithText = {
    open: boolean;
    title: string;
    message?: string;
    onClose: () => void
}

const InfoDialog = (props: InfoDialogPropsWithMarkup | InfoDialogPropsWithText) => {
    const { t } = useTranslation();
    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    function hasMessage(props: any): props is InfoDialogPropsWithText {
        return props.message !== undefined;
    }

    function hasMarkup(props: any): props is InfoDialogPropsWithMarkup {
        return props.children !== undefined;
    }

    return (
        <Dialog id="info-dialog" open={props.open} onClose={() => props.onClose()} aria-label={t("Info Dialog")}>
            <DialogTitle sx={{ color: theme.palette.primary.dark }}>{props.title}</DialogTitle>
            <DialogContent>
                {hasMessage(props) && <DialogContentText>
                    {props.message}
                </DialogContentText>}
                {hasMarkup(props) && props.children}
            </DialogContent>
            <DialogActions>
                <Button className={classes.btn} onClick={(e) => {
                    e.stopPropagation();
                    props.onClose();
                }}>{t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default InfoDialog;