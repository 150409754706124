import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Card, CircularProgress, Divider, Fade, FormControl, FormControlLabel, Radio, RadioGroup, Typography, useTheme, Switch } from "@mui/material"
import { TemperatureUnit, Language, UiSettingsActionType, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext"
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from "react"
import { UiSettingsService } from "../../services/UiSettingsService"
import AuthContext from "../../auth/AuthenticationContext"
import { makeStyles } from "@mui/styles"
import { ProjectType } from "../../store/project/project-data";

export interface UISettingsModalProps{
    open: boolean,
    onClose: () => void
}

export const UISettingsModal = (props: UISettingsModalProps) => {
    const theme = useTheme();
    const uiSettingsContext = useContext(UiSettingsContext);    
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [temperatureUnit, setTemperatureUnit] = useState(uiSettingsContext.uiSettings.temperatureUnit);
    const [language, setLanguage] = useState(uiSettingsContext.uiSettings.language);
    const [projectType, setProjectType] = useState(
        (uiSettingsContext.uiSettings.projectType == ProjectType.Feasibility) ? uiSettingsContext.uiSettings.projectType: ProjectType.Design);
    const [displayTipsAndTricks, setDisplayTipsAndTricks] = useState<boolean>(uiSettingsContext.uiSettings.displayTipsAndTricks);
    const { i18n } = useTranslation();

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    const resetState = () => {
        setTemperatureUnit(uiSettingsContext.uiSettings.temperatureUnit);
        setLanguage(uiSettingsContext.uiSettings.language);
        (uiSettingsContext.uiSettings.projectType == ProjectType.Feasibility) && setProjectType(uiSettingsContext.uiSettings.projectType);
        setDisplayTipsAndTricks(uiSettingsContext.uiSettings.displayTipsAndTricks);
    }

    const cancel = () => {
        resetState();
        props.onClose();
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        setTemperatureUnit(uiSettingsContext.uiSettings.temperatureUnit);
        (uiSettingsContext.uiSettings.projectType == ProjectType.Feasibility) && setProjectType(uiSettingsContext.uiSettings.projectType);
        setLanguage(uiSettingsContext.uiSettings.language)
        setDisplayTipsAndTricks(uiSettingsContext.uiSettings.displayTipsAndTricks)
    }, [uiSettingsContext]);

    const loadData = async () => {
        setLoading(true);
        uiSettingsContext.dispatch({type: UiSettingsActionType.LOAD_SETTINGS, data: await UiSettingsService.Get()});
        setLoading(false);
    }

    const save = async () => {
        setLoading(true);
        const data = {temperatureUnit, projectType, language, displayTipsAndTricks};
        await UiSettingsService.Set(data).then(() => {
            uiSettingsContext.dispatch({type: UiSettingsActionType.SAVE_SETTINGS, data});
        }, () => {
            setOpenErrorDialog(true);
        });
        i18n.changeLanguage(language);
        setLoading(false);
        props.onClose();
    }

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={props.open}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{t("Settings")}</DialogTitle>
            <DialogContent dividers={true}>
                <Fade in={loading} unmountOnExit>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: "0", right: "0", top: "0", bottom: "0"}}>
                        <CircularProgress />
                    </Box>
                </Fade>
                {authContext.isSupervisor && <Box sx={{mb: "15px"}}>
                    <Typography sx={{color: theme.palette.primary.dark}}>{t("Prefered Temperature Unit")}:</Typography>
                    <FormControl>
                        <RadioGroup row value={temperatureUnit} onChange={(e) => setTemperatureUnit(e.target.value as TemperatureUnit)}>
                            <FormControlLabel id="ui-settings-temperature-unit-c" value={TemperatureUnit.C} control={<Radio />} label={TemperatureUnit.C} />
                            <FormControlLabel id="ui-settings-temperature-unit-f" value={TemperatureUnit.F} control={<Radio />} label={TemperatureUnit.F} />
                        </RadioGroup>
                    </FormControl>
                </Box>}
                <Box sx={{mb: "15px"}}>
                    <Typography sx={{color: theme.palette.primary.dark}}>{t("Display Tips and Tricks")}:</Typography>
                    <FormControl>
                        <Switch checked={displayTipsAndTricks} onChange={(e) => {setDisplayTipsAndTricks(e.target.checked)}} />
                    </FormControl>
                </Box>
                {authContext.isSupervisor && 
                    <Box sx={{mb: "15px"}}>
                        <Typography sx={{color: theme.palette.primary.dark}}>{t("Language")}:</Typography>
                        <FormControl>
                            <RadioGroup row value={language} onChange={(e) => setLanguage(e.target.value as Language)}>
                                <FormControlLabel id="ui-settings-lang-en" value={Language.en} control={<Radio />} label='English' />
                                <FormControlLabel id="ui-settings-lang-fr" value={Language.fr} control={<Radio />} label='Français' />                           
                            </RadioGroup>
                        </FormControl>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
            <Button className={classes.btn} color="primary" onClick={save}>
                   {t("Save")}
                </Button>
                <Button className={classes.btn} color="primary" onClick={cancel}>
                {t("Cancel")}
                </Button>            
            </DialogActions>
        </Dialog>
    )
}