import { useContext, useEffect, useRef, useState } from "react";
import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { CeetronScene } from "../CeetronScene";
import { Box, Grid, Typography, Button, Tooltip } from "@mui/material";
import JobProjectSwitcher from "../JobProjectSwitcher";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import JobPage from "../JobPage";
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset, PartType } from "../../../store/job/ceetron-context";
import Iteration, { getIterationMaterialEjectionTemperature } from "../../../store/project/iteration";
import IterationInfoPanel from "../IterationInfoPanel";
import routes from "../../../router/routes";
import queryErrorHandler, { vizServerErrorHandler } from "../../../react-query/queryErrorHandler";
import makeStyles from "@mui/styles/makeStyles";
import { Socket } from "socket.io-client";
import theme from "../../../styles/main-theme";
import FileManagementService from "../../../services/FileManagementService";
import { MutableRefObject } from "react";
import { PickInfoPanel } from "./PickInfoPanel";
import { UiSettingsContext } from "../../../store/uiSettings/UiSettingsContext";
import JobService from "../../../services/JobService";
import { generateGeoModelFromChannelBodiesData } from "../../../utils/geo.utils";
import AuthContext from '../../../auth/AuthenticationContext';
import { truncate } from "../../../utils/formatting";
import CloseIcon from '@mui/icons-material/Close';
import ProjectService from "../../../services/ProjectsService";
import { getParametersFormatVersion, JobChannelsBodiesData, JobSolveInputsParameters } from "../../../store/job/job-data";
import { TipsAndTricksHelper } from "../../../services/TipsAndTricksHelper";
import { ProjectType } from "../../../store/project/project-data";
import { useTranslation } from 'react-i18next';
import { Id, toast } from "react-toastify";
import { linkCameraPosition, matchCameraPosition, transferCameraSettings, unlinkCameraPosition } from "../utils/ceetron.utils";
import { findDefaultTimeStepIndex, getScalarResultByName, getVisibilityState, setModelLegendsTitle, setTemperatureResultsSettings, setVisibilityByType } from "../utils/ceetron.utils";
import { createRemoteModel, disconnectSocketAndClearModel } from "../../../services/CeetronService";
import { ColorLegendSettingsDialog } from "./ColorLegendSettingsDialog";
import { CeetroneSceneControl } from "./CeetronSceneControl";
import { PickInfo } from "../../../store/job/pick-info";
import PostprocessingConfigService from "../../../services/PostprocessingConfigService";

interface JobResultProps {
    resultPath: string,
    iteration: Iteration
}

const useStyles = makeStyles({
    headers: {
        marginTop: "15px"
    },
    wrapper: {
        padding: "5px 8px 8px 12px",
        borderBottom: "1px solid"
    },
    container: {
        padding: "0px 20px 20px 0px",
        borderBottom: "1px solid"
    },
    titleCompare: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.5em',
        fontWeight: '400'
    },
    closeCompare: {
        left: '50%',
        transform: 'translateX(-50%)',
        textTransform: 'none',
        borderRadius: '1em',
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        paddingLeft: "1em",
        paddingRight: "1em",
        marginBottom: "10px",
        backgroundColor: theme.palette.primary.contrastText,
        cursor: 'pointer',
        color: theme.palette.secondary.light,
        "&:hover": {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
            boxShadow: 'inset 0px 0px 0px 1px'
        }
    }
});

const loadModelMessage = 'Full model is loading, please wait...';

export const JobResultPage = (props: JobResultProps) => {
    const [parameters, setParameters] = useState<JobSolveInputsParameters>();
    const { t } = useTranslation();
    const [mainModel, setMainModel] = useState<cee.ug.RemoteModel>();
    const [mainMoldConstantRemoteModel, setMainMoldConstantRemoteModel] = useState<cee.cug.ConstantRemoteModel>();
    const [comparisonModel, setComparisonModel] = useState<cee.ug.RemoteModel>();
    const [comparisonMoldConstantRemoteModel, setComparisonMoldConstantRemoteModel] = useState<cee.cug.ConstantRemoteModel>();
    const [comparisonIteration, setComparisonIteration] = useState<Iteration>();
    const [coolingChannelsModel, setCoolingChannelsModel] = useState<cee.geo.GeometryModel | undefined>(new cee.geo.GeometryModel());
    const [comparisonCoolingChannelsModel, setComparisonCoolingChannelsModel] = useState<cee.geo.GeometryModel | undefined>(new cee.geo.GeometryModel());
    const [ioSocket, setIoSocket] = useState<Socket>();
    const [comparisonIoSocket, setComparisonIoSocket] = useState<Socket>();
    const tempView = useRef<cee.View>();
    const showTempView = useRef<boolean>(true);
    const showComparisonView = useRef<boolean>(false);
    const cugBlob = useRef<Promise<Blob>>();
    const [mainView, setMainView] = useState<cee.View>();
    const [comparisonView, setComparisonView] = useState<cee.View>();
    const [pickInfo, setPickInfo] = useState<PickInfo>();
    const context = useContext(CeetronContext);
    const navigate = useNavigate();
    const location = useLocation();
    const errorToastId = useRef<Id | undefined>(undefined);
    const { id: projectId, jobId, container: containerNameParam } = useParams();
    const classes = useStyles();
    const [wallThickness, setWallThickness] = useState<number>();
    const authContext = useContext(AuthContext);
    const centerContainerRef = useRef<HTMLDivElement>(null);
    const jobProjectSwitcherContainerRef = useRef<HTMLDivElement>(null);
    const [ceetronScenesContainerHeight, setCeetronScenesContainerHeight] = useState<string>('1px');
    const [projectType, setProjectType] = useState<string>();
    const projectService = new ProjectService();
    const uiSettingsContext = useContext(UiSettingsContext);
    const [iterationItem, setIterationItem] = useState<Iteration>();
    const [isLoadingChannelGeometry, setIsLoadingChannelGeometry] = useState<boolean>(false);
    const postprocessingConfigService = new PostprocessingConfigService();

    useEffect(() => {
        setModelLegendsTitle(mainModel);
        setModelLegendsTitle(mainMoldConstantRemoteModel);
        setModelLegendsTitle(comparisonModel);
        setModelLegendsTitle(comparisonMoldConstantRemoteModel);
    }, [uiSettingsContext.uiSettings.language]);

    function updateCeetronContainerSize() {
        if (centerContainerRef.current && jobProjectSwitcherContainerRef.current) {
            setCeetronScenesContainerHeight(centerContainerRef.current.clientHeight - jobProjectSwitcherContainerRef.current.clientHeight - 5 + 'px');
        }
    }

    useEffect(() => {
        window.addEventListener('resize', updateCeetronContainerSize);
        return () => {
            clearAllToasts();
            window.removeEventListener('resize', updateCeetronContainerSize);
        }
    }, []);

    function clearAllToasts() {
        errorToastId.current = undefined;
        toast.dismiss();
    }

    useEffect(() => {
        clearAllToasts();
    }, [location]);

    useEffect(() => updateCeetronContainerSize(), [centerContainerRef.current?.clientHeight, jobProjectSwitcherContainerRef.current?.clientHeight]);

    async function loadChannelsGeometryDetails(channelsBodiesData: JobChannelsBodiesData[], view: cee.View, isComparisonModel = false, overrideJobId?: string) {
        setIsLoadingChannelGeometry(true);
        const geoModel = await generateGeoModelFromChannelBodiesData(channelsBodiesData, props.iteration.containerName);
        geoModel.errors.forEach(e => queryErrorHandler(e));
        if (geoModel.result.partCount > 0) {
            view.addModel(geoModel.result);
            if (isComparisonModel) {
                setComparisonCoolingChannelsModel(geoModel.result);
            } else {
                setCoolingChannelsModel(geoModel.result);
            }
        }
        setIsLoadingChannelGeometry(false);
    }

    async function getFrozenWallThickness() {
        const categories = await JobService.getFrozenWallThickness(projectId!, jobId!, props.iteration.containerName);
        setWallThickness(categories?.frozenWallThickness);
    }

    function unloadAdditionnalGeometryData() {
        if (mainView && mainMoldConstantRemoteModel) {
            mainView.removeModel(mainMoldConstantRemoteModel);
            setMainMoldConstantRemoteModel(undefined);
        }
        if (comparisonView && comparisonMoldConstantRemoteModel) {
            comparisonView.removeModel(comparisonMoldConstantRemoteModel)
            setComparisonMoldConstantRemoteModel(undefined);
        }
        unloadChannelsGeometryDetails();
    }

    function unloadChannelsGeometryDetails() {
        if (mainView && coolingChannelsModel) {
            coolingChannelsModel.deleteAllParts();
            mainView.removeModel(coolingChannelsModel);
        }
        if (comparisonView && comparisonCoolingChannelsModel) {
            comparisonCoolingChannelsModel.deleteAllParts();
            comparisonView.removeModel(comparisonCoolingChannelsModel);
        }
        setCoolingChannelsModel(undefined);
        setComparisonCoolingChannelsModel(undefined);
    }

    useEffect(() => {
        showTempView.current = true;
        const blobInfo = props.iteration.cugBundleBinaryBlobInfo;
        if (blobInfo) {
            cugBlob.current = new FileManagementService().downloadBlob(blobInfo, props.iteration.containerName);
        }
    }, [props.iteration.id]);

    async function handleViewerInit(view: cee.View) {
        context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: false });
        const token = await authContext.token().then();
        const config = await postprocessingConfigService.getConfig(32);
        const { remoteModel, socket } = await createRemoteModel(token, config, handleCeetronError);
        view.addModel(remoteModel);
        openModel(view, tempView, remoteModel, props.resultPath);
        setMainView(view);
        setIoSocket(socket);
        loadProject();
        getFrozenWallThickness();
    }

    async function loadProject() {
        const project = await projectService.getProject(projectId!, containerNameParam!);
        if (project) {
            setProjectType(project.type);
        }
    }

    async function loadAdditionnalGeometryData(view: cee.View, isComparisonModel = false, overrideJobId?: string) {
        let data = await JobService.getJobParameters(projectId!, overrideJobId ? overrideJobId : jobId!, containerNameParam!);
        if (data && data.rawParameters) {
            if (getParametersFormatVersion(data.rawParameters) <= 1) { // version < 2 => need to convert to current format
                data.rawParameters = JobService.convertJobParameters(data.rawParameters);
            }
            data.rawParameters = JobService.convertJobParameters(data.rawParameters);
            if (data.rawParameters && !isComparisonModel) {
                const model = view.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
                const vs = getVisibilityState(model, data.rawParameters);
                setParameters(data.rawParameters);
                context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
            }
            if (getParametersFormatVersion(data.rawParameters) > 1) {
                loadMoldConstantRemoteModels(projectId!, overrideJobId ? overrideJobId : jobId!, view, isComparisonModel);
            }
            if (data.channelsBodiesData) {
                loadChannelsGeometryDetails(data.channelsBodiesData, view, isComparisonModel);
            }
        }
        return data;
    }

    async function loadMoldConstantRemoteModels(projectId: string, jobId: string, view: cee.View, isComparisonModel = false) {
        JobService.getMoldConstantRemoteModelData(projectId, jobId, containerNameParam!).then(blob => {
            if (blob) {
                const moldConstantRemoteModel = new cee.cug.ConstantRemoteModel();
                moldConstantRemoteModel.name = 'mold';
                if (isComparisonModel) {
                    setComparisonMoldConstantRemoteModel(moldConstantRemoteModel);
                    moldConstantRemoteModel.getPartArray().forEach(p => p.visible = false);
                } else {
                    setMainMoldConstantRemoteModel(moldConstantRemoteModel);
                    moldConstantRemoteModel.getPartArray().forEach(p => p.visible = false);
                }
                blob.arrayBuffer().then(buffer => {
                    moldConstantRemoteModel.openLocalBundle(cee.cug.BinaryModelBundle.fromArrayBuffer(buffer), () => {
                        setModelLegendsTitle(moldConstantRemoteModel);
                    });
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
                });
            }
        });
    }

    function handleComparisonViewerInit(view: cee.View) {
        view.overlay.navigationCubeVisible = false;
        view.overlay.modelColorLegendsVisible = false;
        setComparisonView(view);
    }

    useEffect(() => {
        loadIteration();
    }, []);

    async function handleTempViewerInit(view: cee.View) {
        if (cugBlob.current) {
            const constantRemoteModel = new cee.cug.ConstantRemoteModel();
            view.addModel(constantRemoteModel);
            constantRemoteModel.name = 'freeze_time';
            cugBlob.current.then(blob => {
                blob.arrayBuffer().then(buffer => {
                    constantRemoteModel.openLocalBundle(cee.cug.BinaryModelBundle.fromArrayBuffer(buffer), () => {
                        setModelLegendsTitle(constantRemoteModel);
                    });
                    tempView.current = view;
                    setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
                });
            });
        }
    }

    function openModel(view: cee.View, tempView: MutableRefObject<cee.View | undefined>, remoteModel: cee.ug.RemoteModel | undefined, modelKey: string) {
        if (!remoteModel) {
            return;
        }
        // Trigger prop change event in child components.
        setMainModel(undefined);
        remoteModel.openModel(modelKey, (error, model) => {
            if (error) {
                console.error(error);
                return;
            }
            setModelLegendsTitle(remoteModel);
            model.name = modelKey;
            const loadModelPromise = loadAdditionnalGeometryData(view).then(data => {
                if (data) {
                    applyInitialSettings(view, model, data?.rawParameters);
                    setMainModel(model);
                    model.setOneShotDataStreamingCompleteCallback(() => {
                        if (showTempView.current) {
                            transferCameraSettings(view, tempView?.current);
                            showTempView.current = false;
                        }
                    });
                    model.setProgressHandler(progressData => context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: progressData.streamingStatus === cee.ug.StreamingStatus.DATA_STREAMING_COMPLETE }));
                }
            });
            TipsAndTricksHelper.ShowNextTipsAndTricksWhilePromise(loadModelPromise, uiSettingsContext.uiSettings.displayTipsAndTricks, "bottom-right");
        });
    }

    function applyInitialSettings(view: cee.View, model: cee.ug.RemoteModel, parameters: JobSolveInputsParameters) {
        const vs = setVisibilityByType(getVisibilityState(model, parameters), PartType.PLASTIC, true);
        vs.skipAction = true;
        context.updateCeetronState({ type: CeetronActionType.SetVisibilityState, payload: vs });
        model.modelSpec.visibleSetIdArray = [1];
        setTemperatureResultsSettings(model);
        const defaultTimeStepIndex = findDefaultTimeStepIndex(model, props.iteration.solveInfoEjectionTime);
        const defaultTimeStep = model.modelDirectory.stateInfoArray[defaultTimeStepIndex];
        context.ceetronState.vtfxStates[0].defaultIndex = defaultTimeStepIndex;
        context.ceetronState.vtfxStates[0].lastIndex = defaultTimeStepIndex;
        context.ceetronState.vtfxStates[0].currentIndex = defaultTimeStepIndex;
        model.modelSpec.stateIdArray = [defaultTimeStep.id];
    }

    function applyComparisonInitialSettings(view: cee.View, model: cee.ug.RemoteModel) {
        setTemperatureResultsSettings(model);
        const defaultTimeStepIndex = findDefaultTimeStepIndex(model, props.iteration.solveInfoEjectionTime);
        const defaultTimeStep = model.modelDirectory.stateInfoArray[defaultTimeStepIndex];
        context.ceetronState.vtfxStates[1] = {
            defaultIndex: defaultTimeStepIndex,
            lastIndex: defaultTimeStepIndex,
            currentIndex: defaultTimeStepIndex,
            stateInfoArray: []
        };
        model.modelSpec.stateIdArray = [defaultTimeStep.id];

        if (context.ceetronState.displayMode === DisplayMode.ISOVOLUME) {
            model.getPartSettingsArray().forEach(p => {
                p.opacity = 0.02;
                p.drawStyle = cee.ug.DrawStyle.SURFACE;
            });
        }
    }

    const loadIteration = async () => {
        const iteration = await projectService.getIteration(props.iteration.projectId, props.iteration.id, props.iteration.containerName, true);
        if (iteration) {
            setIterationItem(iteration);
        }
    };

    function handleCeetronError(e: Error) {
        if (!props.iteration.cugBundleBinaryBlobInfo) {
            unloadAdditionnalGeometryData();
            navigate(routes.projects);
        }
        e.message = e.message + ' Please refresh to continue.'
        clearAllToasts();
        errorToastId.current = vizServerErrorHandler(e, errorToastId);
    }

    function handleScalarResultsChange() {
        if (!mainModel || !mainModel.modelDirectory || !mainView) {
            return;
        } else {
            if (!mainMoldConstantRemoteModel || !(context.ceetronState.displayPreset === DisplayPreset.MOLD || context.ceetronState.displayPreset === DisplayPreset.PLASTIC_VOLUME)) {
                const scalarResult = getScalarResultByName(mainModel, context.ceetronState.scalarResults);
                if (scalarResult) {
                    mainModel.modelSpec.fringesResultId = scalarResult.id;
                    const cuttingPlane = mainModel.getCuttingPlaneAt(0);
                    if (cuttingPlane) {
                        cuttingPlane.mapScalarResultId = scalarResult.id;
                    }
                }
            }
        }
    }

    useEffect(() => handleScalarResultsChange(), [context.ceetronState.scalarResults])

    const [viewLoadProgress, setViewLoadProgress] = useState<number>(0);

    useEffect(() => {
        if (!showTempView.current && tempView?.current) {
            tempView.current.removeAllModels();
        }
    }, [showTempView]);

    async function onJobCompare(iteration: Iteration) {
        if (context.ceetronState.displayPreset === DisplayPreset.MOLD) { // reset to freeze time if in mold preset to avoid issue with job where CUG is not available
            context.updateCeetronState({ type: CeetronActionType.SetDisplayPreset, payload: DisplayPreset.FREEZE_TIME });
        }
        if (showComparisonView.current) {
            onJobCompareClose();
        }
        if (mainView && comparisonView) {
            showComparisonView.current = true;
            context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: false });
            await new JobService().loadResults(projectId!, iteration.id, containerNameParam!).then(async (resultPath) => {
                //Disconnect current Ceetron socket for remote model
                if (comparisonIoSocket)
                    disconnectSocketAndClearModel(comparisonIoSocket, comparisonView)

                const token = await authContext.token().then();
                const config = await postprocessingConfigService.getConfig(32);
                const { remoteModel, socket } = await createRemoteModel(token, config, handleCeetronError);
                comparisonView.removeAllModels();
                comparisonView.addModel(remoteModel);
                openComparisonModel(comparisonView, remoteModel, resultPath.data, () => {
                    setComparisonIoSocket(socket);
                    const mainModel = mainView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
                    mainModel.setResultRangeChangedHandler(() => {
                        const resultId = mainModel.modelSpec.fringesResultId;
                        const mainSettings = mainModel.getScalarSettingsById(resultId);
                        const refSettings = remoteModel.getScalarSettingsById(resultId);
                        if (typeof mainSettings.rangeMinimum === 'number' && typeof mainSettings.rangeMaximum === 'number') {
                            refSettings.setRange(mainSettings.rangeMinimum, mainSettings.rangeMaximum);
                        }
                    });
                    matchCameraPosition(mainView, comparisonView);
                    linkCameraPosition(mainView, comparisonView);
                    loadAdditionnalGeometryData(comparisonView, true, iteration.id);
                    setComparisonIteration(iteration);
                });
            });
        }
    }

    function onJobCompareClose() {
        context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: true });
        setComparisonIoSocket(undefined);
        setComparisonMoldConstantRemoteModel(undefined);
        setComparisonIteration(undefined);
        if (comparisonView && comparisonIoSocket) {
            //Disconnect current Ceetron socket for remote model
            disconnectSocketAndClearModel(comparisonIoSocket, comparisonView)
        }
        setComparisonModel(undefined);
        setComparisonCoolingChannelsModel(undefined);
        showComparisonView.current = false;
        setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
        if (mainView && comparisonView) {
            unlinkCameraPosition(mainView, comparisonView);
            const mainModel = mainView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
            mainModel.setResultRangeChangedHandler(() => { });
        }
    }

    function openComparisonModel(view: cee.View, remoteModel: cee.ug.RemoteModel | undefined, modelKey: string, cb?: () => void) {
        if (!remoteModel) {
            return;
        }
        // Trigger prop change event in child components.
        remoteModel.openModel(modelKey, (error, model) => {
            if (error) {
                console.error(error);
                return;
            }
            remoteModel.name = modelKey;
            setModelLegendsTitle(remoteModel);
            setComparisonModel(remoteModel);
            setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
            applyComparisonInitialSettings(view, remoteModel);
            remoteModel.setProgressHandler(data => context.updateCeetronState({ type: CeetronActionType.SetViewerReadyState, payload: data.streamingStatus === cee.ug.StreamingStatus.DATA_STREAMING_COMPLETE }));
            cb?.();
        });
    }

    return (
        <JobPage
            openBackdrop={false}
            onJobCancel={() => false}
            backdropProgress={viewLoadProgress}
            onJobCompareClick={showTempView.current ? undefined : onJobCompare}
            content={
                <Grid ref={centerContainerRef} container direction='column' sx={{ height: '100%', position: "relative" }}>
                    <div ref={jobProjectSwitcherContainerRef}>
                        {!showComparisonView.current && <JobProjectSwitcher onJobSubmit={() => { }} onJobCancel={() => { }}
                            selected='results' projectType={projectType as ProjectType} project={projectId!} job={props.iteration} jobId={jobId} hwv={null} backdropFunc={null} />}

                        {showComparisonView.current && <Button startIcon={<CloseIcon />} className={classes.closeCompare}
                            onClick={onJobCompareClose}>{t("Close comparison")}</Button>}
                    </div>
                    <Grid container direction='row' justifyContent={showComparisonView.current ? 'space-evenly' : 'center'} alignItems='center' sx={{ position: 'absolute', top: '5em' }}>
                        {pickInfo && <PickInfoPanel pickInfo={pickInfo} precision={5} onClose={setPickInfo.bind(null, undefined)} />}
                        {mainView?.ownerViewer && mainModel && <ColorLegendSettingsDialog
                            viewer={mainView.ownerViewer} model={mainModel} ejectionTemperature={getIterationMaterialEjectionTemperature(iterationItem)}
                            displayPreset={context.ceetronState.displayPreset} precision={5} />
                        }
                        {!showComparisonView.current &&
                            <Grid item>
                                <IterationInfoPanel iteration={props.iteration} />
                            </Grid>
                        }
                        {showComparisonView.current && comparisonIteration &&
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                                <Box>
                                    <Tooltip title={props.iteration.name} placement='top'>
                                        <Grid item className={classes.titleCompare} sx={{ textAlign: "center" }}>{truncate(props.iteration.name, 15)}</Grid>
                                    </Tooltip>
                                    <IterationInfoPanel iteration={props.iteration} />
                                </Box>
                                <Box>
                                    <Tooltip title={comparisonIteration.name} placement='top'>
                                        <Grid item className={classes.titleCompare} sx={{ textAlign: "center" }}>{truncate(comparisonIteration.name, 15)}</Grid>
                                    </Tooltip>
                                    <IterationInfoPanel iteration={comparisonIteration} />
                                </Box>
                            </Box>
                        }
                    </Grid>
                    <Box style={{ width: '100%', height: ceetronScenesContainerHeight, display: 'flex', flexDirection: 'row' }}>
                        <CeetronScene show={showTempView.current} onInit={handleTempViewerInit} onLoadProgress={setViewLoadProgress} socket={ioSocket} />
                        <CeetronScene show={!showTempView.current || showComparisonView.current} onInit={handleViewerInit} onLoadProgress={setViewLoadProgress} socket={ioSocket} 
                            onPointPicked={pickInfo => setPickInfo(pickInfo)} />
                        <CeetronScene show={showComparisonView.current} isComparisonView={true} onInit={handleComparisonViewerInit} onLoadProgress={setViewLoadProgress} socket={comparisonIoSocket} 
                            onPointPicked={pickInfo => setPickInfo(pickInfo)} />
                    </Box>
                </Grid>
            }
            rightControls={mainModel && mainView && iterationItem ? <CeetroneSceneControl
                isMainViewLoaded={!showTempView.current}
                isComparisonViewLoaded={comparisonModel !== undefined}
                isLoadingChannelGeometry={isLoadingChannelGeometry}
                projectType={projectType as ProjectType}
                iteration={iterationItem}
                comparisonIteration={comparisonIteration}
                solverInputParameter={parameters}
                mainModel={mainModel}
                mainView={mainView}
                constantMainModel={mainMoldConstantRemoteModel}
                comparisonModel={comparisonModel}
                comparisonView={comparisonView}
                showCurrentComparisonView={showComparisonView.current}
                constantComparisonModel={comparisonMoldConstantRemoteModel}
                coolingChannelsModel={coolingChannelsModel}
                comparisonCoolingChannelsModel={comparisonCoolingChannelsModel}
                wallThickness={wallThickness}
                modelKey={props.resultPath}
            /> : <Box sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', color: theme.palette.info.light }}>
                <Typography variant="h6">{t(loadModelMessage)}</Typography>
            </Box>}
        />
    )
}

