// this will contain React query hooks similar to useProjects.ts

import { UseQueryResult, useMutation, useQuery } from "react-query";
import MoldMaterial from "../../../store/job/mold-material";
import RangeMaterial, { defaultMoldRangeMaterial, defaultPartRangeMaterial } from "../../../store/job/range-material";
import MaterialService from "../../../services/MaterialService";
import { queryClient } from "../../../react-query/queryClient";
import { queryKeys } from "../../../react-query/queryKeys";
import { MaterialType } from "../../job/CustomMaterialDialog";

export type MaterialsData = {
    data: {
        moldMaterials: MoldMaterial[],
        userMoldMaterials: MoldMaterial[],
        partMaterials: MoldMaterial[],
        userPartMaterials: MoldMaterial[],
        moldRangeMaterial: RangeMaterial,
        partRangeMaterial: RangeMaterial
    }
};

export const useMaterials = (): UseQueryResult<MaterialsData> => {
    return useQuery<MaterialsData>(queryKeys.materials, async () => {
        const result = await MaterialService.getAllMaterials();
        return { data: result };
    });
}

export const useAddMaterialMutation = () => {
    const { mutate } = useMutation(([newMaterials, type]: [MoldMaterial[], MaterialType.Mold | MaterialType.Part]) => MaterialService.createMaterial(newMaterials, type), {
        onSuccess: (addedMaterials, [_, type]) => {
            queryClient.setQueryData<MaterialsData>([queryKeys.materials], (old) => {
                const newMaterialData: MaterialsData = old ?? {
                    data: {
                        moldMaterials: [],
                        userMoldMaterials: [],
                        partMaterials: [],
                        userPartMaterials: [],
                        moldRangeMaterial: defaultMoldRangeMaterial,
                        partRangeMaterial: defaultPartRangeMaterial
                    }
                };

                //update material cache with response
                if (type == MaterialType.Part) {
                    newMaterialData.data.userPartMaterials = [...newMaterialData.data.userPartMaterials, ...addedMaterials];
                } else if (type == MaterialType.Mold) {
                    newMaterialData.data.userMoldMaterials = [...newMaterialData.data.userMoldMaterials, ...addedMaterials];
                }
                return newMaterialData;
            });
        }
    });

    return mutate;
}

export const useUpdateMaterialMutation = () => {
    const { mutate } = useMutation(([updatedMaterial, type]: [MoldMaterial[], MaterialType.Mold | MaterialType.Part]) => MaterialService.updateMaterial(updatedMaterial, type), {
        onSuccess: (_, [updatedMaterials, type]) => {
            queryClient.setQueryData<MaterialsData>([queryKeys.materials], (old) => {
                let updatedMaterialData: MaterialsData = old ?? {
                    data: {
                        moldMaterials: [],
                        userMoldMaterials: [],
                        partMaterials: [],
                        userPartMaterials: [],
                        moldRangeMaterial: defaultMoldRangeMaterial,
                        partRangeMaterial: defaultPartRangeMaterial
                    }
                };

                //update material in list
                updatedMaterials.forEach((updatedMaterial) => {
                    if (type == MaterialType.Part) {
                        let updatedList = updatedMaterialData?.data.userPartMaterials.filter((m) => { return m.id != updatedMaterial.id });
                        updatedList?.push(updatedMaterial);
                        updatedMaterialData.data.userPartMaterials = updatedList;

                    } else if (type == MaterialType.Mold) {
                        let updatedList = updatedMaterialData.data.userMoldMaterials.filter((m) => { return m.id != updatedMaterial.id });
                        updatedList?.push(updatedMaterial);
                        updatedMaterialData.data.userMoldMaterials = updatedList;
                    }
                });
                return updatedMaterialData;
            });
        }
    });
    return mutate;
}

export const useDeleteMaterialMutation = () => {
    const { mutate } = useMutation(([materialToDelete, type, moldGroupId]: [MoldMaterial, MaterialType.Mold | MaterialType.Part, string]) => MaterialService.deleteMaterial(materialToDelete.id, type, materialToDelete.creatorUserRecordKey, materialToDelete.userContainer), {
        onSuccess: (_, [materialToDelete, type, moldGroupId]) => {
            queryClient.setQueryData<MaterialsData>([queryKeys.materials], (old) => {
                let updatedMaterialData: MaterialsData = old ?? {
                    data: {
                        moldMaterials: [],
                        userMoldMaterials: [],
                        partMaterials: [],
                        userPartMaterials: [],
                        moldRangeMaterial: defaultMoldRangeMaterial,
                        partRangeMaterial: defaultPartRangeMaterial
                    }
                };
                //remove from list
                const predicate = (m: MoldMaterial) => {
                    if (m.id == materialToDelete.id) {
                        return { ...m, isDeleted: true };
                    }
                    return { ...m }
                };

                if (type == MaterialType.Part) {
                    let updatedList = updatedMaterialData?.data.userPartMaterials.map(predicate);
                    updatedMaterialData.data.userPartMaterials = updatedList;

                } else if (type == MaterialType.Mold) {
                    let updatedList = updatedMaterialData.data.userMoldMaterials.map(predicate);
                    updatedMaterialData.data.userMoldMaterials = updatedList;
                }

                return updatedMaterialData;
            });
        }
    });
    return mutate;
}