import { AccountInfo, PublicClientApplication, AuthenticationResult, LogLevel, EventType, EventMessage } from '@azure/msal-browser';
import axios from 'axios';
import moment from 'moment'
import { isInvitationUrl } from '../utils/urls.utils';
import { UserRole } from '../components/users/UserList';
import ConfigurationService from '../config/ConfigurationService';

export type AuthenticationJsonConfig = {
    tenantId: string;
    clientId: string;
    redirectUri: string;
    scopes: string[];
    authority: string,
    authoritySignUp: string,
    authorityInvitation: string,
    knownAuthorities: string[],
};

export type AuthenticationConfig = {
    tenantId: string;
    clientId: string;
    redirectUri: string;
    scopes: string[];
    authority: string,
    knownAuthorities: string[],
};

export type UserAttributes = {
    isAdmin: boolean,
    isSupervisor: boolean,
    isBetaTester: boolean,
    isEmployee: boolean,
    isFeaturePreview_vtfxdownload: boolean,
    companyName: string,
    role: UserRole
}

/**
 * A stateful authentication context singleton. 
**/
export default class AuthenticationService {

    private _pca: PublicClientApplication | undefined;

    private _configuration: ConfigurationService | undefined;

    private _accessTokenResponse: AuthenticationResult | undefined

    private _userAttributes: UserAttributes | undefined;

    constructor() { }

    public async setup(configuration: ConfigurationService) {
        this._configuration = configuration;

        const authentication = this._configuration.configuration.authentication;
        this._pca = new PublicClientApplication({
            auth: {
                navigateToLoginRequestUrl: false,
                clientId: authentication.clientId,
                redirectUri: authentication.redirectUri || window.location.origin,
                authority: authentication.authority,
                knownAuthorities: authentication.knownAuthorities,
            },
            ...commonOptions
        });

        let accounts = this._pca.getAllAccounts();
        let active = this._pca.getActiveAccount();
        if (active || accounts.length > 0) {
            this._pca.setActiveAccount(active ?? accounts[0])
        }

        //No account should be active or active but with token expired when url is for invitation
        //If account active but token is expired and require a log in from the user, that would confuse the invitation flow and invitation would not work.
        if (isInvitationUrl() && this._pca.getActiveAccount()) {
            await this._pca?.acquireTokenSilent({
                scopes: authentication.scopes,
            }).finally(async () => {
                await this.logout();
            });
        }
    }

    public async logout(): Promise<void> {
        await this._pca?.logoutRedirect();
    }

    public onLogout(callback: Function) {
        this._pca?.addEventCallback((message: EventMessage) => {
            if (message.eventType === EventType.LOGOUT_START) {
                callback();
            }
        });
    }

    public get pca(): PublicClientApplication | undefined {
        return this._pca;
    }

    public get activeAccount(): AccountInfo | undefined {
        return this._pca?.getActiveAccount() || undefined;
    }

    public get roles(): string[] | undefined {
        return this.activeAccount?.idTokenClaims?.roles;
    }

    public get role(): UserRole {
        return this._userAttributes?.role ?? UserRole.User;
    }

    public get username(): string | undefined {
        return this.activeAccount?.name;
    }

    public get userEmail(): string | undefined {
        return this.activeAccount?.username;
    }

    public get userCompanyName(): any {
        const claims = this._pca?.getActiveAccount()?.idTokenClaims as any;
        return claims?.extension_CompanyName ?? "";
    }

    public get userAttributes(): UserAttributes | undefined {
        return this._userAttributes;
    }

    public get userid(): string | undefined {
        return this.activeAccount?.localAccountId;
    }

    public isAdmin(): boolean {
        return this._userAttributes?.isAdmin || false;
    }

    public isSupervisor(): boolean {
        return this._userAttributes?.isSupervisor || false;
    }

    public isBetaTester(): boolean {
        return this._userAttributes?.isBetaTester || true;
    }

    public isEmployee(): boolean {
        return this._userAttributes?.isEmployee || false;
    }

    public isFeaturePreview_vtfxdownload(): boolean {
        return this._userAttributes?.isFeaturePreview_vtfxdownload || false;
    }

    public async retrieveAccessToken(): Promise<string | undefined> {
        if (!this._pca || !this._accessTokenResponse || moment().isAfter(this._accessTokenResponse.expiresOn)) {
            try {
                if (this._configuration) {
                    this._accessTokenResponse = await this._pca?.acquireTokenSilent({ scopes: this._configuration.configuration.authentication.scopes });
                }
            } catch (error) {
                console.log(error);
                return undefined;
            }
        }

        if (this._accessTokenResponse?.account)
            this._pca?.setActiveAccount(this._accessTokenResponse?.account)

        return this._accessTokenResponse?.accessToken
    }

    public async postLogin() {
        try {
            this._userAttributes = (await axios.get('/api/authmetadata', { params: { createIfNotExist: true } })).data;
        } catch (error) {
            console.debug(error);
            throw new Error('Error in postLogin.');
        }
    }

    public async initInterceptors(): Promise<void> {
        axios.interceptors.request.use(
            async config => {
                if (config.headers) {
                    const token = await this.retrieveAccessToken()
                    const scopes = this._configuration?.configuration.authentication.scopes;
                    if (!token && scopes) {
                        await this._pca?.acquireTokenRedirect({ scopes });
                    } else {
                        config.headers.Authorization = 'Bearer ' + token;
                    }
                    config.headers['Api-Version'] = process.env.REACT_APP_API_VERSION ?? '';
                    config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_API_SUBSCRIPTION_KEY ?? '';
                }
                config.baseURL = process.env.REACT_APP_API_BASE_URL ?? this._configuration?.configuration.apiBaseUrl;
                return config;
            },
            error => Promise.reject(error)
        )
    }
}

const commonOptions = {
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Error,
            loggerCallback: (level: LogLevel, message: string): void => {
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.debug(message);
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
    }
}