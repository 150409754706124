import { TableContainer, Box, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, Tooltip } from "@mui/material";
import theme from "../../styles/main-theme";
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import { useTranslation } from 'react-i18next';

export interface UserListModel{
    id: string,
    b2cOid: string,
    name: string,
    email: string,
    role: UserRole,
    container: string,
    isDeleted: boolean
}

export interface UserListProps{
    users: Array<UserListModel>,
    onRemoveUser: (userId: string) => void
}

export enum UserRole
{
    Admin = "Admin",
    User = "User"
}

export const UserList = (props: UserListProps) => {
    const { t } = useTranslation();
    const [openCancelInvitationDialog, setOpenCancelInvitationDialog] = useState(false);
    const [userToRemove, setUserToRemove] = useState<string | null>(null);
    
    const confirmCancelInvitation = (answer: boolean) => {

        setOpenCancelInvitationDialog(false);

        if(answer == true && userToRemove){
            props.onRemoveUser(userToRemove);
        }
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" align="left"><b>{t("User Name")}</b></TableCell>
                            <TableCell component="th" align="center"><b>{t("Email")}</b></TableCell>
                            <TableCell component="th" align="center"><b>{t("Role")}</b></TableCell>
                            <TableCell component="th" align="center" width={"110"}><b>{t("Actions")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.users.filter((u) => {return u.isDeleted == false}).map((user, index) => (
                            <TableRow
                                hover
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row" align="left" sx={{width:"20%"}}>{user.name}</TableCell>
                                <TableCell scope="row" align="center">{user.email}</TableCell>
                                <TableCell scope="row" align="center">{UserRole[user.role]}</TableCell>
                                <TableCell scope="row" align="center" width={"110"}>
                                    {UserRole[user.role] != UserRole.Admin && <Tooltip
                                        placement="top"
                                        title={t("Remove user")}
                                    >
                                            <CancelIcon onClick={() => {setUserToRemove(user.id); setOpenCancelInvitationDialog(true);}} 
                                                sx={{color: theme.palette.error.dark, cursor: "pointer"}}></CancelIcon>
                                    </Tooltip>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>  

            <ConfirmationModal 
                open={openCancelInvitationDialog}
                title={t("Remove User Confirmation")}
                content={t("Are you sure you want to remove this user from company account?")}
                onClose={confirmCancelInvitation}
            />
        </Box>
    
    )
}