export class SelectOperator implements Communicator.Operator.Operator {
    protected readonly _hwv: Communicator.WebViewer;
    protected readonly _highlight: boolean;
    protected readonly _name: string;

    protected _selectedItem?: Communicator.Selection.SelectionItem;

    onClose?: () => void;

    constructor(hwv: Communicator.WebViewer, highlight: boolean = true, name: string = "default") {
        this._hwv = hwv;
        this._highlight = highlight;
        this._name = name;
    }

    private onCtxMenuOpen = (event: Communicator.Event.InputEvent, type: Communicator.EventType) => {
        const me: Communicator.Event.MouseInputEvent = event as Communicator.Event.MouseInputEvent;

        if (Communicator.Event.isMouseEventType(type) === true && me.getEventType() === Communicator.MouseInputType.Up && me.getButton() === Communicator.Button.Right) {
            me.setHandled(true);
            this.onClose?.();
        }
    };

    async onActivate() {
        this._hwv.setCallbacks({
            _inputInteraction: this.onCtxMenuOpen
        });
        await this._hwv.model.resetModelHighlight();
    };

    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        delete this._selectedItem;
    }

    onDeactivate() {
        this._hwv.unsetCallbacks({
            _inputInteraction: this.onCtxMenuOpen
        });
    };
}