import { QueryClient } from 'react-query';
import queryErrorHandler from './queryErrorHandler';

export const queryClient = new QueryClient({
    defaultOptions:{
        queries:{
            onError: queryErrorHandler
        },
        mutations:{
            onError: queryErrorHandler
        }
    }
});