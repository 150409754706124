import { ExpandLess, ExpandMore, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Card, CardContent, Collapse, Grid, IconButton, SxProps, Theme, useTheme } from "@mui/material";
import { MouseEvent, ReactNode } from "react";

export type ActionCardProps = {
    top: ReactNode
    bottom: ReactNode
    expandRow?: ReactNode,
    onCardClick?: (event: MouseEvent<HTMLElement>) => void
    onDetailsClick?: (event: MouseEvent<HTMLElement>) => void
    onDeleteClick?: (event: MouseEvent<HTMLElement>) => void
    onExpandToggle: (event: MouseEvent<HTMLElement>) => void
    sx?: SxProps<Theme>
    expanded: boolean
    expandDisabled?: boolean
}

const ActionCard = (props: ActionCardProps) => {

    const theme = useTheme();
    return <Card sx={{ backgroundColor: theme.palette.background.default, boxShadow: theme.shadows[1] }}>
        <CardContent sx={{ padding: '0 !important' }} component="article" >
            <Grid container onClick={(e) => { e.stopPropagation(); props.onCardClick && props.onCardClick(e)}} direction={'row'} wrap='nowrap' sx={{ cursor: "pointer", padding: theme.custom.padding.medium, backgroundColor: theme.palette.background.paper }}>
                <Grid item flexGrow={1} zeroMinWidth>
                    {props.top}
                </Grid>
                <Grid item>
                    <Grid container direction={'column'}>
                        {props.onExpandToggle && <IconButton size='small' onClick={(e) => {e.stopPropagation(); props.onExpandToggle(e)}} disabled={props.expandDisabled || false}>
                            {props.expanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>}
                        {props.onDetailsClick && <IconButton size='small' onClick={(e) => {e.stopPropagation(); props.onDetailsClick && props.onDetailsClick(e)}}><EditIcon /></IconButton>}
                    </Grid>
                </Grid>
            </Grid>
            {props.expandRow &&  
                <Box>
                    {props.expandRow}
                </Box>
            }
            <Collapse in={props.expanded} timeout="auto" orientation={'vertical'}>
                <Box sx={{ padding: theme.custom.padding.medium }}>
                    {props.bottom}
                </Box>
            </Collapse>
        </CardContent>
    </Card>;
}

export default ActionCard;