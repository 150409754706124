import axios from "axios";
import { MetricsRecord } from "../components/account/models/metricsModel";
import { ReportFilePath } from "../components/account/models/metricsModel";

const MetricsService = {
    GetMetrics: async (during: string): Promise<MetricsRecord> => {
        try {
            const response = await axios.get('/api/metrics/metrics', { params: { during: during } });
            return { items: response.data };
        } catch {
            throw new Error('Error getting metrics');
        }
    },
    UsersAggregateMetrics: async (fromDate: Date, toDate: Date): Promise<MetricsRecord> => {
        try {
            const response = await axios.get('/api/applicationusagemetrics/usersaggregatemetrics', { params: { fromDate: fromDate, toDate: toDate } });
            return { items: response.data };
        } catch (error: any) {
            throw new Error(error.response.data);
        }
    },
    UsersTagsAggregateMetrics: async (fromDate: Date, toDate: Date): Promise<MetricsRecord> => {
        try {
            const response = await axios.get('/api/applicationusagemetrics/userstagsaggregatemetrics', { params: { fromDate: fromDate, toDate: toDate } });
            return { items: response.data };
        } catch (error: any) {
            throw new Error(error.response.data);
        }
    },
    GenerateAggregateUsageReport: async (fromDate: Date, toDate: Date, excludesEmployees: Boolean): Promise<ReportFilePath> => {
        try {
            const data = { fromDate, toDate, excludesEmployees };

            const response = await axios.post('/api/applicationusagemetrics/generateaggregateusagereport',
                data);
            return { filePath: response.data };
        } catch (error: any) {
            throw new Error(error.response.data);
        }
    },
    GenerateIndividualUsageReport: async (fromDate: Date, toDate: Date, excludesEmployees: Boolean): Promise<ReportFilePath> => {
        try {
            const data = { fromDate, toDate, excludesEmployees };

            const response = await axios.post('/api/applicationusagemetrics/generateindividualusagereport',
                data);
            return { filePath: response.data };
        } catch (error: any) {
            throw new Error(error.response.data);
        }
    },


}

export default MetricsService;