import { BaseNode } from './Base';
import { Collection, CollectionKinds } from "./Collection";
import { Directives } from "./Directives";

export class Group extends BaseNode {
    constructor(name?: string) {
        super(Directives.Group, name);
    }
}

export class Groups extends Collection<Group> {
    constructor(values? : Group[]) {
        super(CollectionKinds.Groups, values);
    }
}