import axios from "axios";

const CompanyInvitationService = {
    InviteUsers: async (emails: Array<string>) => {
        try {
            const response = await axios.post('/api/CompanyInvitation/InviteUsers', { emails });
            return response.data;
        } catch {
            throw new Error('Error inviting users');
        }
    },
    GetPendingInvitations: async () => {
        try {
            const response = await axios.get('/api/CompanyInvitation/PendingInvitations');
            return response.data;
        } catch {
            throw new Error('Error getting pending company invitations');
        }
    },
    CancelInvitation: async (invitationId: string) => {
        try {
            const response = await axios.delete('/api/CompanyInvitation/' + invitationId);
            return response.data;
        } catch {
            throw new Error('Error cancelling company invitation');
        }
    },
    ValidateInvitation: async (id: string, hmac: string) => {
        try {
            const params = {id, hmac}
            const response = await axios.put("/api/CompanyInvitation/validateInvitation", params);
            return response.data;
        } catch(e){
            throw new Error('Error validating invitation');
        }
    },
    ResendInvitation: async (invitationId: string) => {
        try {
            const params = {invitationId}
            const response = await axios.post("/api/CompanyInvitation/resendInvitation", params);
            return response.data;
        } catch(e){
            throw new Error('Error resending invitation');
        }
    },
}

export default CompanyInvitationService;