import { SelectedUserFilterModel } from "../components/common/ProjectFilter";

const selectedUsersForProjectsKey = "selectedUsersForProjects";

export const localStorageUtil = {
    saveSelectedUsers: (users: SelectedUserFilterModel[]) => {
        const selectedUsers = [
            ...users.filter((u) => {return u.checked == true}).map((u) => {return u.id})
        ]

        localStorage.setItem(selectedUsersForProjectsKey, JSON.stringify(selectedUsers));
    },
    getSelectedUsers: () => {
        return localStorage.getItem(selectedUsersForProjectsKey);
    }
}