import axios from "axios";
import { TipAndTrickModel } from "./TipsAndTricksHelper";

export const TipsAndTricksService = {
    async GetNextTipsAndTricks():Promise<TipAndTrickModel>{
        try{
            const response = (await axios.get('/api/tipsAndTricks/random'));
            return response.data;
        }catch(error){
            throw new Error('Error getting next tips and tricks');
        }
    },
}

