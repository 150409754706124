import { BaseNode } from './Base';
import { Collection, CollectionKinds } from "./Collection";
import { Index } from "./Index";
import { Directives } from "./Directives";
import { Group, Groups } from './Group';

export class ObjectNode extends BaseNode {
    groups?: Groups;

    constructor(name?: string) {
        super(Directives.ObjectNode, name);
    }

    Groups() : Groups {
        if (!this.groups) this.groups = new Groups();

        return this.groups;
    }

    pushGroup(group?: Group) : Index | undefined {
        return this.Groups().push(group);
    }

    pushGroups(groups?: Groups) : Index | undefined  {
        return this.Groups().pushCollection(groups);
    }

    supplementalComments() {
        super.supplementalComments();
                
        if (this.groups) 
            this.addComment(this.groups.commentCount());
    }

    supplementalSections() : string {
        return Groups.stringify(this.groups);
    }
}

export class ObjectNodes extends Collection<ObjectNode> {
    constructor(values? : ObjectNode[]) {
        super(CollectionKinds.ObjectNodes, values);
    }
}