export enum MoldCategory {
    Part,
    Mold,
    Core, // legacy
    Cavity, // legacy
    Channel,
    Runner,
    BasicMold
}

export enum MoldMaterialCategory {
    Molds = "Molds",
    Parts = "Parts",
    BasicMold = "BasicMold",
    Channels = "Channels"
}

export enum MoldMaterialCategoryForSummary {
    molds = "molds",
    parts = "parts",
    basicMold = "basicMold",
    channels = "channels"
}