import { PartType, VisibilityState, VtfxCase, VtfxScalarResults, VtfxState } from "../../../store/job/ceetron-context";
import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { t } from "i18next";
import { JobSolveInputsParameters } from "../../../store/job/job-data";

export const NUMBER_OF_LEVELS = 40;
export const aboveRangeColor = new cee.Color3(0.5, 0, 0);
export const belowRangeColor = new cee.Color3(0.5, 0, 0.5);


export function transferCameraSettings(view: cee.View, prevView?: cee.View): void {
    const previousCamera = prevView?.camera;
    const center = prevView?.getBoundingBox().getCenter();
    if (previousCamera && center) {
        const up = previousCamera.getUp();
        const position = previousCamera.getPosition();
        view.camera.setFromLookAt(position, center, up);
    }
}

export function matchCameraPosition(view: cee.View, view2: cee.View): void {
    const up = view.camera.getUp();
    const position = view.camera.getPosition();
    const direction = view.camera.getDirection();
    view2.camera.setViewpoint(position, direction, up);
    view2.requestRedraw();
}

export const computeInitialCuttingPlanePosition = (model: cee.ug.RemoteModel, normal: cee.Vec3Like) => {
    const min = model.getBoundingBox().minimum;
    if (Math.abs(normal.x) === 1) {
        return min.x;
    } else if (Math.abs(normal.y) === 1) {
        return min.y;
    } else {
        return min.z;
    }
}

export function linkCameraPosition(view: cee.View, view2: cee.View): void {
    const handler = () => {
        matchCameraPosition(view, view2);
    }
    const handler2 = () => {
        matchCameraPosition(view2, view);
    }
    view.camera.setViewChangeHandler(() => {
        view2.camera.setViewChangeHandler(() => { }, false);
        handler();
        view2.camera.setViewChangeHandler(handler2, false);
    }, false);
    view2.camera.setViewChangeHandler(() => {
        view.camera.setViewChangeHandler(() => { }, false);
        handler2();
        view.camera.setViewChangeHandler(handler, false);
    }, false);
}

export function unlinkCameraPosition(view: cee.View, view2: cee.View): void {
    view2.camera.setViewChangeHandler(() => { }, false);
    view.camera.setViewChangeHandler(() => { }, false);
}

export function switchToVtfxCase(model: cee.ug.RemoteModel, caseName?: VtfxCase, cb?: () => void) {
    model.animation.runAnimation(false) // stops before changing case to avoid server crash due to bad index
    const vtfxCaseInfoArray = model.modelDirectory.vtfxCaseInfoArray;
    const vtfxCaseIdToSwitchTo = (vtfxCaseInfoArray.find(c => c.name === caseName) || vtfxCaseInfoArray[0]).caseId;
    model.applyVTFxCase(vtfxCaseIdToSwitchTo, false, cb);
}

export function statesAreEqual(directory: cee.ug.ModelDirectory, vtfxStates: VtfxState[], modelIndex = 0): boolean {
    const stateIds = vtfxStates[modelIndex].stateInfoArray;
    const directoryStateIds = directory.stateInfoArray.map(state => state.id);

    return stateIds.length === directoryStateIds.length && stateIds.every(ssi => directoryStateIds.includes(ssi.id));
}

export function createCaseParametersFromModel(model: cee.ug.RemoteModel) {
    const modelSpec = model.modelSpec.getAsProperties();
    const mirrorSettings = model.mirrorSettings;
    const partsSettings = model.getPartSettingsArray().map(ps => ps.getAsProperties());
    const cuttingPlanes = model.getCuttingPlaneArray().map(cps => cps.getAsProperties());
    const isosurfaces = model.getIsosurfaceArray().map(is => is.getAsProperties());
    const isovolumes = model.getIsovolumeArray().map(iv => iv.getAsProperties());
    const particleTraceGroups = model.getParticleTraceGroupArray().map(ptg => ptg.getAsProperties());
    const scalarSettings = model.getScalarSettingsArray().map(ss => ss.getAsProperties());
    const vectorSettings = model.getVectorSettingsArray().map(vs => vs.getAsProperties());
    const displacementSettingsArray = model.getDisplacementSettingsArray();
    const displacementSettings = displacementSettingsArray.length > 0 ? displacementSettingsArray[0].getAsProperties() : {
        resultId: 0,
        scaleFactor: 1
    };
    return {
        modelSpec, mirrorSettings, partsSettings, cuttingPlanes, isosurfaces, isovolumes, particleTraceGroups, scalarSettings, vectorSettings, displacementSettings
    };
}

export function createPlasticVolumeCaseFromModel(model: cee.ug.RemoteModel, vtfxStates: VtfxState[], modelIndex = 0): cee.ug.Case {
    const plasticVolumeCase = new cee.ug.Case();
    const plasticVolumeCaseParameters = createCaseParametersFromModel(model);
    const newPartsSettings = plasticVolumeCaseParameters.partsSettings.map(ps => {
        const name = model.modelDirectory.getPartNameById(ps.geometryIndex, ps.partId);
        ps.visible = (name === PartType.PLASTIC);
        return ps;
    });
    plasticVolumeCaseParameters.modelSpec.visibleSetIdArray = [];
    plasticVolumeCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[vtfxStates[modelIndex].lastIndex].id];
    const newScalarResults = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
    if (newScalarResults) {
        plasticVolumeCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
        plasticVolumeCaseParameters.scalarSettings.forEach(settings => {
            if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
            } else {
                settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
            }
        });
    }
    plasticVolumeCase.create(
        plasticVolumeCaseParameters.modelSpec,
        plasticVolumeCaseParameters.mirrorSettings,
        newPartsSettings,
        [],
        [],
        [],
        plasticVolumeCaseParameters.particleTraceGroups,
        plasticVolumeCaseParameters.scalarSettings,
        plasticVolumeCaseParameters.vectorSettings,
        plasticVolumeCaseParameters.displacementSettings
    );
    return plasticVolumeCase;
}

export function createSurfaceTemperatureCaseFromModel(model: cee.ug.RemoteModel, vtfxStates: VtfxState[], modelIndex = 0): cee.ug.Case {
    const surfaceTemperatureCase = new cee.ug.Case();
    const surfaceTemperatureCaseParameters = createCaseParametersFromModel(model);
    surfaceTemperatureCaseParameters.modelSpec.visibleSetIdArray = [1];
    surfaceTemperatureCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[vtfxStates[modelIndex].lastIndex].id];
    const newScalarResults = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
    if (newScalarResults) {
        surfaceTemperatureCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
        surfaceTemperatureCaseParameters.scalarSettings.forEach(settings => {
            if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
            } else {
                settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
            }
        });
    }
    surfaceTemperatureCase.create(
        surfaceTemperatureCaseParameters.modelSpec,
        surfaceTemperatureCaseParameters.mirrorSettings,
        surfaceTemperatureCaseParameters.partsSettings,
        [],
        [],
        [],
        surfaceTemperatureCaseParameters.particleTraceGroups,
        surfaceTemperatureCaseParameters.scalarSettings,
        surfaceTemperatureCaseParameters.vectorSettings,
        surfaceTemperatureCaseParameters.displacementSettings
    );
    return surfaceTemperatureCase;
}

export function createFreezingTimeCaseFromModel(model: cee.ug.RemoteModel, vtfxStates: VtfxState[], modelIndex = 0): cee.ug.Case {
    const freezingTimeCase = new cee.ug.Case();
    const freezingTimeCaseParameters = createCaseParametersFromModel(model);
    freezingTimeCaseParameters.modelSpec.visibleSetIdArray = [1];
    freezingTimeCaseParameters.modelSpec.stateIdArray = [model.modelDirectory.stateInfoArray[vtfxStates[modelIndex].defaultIndex].id];
    const newScalarResults = getScalarResultByName(model, VtfxScalarResults.FREEZE_TIME_RESULT);
    if (newScalarResults) {
        freezingTimeCaseParameters.modelSpec.fringesResultId = newScalarResults.id;
        freezingTimeCaseParameters.scalarSettings.forEach(settings => {
            if (settings.resultId !== newScalarResults.id || modelIndex === 1) {
                settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.NEVER;
            } else {
                settings.legendVisibilityMode = cee.ug.LegendVisibilityMode.ALWAYS;
            }
        });
    }
    freezingTimeCase.create(
        freezingTimeCaseParameters.modelSpec,
        freezingTimeCaseParameters.mirrorSettings,
        freezingTimeCaseParameters.partsSettings,
        [],
        [],
        [],
        freezingTimeCaseParameters.particleTraceGroups,
        freezingTimeCaseParameters.scalarSettings,
        freezingTimeCaseParameters.vectorSettings,
        freezingTimeCaseParameters.displacementSettings
    );
    return freezingTimeCase;
}

export function setTemperatureResultsSettings(model: cee.ug.RemoteModel) {
    const temperatureResult = getScalarResultByName(model, VtfxScalarResults.TEMPERATURE_RESULT);
    const settings = temperatureResult ? model.getScalarSettingsById(temperatureResult.id) : model.getScalarSettingsArray()[0];
    settings.levelCount = NUMBER_OF_LEVELS;
    model.getScalarSettingsArray().forEach(ss => {
        ss.aboveRangeColor = aboveRangeColor;
        ss.belowRangeColor = belowRangeColor;
    });
}

export function setModelLegendsTitle(model?: cee.ug.RemoteModel | cee.cug.ConstantRemoteModel) {
    model?.setColorLegendTitle(1, t('Temperature'));
    model?.setColorLegendTitle(2, t('Freeze Time'));
}

export function swapModelAndConstantRemoteModel(
    toConstantRemoteModel: boolean,
    view: cee.View | undefined,
    remoteModel: cee.ug.RemoteModel | undefined,
    constantRemoteModel: cee.cug.ConstantRemoteModel | undefined,
    cb?: () => void
) {
    if (view && remoteModel && constantRemoteModel) {
        const modelToSwapOut = toConstantRemoteModel ? remoteModel : constantRemoteModel;
        const modelToSwapIn = !toConstantRemoteModel ? remoteModel : constantRemoteModel;
        const allModelsInView = view.getModelArray();
        const up = view.camera.getUp();
        const position = view.camera.getPosition();
        const direction = view.camera.getDirection();
        if (allModelsInView.includes(modelToSwapOut)) {
            view.removeModel(modelToSwapOut);
        }
        if (!allModelsInView.includes(modelToSwapIn)) {
            if (modelToSwapIn instanceof cee.ug.RemoteModel) {
                view.addModel(modelToSwapIn);
                const openCompletedCallback = (view: cee.View, error: Error, model: cee.ug.RemoteModel) => {
                    setTemperatureResultsSettings(model);
                    // reposition the camera where it used to be
                    view.camera.setViewpoint(position, direction, up);
                    setModelLegendsTitle(model);
                    cb?.();
                };
                modelToSwapIn.openModel(modelToSwapIn.name, openCompletedCallback.bind(null, view));
            } else {
                view.addModel(modelToSwapIn);
                cb?.();
            }
        } else {
            cb?.();
        }
    }
}

export function getPartTypeByName(name: string, parametersData: JobSolveInputsParameters): PartType {
    return parametersData.Model.Molds.map(m => m.name).includes(name) ? PartType.MOLD : PartType.PLASTIC;
}

export function findDefaultTimeStepIndex(model: cee.ug.RemoteModel, solveInfoEjectionTime: number): number {
    if (typeof solveInfoEjectionTime != 'number') {
        return model.modelDirectory.stateInfoArray.length - 1;
    }
    const safeEjectionTimeStep = model.modelDirectory.stateInfoArray
        .map((si, index) => {
            return {
                scalarResultIdArr: si.scalarResultIdArr,
                index
            };
        })
        .filter(state => state.scalarResultIdArr.includes(2))[0]; // look for 1st time step with a freeze time value mapped
    return safeEjectionTimeStep ? safeEjectionTimeStep.index : model.modelDirectory.stateInfoArray.length - 1;
}

export function getVisibilityState(model: cee.ug.RemoteModel, parametersData: JobSolveInputsParameters): VisibilityState {
    const result = {
        state: {},
        skipAction: false
    } as VisibilityState;
    model.getPartSettingsArray().forEach(p => {
        const name = model.modelDirectory.getPartNameById(p.geometryIndex, p.partId);
        const item = {
            type: getPartTypeByName(name, parametersData),
            value: p.visible
        };
        if (result.state) {
            result.state[name] = item;
        }
    });
    return result;
}

export function setVisibilityByType(visibilityState: VisibilityState, type: PartType, visibility: boolean): VisibilityState {
    const result: VisibilityState = {
        skipAction: visibilityState.skipAction,
        state: {}
    }
    Object.keys(visibilityState.state).forEach(k => {
        result.state[k] = {
            type: visibilityState.state[k].type,
            value: visibilityState.state[k].type === type ? visibility : visibilityState.state[k].value
        }
    });
    return result;
}

export function setVisibilityByName(visibilityState: VisibilityState, name: string, visibility: boolean): VisibilityState {
    const result: VisibilityState = {
        skipAction: visibilityState.skipAction,
        state: {}
    }
    Object.keys(visibilityState.state).forEach(k => {
        result.state[k] = {
            type: visibilityState.state[k].type,
            value: k === name ? visibility : visibilityState.state[k].value
        }
    });
    return result;
}

export function getScalarResultByName(model: cee.ug.RemoteModel, name: string): cee.ug.ResultInfo | null {
    const results = model.modelDirectory?.scalarResultArray.filter(r => r.name === name);
    return results?.length ? results[0] : null;
}

export function hasDefaultCase(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.vtfxCaseInfoArray.some(i => i.name === VtfxCase.DEFAULT_VIEW) : false;
}

export function hasPlasticSkinCase(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.vtfxCaseInfoArray.some(i => i.name === VtfxCase.PLASTIC_SKIN) : false;
}

export function hasFreezeTimeCase(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.vtfxCaseInfoArray.some(i => i.name === VtfxCase.FREEZE_TIME) : false;
}

export function hasFreezeTimeResults(modelDirectory?: cee.ug.ModelDirectory): boolean {
    return modelDirectory ? modelDirectory.scalarResultArray.some(r => r.name === VtfxScalarResults.FREEZE_TIME_RESULT) : false;
}

export function matchScaleRange(mainView?: cee.View, comparisonView?: cee.View) {
    if (mainView && comparisonView) {
        const mainModel = mainView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
        const comparisonModel = comparisonView.getModelArray().filter(m => m instanceof cee.ug.RemoteModel)[0] as cee.ug.RemoteModel;
        const resultId = mainModel.modelSpec.fringesResultId;
        if (mainModel && comparisonModel) {
            const mainSettings = mainModel.getScalarSettingsById(resultId);
            const refSettings = comparisonModel.getScalarSettingsById(resultId);
            if (typeof mainSettings.rangeMinimum === 'number'
                && typeof mainSettings.rangeMaximum === 'number'
                && (refSettings.rangeMinimum !== mainSettings.rangeMinimum
                    || refSettings.rangeMaximum !== mainSettings.rangeMaximum)) {
                refSettings.setRange(mainSettings.rangeMinimum, mainSettings.rangeMaximum);
            }
        }
    }
}