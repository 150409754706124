import { VtfxScalarResults } from "../store/job/ceetron-context";
import { TemperatureUnit } from "../store/uiSettings/UiSettingsContext";

export interface TemperatureWithUnit {
    value: number,
    unit: TemperatureUnit
}

export function getUnit(scalarResultInfo: cee.ug.ResultInfo): string {
    if (scalarResultInfo.unit) {
        const detection = detectKnownTemperatureUnit(scalarResultInfo.unit);
        if (detection.isKnown) {
            return detection.unit;
        }
        return scalarResultInfo.unit;
    }
    // fallback when unit is not provided
    else if (scalarResultInfo.name === VtfxScalarResults.FREEZE_TIME_RESULT) {
        return 's';
    } else if (scalarResultInfo.name === VtfxScalarResults.TEMPERATURE_RESULT) {
        return TemperatureUnit.C;
    } 
    return '';
}

export function detectKnownTemperatureUnit(unit: string): { isKnown: boolean, unit: TemperatureUnit | string } {
    const regexpCelsius = new RegExp(/^\[?°C\]?$/);
    const regexpFahrenheit = new RegExp(/^\[?°F\]?$/);
    if (regexpCelsius.test(unit.trim())) {
        return {
            isKnown: true,
            unit: TemperatureUnit.C
        };
    } else if (regexpFahrenheit.test(unit.trim())) {
        return {
            isKnown: true,
            unit: TemperatureUnit.F
        };
    }
    return {
        isKnown: false,
        unit: unit
    };
}

export function convertTemperature(temperature: TemperatureWithUnit, unit: TemperatureUnit): TemperatureWithUnit {
    if (temperature.unit === TemperatureUnit.C && unit === TemperatureUnit.F) {
        return {
            value: temperature.value * 9/5 + 32,
            unit: TemperatureUnit.F
        }
    } else if (temperature.unit === TemperatureUnit.F && unit === TemperatureUnit.C) {
        return {
            value: (temperature.value - 32) * 5/9,
            unit: TemperatureUnit.C
        }
    }
    return temperature;
}