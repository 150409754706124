import Layout from '../ui/Layout';
import JobRouteGuard from './JobRouteGuard'
import JobCreationPage from './JobCreationPage';

const JobComponent = () => {
    return (
        <Layout>
            <JobRouteGuard>
                <JobCreationPage />
            </JobRouteGuard>
        </Layout>
    )
};

export default JobComponent;