import { Comment, Comments } from "./Comment";
import { Directives, composeDirective } from "./Directives";
import { Face, Faces } from "./Face";
import { Index } from "./Index";
import { Line, Lines } from "./Line";
import { NormalizedVertices } from "./NormalizedVertex";
import { Ratio } from "./Ratio";
import { Textures } from "./Texture";
import { Vertices } from "./Vertex";
import { stringify } from "./Utilities";

export class Base {
    directive: Directives;    
    comments?: Comments;
    vertices?: Vertices;
    normalizedVertices?: NormalizedVertices;
    textures?: Textures;
    faces?: Faces;
    lines?: Lines;

    constructor(directive: Directives) {
        this.directive = directive;
    }

    Comments() : Comments {
        if (!this.comments) this.comments = new Comments();

        return this.comments;
    }

    Vertices() : Vertices {
        if (!this.vertices) this.vertices = new Vertices();

        return this.vertices;
    }

    NormalizedVertices() : NormalizedVertices {
        if (!this.normalizedVertices) this.normalizedVertices = new NormalizedVertices();

        return this.normalizedVertices;
    }

    Textures() : Textures {
        if (!this.textures) this.textures = new Textures();

        return this.textures;
    }

    Faces() : Faces {
        if (!this.faces) this.faces = new Faces();

        return this.faces;
    }

    Lines() : Lines {
        if (!this.lines) this.lines = new Lines();

        return this.lines;
    }

    pushComment(comment?: Comment) : Index | undefined {
        return this.Comments().push(comment);
    }

    addComment(comment: string) : Index | undefined {
        return this.Comments().add(comment);
    }

    addVertex(x: number, y: number, z: number, w?: Ratio) : Index | undefined {
        return this.Vertices().add(x, y, z, w);
    }

    addNormalizedVertex(x: number, y: number, z: number, w?: Ratio) : Index | undefined {
        return this.NormalizedVertices().add(x, y, z, w);
    }

    addTexture(u:Ratio, v?: Ratio, w?: Ratio) : Index | undefined {
        return this.Textures().add(u, v, w);
    }

    addFace(face?: Face) : Index | undefined {
        return this.Faces().add(face);
    }

    addLine(vertexIndexes?: Index[]) : Index | undefined {
        return this.Lines().add(vertexIndexes);
    }

    pushLine(value?: Line) : Index | undefined {
        return this.Lines().push(value);
    }

    pushLines(lines?: Lines) : Index | undefined {
        return this.Lines().pushCollection(lines);
    }

    supplementalComments() {
        if (this.vertices) 
            this.addComment(this.vertices.commentCount());

        if (this.normalizedVertices) 
            this.addComment(this.normalizedVertices.commentCount());

        if (this.textures) 
            this.addComment(this.textures.commentCount());

        if (this.faces) 
            this.addComment(this.faces.commentCount());

        if (this.lines) 
            this.addComment(this.lines.commentCount());
    }

    directiveText() : string {
        return '';
    }

    directiveLine() : string {
        return composeDirective(this.directive, this.directiveText(), true);
    }

    supplementalSections() : string {
        return '';
    }

    toString() : string {
        if (!Comments.isEmpty(this.comments)) this.addComment('');

        this.supplementalComments();

        if (!Comments.isEmpty(this.comments)) this.addComment('');

        return Comments.stringify(this.comments) +
               this.directiveLine() +
               this.supplementalSections() +
               Vertices.stringify(this.vertices) +
               NormalizedVertices.stringify(this.normalizedVertices) +
               Textures.stringify(this.textures) +                            
               Faces.stringify(this.faces) +
               Lines.stringify(this.lines);
    }
}

export class BaseNode extends Base {
    name?: string;

    constructor(directive: Directives, name?: string) {
        super(directive);
        this.name = name;
    }

    directiveLine() : string {
        return composeDirective(this.directive, stringify(this.name), true);
    }
}