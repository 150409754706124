import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import JobService from '../../services/JobService';
import ProjectService from '../../services/ProjectsService';
import { CeetronContextProvider } from '../../store/job/ceetron-context';
import Layout from '../ui/Layout';
import { JobResultPage } from './results-page/JobResultPage';
import Spinner from '../common/Spinner';
import Iteration from "../../store/project/iteration";

const JobResultsWrapper = () => {
    const { id: projectId, jobId, container: containerNameParam } = useParams();
    const [resultPath, setResultPath] = useState<string>("");
    const [iteration, setIteration] = useState<Iteration|null>(null);
    const jobService = new JobService();
    const projectService = new ProjectService();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const load = async () => {
            setReady(false);
            const [resultResponse, iteration] = await Promise.all([
                jobService.loadResults(projectId!, jobId!, containerNameParam!),
                projectService.getIteration(projectId!, jobId!, containerNameParam!)
            ]);
            setResultPath(resultResponse.data);
            setIteration(iteration);
            setReady(true);
        };
        load();
    }, [projectId, jobId]);

    return (
        <CeetronContextProvider>
            <Layout projectName={projectId}>
                <Spinner isLoading={!ready}>
                    <JobResultPage resultPath={resultPath} iteration={iteration as Iteration} />
                </Spinner>
            </Layout>
        </CeetronContextProvider>)
};

export default JobResultsWrapper;