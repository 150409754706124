import { IconButton, Badge, Menu, MenuItem, useTheme, Typography, Box, Divider, Link } from "@mui/material";
import React from "react";
import { useState } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useMutation, useQuery } from "react-query";
import NotificationService from "../../services/NotificationService";
import PaymentService from "../../services/PaymentService";
import { queryClient } from "../../react-query/queryClient";
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';

export interface NotificationItem {
    id: string,
    created: Date,
    title: string,
    message: string,
    type: string,
    read: boolean
}

export interface NotificationsProps {
};

const NotificationsMenu = (props: NotificationsProps) => {
    const { t } = useTranslation();
    const anchorRefNotification = React.useRef<HTMLButtonElement>(null);
    const [anchorElNotification, setAnchorElNotification] = useState<null | HTMLElement>(null);
    const openNotification = Boolean(anchorElNotification);
    const theme = useTheme();
    const notificationQuery = useQuery<NotificationItem[], Error>(["notifications"], async () => {
        const notifications = await NotificationService.GetNotifications();
        return notifications;
    }, {
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
        select: data => data.sort((a: NotificationItem, b: NotificationItem) => !b.created || !a.created ? -1 : new Date(b.created).getTime() - new Date(a.created).getTime())
    });

    const notificationReadMutation = useMutation(async (NotificationList: NotificationItem[]): Promise<NotificationItem[]> => {
        await NotificationService.ReadNotifications(NotificationList.map((n) => { return n.id }))

        return NotificationList;
    }, {
        onSuccess: () => {
            queryClient.setQueryData<NotificationItem[]>(["notifications"], (oldNotifications: NotificationItem[] | undefined) => {
                if (oldNotifications == undefined) return [];
                //For now we know that we read every notifications in the list
                //So just loop and update read attribute
                const updatedNotifications: NotificationItem[] = [];
                for (const oldNotification of oldNotifications) {
                    const updatedNotification = {
                        ...oldNotification,
                        read: true
                    }
                    updatedNotifications.push(updatedNotification);
                }

                return updatedNotifications;
            });
        }
    });

    const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
        if (!notificationQuery.data || notificationQuery.data.length == 0) return;

        setAnchorElNotification(event.currentTarget);
    };

    const handleCloseNotification = () => {
        //Set notifications to read
        if (notificationQuery.data)
            notificationReadMutation.mutate(notificationQuery.data)

        setAnchorElNotification(null);
    };

    const getNotifEmail = (notif: string) => {
        var regex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi; // regex email
        var matches = regex.exec(notif);
        var email = matches && matches[0];
        return email ? email : '';
    }

    const getAction = (notification: NotificationItem) => {
        let action;
        switch (notification.type) {
            case "InvoicePaymentFailed":
                action = <Link
                    component="button"
                    variant="body2"
                    onClick={async () => {
                        const checkoutSession = await PaymentService.CreatePortalSession();
                        window.location.href = checkoutSession.url;
                    }}
                    gutterBottom
                >
                    Manage billing informations
                </Link>
                break;
        }

        return action;
    }

    return (
        <>
            <IconButton
                ref={anchorRefNotification}
                id="notification-button"
                onClick={handleClickNotification}
            >
                <Badge badgeContent={notificationQuery.data?.filter((n) => { return n.read == false; }).length} color="error">
                    <NotificationsIcon sx={{ color: theme.palette.background.paper }}></NotificationsIcon>
                </Badge>
            </IconButton>
            <Menu
                sx={{}}
                id="notification-menu"
                aria-labelledby="notification-button"
                anchorEl={anchorElNotification}
                open={openNotification}
                onClose={handleCloseNotification}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{
                    style: {
                        maxWidth: '80em',
                        minWidth: "300px",
                        scrollbarWidth: "thin"
                    },
                }}
            >
                {notificationQuery.data?.map((notif) => {
                    return <MenuItem key={notif.id} onClick={handleCloseNotification}>
                        <Box sx={{ minWidth: "100%" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "10px" }}>
                                <Typography ><b>{t(notif.title)}</b></Typography>
                                {!notif.read && <CircleIcon sx={{ fontSize: "1em" }} color="error" />}
                            </Box >
                            <Typography sx={{ whiteSpace: "normal" }} variant="body2" gutterBottom>{
                                t(notif.message.replace(getNotifEmail(notif.message), '{0}')).replace('{0}', getNotifEmail(notif.message))}


                            </Typography>
                            {getAction(notif)}
                            <Divider sx={{ mt: "15px" }} />
                        </Box>
                    </MenuItem>
                })
                }
            </Menu>
        </>
    );
}

export default NotificationsMenu;