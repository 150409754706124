import { Navigate } from "react-router-dom";
import routes from "../../router/routes";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProjectService from '../../services/ProjectsService';
import FileManagementService from '../../services/FileManagementService';
import queryErrorHandler from "../../react-query/queryErrorHandler";
import { useTranslation } from 'react-i18next';

type Props = {
    children: JSX.Element;
}

const JobRouteGuard = ({ children }: Props) => {
    const { id: projectIdParam, jobId: jobIdParam, container: containerNameParam } = useParams();
    const [isValid, setIsValid] = useState<boolean>(true);
    const { t } = useTranslation();

    useEffect(() => {
        let getIterations = async () => {
            if (projectIdParam != undefined && jobIdParam == undefined && containerNameParam != undefined) {
                //consider it as new job creation
                setIsValid(true);
            } else if (projectIdParam != undefined && jobIdParam != undefined && containerNameParam != undefined) {
                const result = await new FileManagementService().canFetchContainer(containerNameParam);
                if (!result) {
                    setIsValid(result);
                }
                else {
                    const iterations = await new ProjectService().getIterations(projectIdParam, containerNameParam);
                    if (!iterations) {
                        setIsValid(false);
                    }
                    else {
                        setIsValid((iterations.find(i => i.id == jobIdParam) != null));
                    }
                }
            } else {
                setIsValid(false);
            }
        }
        getIterations();

    }, [projectIdParam, jobIdParam, containerNameParam]);

    if (!isValid) {
        queryErrorHandler(new Error(t("Page doesn't exist or parameters are invalid")));
        return <Navigate to={routes.projects} />;
    }
    return children;
};
export default JobRouteGuard;