/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@emotion/react';
import { useContext, useReducer, useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MoldMaterial from '../../store/job/mold-material';
import Tooltip from '@mui/material/Tooltip';
import JobContext from '../../store/job/job-context';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import { Box, createTheme, FormControl, IconButton, InputAdornment, MenuItem, Typography, useTheme, Button, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import theme from '../../styles/main-theme';
import CustomMaterialDialog, { MaterialFormFields, MaterialType, openSettings, OpenSettingsMode } from './CustomMaterialDialog';
import MaterialDetailDialog from './MaterialDetailDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import ConfirmationModal from '../common/ConfirmationModal';
import { TemperatureUnit, UiSettingsContext } from '../../store/uiSettings/UiSettingsContext';
import { convertTemperature } from '../../utils/units.utils';
import RangeMaterial from '../../store/job/range-material';
import { MoldMaterialCategory } from '../../store/job/mold-categories';
import { useTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import MaterialsUploadErrors from '../common/MaterialsUploadErrors';
import { useAddMaterialMutation, useMaterials, useUpdateMaterialMutation, useDeleteMaterialMutation } from '../projects/hooks/useMaterials';


const customTheme = createTheme({
    components: {
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: theme.palette.background.paper

                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: { color: theme.palette.background.paper },
                root: { color: theme.palette.error.main }
            }
        }
    }
});

let savedMemoryState: MaterialProps;

export interface MaterialSelectorProps {
    categoryName: MoldMaterialCategory,
    moldGroupId: string,
    moldGroupName?: string,
    rangeMaterial: RangeMaterial,
    setMoldTemperatureFor?: string[],
    hideTemperatureInput?: boolean,
    disabled: boolean
}

interface MaterialDialogDispatchData {
    action: string,
    data: MoldMaterial | undefined | null
}

type MaterialProps = {
    materialType: MaterialType,
    materials: MoldMaterial[]
}

export default function MaterialSelector({ categoryName, moldGroupId, moldGroupName, setMoldTemperatureFor, rangeMaterial, hideTemperatureInput, disabled }: MaterialSelectorProps) {
    const uiSettingsContext = useContext(UiSettingsContext);
    const [materialType, setMaterialType] = useState<MaterialType>(categoryName == "Parts" ? MaterialType.Part : MaterialType.Mold);
    const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
    const [selectedMaterialToDelete, setSelectedMaterialToDelete] = useState<MoldMaterial | null>(null);
    const [openMaterialsUploadErrors, setOpenMaterialsUploadErrors] = useState(false);
    const materialsQuery = useMaterials();
    
    let materials = (categoryName == "Parts" ?  materialsQuery.data?.data?.partMaterials ?? [] :  materialsQuery.data?.data?.moldMaterials ?? []);

    materials = (categoryName == "Parts") ? 
                (materialsQuery.data?.data?.userPartMaterials.forEach((m) => { m.userMaterial = true }), 
                materials?.concat(materialsQuery.data?.data?.userPartMaterials ?? [])) : 
                (materialsQuery.data?.data?.userMoldMaterials.forEach((m) => { m.userMaterial = true }), 
                materials?.concat(materialsQuery.data?.data?.userMoldMaterials ?? []));

    const addMaterialMutation = useAddMaterialMutation();
    const updateMaterialMutation = useUpdateMaterialMutation();
    const deleteMaterialMutation = useDeleteMaterialMutation();

    const { t } = useTranslation();
    const theme = useTheme();
    const openModelReducer = (state: openSettings, value: MaterialDialogDispatchData) => {
        if (value.action == "openEdit") {
            return {
                open: true,
                mode: OpenSettingsMode.Edit,
                data: value.data
            }
        } else if (value.action == "openCreate") {
            return {
                open: true,
                mode: OpenSettingsMode.Create,
                data: null
            }
        } else if (value.action == "close") {
            return {
                ...state,
                open: false
            }
        }

        return state;
    }

    const [openCustomMaterialSettings, dispatchCustomMaterialDialog] = useReducer(openModelReducer,
        {
            open: false,
            mode: OpenSettingsMode.Create,
            data: null
        });

    const jobContext = useContext(JobContext);
    const classes = makeStyles({
        container: {
            display: "flex",
            flexWrap: "nowrap",
            alignContent: "center",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        file: {
            fontSize: '1.1em',
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
            fontWeight: '600',
        },
        input: {
            display: 'none'
        },
        fileUpload: {
            display: 'inline-block',
            padding: '10px 12px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            borderRadius: "20px",
            margin: "5px",
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
            fontWeight: '600',
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        iconLoad: {
            position: "relative",
            color: theme.palette.primary.dark,
            transform: 'scale(0.8)',
            margin: 0,
            marginBottom: "5px",
            backgroundColor: theme.palette.info.dark,
            "&:hover": {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        selectItem: {
            flex: "1",
            minWidth: "0",
            maxWidth: "70em"
        },
        select: {
            borderRadius: "2em"
        },
        btnIcon: {
            position: "relative",
            padding: "2px 10px 2px 10px",
            color: theme.palette.primary.dark,
            marginLeft: "5px"
        },
        temperatureInput: {
            borderRadius: "2em",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: theme.palette.secondary.dark,
            paddingLeft: "0.9em",
            paddingRight: "0.2em",
            maxWidth: "105px"
        },
        item: {
            display: "flex",
            flexDirection: "row",
            "&:last-child": {
                marginLeft: "auto"
            }
        },
        ellipsisSelect: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
    })();

    const handleChange = (event: SelectChangeEvent<string>) => {
        if (event.target.value) {
            if (event.target.value == "downloadMaterial") {
                return;
            }
            else if (event.target.value == "uploadMaterial") {
                event.stopPropagation();
                setDataMemory({ materialType: categoryName == "Parts" ? MaterialType.Part : MaterialType.Mold, materials: materials });
                var csvFileInput = document.getElementById("csvFileInput");
                csvFileInput?.click();
            } else if (event.target.value == "addMaterial") {
                dispatchCustomMaterialDialog({ action: "openCreate" } as MaterialDialogDispatchData);
                return;
            } else {
                updateSelectedMaterial(event);
            }
        }
    };

    const updateSelectedMaterial = (event: SelectChangeEvent<string>) => {
        let selectedMaterial = materials.find(material => material.id.toString() === event.target.value);
        if (selectedMaterial) {
            selectedMaterial = Object.assign({}, selectedMaterial);
            selectedMaterial.moldGroupId = moldGroupId;
        }
        setSelectedMaterial(selectedMaterial);
    }

    const setMoldTemperature = (selectedMaterial: MoldMaterial | undefined) => {
        if (setMoldTemperatureFor && setMoldTemperatureFor.length > 0) {
            //If selecting a material for plastic part, we need to set mold temperature for mold if possible
            if (typeof (selectedMaterial!.moldTemperature) === 'number') {
                setMoldTemperatureFor.forEach(category => {
                    jobContext.setMaterialTemperature(category, selectedMaterial!.moldTemperature!, moldGroupId);
                });
            }
        }
    }

    const setSelectedMaterial = (selectedMaterial: MoldMaterial | undefined) => {
        setMoldTemperature(selectedMaterial);

        jobContext.setCategoryMaterial(categoryName, selectedMaterial!, moldGroupId, moldGroupName);
    }

    const getRecommendedMoldTemperature = (material: MoldMaterial) => {
        const convertedValue = convertTemperature({ value: material.moldTemperature ? material.moldTemperature : 0, unit: TemperatureUnit.C }, uiSettingsContext.uiSettings.temperatureUnit);
        return material.moldTemperature ? `${t("Recommended Target Mold Temperature")}: ${convertedValue.value} ${convertedValue.unit} \n` : '';
    }

    const getSafeEjectionTemperature = (material: MoldMaterial) => {
        const convertedValue = convertTemperature({ value: material.ejectionTemperature ? material.ejectionTemperature : 0, unit: TemperatureUnit.C }, uiSettingsContext.uiSettings.temperatureUnit);
        return material.ejectionTemperature ? `${t("Safe Ejection Temperature")}: ${convertedValue.value} ${convertedValue.unit}` : '';
    }

    const onNewMaterialSubmit = (newMaterialData: MaterialFormFields, type: MaterialType) => {
        dispatchCustomMaterialDialog({ action: "close" } as MaterialDialogDispatchData);

        const newMaterial = createMaterialFromFields(newMaterialData);

        if (categoryName == "Parts") {
            setMoldTemperature(newMaterial);
        }

        addMaterialMutation([[newMaterial], type]);
    }

    const editCustomMaterial = (material: MoldMaterial) => {
        dispatchCustomMaterialDialog({ action: "openEdit", data: material });
    }

    const onMaterialUpdate = (updatedMaterialFields: MaterialFormFields) => {
        dispatchCustomMaterialDialog({ action: "close" } as MaterialDialogDispatchData);

        const updatedMaterial = createMaterialFromFields(updatedMaterialFields);

        if (categoryName == "Parts") {
            setMoldTemperature(updatedMaterial);
        }

        updateMaterialMutation([[updatedMaterial], materialType]);
        setSelectedMaterial(updatedMaterial);
    }

    const onSetMaterials = async (materialType: MaterialType, materialsToAdd: MoldMaterial[], materialsToUpdate: MoldMaterial[]) => {
        if (materialsToUpdate && materialsToUpdate.length > 0) {
            await updateMaterialMutation([materialsToUpdate, materialType]);
        }
        if (materialsToAdd && materialsToAdd.length > 0) {
            await addMaterialMutation([materialsToAdd, materialType]);
        }
        $("#csvFileInput").val("");
    }

    const createMaterialFromFields = (materialFields: MaterialFormFields): MoldMaterial => {
        return {
            id: materialFields.id.value ?? "0",
            name: materialFields.name.value ?? "",
            temperature: Number(materialFields.temperature.value) ?? 0,
            specificHeat: Number(materialFields.specificHeat.value) ?? 0,
            density: Number(materialFields.density.value) ?? 0,
            thermalConductivity: Number(materialFields.thermalConductivity.value) ?? 0,
            ejectionTemperature: materialType == MaterialType.Part ? Number(materialFields.ejectionTemperature.value) ?? 0 : undefined,
            moldTemperature: materialType == MaterialType.Part ? Number(materialFields.moldTemperature.value) ?? 0 : undefined,
            userMaterial: true,
            userContainer: openCustomMaterialSettings.data?.userContainer,
            creatorUserRecordKey: openCustomMaterialSettings.data?.creatorUserRecordKey,
            moldGroupId: moldGroupId,
            moldGroupName: moldGroupName
        };
    }

    const createMoldMaterial = (fields: MoldMaterial): MoldMaterial => {
        return {
            id: crypto.randomUUID(),
            name: fields.name,
            temperature: fields.temperature,
            specificHeat: fields.specificHeat,
            density: fields.density,
            thermalConductivity: fields.thermalConductivity,
            ejectionTemperature: (savedMemoryState.materialType == MaterialType.Part) ? fields.ejectionTemperature : undefined,
            moldTemperature: (savedMemoryState.materialType == MaterialType.Part) ? fields.moldTemperature : undefined,
            userMaterial: true,
            userContainer: openCustomMaterialSettings.data?.userContainer,
            creatorUserRecordKey: openCustomMaterialSettings.data?.creatorUserRecordKey,
        };
    }

    const deleteCustomMaterial = (material: MoldMaterial) => {
        setOpenDeleteConfirmationModal(true);
        setSelectedMaterialToDelete(material);
    }

    const onCloseDeleteMaterial = (answer: boolean) => {
        setOpenDeleteConfirmationModal(false);

        if (answer && selectedMaterialToDelete) {
            deleteMaterialMutation([selectedMaterialToDelete, materialType, moldGroupId]);

            const affectedMaterialIndex = jobContext.Categories.Materials[categoryName]?.findIndex(material => material && material.moldGroupId === moldGroupId);
            if (jobContext.Categories.Materials[categoryName] &&
                jobContext.Categories.Materials[categoryName][affectedMaterialIndex].id == selectedMaterialToDelete.id) {
                //Set default select
                const defaultSelection = materials.find((m) => { return m.id == "0" });
                if (defaultSelection) {
                    setSelectedMaterial(defaultSelection);
                }
            }
        }
    }

    const getDisplay = (material: MoldMaterial) => {
        return material?.isDeleted ? "none" : "block";
    }

    const currentMaterial = () => {
        const index = jobContext.Categories.Materials[categoryName]?.findIndex(material => material && material.moldGroupId === moldGroupId);
        return index !== -1 ?
            (jobContext.Categories.Materials[categoryName] && jobContext.Categories.Materials[categoryName][index] ? jobContext.Categories.Materials[categoryName][index].id : '0')
            : '0';
    }


    const [uploadError, setUploadError] = useState<string>('');
    const partHeaders = [
        { label: "Name", key: "name" },
        { label: "Temperature", key: "temperature" },
        { label: "Specific Heat", key: "specificHeat" },
        { label: "Density", key: "density" },
        { label: "Thermal Conductivity", key: "thermalConductivity" },
        { label: "Mold Temperature", key: "moldTemperature" },
        { label: "Ejection Temperature", key: "ejectionTemperature" },
    ];

    const moldHeaders = [
        { label: "Name", key: "name" },
        { label: "Specific Heat", key: "specificHeat" },
        { label: "Density", key: "density" },
        { label: "Thermal Conductivity", key: "thermalConductivity" }
    ];

    function checkUserMaterial(item: any) {
        return (item.userMaterial === true && !item.isDeleted)
    }
    const fileReader = new FileReader();
    const csvToJson = require('csvtojson');
    const validateCSV = (csv: string) => {
        var isValid = csv.includes('name') &&
            csv.includes('specificHeat') &&
            csv.includes('density') &&
            csv.includes('thermalConductivity');
        if (savedMemoryState.materialType == MaterialType.Part) {
            isValid = isValid && csv.includes('temperature') && csv.includes('moldTemperature') && csv.includes('ejectionTemperature');
        }
        else {
            isValid = isValid && !csv.includes('temperature') && !csv.includes('moldTemperature') && !csv.includes('ejectionTemperature');
        }
        setUploadError("");
        if (!isValid) {
            setUploadError("Invalid csv file format");
            setOpenMaterialsUploadErrors(true);
            $("#csvFileInput").val("");
        }
        return isValid;
    };

    function useMemoryState(initialState: any) {
        const [value, setValue] = useState(savedMemoryState ? savedMemoryState : initialState);

        useEffect(() => {
            savedMemoryState = value;
        }, [value]);

        return [value, setValue];
    }

    const [dataMemory, setDataMemory] = useMemoryState(
        {
            materials: [],
            materialType: MaterialType.Mold
        });

    const updCommaDelimitedDecimalFields = (item: any) => {
        item.specificHeat = item.specificHeat.replace(',', '.');
        item.density = item.density.replace(',', '.');
        item.thermalConductivity = item.thermalConductivity.replace(',', '.');
        item.thermalConductivity = item.thermalConductivity.replace(',', '.');
        item.temperature = item.temperature?.replace(',', '.');
        item.moldTemperature = item.moldTemperature?.replace(',', '.');
        item.ejectionTemperature = item.ejectionTemperature?.replace(',', '.');
        return item;
    }

    const handleOnUpload = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const materialsToAdd: MoldMaterial[] = [];
            const materialsToUpdate: MoldMaterial[] = [];
            fileReader.onload = function (event) {
                const csvOutput = event?.target?.result;
                var res = csvOutput?.toString()
                    .replace('Name', 'name')
                    .replace('Temperature', 'temperature')
                    .replace('Specific Heat', 'specificHeat')
                    .replace('Density', 'density')
                    .replace('Thermal Conductivity', 'thermalConductivity')
                    .replace('Mold Temperature', 'moldTemperature')
                    .replace('Ejection Temperature', 'ejectionTemperature');
                var isValid = res && validateCSV(res);
                if (isValid) {
                    csvToJson()
                        .fromString(res)
                        .subscribe((item: any) => {
                            item = updCommaDelimitedDecimalFields(item);
                            const fields = {
                                name: item.name,
                                specificHeat: item.specificHeat,
                                density: item.density,
                                thermalConductivity: item.thermalConductivity
                            } as MoldMaterial;
                            if (savedMemoryState.materialType == MaterialType.Part) {
                                fields.temperature = item.temperature;
                                fields.moldTemperature = item.moldTemperature;
                                fields.ejectionTemperature = item.ejectionTemperature;
                            }
                            const mat = createMoldMaterial(fields);
                            const userMaterial = savedMemoryState.materials && savedMemoryState.materials.find(m => m.name === mat.name && !m.isDeleted && m.userMaterial);
                            if (userMaterial) {
                                userMaterial.specificHeat = mat.specificHeat;
                                userMaterial.density = mat.density;
                                userMaterial.thermalConductivity = mat.thermalConductivity;
                                if (savedMemoryState.materialType == MaterialType.Part) {
                                    userMaterial.temperature = mat.temperature;
                                    userMaterial.moldTemperature = mat.moldTemperature;
                                    userMaterial.ejectionTemperature = mat.ejectionTemperature;
                                }
                                materialsToUpdate.push(userMaterial);
                            }
                            else {
                                const material = savedMemoryState.materials && savedMemoryState.materials.find(m => m.name === mat.name && !m.isDeleted && !m.userMaterial);
                                if (!material) {
                                    materialsToAdd.push(mat);
                                }
                            }
                        },
                            () => {
                                //on reject
                            },

                            () => {
                                onSetMaterials(savedMemoryState.materialType, materialsToAdd, materialsToUpdate);
                            }
                        );
                }
            };
            fileReader.readAsText(file);
        }
    };

    return (
        <ThemeProvider theme={customTheme}>
            <input
                className={classes.input}
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnUpload}
            />
            <Grid container className={classes.container}>
                <Grid item className={classes.selectItem}>
                    <FormControl fullWidth size="small">
                        <Select
                            data-testid={`select-input-${categoryName}-${moldGroupId}`}
                            MenuProps={{
                                style: { zIndex: 1200 }
                            }}
                            labelId="material-label"
                            id="material-select"
                            value={currentMaterial()}
                            onChange={handleChange}
                            className={classes.select}
                            disabled={disabled}
                        >
                            <MenuItem value="addMaterial">
                                <Box sx={{ width: "100%", display: "flex", alignItems: "center", color: theme.palette.primary.main }}>
                                    <Typography sx={{ mr: "10px", color: theme.palette.primary.main }}><b>{t("Add material")}</b></Typography>
                                    <AddCircleIcon sx={{ fontSize: "1em" }}></AddCircleIcon>
                                </Box>
                            </MenuItem>
                            <MenuItem value="uploadMaterial">
                                <label >
                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", color: theme.palette.primary.main }}>
                                        <Typography sx={{ mr: "10px", color: theme.palette.primary.main }}><b>{t("Upload Materials")}</b></Typography>
                                        <UploadIcon sx={{ fontSize: "1em" }}></UploadIcon>
                                    </Box>
                                </label>
                            </MenuItem>
                            <MenuItem value="downloadMaterial">
                                <CSVLink
                                    style={{ textDecoration: 'none' }}
                                    target="_blank"
                                    data={materials.filter(checkUserMaterial)}
                                    headers={materialType == MaterialType.Mold ? moldHeaders : partHeaders}
                                    filename={"materials.csv"}>
                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center", color: theme.palette.primary.main }}>
                                        <Typography sx={{ mr: "10px", color: theme.palette.primary.main }}><b>{t("Download Materials")}</b></Typography>
                                        <DownloadIcon sx={{ fontSize: "1em" }} />
                                    </Box>
                                </CSVLink>
                            </MenuItem>
                            {materials.map((material: MoldMaterial, index) => (
                                <MenuItem data-testid={material.name + "-" + categoryName + "-" + material.id} sx={{ maxWidth: "300px", display: getDisplay(material) }} value={material.id.toString()} key={categoryName + "-" + material.id + " " + index}>
                                    <Box className={"materialItem"} sx={{ width: "100%", display: "flex", alignItems: "center" }} >
                                        {material?.userMaterial === true && <>
                                            <PersonIcon sx={{ fontSize: "1.2em", mr: "5px", color: theme.palette.primary.main }}></PersonIcon>
                                        </>}
                                        {categoryName == "Parts" && material.id != "0" ?
                                            <Tooltip
                                                className={classes.ellipsisSelect}
                                                placement="left"
                                                key={material.id}
                                                title={
                                                    <>
                                                        <div>{material.name}</div>
                                                        <div style={{ marginBottom: '5px' }}>{getRecommendedMoldTemperature(material)}</div>
                                                        <div>{getSafeEjectionTemperature(material)}</div>
                                                    </>
                                                }>
                                                <div>{material.name}</div>
                                            </Tooltip>
                                            :
                                            <Tooltip placement="left" title={material.name}>
                                                <Box className={classes.ellipsisSelect} sx={{ width: "100%" }}>{material.name == "Select Material" ? t(material.name) : material.name}</Box></Tooltip>
                                        }

                                        {material?.userMaterial === true && <>
                                            <Box className="userMaterialIconBox" sx={{ marginLeft: "auto", pl: "30px", display: "flex", alignItems: "center" }}>
                                                <IconButton onClick={(e) => { e.stopPropagation(); editCustomMaterial(material) }} size="small"><EditIcon className="editMaterialIcon" sx={{ cursor: "pointer", fontSize: "1.1em", color: theme.palette.primary.main }}></EditIcon></IconButton>
                                                <IconButton onClick={(e) => { e.stopPropagation(); deleteCustomMaterial(material) }} size="small"><DeleteIcon className="deleteMaterialIcon" sx={{ cursor: "pointer", fontSize: "1.1em", color: theme.palette.primary.main }}></DeleteIcon></IconButton>
                                            </Box>
                                        </>}

                                        {!material?.userMaterial && material?.name != 'Select Material' && <>
                                            <MaterialDetailDialog type={materialType} material={material} />
                                        </>}
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <CustomMaterialDialog
                openSettings={openCustomMaterialSettings}
                type={materialType}
                rangeMaterial={rangeMaterial}
                onClose={() => { dispatchCustomMaterialDialog({ action: "close" } as MaterialDialogDispatchData); }}
                onSubmit={onNewMaterialSubmit}
                onUpdate={onMaterialUpdate}
                materials={materials}
            ></CustomMaterialDialog>
            <ConfirmationModal
                open={openDeleteConfirmationModal}
                onClose={onCloseDeleteMaterial}
                title={t("Delete material")}
                content={t("Are you sure you want to delete this material?")}></ConfirmationModal>
            <MaterialsUploadErrors
                open={openMaterialsUploadErrors}
                message={uploadError}
                title={t('Upload Materials Error')}
                onClose={() => { setOpenMaterialsUploadErrors(false); }}></MaterialsUploadErrors>
        </ThemeProvider>
    );
}