import React, { PropsWithChildren, useReducer, Reducer } from "react";

export enum DisplayMode {
    CUTTING_PLANE,
    ISOVOLUME
}

export enum VtfxScalarResults {
    TEMPERATURE_RESULT = 'Temperature',
    FREEZE_TIME_RESULT = 'Freeze Time'
}

export enum VtfxCase {
    DEFAULT_VIEW = 'Default View',
    PLASTIC_SKIN = 'Plastic Skin Elements',
    FREEZE_TIME = 'Freeze Time'
}

export enum PartType {
    CAVITY = 'Cavity', // legacy
    CORE = 'Core', //legacy
    MOLD = 'Mold',
    PLASTIC = 'Plastic'
}

export enum DisplayPreset {
    MOLD,
    PLASTIC_VOLUME,
    PLASTIC_SURFACE,
    FREEZE_TIME
}

export interface ICeetronContext {
    ceetronState: CeetronState,
    updateCeetronState: React.Dispatch<CeetronStateAction>
}

export type VisibilityState = { 
    state: { [key: string]: {type: PartType, value:boolean} },
    skipAction: boolean
 }

 export interface ScalarResultsRange {
    min: number,
    max: number
 }

export enum CeetronActionType {
    SetVisibilityState,
    SetViewerReadyState,
    SetScalarResultsRange,
    SetScalarResults,
    SetDisplayMode,
    SetDisplayPreset,
    SetVtfxCase,
    SetVtfxStateIndex,
    SetVtfxDefaultStateIndex,
    SetVtfxLastStateIndex,
    SetVtfxStateInfoArray,
    SetScaleUpToDateState,
    SetCuttingPlanePosition,
    SetCuttingPlaneNormal,
    SetDisplayModeEnabled,
    SetMainTimeStepsEnabled,
    SetComparisonTimeStepsEnabled
}

export type CeetronStateAction =
    { type: CeetronActionType.SetVisibilityState, payload: VisibilityState }
    | { type: CeetronActionType.SetScalarResultsRange, payload: ScalarResultsRange }
    | { type: CeetronActionType.SetScalarResults, payload: VtfxScalarResults }
    | { type: CeetronActionType.SetViewerReadyState, payload: boolean }
    | { type: CeetronActionType.SetDisplayMode, payload: DisplayMode }
    | { type: CeetronActionType.SetDisplayPreset, payload: DisplayPreset }
    | { type: CeetronActionType.SetVtfxCase, payload: VtfxCase }
    | { type: CeetronActionType.SetVtfxStateIndex, payload: {value: number, modelIndex: number} }
    | { type: CeetronActionType.SetVtfxDefaultStateIndex, payload: {value: number, modelIndex: number} }
    | { type: CeetronActionType.SetVtfxLastStateIndex, payload: {value: number, modelIndex: number} }
    | { type: CeetronActionType.SetVtfxStateInfoArray, payload: {value: {label: string, id: number, value: number}[], modelIndex: number} }
    | { type: CeetronActionType.SetScaleUpToDateState, payload: boolean }
    | { type: CeetronActionType.SetCuttingPlanePosition, payload: number }
    | { type: CeetronActionType.SetCuttingPlaneNormal, payload: cee.Vec3Like }
    | { type: CeetronActionType.SetDisplayModeEnabled, payload: boolean }
    | { type: CeetronActionType.SetMainTimeStepsEnabled, payload: boolean }
    | { type: CeetronActionType.SetComparisonTimeStepsEnabled, payload: boolean }

export interface VtfxStateInfo {
    label: string,
    id: number,
    value: number
};

export interface VtfxState {
    stateInfoArray: VtfxStateInfo[],
    currentIndex: number,
    defaultIndex: number,
    lastIndex: number,
}

export type CeetronState = { 
    visibility: VisibilityState,
    scalarResultsRange: ScalarResultsRange,
    scalarResults: VtfxScalarResults,
    displayMode: DisplayMode,
    displayPreset: DisplayPreset,
    vtfxCase: VtfxCase,
    vtfxStates: VtfxState[],
    isViewerReady: boolean,
    isScaleUpToDate: boolean,
    cuttingPlanePosition: number,
    cuttingPlaneNormal: cee.Vec3Like,
    isDisplayModeEnabled: boolean,
    isMainTimeStepsEnabled: boolean,
    isComparisonTimeStepsEnabled: boolean
};

const initalState: CeetronState = {
    visibility: {
        state: {},
        skipAction: false
    },
    isViewerReady: false,
    displayMode: DisplayMode.CUTTING_PLANE,
    displayPreset: DisplayPreset.FREEZE_TIME,
    scalarResultsRange: {min: 0, max: 0},
    scalarResults: VtfxScalarResults.FREEZE_TIME_RESULT,
    vtfxCase: VtfxCase.FREEZE_TIME,
    vtfxStates: [{
        stateInfoArray: [],
        currentIndex: 0,
        defaultIndex: 0,
        lastIndex: 0,
    }, {
        stateInfoArray: [],
        currentIndex: 0,
        defaultIndex: 0,
        lastIndex: 0,
    }],
    isScaleUpToDate: true,
    cuttingPlanePosition: -5,
    cuttingPlaneNormal: {x: 0, y: 0, z: 1},
    isDisplayModeEnabled: true,
    isMainTimeStepsEnabled: true,
    isComparisonTimeStepsEnabled: true
};

export const CeetronContext = React.createContext<ICeetronContext>({
    ceetronState: initalState,
    updateCeetronState: () => {}
});

const reducer: Reducer<Readonly<CeetronState>, CeetronStateAction> = (state, action) => {
    const newState = Object.assign({}, state) as Partial<CeetronState>;
    switch (action.type) {
        case CeetronActionType.SetViewerReadyState:
            return Object.assign({}, state, { isViewerReady: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetScaleUpToDateState:
            return Object.assign({}, state, { isScaleUpToDate: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetVisibilityState:
            return Object.assign({}, state, { visibility: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetScalarResultsRange:
            return Object.assign({}, state, { scalarResultsRange: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetScalarResults:
            return Object.assign({}, state, { scalarResults: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetDisplayMode:
            return Object.assign({}, state, { displayMode: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetDisplayPreset:
            return Object.assign({}, state, { displayPreset: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetVtfxCase:
            return Object.assign({}, state, { vtfxCase: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetVtfxStateInfoArray:
            if (newState.vtfxStates) {
                newState.vtfxStates[action.payload.modelIndex].stateInfoArray = action.payload.value;
            }
            return Object.assign({}, state, newState);
        case CeetronActionType.SetVtfxStateIndex:
            if (newState.vtfxStates) {
                newState.vtfxStates[action.payload.modelIndex].currentIndex = action.payload.value;
            }
            return Object.assign({}, state, newState);
        case CeetronActionType.SetVtfxDefaultStateIndex:
            if (newState.vtfxStates) {
                newState.vtfxStates[action.payload.modelIndex].defaultIndex = action.payload.value;
            }
            return Object.assign({}, state, newState);
        case CeetronActionType.SetVtfxLastStateIndex:
            if (newState.vtfxStates) {
                newState.vtfxStates[action.payload.modelIndex].lastIndex = action.payload.value;
            }
            return Object.assign({}, state, newState);
        case CeetronActionType.SetCuttingPlanePosition:
            return Object.assign({}, state, { cuttingPlanePosition: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetCuttingPlaneNormal:
            return Object.assign({}, state, { cuttingPlaneNormal: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetDisplayModeEnabled:
            return Object.assign({}, state, { isDisplayModeEnabled: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetMainTimeStepsEnabled:
            return Object.assign({}, state, { isMainTimeStepsEnabled: action.payload } as Partial<CeetronState>);
        case CeetronActionType.SetComparisonTimeStepsEnabled:
            return Object.assign({}, state, { isComparisonTimeStepsEnabled: action.payload } as Partial<CeetronState>);
        default:
            throw new Error(`Action is not supported`)
    }
}

export const CeetronContextProvider = (props: PropsWithChildren<{}>) => {
    const [ceetronState, updateCeetronState] = useReducer(reducer, initalState);

    return (
        <CeetronContext.Provider value={{
            ceetronState: ceetronState, 
            updateCeetronState: updateCeetronState
        }}>
            {props.children}
        </CeetronContext.Provider>
    );
};
