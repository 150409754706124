import { intletColor, outletColor } from "../job/job-data";
import { MoldCategory } from "../job/mold-categories";

export const moldGroup1Color = Communicator.Color.green();
export const moldGroup2Color = Communicator.Color.createFromFloat(1.0, 0.5, 0);
export const inletSelectionModeColor = new Communicator.Color(intletColor.R, intletColor.G, intletColor.B);
export const outletSelectionModeColor = new Communicator.Color(outletColor.R, outletColor.G, outletColor.B);
export const channelSelectionModeColorInletDone = new Communicator.Color(200,55,55);
export const channelSelectionModeColorInletOutletDone = new Communicator.Color(37, 120, 227);
export const channelSelectionModeColorNoInlet = new Communicator.Color(200,200,51);
export const channelSelectionModeColorInletDoneSelected = new Communicator.Color(255,55,55);
export const channelSelectionModeColorInletOutletDoneSelected = new Communicator.Color(135, 179, 236);
export const channelSelectionModeColorNoInletSelected = new Communicator.Color(255,255,51);
export const channelSelectionModeColorDefaultSelected = new Communicator.Color(200, 200, 200);
export const channelSelectionModeColorDefault = new Communicator.Color(86, 86, 86);
export const baffleColor = new Communicator.Color(25,153,128);
export const categoryColors = {
    [MoldCategory.Core]: Communicator.Color.createFromFloat(1.0, 0.5, 0),
    [MoldCategory.Mold]: Communicator.Color.createFromFloat(1.0, 0.5, 0),
    [MoldCategory.BasicMold]: Communicator.Color.createFromFloat(1.0, 0.5, 0),
    [MoldCategory.Cavity]: Communicator.Color.green(),
    [MoldCategory.Part]: Communicator.Color.createFromFloat(0.5, 0, 1.0),
    [MoldCategory.Runner]: Communicator.Color.createFromFloat(0.5, 0, 1.0),
    [MoldCategory.Channel]: new Communicator.Color(200, 200, 200)
};