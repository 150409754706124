import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { CeetronActionType, CeetronContext, DisplayMode, DisplayPreset } from "../../../store/job/ceetron-context";
import { computeInitialCuttingPlanePosition, getScalarResultByName, matchScaleRange, statesAreEqual } from "../utils/ceetron.utils";
import { getCuttingPlanePosition, updateClippingPlanePosition } from "../../../utils/clippingPlane.utils";
import { JobSolveInputsParameters } from "../../../store/job/job-data";
import { ProjectType } from "../../../store/project/project-data";
import { Box, Button, CircularProgress, FormControl, FormControlLabel, InputAdornment, OutlinedInput, Radio, RadioGroup, ThemeProvider, Typography, createTheme } from "@mui/material";
import { DisplayPresetControl } from "./DisplayPresetControl";
import { CuttingPlane } from "../CuttingPlane";
import { IsoVolume } from "../IsoVolume";
import { StatesControl } from "../StatesControl";
import AuthContext from "../../../auth/AuthenticationContext";
import { useTranslation } from "react-i18next";
import theme from "../../../styles/main-theme";
import { truncate } from "../../../utils/formatting";
import { PartList } from "../PartList";
import Iteration, { getIterationMaterialEjectionTemperature } from "../../../store/project/iteration";
import makeStyles from "@mui/styles/makeStyles";
import FileManagementService from "../../../services/FileManagementService";
import DownloadIcon from '@mui/icons-material/Download';

interface JobResultViewManagerProps {
    projectType: ProjectType,
    isMainViewLoaded: boolean,
    isComparisonViewLoaded: boolean,
    isLoadingChannelGeometry: boolean,
    iteration: Iteration,
    comparisonIteration?: Iteration,
    wallThickness?: number,
    solverInputParameter?: JobSolveInputsParameters
    mainView: cee.View,
    mainModel: cee.ug.RemoteModel,
    constantMainModel?: cee.cug.ConstantRemoteModel,
    comparisonView?: cee.View,
    showCurrentComparisonView: boolean,
    comparisonModel?: cee.ug.RemoteModel,
    constantComparisonModel?: cee.cug.ConstantRemoteModel,
    coolingChannelsModel?: cee.geo.GeometryModel,
    comparisonCoolingChannelsModel?: cee.geo.GeometryModel,
    modelKey: string
}

const loadModelMessage = 'Full model is loading, please wait...';

const customTheme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: '0',
                    paddingRight: '0',
                    width: '27%'
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '0'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: theme.palette.primary.main
                    }
                }
            }
        },
    }
});

const useStyles = makeStyles({
    headers: {
        marginTop: "15px"
    },
    wrapper: {
        padding: "5px 8px 8px 12px",
        borderBottom: "1px solid"
    },
    container: {
        padding: "0px 20px 20px 0px",
        borderBottom: "1px solid"
    },
    titleCompare: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.5em',
        fontWeight: '400'
    },
    closeCompare: {
        left: '50%',
        transform: 'translateX(-50%)',
        textTransform: 'none',
        borderRadius: '1em',
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
        paddingLeft: "1em",
        paddingRight: "1em",
        marginBottom: "10px",
        backgroundColor: theme.palette.primary.contrastText,
        cursor: 'pointer',
        color: theme.palette.secondary.light,
        "&:hover": {
            color: theme.palette.primary.contrastText,
            borderColor: theme.palette.primary.contrastText,
            boxShadow: 'inset 0px 0px 0px 1px'
        }
    }
});

export const CeetroneSceneControl = (props: PropsWithChildren<JobResultViewManagerProps>) => {
    const [cuttingPlane, setCuttingPlane] = useState<cee.ug.CuttingPlane>();
    const [comparisonCuttingPlane, setComparisonCuttingPlane] = useState<cee.ug.CuttingPlane>();
    const [isoVolume, setIsovolume] = useState<cee.ug.Isovolume>();
    const [comparisonIsoVolume, setComparisonIsoVolume] = useState<cee.ug.Isovolume>();
    const { mainModel, mainView, constantMainModel, comparisonModel, comparisonView, showCurrentComparisonView, constantComparisonModel, isMainViewLoaded, isComparisonViewLoaded } = props;
    const context = useContext(CeetronContext);
    const authContext = useContext(AuthContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const [vtfxDownloading, setVtfxDownloading] = useState<boolean>(false);

    useEffect(() => {
        if (isMainViewLoaded) {
            context.updateCeetronState({
                type: CeetronActionType.SetCuttingPlanePosition,
                payload: computeInitialCuttingPlanePosition(mainModel, context.ceetronState.cuttingPlaneNormal)
            });

            if (!statesAreEqual(mainModel.modelDirectory, context.ceetronState.vtfxStates)) {
                setupStates(mainModel);
            }
        }
    }, [isMainViewLoaded]);

    useEffect(() => {
        if (comparisonModel && isComparisonViewLoaded) {
            if (!statesAreEqual(comparisonModel.modelDirectory, context.ceetronState.vtfxStates, 1)) {
                setupStates(comparisonModel, 1);
            }
        }
    }, [isComparisonViewLoaded, comparisonModel]);

    useEffect(() => {
        if (isMainViewLoaded && isComparisonViewLoaded) {
            matchScaleRange(mainView, comparisonView);
        }
    }, [isMainViewLoaded, isComparisonViewLoaded]);

    useEffect(() => {
        if (!mainModel || !mainView) {
            return;
        } else {
            handleDisplayMode(mainView, mainModel, context.ceetronState.displayMode);

            if (comparisonModel && comparisonView) {
                handleDisplayMode(comparisonView, comparisonModel, context.ceetronState.displayMode, true);
            }
        }
    }, [context.ceetronState.displayMode]);

    useEffect(() => {
        if (cuttingPlane && context.ceetronState.displayPreset == DisplayPreset.MOLD) {
            cuttingPlane.clipping = false;
        }
    }, [context.ceetronState.displayPreset]);

    useEffect(() => {
        if (comparisonModel && comparisonView) {
            handleStateIndexChange(comparisonModel, 1);
        }
    }, [context.ceetronState.vtfxStates[1].currentIndex]);

    useEffect(() => {
        if (mainModel && mainView) {
            handleStateIndexChange(mainModel, 0);
        }
    }, [context.ceetronState.vtfxStates[0].currentIndex]);

    useEffect(() => {
        if (!mainModel || !mainView) {
            return;
        } else {
            const currentCuttingPlane = mainModel.getCuttingPlaneAt(0);
            if (currentCuttingPlane) {
                currentCuttingPlane.normal = context.ceetronState.cuttingPlaneNormal;
                const normal = context.ceetronState.cuttingPlaneNormal;
                if (normal.x == 1 || normal.x == -1) {
                    context.ceetronState.cuttingPlanePosition = currentCuttingPlane.point.x;
                } else if (normal.y == 1 || normal.y == -1) {
                    context.ceetronState.cuttingPlanePosition = currentCuttingPlane.point.y;
                } else {
                    context.ceetronState.cuttingPlanePosition = currentCuttingPlane.point.z;
                }
                const currentComparisonCuttingPlane = comparisonModel?.getCuttingPlaneAt(0);
                if (currentComparisonCuttingPlane) {
                    currentComparisonCuttingPlane.normal = context.ceetronState.cuttingPlaneNormal;
                }

                //for channel bodies
                updateClippingPlane(mainView, mainModel, comparisonView, comparisonModel);
            }
        }
    }, [context.ceetronState.cuttingPlaneNormal]);

    useEffect(() => {
        if (!mainModel || !mainView || context.ceetronState.visibility.skipAction) {
            return;
        } else {
            if (constantMainModel) {
                if (context.ceetronState.displayPreset === DisplayPreset.MOLD) {
                    constantMainModel.getPartArray().forEach(p => {
                        const part = context.ceetronState.visibility.state[p.name];
                        p.visible = part ? part.value : false; // hide parts that are unknown
                    });
                    mainModel.getPartSettingsArray().forEach(settings => {
                        settings.visible = false;
                    });
                } else {
                    constantMainModel.getPartArray().forEach(p => {
                        p.visible = false;
                    });
                    mainModel.getPartSettingsArray().forEach(settings => {
                        const name = mainModel.modelDirectory.getPartNameById(settings.geometryIndex, settings.partId);
                        settings.visible = context.ceetronState.visibility.state[name] ? context.ceetronState.visibility.state[name].value : false;
                    });
                }
            }
            if (constantComparisonModel && comparisonModel) {
                if (context.ceetronState.displayPreset === DisplayPreset.MOLD) {
                    constantComparisonModel.getPartArray().forEach(p => {
                        const part = context.ceetronState.visibility.state[p.name];
                        p.visible = part ? part.value : false; // hide parts that are unknown
                    });
                    comparisonModel.getPartSettingsArray().forEach(settings => {
                        settings.visible = false;
                    });
                } else {
                    constantComparisonModel.getPartArray().forEach(p => {
                        p.visible = false;
                    });
                    comparisonModel.getPartSettingsArray().forEach(settings => {
                        const name = comparisonModel.modelDirectory.getPartNameById(settings.geometryIndex, settings.partId);
                        settings.visible = context.ceetronState.visibility.state[name] ? context.ceetronState.visibility.state[name].value : false;
                    });
                }
            }
        }
    }, [context.ceetronState.visibility]);

    useEffect(() => {
        if (context.ceetronState.isScaleUpToDate && context.ceetronState.displayPreset != DisplayPreset.MOLD) {
            matchScaleRange(mainView, comparisonView);
        }
    }, [context.ceetronState.isScaleUpToDate]);


    function setupStates(model: cee.ug.RemoteModel, modelIndex = 0) {
        const stateInfoArray = model.modelDirectory.stateInfoArray.map((si, index) => {
            return {
                label: `${t("Time")}: ${(si.referenceValue).toFixed(2)} s`,
                id: si.id,
                value: index
            }
        });
        context.updateCeetronState({ type: CeetronActionType.SetVtfxStateInfoArray, payload: { value: stateInfoArray, modelIndex } });
    }

    function handleStateIndexChange(model: cee.ug.RemoteModel, modelIndex = 0) {
        if (context.ceetronState.displayPreset !== DisplayPreset.FREEZE_TIME) {
            context.ceetronState.vtfxStates[modelIndex].lastIndex = context.ceetronState.vtfxStates[modelIndex].currentIndex;
        }
        restoreStateIdsArray(model, modelIndex);
    }

    function handleModeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const mode = parseInt(event.target.value) as DisplayMode;
        context.updateCeetronState({ type: CeetronActionType.SetDisplayMode, payload: mode });
    }

    function restoreStateIdsArray(model: cee.ug.RemoteModel, modelIndex = 0) {
        const stepId = model.modelDirectory.stateInfoArray[context.ceetronState.vtfxStates[modelIndex].currentIndex].id;
        model.modelSpec.stateIdArray = [stepId];
    }

    function handleDisplayMode(view: cee.View, model: cee.ug.RemoteModel, displayMode?: DisplayMode, isComparisonModel = false) {
        if (!model || !view) {
            return;
        } else {
            switch (displayMode ? displayMode : context.ceetronState.displayMode) {
                case DisplayMode.ISOVOLUME:
                    removeCuttingPlane(view, model, isComparisonModel);
                    addIsovolume(model, isComparisonModel);
                    break;
                default:
                    if (context.ceetronState.displayPreset != DisplayPreset.MOLD) {
                        addCuttingPlane(view, model, isComparisonModel);
                    }
                    removeIsovolume(model, isComparisonModel);
            }
        }
    }

    function updateClippingPlane(mainView: cee.View, mainModel: cee.ug.RemoteModel, comparisonView?: cee.View, comparisonModel?: cee.ug.RemoteModel,) {
        if (mainView && mainModel) {
            updateClippingPlanePosition(mainView, mainModel, context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
        }

        if (comparisonView && comparisonModel) {
            updateClippingPlanePosition(comparisonView, comparisonModel, context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
        }
    }

    function addCuttingPlane(view: cee.View, model: cee.ug.RemoteModel, isComparisonModel = false) {
        model.deleteAllCuttingPlanes();
        const currentCuttingPlane = model.addCuttingPlane();
        // restoring normal
        currentCuttingPlane.clipping = true;
        currentCuttingPlane.normal = context.ceetronState.cuttingPlaneNormal;
        currentCuttingPlane.point = updateCuttingPlane(currentCuttingPlane, currentCuttingPlane.normal, context.ceetronState.cuttingPlanePosition);
        const scalarResults = getScalarResultByName(model, context.ceetronState.scalarResults);
        // restoring scalar mapping
        if (scalarResults) {
            currentCuttingPlane.mapScalarResultId = scalarResults.id;
        }
        if (isComparisonModel) {
            setComparisonCuttingPlane(currentCuttingPlane);
        } else {
            setCuttingPlane(currentCuttingPlane);
        }

        updateClippingPlanePosition(view, model, context.ceetronState.cuttingPlanePosition, context.ceetronState.cuttingPlaneNormal);
    }

    function updateCuttingPlane(cuttingPlane: cee.ug.CuttingPlane, normal: cee.Vec3Like, position: number) {

        const cuttingPlanePosition = { x: cuttingPlane.point.x, y: cuttingPlane.point.x, z: cuttingPlane.point.z }
        return getCuttingPlanePosition(cuttingPlanePosition, normal, position);
    }

    function removeCuttingPlane(view: cee.View, model: cee.ug.RemoteModel, isComparisonModel = false) {
        if (view)
            view.clipping.removeAllPlanes();

        model.deleteAllCuttingPlanes();
        if (isComparisonModel) {
            setComparisonCuttingPlane(undefined);
        } else {
            setCuttingPlane(undefined);
        }
    }

    function addIsovolume(model: cee.ug.RemoteModel, isComparisonModel = false) {
        model.deleteAllIsovolumes();
        const isoVolume = model.addIsovolume();
        isoVolume.computeFromVisibleParts = true;
        isoVolume.minimumIsoValue = isoVolume.maximumIsoValue; // initialize iso volume with the least computing possible
        if (isComparisonModel) {
            setComparisonIsoVolume(isoVolume);
        } else {
            setIsovolume(isoVolume);
        }
    }

    function removeIsovolume(model: cee.ug.RemoteModel, isComparisonModel = false) {
        model.deleteAllIsovolumes();
        if (isComparisonModel) {
            setComparisonIsoVolume(undefined);
        } else {
            setIsovolume(undefined);
        }
    }

    const handleClickDownloadVTFXFile = async () => {
        setVtfxDownloading(true);
        const modelKey = props.modelKey;
        const vtfxfilelocation = modelKey.slice(modelKey.indexOf('/') + 1);
        const vtfxfile = await new FileManagementService().downloadFile(vtfxfilelocation, props.iteration.containerName);

        // Create a link element and click it to trigger the download
        const url = URL.createObjectURL(vtfxfile);

        const link = document.createElement('a');
        link.href = url;
        link.download = "jobResultFile.vtfx";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
        setVtfxDownloading(false);
    };

    return (<>
        {!isMainViewLoaded && <Box sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', color: theme.palette.info.light }}>
            <Typography variant="h6">{t(loadModelMessage)}</Typography>
        </Box>}
        {isMainViewLoaded && <Box sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', paddingTop: '3.5em', position: "relative" }}>
            <Typography variant="h6">{t("Display Mode")}</Typography>
            {(!context.ceetronState.isViewerReady || props.isLoadingChannelGeometry) && <Box sx={{ display: 'flex', alignItems: "center", position: "absolute", top: "10px", color: theme.palette.text.primary }}>
                <CircularProgress size="1.3em" sx={{ marginRight: "10px", color: theme.palette.grey[700] }} />
                <Typography sx={{ color: theme.palette.grey[700] }}>{t("Working to update view...")}</Typography>
            </Box>}
            <DisplayPresetControl
                projectType={props.projectType as ProjectType}
                solverInputParameter={props.solverInputParameter}
                mainModel={mainModel}
                mainView={mainView}
                constantMainModel={props.constantMainModel}
                comparisonModel={comparisonModel}
                comparisonView={comparisonView}
                showCurrentComparisonView={showCurrentComparisonView}
                constantComparisonModel={props.constantComparisonModel}
                onHandleDisplayMode={handleDisplayMode}
                onRestoreStateIds={restoreStateIdsArray}
            />
            {((context.ceetronState.displayPreset === DisplayPreset.MOLD) || !!(props.coolingChannelsModel?.partCount || props.comparisonCoolingChannelsModel?.partCount)) &&
                <ThemeProvider theme={customTheme}>
                    <Box id="category-types-list">
                        <div className={classes.wrapper}>
                            <PartList model={mainModel}
                                parameters={props.solverInputParameter}
                                partsVisibility={context.ceetronState.visibility}
                                coolingChannelsModel={props.coolingChannelsModel}
                                comparisonCoolingChannelsModel={props.comparisonCoolingChannelsModel}
                                isLoadingGeometry={props.isLoadingChannelGeometry} />
                        </div>
                    </Box>
                </ThemeProvider>
            }
            {context.ceetronState.isDisplayModeEnabled && <Box id="cutplane-isovolume-display-modes">
                <div className={classes.wrapper}>
                    <FormControl>
                        <RadioGroup row defaultValue={DisplayMode.CUTTING_PLANE} onChange={handleModeChange}>
                            <FormControlLabel id="cutting-plane-display-mode" value={DisplayMode.CUTTING_PLANE} control={<Radio />} label={t("Cutting plane")} disabled={!context.ceetronState.isViewerReady} />
                            <FormControlLabel id="isovolume-display-mode" value={DisplayMode.ISOVOLUME} sx={{ marginLeft: '-2px', marginRight: '0' }} control={<Radio />} label={t("FilterPlastic")} disabled={!context.ceetronState.isViewerReady} />
                        </RadioGroup>
                    </FormControl>
                </div>
            </Box>
            }
            {context.ceetronState.isDisplayModeEnabled && context.ceetronState.displayMode === DisplayMode.CUTTING_PLANE
                && cuttingPlane && <Box id="cutplane-slider-section"><CuttingPlane view={mainView}
                    projectType={props.projectType as ProjectType}
                    cuttingPlane={cuttingPlane}
                    model={mainModel}
                    comparisonView={comparisonView}
                    comparisonModel={comparisonModel}
                    comparisonCuttingPlane={comparisonCuttingPlane}
                    updateCuttingPlane={updateCuttingPlane} /></Box>}
            {context.ceetronState.isDisplayModeEnabled && context.ceetronState.displayMode === DisplayMode.ISOVOLUME
                && isoVolume && <Box id="isovolume-slider-section"><IsoVolume model={mainModel} isoVolume={isoVolume} comparisonIsoVolume={comparisonIsoVolume}
                    ejectionTemperature={getIterationMaterialEjectionTemperature(props.iteration)} /></Box>}
            {context.ceetronState.isMainTimeStepsEnabled && context.ceetronState.vtfxStates[0].stateInfoArray.length > 1 &&
                <Box id="simulation-time-control">
                    <StatesControl model={mainModel} ejectionTime={props.iteration.solveInfoEjectionTime} modelIndex={0} name={isComparisonViewLoaded ? truncate(props.iteration.name, 30) : ''} />
                </Box>}
            {isComparisonViewLoaded && props.comparisonIteration && context.ceetronState.isComparisonTimeStepsEnabled && context.ceetronState.vtfxStates[1].stateInfoArray.length > 1 &&
                <Box id="simulation-time-control">
                    <StatesControl model={comparisonModel!} ejectionTime={props.comparisonIteration.solveInfoEjectionTime} modelIndex={1} name={truncate(props.comparisonIteration.name, 30)} />
                </Box>}
            {authContext.isSupervisor && context.ceetronState.displayPreset == DisplayPreset.FREEZE_TIME &&
                <Box className={classes.container}>
                    <Typography variant="h6" className={classes.headers}>{t("Frozen Wall Thickness")}</Typography>
                    <OutlinedInput disabled id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        sx={{
                            width: '100%',
                            marginRight: '1em',
                            marginTop: '1em'
                        }}
                        value={props.wallThickness} />
                </Box>}
            {authContext.isFeaturePreview_vtfxdownload &&
                <Box className={classes.container}>
                {vtfxDownloading &&
                    <CircularProgress size="1.2em" sx={{ color: theme.palette.grey[700] }} />
                }
                {<Button
                    sx={{
                        fontSize: '1.2em',
                        textTransform: 'none',
                        color: theme.palette.primary.contrastText,
                        fontWeight: 'bold'
                    }}
                    startIcon={<DownloadIcon sx={{ color: theme.palette.primary.contrastText }} />}
                    onClick={handleClickDownloadVTFXFile}
                    disabled={vtfxDownloading}>
                    {t("Download VTFX file")}
                </Button>}
            </Box>}
        </Box>}
    </>);
}