import { Channel } from "../../../store/job/channel";
import { ChannelEnd, Face, Mold, Part, SyntheticFace, Tree, TreeviewFace, TreeviewFaceGroup } from "../../../store/job/job-data";
import { TreeviewItemModel } from "../TreeviewNodeModel";
import { arePathsEqual } from "../utils/tree.utils";

function removeRoot(path: string): string {
    return `/${path.split('/').slice(2).join('/')}`;
}

function filterPartPredicate(node: TreeviewItemModel, item: { path: string }) {
    if (!node.path || !item.path) {
        return false;
    }

    return arePathsEqual(node.path, item.path);
};

function mapTreeViewFaceGroups(groups: TreeviewFaceGroup[], tree: Tree): TreeviewFaceGroup[] {
    const result: TreeviewFaceGroup[] = []; 

    for (const group of groups) {
        const config: (TreeviewFace|SyntheticFace)[] = [];

        for (const face of group.config) {
            const node = Object.values(tree).find(n => filterPartPredicate(n, face));

            if (!node) {
                continue;
            }

            if (Channel.isSyntheticFace(face)) {
                config.push({
                    ...face,
                    path: node.id,
                    nodeId: 0,
                    faceIndex: 0,
                });
            } else {
                config.push({
                    ...face,
                    nodeId: node.nodeIds[0]
                });
            }
        }

        if (config.length) {
            result.push({
                ...group,
                config
            });
        }
    }

    return result;
}

function mapTerminal(terminals: ChannelEnd[], tree: Tree): ChannelEnd[] {
    return terminals.map(t => ({
        ...t,
        node: Object.values(tree).find(node => filterPartPredicate(node, t))
    })).filter(t => t.node !== undefined).map(t => {
        const commonProperties = {
            id: t.id,
            name: t.name,
            type: t.type,
            path: t.node!.id,
            face: {
                faceIndex: t.face.faceIndex,
                nodeId: t.node!.nodeIds[0]
            }
        };

        if(Channel.isSyntheticFace(t.face)){
            return {
                ...commonProperties,
                face: {
                    ...commonProperties.face,
                    config: t.face.config
                }
            }
        }

        return commonProperties;
    });
}


export function mapParts(parts: Part[], tree: Tree): Part[] {
    const result: Part[] = [];

    for (const part of parts) {
        const node = Object.values(tree).find(node => filterPartPredicate(node, part));

        if (node) {
            result.push({
                id: node.id,
                nodesIds: node.nodeIds,
                name: part.name,
                path: part.path,
                cadFileName: part.cadFileName
            });
        } 
    }

    return result;
}

export function mapMolds(molds: Mold[], tree: Tree): Mold[] {
    const result: Mold[] = [];

    for (const mold of molds) {
        result.push({
            ...mold,
            bodies: [
                ...mapParts(mold.bodies, tree)
            ]
        });
    }

    return result;
}

export function mapChannels(channels: Channel[], tree: Tree): Channel[] {
    const result: Channel[] = [];

    for (const channel of channels) {
        const mappedChannel = new Channel({
            ...channel,
            inlets: mapTerminal(channel.inlets, tree),
            outlets: mapTerminal(channel.outlets, tree),
            bodies: [
                ...mapParts(channel.getBodyParts(), tree),
                ...mapTreeViewFaceGroups(channel.getBodyFaceGroups(), tree)
            ],
            baffles: mapTreeViewFaceGroups(channel.baffles, tree)
        });

        if (!mappedChannel.isEmpty()) {
            result.push(mappedChannel);
        }

    }

    return result;
}