export enum ProjectType {
    Design = "Design",
    Feasibility = "Feasibility"
}

export enum ProjectName {
    Design = "Design Project",
    Feasibility = "Feasibility Project"
}

export const projectTypes = [ProjectType.Design, ProjectType.Feasibility];
export const DEFAULT_TYPE = ProjectType.Design;