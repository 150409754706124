const routes = {
    root: '/',
    projects: '/projects',
    setup: '/projects/:type/:id/jobs/:jobId/:container/setup/',
    setups: '/projects/:type/:id/jobs/newJob/:container/setup',
    result: '/projects/:type/:id/jobs/:jobId/:container/results',
    login: '/aad-callback',
    error: '/error',
    authMetadata: '/projects/authMetadata',
    account: '/settings/account',
    uiSettings: '/settings/uisettings',
    metrics: '/settings/metrics',
    users: '/settings/users'
};

export default routes;