import axios from "axios";

const NotificationService = {
    GetNotifications: async () => {
        try {
            const response = await axios.get("/api/notification");
            return response.data;
        } catch {
            throw new Error('Error getting notifications');
        }
    },
    ReadNotifications: async (notificationIds: string[]) => {
        try {
            const config = { headers: {'Content-Type': 'application/json'} };
            const response = await axios.post("/api/notification/read", notificationIds, config);
            return response.data;
        } catch {
            throw new Error('Error updating read notifications');
        }
    }
}

export default NotificationService;