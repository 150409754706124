import { List, Tooltip, TextField, Box, ListItemText, ListItem, Typography, FormGroup, useTheme, IconButton } from "@mui/material"
import { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Collapse } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Part } from '../../store/job/job-data';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Parser from 'html-react-parser'
import MoldMaterial from '../../store/job/mold-material';
import { BasicMold } from "../../store/job/job-data";
import { useTranslation } from 'react-i18next';
import { TemperatureUnit, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext"
import { convertTemperature } from "../../utils/units.utils"

interface IterationMoldCategoryProps {
    elements: Part[] | undefined,
    material: MoldMaterial | undefined,
    basicMold: BasicMold | undefined,
    title: string
}

export const IterationMoldCategory = (props: IterationMoldCategoryProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation();
    const [expand, setExpand] = useState(false);
    const uiSettingsContext = useContext(UiSettingsContext);
    const classes = makeStyles({
        listHeader: {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "end",
            position: "relative",
            width: "100%"
        },
        expandIcon: {
            cursor: "pointer",
            color: theme.palette.primary.dark,
        },
        collapse: {
            width: "100%"
        },
        elementsTitle: {
            fontSize: "0.95em",
            color: theme.palette.primary.dark,
            cursor: "pointer"
        },
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    const isValidBasicMoldSize = (props: IterationMoldCategoryProps | undefined) => {
        return props &&
            props.basicMold?.size.x != 0 &&
            props.basicMold?.size.y != 0 &&
            props.basicMold?.size.z != 0
    }

    const handleOpenClick = () => {
        setIsOpen(!isOpen);
    }

    let details = [];
    let elements = props.elements;
    var material = props.material;
    var basicMold = props.basicMold;
    const tempUnit = uiSettingsContext.uiSettings.temperatureUnit;
    
    if (material) {
        if (material.density) {
            details.push({ label: t("Density"), value: material.density, units: 'kg/m<sup>3</sup>' });
        }
        if (material.thermalConductivity) {
            details.push({ label: t("Thermal Conductivity"), value: material.thermalConductivity, units: 'W/(m &#8451;)' });
        }
        if (material.specificHeat) {
            details.push({ label: t("Specific Heat"), value: material.specificHeat, units: 'J/(kg &#8451;)' });
        }
        if (material.temperature) {
            convertValueBasedOnTempUnit(material.temperature, "Temperature");
        }
        if (material.ejectionTemperature) {
            convertValueBasedOnTempUnit(material.ejectionTemperature, "Ejection Temperature");
        }
        if (material.moldTemperature) {
            convertValueBasedOnTempUnit(material.moldTemperature, "Target Mold Temperature");
        }
    }

    function convertValueBasedOnTempUnit (valueToConvert: number, labelString: string) {
        if (tempUnit == TemperatureUnit.F) {
            const convertedValue = convertTemperature({
                value: valueToConvert,
                unit: TemperatureUnit.C
            }, TemperatureUnit.F);
            details.push({ label: t(labelString), value: convertedValue.value, units: convertedValue.unit });
        }else {
            details.push({ label: t(labelString), value: valueToConvert, units: '&#8451;' });
        }
    }
    return (<div>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.dark }}>{props.title}</Typography>
        {basicMold && isValidBasicMoldSize(props) && <div>
            <TextField
                disabled
                variant="outlined"
                fullWidth
                key='basicMold-x'
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: theme.palette.primary.dark,
                    },
                }}
                label='Mold Size - X'
                value={basicMold?.size.x}
                InputProps={{
                    endAdornment: <Box sx={{ whiteSpace: "nowrap", width: '15px' }}>
                        <Typography variant="caption" color="GrayText">mm</Typography></Box>
                }}
                size="small"
                margin="normal"
            />
            <TextField
                disabled
                variant="outlined"
                fullWidth
                key='basicMold-y'
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: theme.palette.primary.dark,
                    },
                }}
                label='Mold Size - Y'
                value={basicMold?.size.y}
                InputProps={{
                    endAdornment: <Box sx={{ whiteSpace: "nowrap", width: '15px' }}>
                        <Typography variant="caption" color="GrayText">mm</Typography></Box>
                }}
                size="small"
                margin="normal"
            />
            <TextField
                disabled
                variant="outlined"
                fullWidth
                key='basicMold-z'
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: theme.palette.primary.dark,
                    },
                }}
                label='Mold Size - Z'
                value={basicMold?.size.z}
                InputProps={{
                    endAdornment: <Box sx={{ whiteSpace: "nowrap", width: '15px'}}>
                        <Typography variant="caption" color="GrayText">mm</Typography></Box>
                }}
                size="small"
                margin="normal"
            />
        </div>}
        {details.length > 0 && <IconButton onClick={() => setExpand(!expand)}
            sx={{ padding: '20px 0px 0px 0px', "&:hover": { backgroundColor: theme.palette.background.paper } }}>
            {expand && <KeyboardArrowUpIcon />}
            {!expand && <KeyboardArrowDownIcon />}
            <Typography sx={{ color: theme.palette.primary.contrastText }}>{material?.name}</Typography >
        </IconButton>}
        {details.length > 0 && <Collapse in={expand} className={classes.collapse}
            sx={{
                padding: expand ? '20px 5px 20px 5px' : '0px',
                backgroundColor: theme.palette.background.default,
                borderRadius: '4px'
            }}>
            <FormGroup>
                {details.map((detail, index) => (
                    <Tooltip placement="left" title={detail.value} key={index}>
                        <TextField
                            fullWidth
                            disabled
                            variant="outlined"
                            key={'material-detail-item-' + index}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: theme.palette.primary.dark,
                                },
                            }}
                            label={detail.label}
                            value={detail.value}
                            size="small"
                            margin="normal"
                            InputProps={{
                                endAdornment: <Box sx={{ whiteSpace: "nowrap" }}>
                                    <Typography variant="caption" color="GrayText">{Parser(detail.units)}</Typography></Box>
                            }}
                        /></Tooltip>
                ))}
            </FormGroup>
        </Collapse>}
        {elements && <br />}
        {elements && <div className={classes.listHeader}>
            {isOpen ?
                <ExpandLess className={classes.expandIcon} onClick={handleOpenClick} /> :
                <ExpandMore className={classes.expandIcon} onClick={handleOpenClick} />}
            <Typography className={classes.elementsTitle} onClick={handleOpenClick}>{elements.length + " " + t("element(s)")} </Typography>
        </div>}
        {elements && <Collapse in={isOpen} className={classes.collapse}>
            <List dense component="div" role="list">
                {elements && elements.map((part: Part) => {
                    const labelId = `element-list-item-${part.id}-label`;
                    return (
                        <ListItem key={part.id} role="listitem">
                            <ListItemText id={labelId} primary={`${part.name}`} />
                        </ListItem>
                    )})
                }
            </List>
        </Collapse>}
        <br />
    </div>
    );
}
export default IterationMoldCategory;