import axios, { AxiosResponse } from "axios";
import BlobInfo from "../store/job/blob-info";
import CadInfo from "../store/job/cad-info";
import FileInfo from "../store/job/file-info";
import ResourceCacheService from "./ResourceCacheService";


export default class FileManagementService {
    readonly resourceCache?: ResourceCacheService;

    constructor() {
        if ("caches" in globalThis) {
            this.resourceCache = new ResourceCacheService();
        }
    }

    async uploadData(formData: FormData, projectId: string, jobId: string): Promise<FileInfo> {
        try {
            const response = await axios.post('/api/file', formData, {
                headers: { "Content-Type": "multipart/form-data" },
                params: {
                    projectId,
                    jobId
                }
            });
            return response.data;

        } catch (error) {
            throw new Error('Error uploading file');
        }
    }

    async invokeConvertion(projectId: string, jobId: string, filename: string): Promise<CadInfo> {
        try {
            let encodedProjectId = encodeURI(projectId);
            let encodedJobId = encodeURI(jobId);
            const response = await axios.get('/api/file/convert', { params: { filename, projectId: encodedProjectId, jobId: encodedJobId } });
            return response.data;
        }
        catch (error) {
            throw new Error('Error converting file');
        }
    }

    async uploadGeometries(formData: FormData): Promise<any[]> {
        try {
            const response = await axios.post('/api/file/uploadgeometries', formData);
            return response.data
        } catch (error) {
            throw new Error('Error uploading geometrical files');
        }
    }

    async downloadFile(path: string, containerName: string, version: string | null = null): Promise<Blob> {
        try {
            if (version && this.resourceCache) {
                return this.downloadWithCache(this.resourceCache, path, version, containerName);
            }
            else {
                const response = await this.downloadNoCache(path, containerName);
                return response.data
            }
        } catch (error) {
            throw new Error('Error downloading file stream.');
        }
    }

    async uploadFileToStorage(projectId: string, jobId: string, containerName: string, file: File): Promise<string> {
        async function uploadChunk(chunk: Blob, start: number, filename: string, blockId: string) {
            const fileData = await chunk.arrayBuffer();

            await axios.post(`/api/storage/${projectId}/${jobId}/${containerName}/putBlobBlock?filename=${filename}&blockId=${encodeURI(blockId)}`, fileData, {
                headers: {
                    'Content-Type': file.type
                },
            });
        }

        function generateBlockId(start: number): string {
            const blockId = btoa(`${start}`.padStart(8, '0'));
            return blockId;
        }

        const chunkSize = 4 * 1024 * 1024; // 4MB
        const blockIds: string[] = [];
        const promises: Promise<void>[] = [];

        console.time(`File upload ${file.name}`);

        for (let i = 0; i < Math.ceil(file.size / chunkSize); i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            const blockId = generateBlockId(i);
            blockIds.push(blockId);

            promises.push(uploadChunk(chunk, start, file.name, blockId));
        }

        await Promise.all(promises);

        await axios.post(`/api/storage/${projectId}/${jobId}/${containerName}/putBlobBlockList?filename=${file.name}`, blockIds, { headers: { 'Content-Type': 'application/json' } });

        console.timeEnd(`File upload ${file.name}`);

        return file.name;
    }

    async removeTempFile(filename: string): Promise<void> {
        return await axios.delete(`/api/file/removeTemp/${filename}`);
    }

    async downloadBlob(blobInfo: BlobInfo, containerName: string): Promise<Blob> {
        return await this.downloadFile(blobInfo.uri, containerName, blobInfo.versionId);
    }

    async canFetchContainer(containerName: string): Promise<boolean> {
        const response = await axios.get('/api/file/canFetchContainer', { params: { containerName } });
        return response.data;
    }

    async removeBlobs(urls: string[], containerName: string) {
        try {
            const response = await axios.delete(`/api/file/removeBlobs/${containerName}`, {
                data: urls
            });
            return response.data
        } catch (error) {
            throw new Error('Error removing files');
        }
    }

    private async downloadNoCache(path: string, containerName: string): Promise<AxiosResponse> {
        return await axios.get('/api/file/downloadBlob', {
            params: { path, containerName },
            responseType: 'blob'
        });
    }

    private async downloadWithCache(cache: ResourceCacheService, path: string, version: string, containerName: string,): Promise<Blob> {
        var urlWithVersion = new URL(path);
        urlWithVersion.searchParams.set("version", version.toString());

        const cachedBlob = await cache.tryGetAsBlob(urlWithVersion);
        if (cachedBlob) {
            return cachedBlob;
        }

        const response = await this.downloadNoCache(path, containerName);
        if (response.status === 200) {
            await cache.put(urlWithVersion, response);
        }
        return response.data;
    }
}