import { Collection, CollectionKinds } from "./Collection";
import { Directives, composeDirective } from "./Directives";
import { Index } from "./Index";
import { Ratio } from "./Ratio";

export class Texture {
    readonly u: Ratio;
    readonly v?: Ratio;
    readonly w?: Ratio;

    constructor(u:Ratio, v?: Ratio, w?: Ratio) {
        this.u = u;
        this.v = v ?? new Ratio(0);
        this.w = w ?? new Ratio(0);
    }

    toString() : string {
        return composeDirective(Directives.Texture, `${this.u} ${this.v} ${this.w}`);
    }
}

export class Textures extends Collection<Texture> {
    constructor(values? : Texture[]) {
        super(CollectionKinds.Textures, values);
    }

    add(u:Ratio, v?: Ratio, w?: Ratio) : Index | undefined  {
        return this.push(new Texture(u, v, w));
    }
}