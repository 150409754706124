import * as cee from "@ceetron/common/CeeEnvisionWebComponents";

export function getCuttingPlanePosition(modelPosition: cee.Vec3Like, normal: cee.Vec3Like, position: number){
    let absolutePosition;
    if (normal.x == 1 || normal.x == -1) {
        absolutePosition = new cee.Vec3(position, modelPosition.y, modelPosition.z);
    } else if (normal.y == 1 || normal.y == -1) {
        absolutePosition = new cee.Vec3(modelPosition.x, position, modelPosition.z);
    } else {
        absolutePosition = new cee.Vec3(modelPosition.x, modelPosition.y, position);
    }
    return absolutePosition;
}

export function getClippingPlane(clippingPlanePosition: number, clippingPlaneNormal: cee.Vec3Like, model: cee.ug.RemoteModel): cee.Plane{
    const modelCenterPosition = model?.getBoundingBox().getCenter();
    let absolutePosition = getCuttingPlanePosition(modelCenterPosition, clippingPlaneNormal, clippingPlanePosition);
    const newPlane = cee.Plane.fromPointAndNormal(absolutePosition, clippingPlaneNormal)

    return newPlane;
}


export function updateClippingPlanePosition(view: cee.View,  model: cee.ug.RemoteModel, position: number, clippingPlaneNormal: cee.Vec3Like) {
    view.clipping.removeAllPlanes();

    const cuttingPlane = model.getCuttingPlaneAt(0);
    if(!cuttingPlane || cuttingPlane.clipping === false){
        return;
    } 

    //The clipping plane needs to be just in front of the remote model cutting plane otherwise we cant see inner voxels for plastic volume
    let adjustedPosition;
    if(clippingPlaneNormal.x == 1 || clippingPlaneNormal.y == 1 || clippingPlaneNormal.z == 1){
        adjustedPosition = position - 0.00001;
    }else{
        adjustedPosition = position + 0.00001;
    }

    const updatedPlane = getClippingPlane(adjustedPosition, clippingPlaneNormal, model);

    view.clipping.addPlane(updatedPlane);
}