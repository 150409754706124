import CancelIcon from '@mui/icons-material/Cancel';
import theme from "../../styles/main-theme";
import { IconButton, Button, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from 'react-i18next';

export interface CancelSubmittedJobProps {
    onCancel: () => void,
    listItem?: boolean
}

export const CancelSubmittedJob = (props: CancelSubmittedJobProps) => {
    const useStyles = makeStyles({
        cancelSubmit: {
            textTransform: 'none',
            borderRadius: '1em',
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            paddingLeft: "1em",
            paddingRight: "1em",
            margin: 0,
            color: theme.palette.error.dark,
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.light,
            "&:hover": {
                borderColor: theme.palette.error.dark,
                border: '0.0225em solid'
            }
        }
    });
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            {!props.listItem && <Tooltip title={t("The submitted job will not be solved and you can resubmit it later.")}>
                <Button startIcon={<CancelIcon />} className={classes.cancelSubmit}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onCancel();
                    }}>{t("Cancel")}</Button></Tooltip>}

            {props.listItem && <Tooltip title={t("Cancel submitted job. The job won't be solved and you will be able to resubmit it later.")}>
                <IconButton onClick={(e) => { e.stopPropagation(); props.onCancel(); }}>
                    <CancelIcon data-testid="cancel-submit-button"
                        sx={{ color: theme.palette.error.dark }}></CancelIcon>
                </IconButton></Tooltip>}

        </>
    )
}