import { Button, SxProps, Theme } from "@mui/material"
import { MouseEvent, ReactNode } from "react"

export type LinkTextButtonProps = {
    sx?: SxProps<Theme>
    ariaLabel?: string
    onClick: (e: MouseEvent<HTMLElement>) => void
    children: ReactNode
}

const LinkTextButton = (props: LinkTextButtonProps) => {
    return <Button onClick={props.onClick} aria-label={props.ariaLabel} sx={{ textDecoration: 'underline', fontWeight: 'bold', textTransform: 'none', fontSize: '0.8rem' }}>
        {props.children}
    </Button>
}

export default LinkTextButton;