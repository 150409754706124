import { useCallback, useContext, useEffect, useState } from "react"
import { MoldMaterialCategory } from "../../store/job/mold-categories"
import MoldMaterial from "../../store/job/mold-material"
import RangeMaterial from "../../store/job/range-material"
import { MaterialType } from "./CustomMaterialDialog"
import MaterialSelector from "./MaterialSelector"
import JobContext from "../../store/job/job-context"
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip } from "@mui/material"
import MaterialDetailDialog from "./MaterialDetailDialog"
import theme from "../../styles/main-theme"
import makeStyles from '@mui/styles/makeStyles';
import { convertTemperature } from "../../utils/units.utils"
import { TemperatureUnit, UiSettingsContext } from "../../store/uiSettings/UiSettingsContext"
import { useTranslation } from "react-i18next"
import debounce from 'lodash.debounce'
import JobData from "../../store/job/job-data"

export interface PlasticPartMaterialsSelectorProps{
    disableSideControl: boolean
    rangeMaterials: RangeMaterial
}

export const PlasticPartMaterialSelector = (props: PlasticPartMaterialsSelectorProps) => {
    const jobContext = useContext(JobContext)
    const uiSettingsContext = useContext(UiSettingsContext);
    const { t } = useTranslation();

    const currentTemperature = () => {
        const index = jobContext.Categories.Materials[MoldMaterialCategory.Parts]?.findIndex(material => material.moldGroupId === "0");
        return index !== -1 ?
            (jobContext.Categories.Materials[MoldMaterialCategory.Parts] && jobContext.Categories.Materials[MoldMaterialCategory.Parts][index] && jobContext.Categories.Materials[MoldMaterialCategory.Parts][index].temperature ?
                jobContext.Categories.Materials[MoldMaterialCategory.Parts][index].temperature as number : 0)
            : null;
    }

    const currentMoldTemperature = () => {
        const index = jobContext.Categories.Materials[MoldMaterialCategory.Parts]?.findIndex(material => material.moldGroupId === "0");
        return index !== -1 ?
            (jobContext.Categories.Materials[MoldMaterialCategory.Parts] && jobContext.Categories.Materials[MoldMaterialCategory.Parts][index] && jobContext.Categories.Materials[MoldMaterialCategory.Parts][index].temperature ?
                jobContext.Categories.Materials[MoldMaterialCategory.Parts][index].moldTemperature as number : 0)
            : null;
    }

    const plasticTempDebounceHandler = (updatedPlasticTemp: number, jobContext: JobData) => {
        if(updatedPlasticTemp != null){
            jobContext.setUserEnteredMaterialTemperature(MoldMaterialCategory.Parts, updatedPlasticTemp, "0");
        }   
    }

    const moldTempDebounceHandler = (updatedMoldTemp: number, jobContext: JobData) => {
        if(updatedMoldTemp != null){
            jobContext.setMoldTemperature(updatedMoldTemp);
        }  
    }

    const plasticTemperature = currentTemperature();
    const [plasticTemperatureState, setPlasticTemperatureState] = useState(plasticTemperature);

    const moldTemperature = currentMoldTemperature();
    const [moldTemperatureState, setMoldTemperatureState] = useState(moldTemperature);

    const debouncePlasticTempInputChangeHandler = useCallback(debounce(plasticTempDebounceHandler, 500), []);
    const debounceMoldTempInputChangeHandler = useCallback(debounce(moldTempDebounceHandler, 500), []);

    useEffect(() => {
        setTemperatureForDisplay();
    }, [JSON.stringify(jobContext.Categories.Materials[MoldMaterialCategory.Parts])])

    useEffect(() => {
        setTemperatureForDisplay();
    }, [uiSettingsContext.uiSettings.temperatureUnit])

    const setTemperatureForDisplay = () => {
        const currentTemperatureUnit = uiSettingsContext.uiSettings.temperatureUnit;

        if(!jobContext.Categories.Materials[MoldMaterialCategory.Parts]) return;

        const plasticMat = jobContext.Categories.Materials[MoldMaterialCategory.Parts][0];
        if(plasticMat && plasticMat.moldTemperature && plasticMat.temperature){
            setConvertedTempState(plasticMat.temperature, TemperatureUnit.C, currentTemperatureUnit, setPlasticTemperatureState);
            setConvertedTempState(plasticMat.moldTemperature, TemperatureUnit.C, currentTemperatureUnit, setMoldTemperatureState);
        }
    }

    const setConvertedTempState = (value: any, from: TemperatureUnit, to: TemperatureUnit, stateFn: any) => {
        const convertedValue = convertTemperature({
            value: value === '' ? 0 : Number(value),
            unit: from
        }, to);

        stateFn(convertedValue.value);
    }

    const getCelciusConvertedValue = (inputValue: any) => {
        const value = inputValue === '' ? 0 : Number(inputValue);

        const convertedValue = convertTemperature({
            value: value,
            unit: uiSettingsContext.uiSettings.temperatureUnit
        }, TemperatureUnit.C);

        return convertedValue;
    }

    const moldTempInputChangeHandler = (event: any) => {
        const userEnteredValue = event.target.value
        const celciusValue = getCelciusConvertedValue(userEnteredValue);

        debounceMoldTempInputChangeHandler(celciusValue.value, jobContext);
        setMoldTemperatureState(userEnteredValue);
    }

    const plasticTempInputChangeHandler = (event: any) => {
        const userEnteredValue = event.target.value;
        const celciusValue = getCelciusConvertedValue(userEnteredValue);

        debouncePlasticTempInputChangeHandler(celciusValue.value, jobContext);
        setPlasticTemperatureState(userEnteredValue);
    }

    const onPlasticTempInputBlur = () => {
        if(plasticTemperatureState == null || plasticTemperatureState as any == ""){
            const defaultValue = 0;
            jobContext.setUserEnteredMaterialTemperature(MoldMaterialCategory.Parts, defaultValue, "0");
            setPlasticTemperatureState(defaultValue);
        }
    }

    const onMoldTempInputBlur = () => {
        if(moldTemperatureState == null || moldTemperatureState as any == ""){
            const defaultValue = 0;
            jobContext.setMoldTemperature(defaultValue);
            setMoldTemperatureState(defaultValue);
        }
    }

    const useStyles = makeStyles({
        formMaterial: {
            maxWidth: "250px",
            fontSize: '1rem',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: '400',
            textTransform: 'none',
            '& .MuiIconButton-root': {
                '& fieldset': {
                    padding: '1px'
                }
            },
            '& .MuiInputLabel-root': {
                left: '10px'
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: "2em",
                    borderColor: theme.palette.text.disabled,
                    color: theme.palette.text.disabled,
                    padding: '17px 0px',
                    margin: '5px'
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.text.disabled,
                }
            }
        },
        item: {
            display: "flex",
            flexDirection: "row",
            "&:last-child": {
                marginLeft: "auto"
            }
        }
    });
    
    const classes = useStyles();

    const temperatureSelected: boolean = jobContext.Categories.Materials[MoldMaterialCategory.Parts]?.length > 0;

    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            {!props.disableSideControl && 
                <Box >
                    <MaterialSelector
                        categoryName={MoldMaterialCategory.Parts}
                        moldGroupId={'0'}
                        moldGroupName={"Part"}
                        rangeMaterial={props.rangeMaterials}
                        setMoldTemperatureFor={[MoldMaterialCategory.Molds]}
                        disabled={props.disableSideControl} />
                </Box>
            }
            {props.disableSideControl && jobContext.Categories.Materials.Parts?.length > 0 &&
                <Box>
                    <Tooltip placement="left" title={jobContext.Categories.Materials.Parts[0]?.name}>
                        <FormControl disabled variant="outlined" className={classes.formMaterial} sx={{minWidth: "100%"}} >
                            <InputLabel htmlFor="outlined-adornment-password" sx={{ width: '75%' }}>{jobContext.Categories.Materials.Parts[0]?.name}</InputLabel>
                            <OutlinedInput
                                type='text'
                                endAdornment={
                                    <InputAdornment position="end" sx={{ marginright: '10px' }} >
                                        <MaterialDetailDialog type={MaterialType.Part} material={jobContext.Categories.Materials.Parts[0]} />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Tooltip>
                </Box>
            }
            {temperatureSelected && 
                <Box id={"temperature-input-" + MoldMaterialCategory.Parts} sx={{display:"flex", justifyContent: "space-between", marginTop: "20px"}}>
                    <>      
                        <TextField
                            data-testid={"plastic-temperature-textfield"}
                            sx={{flexBasis: "auto", marginRight: "8px"}}
                            label={t("Plastic Injection")}
                            size="small"
                            disabled={props.disableSideControl}
                            onChange={plasticTempInputChangeHandler}
                            onBlur={onPlasticTempInputBlur}
                            value={plasticTemperatureState ?? ""}
                            type="number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{uiSettingsContext.uiSettings.temperatureUnit}</InputAdornment>
                                ),
                                sx: { borderRadius: 4 }
                            }}
                        />
                        <TextField
                            data-testid={"target-mold-temperature-textfield"}
                            sx={{flexBasis: "auto"}}
                            label={t("Target Mold")}
                            size="small"
                            disabled={props.disableSideControl}
                            onChange={moldTempInputChangeHandler}
                            onBlur={onMoldTempInputBlur}
                            value={moldTemperatureState ?? ""}
                            type="number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">{uiSettingsContext.uiSettings.temperatureUnit}</InputAdornment>
                                ),
                                sx: { borderRadius: 4 }
                            }}
                        />
                    </>
                </Box>
            }
        </Box>
    )
}