import JobService from "../../../services/JobService";
import JobData, { Status } from "../../../store/job/job-data";

export const saveJobChanges = async (jobContext: JobData | null | undefined, containerName: string) => {
    if (isJobReadyToBeSaved(jobContext)) {
        await JobService.saveJob(jobContext!, containerName);
    }
}

export const isJobReadyToBeSaved = (jobContext: JobData | null | undefined) => {
    return (jobContext?.Project &&
        jobContext?.JobId &&
        jobContext?.Status === Status.None);
}
