import { Box, CircularProgress } from '@mui/material';
import React, { PropsWithoutRef } from 'react';

export type SpinnerProps = {
  isLoading: boolean
  children: React.ReactNode
};

/**
 * Spinner 'parent' type component. Wraping an element with this component will render a spinner if isLoading true, or the children content otherwise,
 *
 * <Spinner isLoading={boolean}>
 *   <div>content</div>
 * </Spinner>
 */
const Spinner = (props: PropsWithoutRef<SpinnerProps>) => {

  return props.isLoading
    ? <Box sx={{
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      width: '100%',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      position: 'relative',
    }}><Box><CircularProgress /></Box></Box>
    : <>{props.children}</>

};

export default Spinner;
