import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Alert, Autocomplete, AutocompleteRenderGetTagProps, Select, MenuItem, Box,
    AutocompleteRenderInputParams, FormControl, InputLabel, Chip, Stack, TextField
} from '@mui/material';
import useInput from '../ui/hooks/use-input';
import { ProjectNameMinimumLengthError } from './ProjectsConfig';
import OkCancelDialogProps from '../ui/OkCancelDialogProps';
import Project from '../../store/project/project';
import { validateProjectName, validateTagName } from '../../services/FormsValidation';
import { makeStyles } from "@mui/styles";
import theme from "../../styles/main-theme";
import { DEFAULT_TYPE } from '../../store/project/project-data';
import { CanSeeCredits } from '../../utils/visibilityConfig.utils';
import AuthContext from '../../auth/AuthenticationContext';
import CreditsAccountContext from '../../store/creditsAccount/CreditsAccountContext';
import { useTranslation } from 'react-i18next';

export interface ProjectDialogProps extends OkCancelDialogProps {
    edit: boolean,
    title: string,
    buttonText?: string,
    project?: Project,
    numberOfCredits?: number,
    type: string,
    setDetails: (name: string, description: string, type: string, tags?: string[], currentProjectName?: string) => void
}

const ProjectDialog = (props: ProjectDialogProps) => {
    const descriptionElementRef = useRef<HTMLElement>(null);
    const [tagError, setTagError] = useState<boolean>(false);
    const [tagArray, setTagArray] = useState<string[]>([]);
    const [clearAutocomplete, setClearAutocomplete] = useState<number>(0);
    const [type, setType] = useState<string>(DEFAULT_TYPE);
    const creditsAccountContext = useContext(CreditsAccountContext);
    const authContext = useContext(AuthContext);
    const { t } = useTranslation();
    const { value: projectName,
        hasError: projectNameHasError,
        isValid: projectNameIsValid,
        valueChangedHandler: projectNameChangedHandler,
        errorMessage: projectNameErrorMessage,
        inputBlurHandler: projectNameBlurHandler,
        reset: resetName,
        setValue: setName
    } = useInput(validateProjectName, ProjectNameMinimumLengthError);

    const { value: description,
        valueChangedHandler: descriptionChangeHandler,
        setValue: setDescription,
        reset: resetDescription } = useInput(() => true, '');

    const { value: tags,
        valueChangedHandler: tagsChangeHandler,
        setValue: setTags,
        reset: resetTags } = useInput(() => true, '');

    useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            descriptionElement?.focus();
        }

        setName(props.project?.name ?? '');
        setDescription(props.project?.description ?? '');
        setTagArray(props.project?.tags ?? []);
    }, [props.open]);

    const splitTags = (tags: string[]) => {
        let splitTags: string[] = [];

        for (let tag of tags) {
            let spaceSplitTags = tag.split(" ");
            for (let splitTag2 of spaceSplitTags) {
                let commaSplitTags = splitTag2.split(",")
                commaSplitTags.forEach((tag) => { if (tag != "") splitTags.push(tag) })
            }
        }

        return splitTags;
    }

    const removeInvalidTags = (newval: string[]) => {
        let newTagArray: string[] = [];

        for (let tag of newval) {
            if (validateTagName(tag)) {
                newTagArray.push(tag)
            }
        }

        return newTagArray;
    }

    const submit = async () => {
        props.setDetails(projectName, description, props.type, tagArray, props.edit ? props.project?.id : undefined);
        props.OkHandler();
        resetForm();
    }

    const resetForm = () => {
        resetName();
        resetTags();
        resetDescription();
        setType(DEFAULT_TYPE);
    }

    const setCleanTagArray = (tags: string[]) => {
        let validTags = splitTags(tags);
        validTags = removeInvalidTags(validTags);
        setTagArray(validTags);
    }

    const onChangeAutocomplete = (e: any, newval: string[], reason: any) => {
        setTagError(false);
        setCleanTagArray(newval);
    }

    const renderTags = (value: string[], getTagProps: AutocompleteRenderGetTagProps) => {
        return value.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
    }

    const onInputBlur = (event: any) => {
        setTagError(false)

        let value = event.target.value;
        if (!value) return;

        let tags = splitTags([value]);
        let validTags = removeInvalidTags(tags);
        let newArray = tagArray.concat(validTags)
        setTagArray(newArray);

        setClearAutocomplete(clearAutocomplete + 1);
    }

    const onInputChange = (event: any) => {
        if (!validateTagName(event.target.value)) {
            setTagError(true)
        } else {
            setTagError(false)
        }
    }

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            error={tagError}
            size='small'
            id='tags'
            data-testid='tagInput'
            variant='outlined'
            label={t("Tags")}
            placeholder={t("Tags")}
            onBlur={onInputBlur}
            onChange={onInputChange}
        />
    }

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        select: {
            borderRadius: "2em",
            flex: "1",
            marginRight: "10px",
            minWidth: "0",
            width: "250px"
        },
        ellipsisSelect: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
    })();

    const handleClose = (event: any) => {
        resetForm();
        props.CancelHandler();
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            data-testid='dialog'
            open={props.open}
            onClose={props.OkHandler}
            scroll="paper"
            aria-label='project-dialog'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {CanSeeCredits(creditsAccountContext.accountConfiguration.AccountUsersConfiguration, authContext.role) && 
                    (<>
                        <Alert severity="warning">{t("Creating this project will consume")} <b>{props.numberOfCredits} {t("Credits")}</b></Alert>
                        <br />
                    </>)
                }

                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                </DialogContentText>
                <Stack spacing={2} sx={{ paddingTop: 1 }} direction='column'>
                    <TextField autoFocus
                        fullWidth
                        required
                        size='small'
                        id='project-name-dialog'
                        label={t("Project Name")}
                        variant='outlined'
                        error={projectNameHasError}
                        helperText={t(projectNameErrorMessage)}
                        onBlur={projectNameBlurHandler}
                        onChange={projectNameChangedHandler}
                        inputProps={{ maxLength: 500 }}
                        value={projectName} />
                    <TextField
                        fullWidth
                        size='small'
                        data-testid='description'
                        id='description'
                        label={t("Description")}
                        variant='outlined'
                        inputProps={{ maxLength: 500 }}
                        value={description}
                        onChange={descriptionChangeHandler} />
                    <Autocomplete
                        key={clearAutocomplete}
                        multiple
                        id="tags-filled"
                        value={tagArray}
                        options={new Array<string>()}
                        freeSolo
                        onChange={onChangeAutocomplete}
                        renderTags={renderTags}
                        renderInput={renderInput}
                    />
                </Stack>
            </DialogContent>
            <DialogActions >
                <Button id="add-project-button-dialog" onClick={submit} className={classes.btn} color="primary" disabled={!projectNameIsValid || tagError} aria-labelledby="project-dialog-submit-btn">
                    {props.buttonText ?? t("OK")}
                </Button>
                <Button onClick={handleClose} className={classes.btn}>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProjectDialog;