import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import CreditsAccountService from '../../services/CreditsAccountService';
import { queryClient } from '../../react-query/queryClient';
import { UserListModel } from '../../components/users/UserList';
import { useTranslation } from 'react-i18next';

export interface AccountUsersConfiguration{
    DisplayCreditsToInvitedUsers: boolean,
    [key: string]: any
}

export interface AccountConfiguration{
    AccountUsersConfiguration: AccountUsersConfiguration,
    [key: string]: any
}

const defaultAccountUserConfiguration: AccountUsersConfiguration = {
    DisplayCreditsToInvitedUsers: true
}

const defaultAccountConfiguration: AccountConfiguration = {
    AccountUsersConfiguration: defaultAccountUserConfiguration
}

const defaultCreditsAccountData: CreditsAccountContextValue = {
    refreshCreditsBalance: () => {},
    updateUsersConfiguration: () => {},
    balance: 0,
    isFree: false,
    averagePricePerUnit: 0,
    accountConfiguration: defaultAccountConfiguration,
    isMutating: false
}

const defaultCompanyAccountUsers: CompanyUsersContextValue = {
    users: [],
    removeUser: () => {}
};

export interface CreditsAccount{
    balance: number;
    isFree: boolean,
    averagePricePerUnit: number,
    accountConfiguration: AccountConfiguration
}

export interface CreditsAccountContextValue extends CreditsAccount{
    isMutating: boolean,
    refreshCreditsBalance: () => void;
    updateUsersConfiguration: (updatedUserConfig: AccountUsersConfiguration) => void
}

export interface CompanyUsersContextValue{
    users: UserListModel[],
    removeUser: (userId: string) => void
}

export const CreditsAccountContext = React.createContext<CreditsAccountContextValue>(defaultCreditsAccountData);
export const CompanyUsersContext = React.createContext<CompanyUsersContextValue>(defaultCompanyAccountUsers);

export const CreditsAccountContextProvider = (props: PropsWithChildren) => {
    const { t } = useTranslation();
    const [companyUsers, setCompanyUsers] = useState<UserListModel[]>([]);
    const [companyUsersLoaded, setCompanyUsersLoaded] = useState(false);

    useEffect(() => {
        loadUsers();
    }, [])

    const loadUsers = async () => {
        try{
            const users = await CreditsAccountService.GetAllUsers();
            setCompanyUsers(users);
        }catch(e){
            window.console.log(e)
        }finally{
            setCompanyUsersLoaded(true);
        }
    }

    const creditsAccountQuery = useQuery<CreditsAccount>('creditsAccount', async () => {
        var creditsAccount = await CreditsAccountService.GetCreditsAccount() as CreditsAccount;

        return creditsAccount;
    }, {
        initialData: 
        {
            balance: 0,
            isFree: true,
            averagePricePerUnit: 0,
            accountConfiguration: defaultAccountConfiguration
        },
        refetchInterval: 30000,
        refetchOnWindowFocus: true
    })

    const {mutate, isLoading: isLoading} = useMutation({
        mutationFn: async (data: AccountUsersConfiguration): Promise<AccountUsersConfiguration> => {
            await CreditsAccountService.UpdateUsersConfiguration(data);

            return data;
        },
        onSuccess: (data: AccountUsersConfiguration) => {
            
            let creditsAccountCopy = {...creditsAccountQuery.data} as CreditsAccount;
            let configurationCopy = {...creditsAccountQuery.data?.accountConfiguration}  as AccountConfiguration;

            creditsAccountCopy.accountConfiguration = configurationCopy

            creditsAccountCopy.accountConfiguration.AccountUsersConfiguration = data;

            queryClient.setQueryData(['creditsAccount'], creditsAccountCopy)
        }
    })

    const refreshCreditsBalance = () => {
        creditsAccountQuery.refetch();
    }

    const updateUsersConfiguration = (updatedUserConfig: AccountUsersConfiguration) => {
        mutate(updatedUserConfig);
    }

    const defaultCreditsAccount: CreditsAccountContextValue = {
        balance: creditsAccountQuery.data?.balance ?? 0,
        isFree: creditsAccountQuery.data?.isFree ?? false,
        averagePricePerUnit: creditsAccountQuery.data?.averagePricePerUnit ?? 0,
        accountConfiguration: creditsAccountQuery.data?.accountConfiguration ?? defaultAccountConfiguration,
        refreshCreditsBalance: refreshCreditsBalance,
        updateUsersConfiguration: updateUsersConfiguration,
        isMutating: isLoading
    }

    const companyUsersValue: CompanyUsersContextValue = {
        users: companyUsers,
        removeUser: async (userId: string) => {
            await CreditsAccountService.RemoveUserFromCreditsAccount(userId);
            const user = companyUsers.find((u) => {return u.id == userId});
            if(user){
                const deletedUser: UserListModel = {
                    ...user,
                    isDeleted: true
                }
                setCompanyUsers([...companyUsers.filter((u) => { return u.id != userId }), deletedUser]);
            }
        }
    }

    return (
        <CreditsAccountContext.Provider value={defaultCreditsAccount}>
            {!creditsAccountQuery.isFetched && !companyUsersLoaded &&
                <div>{t("Loading Account...")}</div>
            }

            {creditsAccountQuery.isFetched && 
                <CompanyUsersContext.Provider value={companyUsersValue}>
                    {companyUsersLoaded && 
                        props.children
                    }
                </CompanyUsersContext.Provider>
            }
        </CreditsAccountContext.Provider>
    );
};

export default CreditsAccountContext;

