import placeolderImgData from "./defaultMoldPlaceholder"
// just a placeholder
const ProjectThumbnail = (data: string | null | undefined, width: number, height: number) => {
let dataBase64 = data != null && data != undefined && data != "" ? "data:image/png;base64," + data : placeolderImgData;

return <>
    <div >
        <img width={width} height={height} src={dataBase64}/>
    </div>
</>
}
export default ProjectThumbnail;