import axios from "axios";

const AccountProductService = {
    GetBlobContainerName: async (): Promise<string> => {
        try {
            const response = await axios.get('/api/authmetadata/getUserBlobContainerName');
            return response.data;
        } catch {
            throw new Error('Error getting user blob container name');
        }
    }
}

export default AccountProductService;