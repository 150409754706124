import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from "react"
import ConfigurationService from '../config/ConfigurationService'
import ResourceCacheService from '../services/ResourceCacheService'
import { AuthContextProvider } from './AuthenticationContext'
import AuthenticationService from './AuthenticationService'
import LoginRouter from "./LoginRouter"

type AuthenticatedWrapperProps = {
    children: ReactElement
    authenticationService: AuthenticationService
    configurationService: ConfigurationService
}

const AuthenticatedWrapper: FC<AuthenticatedWrapperProps> = (props: AuthenticatedWrapperProps) => {
    const [postLoginDone, setPostLoginDone] = useState(false);
    
    const authenticationRequest = { 
        scopes: props.configurationService.configuration.authentication.scopes,
        state: "monTestDataRedirection:allo1234bonjour"
    }

    return <LoginRouter>
        {props.authenticationService.pca && <MsalProvider instance={props.authenticationService.pca}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authenticationRequest}>
                <AuthContextProvider authenticationService={props.authenticationService}>
                    <PostLogin authenticationService={props.authenticationService} setPostLoginDone={setPostLoginDone} />                  
                    {postLoginDone && props.children} 
                </AuthContextProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>} 
    </LoginRouter >
}

type PostLoginProps = {
    authenticationService: AuthenticationService
    setPostLoginDone: Dispatch<SetStateAction<boolean>>
}

const PostLogin = (props: PostLoginProps) => {
    useEffect(() => {
        //Set guided tour user Id so once completed, it wont show again for same user on any machine.
        const userId = props.authenticationService.userid;
        window.userGuiding.identify(userId)

        props.authenticationService.postLogin().then(() => {
            props.setPostLoginDone(true);

            props.authenticationService.onLogout(() => {
                const resourceCache = new ResourceCacheService();
                resourceCache.purge();
            });
        }, (error) => {
            //log out if error
            props.authenticationService.logout()
        })
    }, [props.authenticationService.username])

    return <></>
}

export default AuthenticatedWrapper