export const freeTrialUrlHint = "freetrial";
export const signupUrlHint = "signup";
export const invitationSuccessUrl = "/invitationSuccess";
export const invitationSuccessUrlHint = `${invitationSuccessUrl}?`;
export const invitationUrlHint = "?flow-hint=invitation";

export function isInvitationUrl(){
    const url = window.location.href;
    return url.includes(invitationUrlHint);
}

export function isInvitationSuccessUrl(){
    const url = window.location.href;
    return url.includes(invitationSuccessUrl);
}

export function getCurrentUrlParameters(){
    const url = window.location.href;
    return "?" + url.split('?')[1];
}