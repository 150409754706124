import { string } from "prop-types";
import { GetNodeFromId, mapToPart } from "../../../services/TreeviewFunctions";
import { Channel } from "../../../store/job/channel";
import JobData, { ChannelBodyType, Part, SyntheticFace, TreeviewFace, TreeviewFaceGroup } from "../../../store/job/job-data";
import { clearPoints, detectBafflesFromFaces, detectBafflesFromNodeId, drawFace, drawLine, drawPoint, Edge, Face, getChannelFromFaceColor, GetColorFn, getPlaneAreaBox, isChannelFace, makeFaceKey, makePointKey, MeshDataCopyElementIterable, parsePointKey, VertexGroup } from "../../../utils/hoops.utils";

export async function getChannelBodies(selected: TreeviewFace[], jobContext: JobData, hwv: Communicator.WebViewer, bodyType: ChannelBodyType, getColorFn: GetColorFn): Promise<(Part | TreeviewFaceGroup)[]> {
    if (bodyType === ChannelBodyType.PARTS) {
        const parts = mapToPart(selected.map(s => s.path), jobContext.Tree);
        return parts.filter(part => jobContext.Categories.Channel.every(c => c.hasPart(part) === false));
    }

    if (bodyType === ChannelBodyType.FACE_GROUPS) {
        const parts: TreeviewFaceGroup[] = [];

        for (const face of selected) {
            const isValid = await isChannelFace(face, hwv);

            if (isValid) {
                const channelFaces = await getChannelFromFaceColor({ nodeId: face.nodeId, faceIndex: face.faceIndex }, hwv, getColorFn);
                const channelTreeViewFaces: TreeviewFace[] = channelFaces.map(f => {
                    const part = GetNodeFromId(f.nodeId, jobContext);
                    return {
                        ...f,
                        path: part!.id
                    }
                }).filter(face => jobContext.Categories.Channel.every(c => c.hasFaces([face]) === false));

                parts.push({ id: '', name: '', config: channelTreeViewFaces } as TreeviewFaceGroup);
            }
        }

        return parts;
    }

    return [];
}

export async function detectBaffles(body: Part | TreeviewFaceGroup, hwv: Communicator.WebViewer, state: JobData): Promise<TreeviewFaceGroup[]> {
    const baffles: TreeviewFaceGroup[] = [];
    let faceGroups: Face[][] = [];

    if (Channel.isPart(body)) {
        faceGroups = (await Promise.all(
            body.nodesIds.map(async nodeId => await detectBafflesFromNodeId(nodeId, hwv))
        )).flat();
    }

    if (Channel.isTreeviewFaceGroup(body)) {
        faceGroups = await detectBafflesFromFaces(body.config, hwv);
    }

    for (const group of faceGroups) {
        const treeViewFaces: TreeviewFace[] = group.map(f => {
            const node = GetNodeFromId(f.nodeId, state);

            return {
                ...f,
                path: node?.id ?? ''
            }
        }).filter(f => f.path !== '');

        baffles.push({
            id: '',
            name: '',
            config: treeViewFaces
        });
    }

    return baffles;
}

export async function repairBaffle(baffles: TreeviewFaceGroup[], bodyFaces: TreeviewFace[], hwv: Communicator.WebViewer, jobContext: JobData) {
    const meshDataCache = new Map<number, {
        meshData: Communicator.MeshDataCopy,
        translationMatrix: Communicator.Matrix
    }>();
    const nodeIds = new Set<number>([
        ...bodyFaces.map(f => f.nodeId),
        ...baffles.flatMap(b => b.config).map(f => f.nodeId)
    ]);

    for (const nodeId of nodeIds) {
        const meshData = await hwv.model.getNodeMeshData(nodeId);
        const translationMatrix = hwv.model.getNodeNetMatrix(nodeId);

        meshDataCache.set(nodeId, {
            meshData,
            translationMatrix
        });
    }

    for (const baffle of baffles) {
        const [openEdges, allEdges] = getOpenEdges(baffle.config, bodyFaces, meshDataCache);

        for (const edge of [...openEdges, ...allEdges]) {
            const props = await hwv.model.getEdgeProperty(edge.nodeId, edge.edgeIndex);
            edge.setProps(props);
        }

        const surfaceVertices = await buildSurfaces(openEdges, allEdges, hwv);
        for (const sv of surfaceVertices) {
            const meshInfo = drawFace(sv.vertices, hwv);
            const part = GetNodeFromId(sv.nodeId, jobContext);

            if (meshInfo && part) {
                baffle.config.push({
                    path: part.path,
                    nodeId: sv.nodeId,
                    faceIndex: 0,
                    config: meshInfo
                })
            }

        }
    }
}

export async function filterDuplicateSelectedChannelBodies(selected: TreeviewFace[], jobContext: JobData, hwv: Communicator.WebViewer, bodyType: ChannelBodyType, faceOriginalColor: any) {
    let bodies = await getChannelBodies(selected, jobContext, hwv, bodyType, faceOriginalColor);

    if (jobContext.SelectionMode == 1) {
        // Check if any bodies are similar
        let similarBodiesToRemove: TreeviewFaceGroup[] = [];
        bodies.forEach((body, index) => {
            bodies.slice(index + 1).forEach((otherBody) => {
                if (Channel.isTreeviewFaceGroup(body) && Channel.isTreeviewFaceGroup(otherBody)) {
                    //compare selected bodies if they are same channel faces
                    if (compareConfigs(body.config, otherBody.config)) {
                        similarBodiesToRemove.push(otherBody);
                    }
                }
            });
        });

        bodies = bodies.filter(body => {
            return Channel.isTreeviewFaceGroup(body) && !similarBodiesToRemove.some(bodyToRemove => bodyToRemove.config === body.config)
        }
        );
    }

    return bodies;
}

// Function to compare two configurations
function compareConfigs(config1: (TreeviewFace)[], config2: (TreeviewFace)[]) {
    if (config1.length !== config2.length) {
        return false;
    }
    let similarFaceIndexes = 0;
    for (let i = 0; i < config1.length; i++) {
        for (let j = 0; j < config2.length; j++) {

            if (config1[i].faceIndex == config2[j].faceIndex) {
                similarFaceIndexes++;
            }
        }
    }
    if (similarFaceIndexes == config1.length && similarFaceIndexes == config2.length) {
        return true;
    } else {
        return false;
    }
}

export function validateSelectedFaces(selected: TreeviewFace[], jobContext: JobData): {
    isValid: boolean,
    message: string
} {
    const selectedParts = mapToPart(selected.map(s => s.path), jobContext.Tree);

    for (const channel of jobContext.Categories.Channel) {
        const hasFaces = channel.hasFaces(selected);
        const hasParts = selectedParts.some(part => channel.hasPart(part));

        if (hasParts || hasFaces) {
            return {
                isValid: false,
                message: 'One or more of the selected items already belong to another channel'
            }
        }
    }

    return {
        isValid: true,
        message: ''
    }
}

function getOpenEdges(baffleFaces: (TreeviewFace | SyntheticFace)[], bodyFaces: TreeviewFace[], mdCache: Map<number, {
    meshData: Communicator.MeshDataCopy,
    translationMatrix: Communicator.Matrix
}>): [Edge[], Edge[]] {
    const bodyFaceVertices = new Map<string, Set<number>>();
    const baffleFaceVertices = new Set<string>();
    const baffleFaceKeys = baffleFaces.map(makeFaceKey);
    const bodyFaceKeys = [
        ...bodyFaces,
        ...baffleFaces.filter(Channel.isSyntheticFace)

    ].map(makeFaceKey);
    const nodeIds = new Set<number>([
        ...baffleFaces.map(f => f.nodeId),
        ...bodyFaces.map(f => f.nodeId)
    ]);

    for (const nodeId of nodeIds.values()) {
        const md = mdCache.get(nodeId)!.meshData;
        const translationMatrix = mdCache.get(nodeId)!.translationMatrix;

        for (let faceIndex = 0; faceIndex < md.faces.elementCount; faceIndex++) {
            const faceKey = makeFaceKey({ nodeId, faceIndex });

            if (bodyFaceKeys.length && bodyFaceKeys.includes(faceKey) === false) {
                continue;
            }

            [...md.faces.element(faceIndex) as MeshDataCopyElementIterable].forEach(v => {
                const point = Communicator.Point3.createFromArray(v.position);
                const pointKey = makePointKey(translationMatrix.transform(point));

                if (bodyFaceVertices.has(pointKey)) {
                    bodyFaceVertices.get(pointKey)!.add(faceIndex);
                } else {
                    bodyFaceVertices.set(pointKey, new Set([faceIndex]));
                }

                if (baffleFaceKeys.includes(faceKey)) {
                    baffleFaceVertices.add(pointKey);
                }
            })
        }
    }

    const edges: Edge[] = [];
    const allEdges: Edge[] = [];

    for (const nodeId of nodeIds.values()) {
        const md = mdCache.get(nodeId)!.meshData;
        const translationMatrix = mdCache.get(nodeId)!.translationMatrix;

        for (let edgeIndex = 0; edgeIndex < md.lines.elementCount; edgeIndex++) {
            const vertices = [...md.lines.element(edgeIndex) as MeshDataCopyElementIterable].map(v => translationMatrix.transform(
                Communicator.Point3.createFromArray(v.position)
            ));
            const vKeys = vertices.map(makePointKey);
            const edgeBelongsToBaffle = vKeys.length > 0 === true && vKeys.every(v => baffleFaceVertices.has(v));
            const edgeIsShared = vKeys.length > 0 === true && vKeys.every(key => bodyFaceVertices.has(key) && bodyFaceVertices.get(key)!.size > 1);

            if (edgeBelongsToBaffle === true && edgeIsShared === false) {
                const edge = new Edge(nodeId, edgeIndex, vertices);
                edges.push(edge);
            }

            if (edgeBelongsToBaffle) {
                allEdges.push(new Edge(nodeId, edgeIndex, vertices));
            }

        }

    }

    return [edges, allEdges];
}

async function buildSurfaces(edges: Edge[], allEdges: Edge[], hwv: Communicator.WebViewer): Promise<VertexGroup[]> {
    const refSurfaces: VertexGroup[] = [];

    function validateRectangularSurface(surface: VertexGroup, allEdges: Edge[], hwv: Communicator.WebViewer): boolean {
        const edge1Left = surface.edges[0].vertices[0];
        const edge1Right = surface.edges[0].vertices[surface.edges[0].vertices.length - 1];
        const edge2Left = surface.edges[1].vertices.sort((v1, v2) => Communicator.Point3.distance(v1, edge1Left) - Communicator.Point3.distance(v2, edge1Left))[0];
        const edge2Right = surface.edges[1].vertices.sort((v1, v2) => Communicator.Point3.distance(v1, edge1Right) - Communicator.Point3.distance(v2, edge1Right))[0];
        const center = surface.bb.center();

        const cp1 = Communicator.Util.closestPointFromPointToSegment(edge1Left, edge2Left, center);
        const cp2 = Communicator.Util.closestPointFromPointToSegment(edge1Right, edge2Right, center);

        const firstLineFragmentBelongsToBaffle = allEdges.some(e => {
            return Communicator.Util.isPointOnLineSegment(e.vertices[0], e.vertices[e.vertices.length - 1], cp1, 0.01);
        });
        const secondLineFragmentBelongsToBaffle = allEdges.some(e => {
            return Communicator.Util.isPointOnLineSegment(e.vertices[0], e.vertices[e.vertices.length - 1], cp2, 0.01);
        });
        return firstLineFragmentBelongsToBaffle && secondLineFragmentBelongsToBaffle;
    }

    function onTheSameSideFromSurface(surface: VertexGroup, edge1: Edge, edge2: Edge): boolean {
        const plane = surface.plane;
        const v1 = edge1.vertices.filter(v => (Math.round(100 * plane.distanceToPoint(v)) / 100 !== 0));
        const v2 = edge2.vertices.filter(v => (Math.round(100 * plane.distanceToPoint(v)) / 100 !== 0));

        let side = plane.determineSide(v1[0]);

        return [...v1, ...v2].every(v => plane.determineSide(v) === side);
    }

    for (const edge of edges) {
        const path = refSurfaces.map(s => s.key).join('/');

        if (path.includes(edge.key)) {
            continue;
        }

        const availableEdges = edges.filter(e => Edge.isEqual(e, edge) === false).filter(e => path.includes(e.key) === false);

        if (!edge.isLine() && edge.vertices.length > 2) {
            const connectedEdges: Edge[] = availableEdges
                .filter(e => Edge.areConnected(e, edge) === true)
                .filter(e => Edge.areOnSamePlane(e, edge) === true);

            const refSurface = new VertexGroup(edge.nodeId, [edge, ...connectedEdges]);
            refSurfaces.push(refSurface);
        } else if (edge.isLine()) {
            const otherEdges = availableEdges
                .filter(e => e.isLine() === true)
                .filter(e => Edge.areOnSamePlane(e, edge) === true)
                .filter(e => Edge.areConnected(e, edge) === false);

            for (const oe of otherEdges) {
                const refSurface = new VertexGroup(edge.nodeId, [edge, oe]);
                const isValid = validateRectangularSurface(refSurface, allEdges.filter(e => e.isLine()), hwv);

                if (isValid) {
                    refSurfaces.push(refSurface);
                }
            }
        }
    }

    const stack = [...refSurfaces];

    while (stack.length) {
        const refSurface = stack.pop()!;
        const path = refSurfaces.map(s => s.key).join('/');
        const avaliableEdges = edges.filter(e => path.includes(e.key) === false).filter(e => refSurface.isEdgeConnected(e, hwv));

        const samePlaneEdges = avaliableEdges.filter(e => Edge.areOnSamePlane(e, refSurface) === true);

        if (samePlaneEdges.length) {
            samePlaneEdges.forEach(spe => refSurface.addEdge(spe));
            stack.push(refSurface);

            continue;
        }

        const otherPlaneEdges = avaliableEdges.filter(e => Edge.areOnSamePlane(e, refSurface) === false);

        for (const oe of otherPlaneEdges) {
            const path = refSurfaces.map(s => s.key).join('/');
            const parallelEdges = otherPlaneEdges
                .filter(pe => Edge.isEqual(pe, oe) === false)
                .filter(pe => path.includes(pe.key) === false)
                .filter(pe => Edge.areOnSamePlane(oe, pe));

            for (const pe of parallelEdges) {
                if (onTheSameSideFromSurface(refSurface, oe, pe)) {
                    const linearSurface = new VertexGroup(pe.nodeId, [oe, pe]);

                    refSurfaces.push(linearSurface);
                    stack.push(linearSurface);
                }
            }
        }
    }

    return [...refSurfaces];
}


