/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Fab, InputAdornment, TextField, useTheme } from '@mui/material';
import JobContext from '../../store/job/job-context';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HoopsVisibilityContext } from '../../store/job/hoops-visibility-context';
import { getBasicMoldPart } from '../../services/TreeviewFunctions';
import { useTranslation } from 'react-i18next';
import { ProjectType } from '../../store/project/project-data';
import { VisibilityMode, VisibilityStateActionType } from '../../store/job/hoops-visibility-reducer';
import debounce from 'lodash.debounce';

interface CustomListProps {
    isLoading: boolean,
    disabled: boolean
}

export default function BasicMoldBlockDisplay(props: PropsWithChildren<CustomListProps>) {
    const { t } = useTranslation();
    const jobContext = useContext(JobContext);
    const { updateVisibility, mode: visibilityMode, getVisibility } = useContext(HoopsVisibilityContext);
    const [displayedSize, setDisplayedSize] = useState<{x: string, y: string, z: string}>({x: '0', y: '0', z: '0'});
    const basicMoldPart = useMemo(() => getBasicMoldPart(jobContext.basicMold.nodesIds), [jobContext.basicMold.nodesIds]);
    const theme = useTheme();
    const [feasibilityProject, setFeasibilityProject] = useState<boolean>(false);
    const classes = makeStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: "1.3em"
        },
        paper: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1em 1em 1.4em 1em"
        },
        materialSelectorContainer: {
            width: "100%",
            marginBottom: "20px"
        },
        icon: {
            position: "relative",
            padding: "5px 15px 5px 15px",
            color: theme.palette.primary.dark,
            marginLeft: "10px"
        }
    })();

    useEffect(() => {
        const size = jobContext.basicMold.size;
        if (jobContext.basicMold.enabled            
            && size.x > 0
            && size.y > 0
            && size.z > 0) {
            setDisplayedSize({x: size.x + '', y: size.y + '', z: size.z + ''});
        }
    }, [jobContext.basicMold.enabled, jobContext.basicMold.size]);

    useEffect(() => {
        setFeasibilityProject(jobContext?.ProjectType == ProjectType.Feasibility);
    }, [jobContext?.ProjectType]);

    const onTextFieldChangeX = (value: string) => {
        setDisplayedSize({x: value, y: displayedSize.y, z: displayedSize.z});
    };

    const onTextFieldChangeY = (value: string) => {
        setDisplayedSize({x: displayedSize.x, y: value, z: displayedSize.z});
    };

    const onTextFieldChangeZ = (value: string) => {
        setDisplayedSize({x: displayedSize.x, y: displayedSize.y, z: value});
    };

    useEffect(() => {
        onDisplaySizeChange(displayedSize, jobContext);
    }, [displayedSize]);

    const onDisplaySizeChange = useCallback(debounce((displayedSize, jobContext) => {
        const newX = displayedSize.x ? Number(displayedSize.x) : 0;
        const newY = displayedSize.y ? Number(displayedSize.y) : 0;
        const newZ = displayedSize.z ? Number(displayedSize.z) : 0;
        const validMoldSize = newX > 0 && newY > 0 && newZ > 0;
        const moldSizeHasChanged = newX != jobContext.basicMold.size.x || newY != jobContext.basicMold.size.y || newZ != jobContext.basicMold.size.z;
        if (validMoldSize && moldSizeHasChanged) {
            jobContext.setBasicMoldSize({
                x: newX,
                y: newY,
                z: newZ,
            });
        }
    }, 300), []);

    const toggleVisibility = () => {
        const newVisibility = !getVisibility(basicMoldPart);

        updateVisibility({
            type: VisibilityStateActionType.SET_PART_VISIBILITY,
            items: [basicMoldPart],
            visibility: newVisibility
        });
    }

    useEffect(() => {
        updateVisibility({
            type: VisibilityStateActionType.SET_PART_VISIBILITY,
            items: jobContext.basicMold.enabled ? (jobContext.basicMold.nodesIds.length > 0 ? [getBasicMoldPart(jobContext.basicMold.nodesIds)] : []) : [],
            visibility: jobContext.basicMold.enabled ? jobContext.basicMold.nodesIds.length > 0 : false
        });
    }, [jobContext.basicMold.nodesIds]);

    return (
        <Box id={"section-basic-mold"} sx={{ position: "relative" }}>
            {props.isLoading &&
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", width: "100%", height: "100%" }}>
                    <CircularProgress sx={{ color: theme.palette.primary.main }} />
                </Box>
            }
            <Paper elevation={1} className={classes.paper}>
                <div className={classes.header}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.dark }}>{feasibilityProject ? t("Mold Material") : t("Basic Mold")}</Typography>
                    {!feasibilityProject && <div>
                        <Fab id='visibility-icon-basic-mold' disabled={visibilityMode === VisibilityMode.ISOLATED} className={classes.icon} variant="extended" size="small" color="default" aria-label="toggle visibility" onClick={toggleVisibility}>
                            {getVisibility(basicMoldPart) ? <Visibility /> : <VisibilityOff />}
                        </Fab>
                    </div>}
                </div>
                {!feasibilityProject && <div>
                    <div id={"size-x-input-basic-mold"} className={classes.materialSelectorContainer}>
                        <TextField id={"basic-mold-size-x-input"} label={t("Mold Size - X")} type='string' disabled={props.disabled}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => onTextFieldChangeX(event.target.value)}
                            value={displayedSize.x}
                            sx={{
                                width: '13em',
                                '& .MuiInputBase-root': { borderRadius: '2em' },
                                '& .MuiInputLabel-root': { fontSize: '1.2rem', backgroundColor: theme.palette.background.paper }
                            }}
                        />
                    </div>
                    <div id={"size-y-input-basic-mold"} className={classes.materialSelectorContainer}>
                        <TextField id={"basic-mold-size-y-input"} label={t("Mold Size - Y")} type='string' disabled={props.disabled}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => onTextFieldChangeY(event.target.value)}
                            value={displayedSize.y}
                            sx={{
                                width: '13em',
                                '& .MuiInputBase-root': { borderRadius: '2em' },
                                '& .MuiInputLabel-root': { fontSize: '1.2rem', backgroundColor: theme.palette.background.paper }
                            }}
                        />
                    </div>
                    <div id={"size-z-input-basic-mold"} className={classes.materialSelectorContainer}>
                        <TextField id={"basic-mold-size-z-input"} label={t("Mold Size - Z")} type='string' disabled={props.disabled}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => onTextFieldChangeZ(event.target.value)}
                            value={displayedSize.z}
                            sx={{
                                width: '13em',
                                '& .MuiInputBase-root': { borderRadius: '2em' },
                                '& .MuiInputLabel-root': { fontSize: '1.2rem', backgroundColor: theme.palette.background.paper }
                            }}
                        />
                    </div>
                </div>
                }
                <div id={"material-select-basic-mold"} className={classes.materialSelectorContainer}>
                    {props.children}
                </div>
            </Paper>
        </Box>
    );
}