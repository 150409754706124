import { Grid, Stack, useTheme } from "@mui/material"
import Iteration from "../../store/project/iteration"
import DisplayFreezeTime from "../../utils/freezeTime.utils"

interface JobResultProps {
    iteration: Iteration
}

export default function IterationInfoPanel(props: JobResultProps) {
    const theme = useTheme();
    
    return (
        <Grid container sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            color: theme.palette.primary.contrastText,
            zIndex: 2
        }}>
            <Grid item>
                <Stack id="estimated_cooling_time_container" direction="row">
                    {props.iteration.solveInfo && <DisplayFreezeTime solveInfo={props.iteration.solveInfo}/>}
                </Stack>
            </Grid>
        </Grid>
    )
}