import { AuthenticationConfig, AuthenticationJsonConfig } from "../auth/AuthenticationService";
import CompanyInvitationService from "../services/CompanyInvitationService";
import CreditsAccountService from "../services/CreditsAccountService";
import { freeTrialUrlHint, getCurrentUrlParameters, invitationSuccessUrl, invitationUrlHint, isInvitationUrl, signupUrlHint } from "../utils/urls.utils";
import configJson from './config.json'

export type ApplicationJsonConfiguration = {
    authentication: AuthenticationJsonConfig
    apiBaseUrl: string
};

export type ApplicationConfiguration = {
    authentication: AuthenticationConfig
    apiBaseUrl: string
};

export default class ConfigurationService {

    private _configuration!: ApplicationConfiguration;

    constructor() {

    }

    public async setupConfig(){
        const appConfig = configJson as unknown as { [env: string]: ApplicationJsonConfiguration };
        const environment = process.env.REACT_APP_NODE_ENV_OVERRIDE || process.env.NODE_ENV;
        const current: ApplicationJsonConfiguration = appConfig[environment];
        if (!current) {
            console.warn(`No configuration available for NODE_ENV "${environment}"`)
        }

        if(isInvitationUrl()){
            this._configuration = this.getInvitationB2cConfig(current);
        }else{
            this._configuration = this.getB2cConfig(current);
        }
    }

    public getInvitationB2cConfig(config: ApplicationJsonConfiguration){
        return {
            apiBaseUrl: config.apiBaseUrl,
            authentication: {
                tenantId: config.authentication.tenantId,
                clientId: config.authentication.clientId,
                redirectUri: this.getRedirectUri(config),
                scopes: config.authentication.scopes,
                authority: this.getInvitationAuthority(config),
                knownAuthorities: config.authentication.knownAuthorities
            }
        };
    }

    public getB2cConfig(config: ApplicationJsonConfiguration){
        return {
            apiBaseUrl: config.apiBaseUrl,
            authentication: {
                tenantId: config.authentication.tenantId,
                clientId: config.authentication.clientId,
                redirectUri: this.getRedirectUri(config),
                scopes: config.authentication.scopes,
                authority: this.getAuthority(config),
                knownAuthorities: config.authentication.knownAuthorities
            }
        };
    }

    public get configuration(): ApplicationConfiguration {
        return this._configuration;
    }

    private getInvitationRedirectUri(config: ApplicationJsonConfiguration){
        let redirectUri = process.env.REACT_APP_REDIRECT_URI || config.authentication.redirectUri || window.location.origin;
        const completeRedirectUri = redirectUri + invitationSuccessUrl;
        return completeRedirectUri;
    }

    private getInvitationAuthority(config: ApplicationJsonConfiguration){
        return config.authentication.authorityInvitation;
    }

    private getRedirectUri(config: ApplicationJsonConfiguration){
        return process.env.REACT_APP_REDIRECT_URI || config.authentication.redirectUri || window.location.origin;
    }

    private getAuthority(config: ApplicationJsonConfiguration){
        const urlLowerCase = window.location.href.toLocaleLowerCase();

        if(urlLowerCase.includes(freeTrialUrlHint) || urlLowerCase.includes(signupUrlHint)){
            return config.authentication.authoritySignUp
        }else{
            return config.authentication.authority;
        }
    }
}
