import { AccessTime } from "@mui/icons-material";
import { t } from "i18next";
import { SolveInfo } from "../store/project/iteration";
import { millisecondsToSecondsStr } from "./formatting";
import { Box } from "@mui/material";

export interface DisplayFreezeTimeProps{
    solveInfo: SolveInfo
}

export default function DisplayFreezeTime(props: DisplayFreezeTimeProps){

    return (
        <Box>
            {!props.solveInfo.isReportEjectionTimeRange ?
                (
                    props.solveInfo.isTimeMaxReached == true ?
                        //ex.: >30s
                        (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} /> {`>${millisecondsToSecondsStr(props.solveInfo.ejectionTime, 0)}`}
                            </Box>
                        )
                    :
                        //ex.: 36s
                        (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} /> {millisecondsToSecondsStr(props.solveInfo.ejectionTime, 0)}
                            </Box>
                        )
                )

            : props.solveInfo.isReportEjectionTimeRange == true ?
                (
                    //Display range
                    (props.solveInfo.isTimeMaxReached == true && props.solveInfo.ejectionTimeLowerBound && props.solveInfo.ejectionTimeUpperBound) ?
                        //ex.: 22s - 240+s
                        (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} />
                                {millisecondsToSecondsStr(props.solveInfo.ejectionTimeLowerBound, 0) + " - " + millisecondsToSecondsStr(props.solveInfo.ejectionTimeUpperBound, 0, "+s")}
                            </Box>
                        )
                    :  (props.solveInfo.isTimeMaxReached == true && props.solveInfo.ejectionTimeLowerBound && !props.solveInfo.ejectionTimeUpperBound) ?
                        //ex.: 22s - 240+s
                        (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} />
                                {millisecondsToSecondsStr(props.solveInfo.ejectionTimeLowerBound, 0) + " - " + millisecondsToSecondsStr(props.solveInfo.ejectionTime, 0, "+s")}
                            </Box>
                        )
                    : (props.solveInfo.isTimeMaxReached == false && props.solveInfo.ejectionTimeLowerBound && props.solveInfo.ejectionTimeUpperBound) ?
                        //ex.: 22s - 32s
                        (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} />
                                {millisecondsToSecondsStr(props.solveInfo.ejectionTimeLowerBound, 0) + " - " + millisecondsToSecondsStr(props.solveInfo.ejectionTimeUpperBound, 0)}
                            </Box>
                        )
                    :
                        (
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} />
                                {millisecondsToSecondsStr(props.solveInfo.ejectionTime, 0)}
                            </Box>
                        )
                )
            :
                (
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <AccessTime titleAccess={t("Estimated Cooling Time")} sx={{ marginRight: '5px' }} />
                        {millisecondsToSecondsStr(props.solveInfo.ejectionTime, 0)}
                    </Box>
                )}
        </Box>
    )
}