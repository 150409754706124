import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Alert, Divider } from "@mui/material";
import theme from "../../styles/main-theme";
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

interface MaterialsUploadErrorsProps {
    message: string;
    title: string;
    onClose: () => void;
    open: boolean;
}

const MaterialsUploadErrors = (props: MaterialsUploadErrorsProps) => {

    const { t } = useTranslation();

    const closeDialog = () => {
        props.onClose();
    }
    const onCloseDialog = () => {
        closeDialog();
    }
    const classes = makeStyles({
        select: {
            borderRadius: "2em"
        },
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            fontWeight: '600',
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        file: {
            fontSize: '1.1em',
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
            fontWeight: '600',
        },
        input: {
            display: 'none'
        },
        fileUpload: {
            display: 'inline-block',
            padding: '10px 12px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            borderRadius: "20px",
            margin: "5px",
            fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
            fontWeight: '600',
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return (
        <Dialog id="info-dialog" open={props.open} aria-label={t("Error Dialog")} onClose={onCloseDialog}>
            <DialogTitle sx={{ color: theme.palette.primary.dark }}>{props.title}</DialogTitle>
            <DialogContent sx={{ position: "relative" }}>
                <div style={{ textAlign: "center" }}>
                   {props.message && <Alert id='id-alert-materials-upload-error' severity="error" sx={{ mt: "15px" }}>{t(props.message)}</Alert>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialog} className={classes.btn}>{t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MaterialsUploadErrors;