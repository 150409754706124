import {
    Dialog, DialogTitle, DialogContent, DialogContentText, MenuItem, TextField,
    DialogActions, Button, FormGroup, Select, Typography, Box, Tooltip, IconButton
} from "@mui/material"
import { useContext, useEffect, useReducer, useState } from "react";
import ConfigurationContext from "../../store/config/ConfigurationContext";
import MoldMaterial from "../../store/job/mold-material";
import theme from "../../styles/main-theme";
import { makeStyles } from "@mui/styles";
import RangeMaterial from '../../store/job/range-material';
import Alert from '@mui/material/Alert';
import { Collapse } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTranslation } from 'react-i18next';

const RANGEWAR = 'Recommended range';

export enum MaterialType {
    Mold = "Mold",
    Part = "Part"
}

export enum OpenSettingsMode {
    Create = "Create",
    Edit = "Edit"
}

export interface openSettings {
    open: boolean,
    mode: OpenSettingsMode,
    data: MoldMaterial | null | undefined
}

export type customMaterialProps = {
    openSettings: openSettings,
    onClose: () => void,
    onSubmit: (newMaterialData: MaterialFormFields, type: MaterialType) => void,
    onUpdate: (updateData: MaterialFormFields) => void
    type: MaterialType,
    rangeMaterial: RangeMaterial,
    materials: MoldMaterial[] | undefined
}

export interface Field<T> {
    value: T | null | undefined,
    type: string,
    error: boolean,
    duplicated: boolean,
    warning: boolean
}

interface MaterialFormDispatch {
    action: string,
    field: string | undefined,
    value: string | undefined,
    fieldType: string | undefined,
    min: number | undefined,
    max: number | undefined,
    materialToEdit: MoldMaterial | undefined
}

export interface MaterialFormFields {
    id: Field<string>,
    name: Field<string>,
    density: Field<number>,
    thermalConductivity: Field<number>,
    specificHeat: Field<number>,
    temperature: Field<number>,
    ejectionTemperature: Field<number>,
    moldTemperature: Field<number>,
    [key: string]: Field<string> | Field<number>;
}

interface MaterialForm {
    fields: MaterialFormFields
    isValid: boolean
}

export default function CustomMaterialDialog(props: customMaterialProps) {
    const globalConfigContext = useContext(ConfigurationContext);
    const materials = props.materials && props.materials.filter(m => !m.isDeleted && (m.userMaterial == undefined || !m.userMaterial));
    const customMaterials = props.materials && props.materials.filter(m => m.userMaterial == true && !m.isDeleted);
    const matchedMaterials = props.materials && props.materials.filter(m => !m.isDeleted);

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        collapse: {
            width: "100%"
        },
        ellipsisSelect: {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        select: {
            borderRadius: "2em",
            flex: "1",
            marginRight: "10px",
            minWidth: "0",
            width: "250px"
        },
    })();
    const [materialId, setMaterialId] = useState<number>(0);
    const [expand, setExpand] = useState(false);
    const isValid = (value: string, fieldType: string) => {
        if (fieldType == "text") {
            return value != null && value != ""
        } else if (fieldType == "number") {
            const isNan = isNaN(Number(value.toString()));
            return value != null && value != "" && !isNan
        }

        return false;
    }

    const findMaterialByName = (name: string) => {
        return matchedMaterials &&
            (props.openSettings.mode == OpenSettingsMode.Create ?
                matchedMaterials.find(m => m.name === name) :
                matchedMaterials.filter(m => m.name != props.openSettings.data?.name).find(m => m.name === name));
    }

    const materialFormReducer = (state: MaterialForm, value: MaterialFormDispatch) => {
        if (value.action == "leaveField") {
            if (value.value == undefined || value.field == undefined || value.fieldType == undefined) return state;
            let newState: MaterialForm = {
                ...state
            };
            newState.fields[value.field].value = value.value;
            newState.fields[value.field].error = false;
            newState.fields[value.field].duplicated = false;
            if (value.field == "name" && customMaterials) {
                if (findMaterialByName(value.value)) {
                    newState.fields[value.field].value = value.value;
                    newState.fields[value.field].error = true;
                    newState.fields[value.field].duplicated = true;
                    newState.isValid = isFormValid(newState.fields);
                    return newState;
                }
            }
            let number = Number(value.value.toString());
            if (value.min != undefined && value.max != undefined) {
                newState.fields[value.field].warning = (number < value.min || number > value.max);
            }

            return newState;
        }
        else if (value.action == "setField") {
            if (value.value == undefined || value.field == undefined || value.fieldType == undefined) return state;

            const isFieldValid = isValid(value.value, value.fieldType);
            let newState: MaterialForm = {
                ...state
            };

            newState.fields[value.field].value = value.value;
            newState.fields[value.field].error = !isFieldValid;
            newState.isValid = isFormValid(newState.fields);

            return newState;
        } else if (value.action == "resetForm") {
            let newState: MaterialForm = {
                ...state,
                fields: initialFieldsValue
            };

            return newState;
        } else if (value.action == "editMaterial") {
            const materialToEdit = value.materialToEdit;
            let newState: MaterialForm = {
                isValid: true,
                fields: {
                    id: { value: materialToEdit?.id, error: false, type: "text", warning: false, duplicated: false },
                    name: { value: materialToEdit?.name, error: false, type: "text", warning: false, duplicated: false },
                    density: { value: materialToEdit?.density, error: false, type: "number", warning: false, duplicated: false },
                    thermalConductivity: { value: materialToEdit?.thermalConductivity, error: false, type: "number", warning: false, duplicated: false },
                    specificHeat: { value: materialToEdit?.specificHeat, error: false, type: "number", warning: false, duplicated: false },
                    temperature: { value: materialToEdit?.temperature, error: false, type: "number", warning: false, duplicated: false },
                    ejectionTemperature: { value: materialToEdit?.ejectionTemperature, error: false, type: "number", warning: false, duplicated: false },
                    moldTemperature: { value: materialToEdit?.moldTemperature, error: false, type: "number", warning: false, duplicated: false },
                }
            };

            return newState;
        }

        return state;
    }

    const initialFieldsValue: MaterialFormFields = {
        id: {
            value: "0",
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        },
        name: {
            value: "New material",
            type: "text",
            error: false,
            warning: false,
            duplicated: false
        },
        density: {
            value: 0,
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        },
        thermalConductivity: {
            value: 0,
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        },
        specificHeat: {
            value: 0,
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        },
        temperature: {
            //mold temperature set to room temperature
            value: props.type == MaterialType.Mold ? 23 : 0,
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        },
        ejectionTemperature: {
            value: 0,
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        },
        moldTemperature: {
            value: 0,
            type: "number",
            error: false,
            warning: false,
            duplicated: false
        }
    };

    const [form, dispatchForm] = useReducer(materialFormReducer,
        {
            fields: initialFieldsValue,
            isValid: true
        })

    function isFormValid(fields: MaterialFormFields) {
        let isValid = true;

        for (let key in fields) {
            let value = fields[key];
            if (value.error) {
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    const handleClose = () => {
        resetFieldValues();
        setMaterialId(0);
        setExpand(false);
        props.onClose();
    }

    const create = () => {
        if (customMaterials && form.fields.name.value) {
            if (findMaterialByName(form.fields.name.value)) {
                form.isValid = false;
                form.fields.name.error = true;
                form.fields.name.duplicated = true;
                return;
            }
        }

        form.fields.id.value = crypto.randomUUID();
        props.onSubmit(form.fields, props.type);
        setMaterialId(0);
        setExpand(false);
        resetFieldValues();
    }

    const update = () => {
        props.onUpdate(form.fields);
        resetFieldValues();
    }

    const resetFieldValues = () => {
        dispatchForm({
            action: "resetForm",
            field: undefined,
            value: undefined,
            fieldType: undefined,
            min: undefined,
            max: undefined
        } as MaterialFormDispatch)
    }

    const handleSelectMaterial = (event: any) => {
        if (event.target.value) {
            let materialId = parseInt(event.target.value);
            setMaterialId(materialId);
            if (materials && materialId > 0) {
                dispatchForm({
                    action: "leaveField", value: 'COPY-' + materials[materialId].name, field: "name",
                    fieldType: form.fields.name?.type
                } as MaterialFormDispatch);
                dispatchForm({
                    action: "leaveField", value: materials[materialId].density, field: "density",
                    fieldType: form.fields.density?.type, min: props.rangeMaterial.densityMin, max: props.rangeMaterial.densityMax
                } as MaterialFormDispatch);
                dispatchForm({
                    action: "leaveField", value: materials[materialId].thermalConductivity, field: "thermalConductivity",
                    fieldType: form.fields.thermalConductivity?.type, min: props.rangeMaterial.thermalConductivityMin, max: props.rangeMaterial.thermalConductivityMax
                } as MaterialFormDispatch);
                dispatchForm({
                    action: "leaveField", value: materials[materialId].specificHeat, field: "specificHeat",
                    fieldType: form.fields.specificHeat?.type, min: props.rangeMaterial.specificHeatMin, max: props.rangeMaterial.specificHeatMax
                } as MaterialFormDispatch);
                if (props.type == MaterialType.Part) {
                    dispatchForm({
                        action: "leaveField", value: materials[materialId].moldTemperature, field: "moldTemperature",
                        fieldType: form.fields.moldTemperature?.type, min: props.rangeMaterial.moldTemperatureMin, max: props.rangeMaterial.moldTemperatureMax
                    } as MaterialFormDispatch);
                    dispatchForm({
                        action: "leaveField", value: materials[materialId].temperature, field: "temperature",
                        fieldType: form.fields.temperature?.type, min: props.rangeMaterial.temperatureMin, max: props.rangeMaterial.temperatureMax
                    } as MaterialFormDispatch);
                    dispatchForm({
                        action: "leaveField", value: materials[materialId].ejectionTemperature, field: "ejectionTemperature",
                        fieldType: form.fields.ejectionTemperature?.type, min: props.rangeMaterial.ejectionTemperatureMin, max: props.rangeMaterial.ejectionTemperatureMax
                    } as MaterialFormDispatch);
                }
            }
        }
    };
    const { t } = useTranslation();
    useEffect(() => {
        if (props.openSettings.open && props.openSettings.mode == OpenSettingsMode.Edit) {
            const materialToEdit = props.openSettings.data;

            dispatchForm({
                action: "editMaterial",
                materialToEdit: materialToEdit
            } as MaterialFormDispatch)
        } else if (props.openSettings.open && props.openSettings.mode == OpenSettingsMode.Create) {
            resetFieldValues();
        }
    }, [props.openSettings.open, props.openSettings.mode])

    return (
        <Dialog open={props.openSettings.open} onClose={handleClose} maxWidth="xs">
            <DialogTitle sx={{ color: theme.palette.primary.dark }}>{t("Custom")} {props.type} {t("Material")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("This material will be added to your material library.")}
                </DialogContentText>
                <br />
                <Typography variant="subtitle2" sx={{ color: theme.palette.secondary.contrastText }}>{t("If you are not sure where to find the material properties you need")}, <a onClick={
                    (e) => { window.open(globalConfigContext?.supportEmail ? "mailto:" + globalConfigContext?.supportEmail : 'mail'); e.preventDefault(); }} href="">{t("contact us")}!</a> {t("Our experts will be happy to assist.")}</Typography>
                {props.openSettings.mode == OpenSettingsMode.Create &&
                    <IconButton onClick={() => setExpand(!expand)}
                        sx={{ padding: '20px 0px 0px 0px', "&:hover": { backgroundColor: theme.palette.background.paper } }}>
                        <Typography sx={{ color: theme.palette.primary.contrastText }}>{t("Copy from")} {props.type} {t("Material")}</Typography >
                        {expand && <ArrowDropUpIcon />}
                        {!expand && <ArrowDropDownIcon />}
                    </IconButton>}
                <Collapse in={expand} className={classes.collapse}
                    sx={{
                        padding: expand ? '20px 5px 20px 5px' : '0px',
                        backgroundColor: theme.palette.background.default,
                        borderRadius: '4px'
                    }}>
                    {materials &&
                        <Select
                            className={classes.select}
                            id="material-select"
                            onChange={handleSelectMaterial}
                            value={materialId.toString()}>
                            {materials.map((material, index) => {
                                return (
                                    <MenuItem sx={{ maxWidth: "300px" }} value={material.id.toString()} key={material.id + '-' + index}>
                                        <Box className={"materialItem"} sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                            <Box className={classes.ellipsisSelect} sx={{ width: "100%" }}>
                                                {material.name}
                                            </Box>
                                        </Box>
                                    </MenuItem>
                                );
                            })}
                        </Select>}
                </Collapse>
                <br />
                <FormGroup>
                    <TextField
                        label={t("Name")}
                        id="material-name"
                        error={form.fields.name?.error}
                        value={form.fields.name?.value}
                        onChange={(e) => { dispatchForm({ action: "setField", value: e.target.value, field: "name", fieldType: form.fields.name?.type } as MaterialFormDispatch) }}
                        onBlur={(e) => {
                            dispatchForm({
                                action: "leaveField", value: e.target.value, field: "name",
                                fieldType: form.fields.name?.type
                            } as MaterialFormDispatch)
                        }}
                        size="small"
                        margin="normal"
                    />
                    {form.fields.name?.duplicated && <Alert severity="error">{t('Material with the same name already exists.')}.</Alert>}
                    <Tooltip placement="left" title={`${t(RANGEWAR)} min: ` + props.rangeMaterial.densityMin + ', max: ' + props.rangeMaterial.densityMax}>
                        <TextField
                            label={t("Density")}
                            id="material-density"
                            type="number"
                            error={form.fields.density?.error}
                            value={form.fields.density?.value}
                            color={form.fields.density?.warning ? "warning" : "success"}
                            onBlur={(e) => {
                                dispatchForm({
                                    action: "leaveField", value: e.target.value, field: "density",
                                    fieldType: form.fields.density?.type, min: props.rangeMaterial.densityMin, max: props.rangeMaterial.densityMax
                                } as MaterialFormDispatch)
                            }}
                            onChange={(e) => { dispatchForm({ action: "setField", value: e.target.value, field: "density", fieldType: form.fields.density?.type } as MaterialFormDispatch) }}
                            size="small"
                            margin="normal"
                            InputProps={{
                                endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">kg/m<sup>3</sup></Typography></Box>
                            }}
                        />
                    </Tooltip>
                    {form.fields.density?.warning && <Alert severity="warning">{t(RANGEWAR)} of 'Density': minimum: {props.rangeMaterial.densityMin}, maximum: {props.rangeMaterial.densityMax}.</Alert>}
                    <Tooltip placement="left" title={`${t(RANGEWAR)} min: ` + props.rangeMaterial.thermalConductivityMin + ', max: ' + props.rangeMaterial.thermalConductivityMax}>
                        <TextField
                            label={t("Thermal Conductivity")}
                            id="material-thermal-conductivity"
                            type="number"
                            error={form.fields.thermalConductivity?.error}
                            value={form.fields.thermalConductivity?.value}
                            color={form.fields.thermalConductivity?.warning ? "warning" : "success"}
                            onBlur={(e) => {
                                dispatchForm({
                                    action: "leaveField", value: e.target.value, field: "thermalConductivity", fieldType: form.fields.thermalConductivity?.type,
                                    min: props.rangeMaterial.thermalConductivityMin, max: props.rangeMaterial.thermalConductivityMax
                                } as MaterialFormDispatch)
                            }}
                            onChange={(e) => { dispatchForm({ action: "setField", value: e.target.value, field: "thermalConductivity", fieldType: form.fields.thermalConductivity?.type } as MaterialFormDispatch) }}
                            size="small"
                            margin="normal"
                            InputProps={{
                                endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">W/(m &#8451;)</Typography></Box>
                            }}
                        />
                    </Tooltip>
                    {form.fields.thermalConductivity?.warning && <Alert severity="warning">{t(RANGEWAR)} {t("of 'Thermal conductivity': minimum")}: {props.rangeMaterial.thermalConductivityMin}, {t("maximum")}: {props.rangeMaterial.thermalConductivityMax}.</Alert>}
                    <Tooltip placement="left" title={`${t(RANGEWAR)} min: ` + props.rangeMaterial.specificHeatMin + ', max: ' + props.rangeMaterial.specificHeatMax}>
                        <TextField
                            label={t("Specific Heat")}
                            id="material-specific-heat"
                            type="number"
                            error={form.fields.specificHeat?.error}
                            value={form.fields.specificHeat?.value}
                            color={form.fields.specificHeat?.warning ? "warning" : "success"}
                            onBlur={(e) => {
                                dispatchForm({
                                    action: "leaveField", value: e.target.value, field: "specificHeat", fieldType: form.fields.specificHeat?.type,
                                    min: props.rangeMaterial.specificHeatMin, max: props.rangeMaterial.specificHeatMax
                                } as MaterialFormDispatch)
                            }}
                            onChange={(e) => { dispatchForm({ action: "setField", value: e.target.value, field: "specificHeat", fieldType: form.fields.specificHeat?.type } as MaterialFormDispatch) }}
                            size="small"
                            margin="normal"
                            InputProps={{
                                endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">J/(kg &#8451;)</Typography></Box>
                            }}
                        />
                    </Tooltip>
                    {form.fields.specificHeat?.warning && <Alert severity="warning">{t(RANGEWAR)} {t("of 'Specific Heat': minimum")}: {props.rangeMaterial.specificHeatMin}, maximum: {props.rangeMaterial.specificHeatMax}.</Alert>}
                    {props.type == MaterialType.Part &&
                        <>
                            <Tooltip placement="left" title={`${t(RANGEWAR)} min: ` + props.rangeMaterial.temperatureMin + ', max: ' + props.rangeMaterial.temperatureMax}>
                                <TextField
                                    label={t("Temperature")}
                                    id="material-temperature"
                                    type="number"
                                    error={form.fields.temperature?.error}
                                    value={form.fields.temperature?.value}
                                    color={form.fields.temperature?.warning ? "warning" : "success"}
                                    onChange={(e) => {
                                        dispatchForm({
                                            action: "setField", value: e.target.value,
                                            field: "temperature", fieldType: form.fields.temperature?.type
                                        } as MaterialFormDispatch)
                                    }}
                                    onBlur={(e) => {
                                        dispatchForm({
                                            action: "leaveField", value: e.target.value, field: "temperature", fieldType: form.fields.temperature?.type,
                                            min: props.rangeMaterial.temperatureMin, max: props.rangeMaterial.temperatureMax
                                        } as MaterialFormDispatch)
                                    }}
                                    size="small"
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">&#8451;</Typography></Box>
                                    }}
                                />
                            </Tooltip>
                            {form.fields.temperature?.warning && <Alert severity="warning">{t(RANGEWAR)} {t("of 'Temperature': minimum")}: {props.rangeMaterial.temperatureMin}, {t("maximum")}: {props.rangeMaterial.temperatureMax}.</Alert>}
                            <Tooltip placement="left" title={`${t(RANGEWAR)} min: ` + props.rangeMaterial.ejectionTemperatureMin + ', max: ' + props.rangeMaterial.ejectionTemperatureMax}>
                                <TextField
                                    label={t("Ejection Temperature")}
                                    id="material-ejection-temperature"
                                    type="number"
                                    error={form.fields.ejectionTemperature?.error}
                                    value={form.fields.ejectionTemperature?.value}
                                    color={form.fields.ejectionTemperature?.warning ? "warning" : "success"}
                                    onBlur={(e) => {
                                        dispatchForm({
                                            action: "leaveField", value: e.target.value, field: "ejectionTemperature", fieldType: form.fields.ejectionTemperature?.type,
                                            min: props.rangeMaterial.ejectionTemperatureMin, max: props.rangeMaterial.ejectionTemperatureMax
                                        } as MaterialFormDispatch)
                                    }}
                                    onChange={(e) => {
                                        dispatchForm({
                                            action: "setField", value: e.target.value,
                                            field: "ejectionTemperature", fieldType: form.fields.ejectionTemperature?.type
                                        } as MaterialFormDispatch)
                                    }}
                                    size="small"
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">&#8451;</Typography></Box>
                                    }}
                                />
                            </Tooltip>
                            {form.fields.ejectionTemperature?.warning && <Alert severity="warning">{t(RANGEWAR)} {t("of 'Ejection Temperature': minimum")}: {props.rangeMaterial.ejectionTemperatureMin}, {t("maximum")}: {props.rangeMaterial.ejectionTemperatureMax}.</Alert>}
                            <Tooltip placement="left" title={`${t(RANGEWAR)} min: ` + props.rangeMaterial.moldTemperatureMin + ', max: ' + props.rangeMaterial.moldTemperatureMax}>
                                <TextField
                                    label={t("Target Mold Temperature")}
                                    id="material-target-mold-temperature"
                                    type="number"
                                    error={form.fields.moldTemperature?.error}
                                    value={form.fields.moldTemperature?.value}
                                    color={form.fields.moldTemperature?.warning ? "warning" : "success"}
                                    onChange={(e) => {
                                        dispatchForm({
                                            action: "setField", value: e.target.value, field: "moldTemperature",
                                            fieldType: form.fields.moldTemperature?.type
                                        } as MaterialFormDispatch)
                                    }}
                                    onBlur={(e) => {
                                        dispatchForm({
                                            action: "leaveField", value: e.target.value, field: "moldTemperature", fieldType: form.fields.moldTemperature?.type,
                                            min: props.rangeMaterial.moldTemperatureMin, max: props.rangeMaterial.moldTemperatureMax
                                        } as MaterialFormDispatch)
                                    }}
                                    size="small"
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <Box sx={{ whiteSpace: "nowrap" }}><Typography variant="caption" color="GrayText">&#8451;</Typography></Box>
                                    }}
                                />
                            </Tooltip>
                            {form.fields.moldTemperature?.warning && <Alert severity="warning">{t(RANGEWAR)} {t("of 'Mold Temperature': minimum")}: {props.rangeMaterial.moldTemperatureMin}, {t("maximum")}: {props.rangeMaterial.moldTemperatureMax}.</Alert>}
                        </>
                    }
                </FormGroup>
            </DialogContent>
            <DialogActions>
                {props.openSettings.mode == OpenSettingsMode.Create && <Button disabled={!form.isValid} id="create-material-modal-button"
                    onClick={() => { create() }} className={classes.btn}>{t("Create")}</Button>}
                {props.openSettings.mode == OpenSettingsMode.Edit && <Button disabled={!form.isValid} id="update-material-button"
                    onClick={() => { update() }} className={classes.btn}>{t("Update")}</Button>}
                <Button id="cancel-material-button" onClick={handleClose} className={classes.btn}>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    )
}