import { Collection, CollectionKinds } from "./Collection";
import { Directives, composeDirective } from "./Directives";
import { Index } from "./Index";
import { Ratio } from "./Ratio";
import { toPrecision } from "./Utilities";

export class NormalizedVertex {
    readonly x: number;
    readonly y: number;
    readonly z: number;
    readonly w?: Ratio;

    constructor(x: number, y: number, z: number, w?: Ratio) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.w = w ?? new Ratio(1);
    }

    equals(other: NormalizedVertex) : boolean {
        return (this.x === other.x &&
                this.y === other.y &&
                this.z === other.z &&
                this.w === other.w);
    }    

    toString() : string {
        return composeDirective(Directives.NormalizedVertex, `${toPrecision(this.x)} ${toPrecision(this.y)} ${toPrecision(this.z)} ${this.w}`);
    }
}

export class NormalizedVertices extends Collection<NormalizedVertex> {
    constructor(values? : NormalizedVertex[]) {
        super(CollectionKinds.NormalizedVertices, values);
    }

    add(x: number, y: number, z: number, w?: Ratio) : Index | undefined {
        return this.push(new NormalizedVertex(x, y, z, w));
    }
}