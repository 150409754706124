import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Typography } from "@mui/material";
import { useContext } from "react";
import FeatureService from "../../services/FeatureService";
import ConfigurationContext from "../../store/config/ConfigurationContext";
import theme from "../../styles/main-theme";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@mui/styles";

interface UpcomingFeatureDialogProps {
    open: boolean;
    upcomingFeature: string,
    onClose: () => void
}

const UpcomingFeatureDialog = (props: UpcomingFeatureDialogProps) => {
    const globalConfigContext = useContext(ConfigurationContext);
    const { t } = useTranslation();
    const onClose = () => {
        FeatureService.UpcomingFeature(props.upcomingFeature);

        props.onClose();
    }

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return (
        <Dialog maxWidth="sm" fullWidth={true} open={props.open} onClose={onClose}>
            <DialogTitle sx={{ color: theme.palette.primary.dark}}>
                {t("Coming soon")}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t("We're planning")} "{props.upcomingFeature}" {t("soon. If you are interested in this, we'd love to talk to you to find out more about what you need.")}
                    {t("Please")} <a onClick={(e) => {window.open(globalConfigContext?.supportEmail ?  "mailto:" + globalConfigContext?.supportEmail + "?subject=" + props.upcomingFeature : 'mail');e.preventDefault();}} href="">{t("contact us")}</a> 
                    &nbsp;{t("to tell us more, or")} <a target="_blank" href="https://outlook.office365.com/owa/calendar/SimFormonboarding@mayahtt.com/bookings/">{t("book some time")}</a> {t("to talk to us about it!")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className={classes.btn} onClick={onClose}>{t("Continue")}...</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpcomingFeatureDialog;