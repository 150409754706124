import { Menu, MenuItem, Typography, Box, Divider, Button, Link } from "@mui/material";
import React from "react";
import { useState } from "react";
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import SchoolIcon from '@mui/icons-material/School';
import TourIcon from '@mui/icons-material/Tour';
import EmailIcon from '@mui/icons-material/Email';
import theme from "../../styles/main-theme";
import AboutDialog from "../common/AboutDialog";
import { useTranslation } from 'react-i18next';

export interface HelpMenuProps {

};

const HelpMenu = (props: HelpMenuProps) => {
    const { t } = useTranslation();
    const anchorRefHelp = React.useRef<HTMLButtonElement>(null);
    const [anchorElHelp, setAnchorElHelp] = useState<null | HTMLElement>(null);
    const openHelp = Boolean(anchorElHelp);
    const [openAbout, setOpenAbout] = useState<boolean>(false);

    const handleClickHelp = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElHelp(event.currentTarget);
    };

    const handleCloseHelp = () => {
        setAnchorElHelp(null);
    };

    return (
        <>
            <Button startIcon={<HelpIcon />} onClick={handleClickHelp} size="small" aria-label={t("Help")} color="inherit"
                sx={{ textTransform: "initial", fontSize: "1.1em", color: theme.palette.background.paper, cursor: "pointer" }}>
                {t("Help")}
            </Button>
            <Menu
                sx={{}}
                id="help-menu"
                aria-labelledby="help-button"
                anchorEl={anchorElHelp}
                open={openHelp}
                onClose={handleCloseHelp}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{
                    style: {
                        maxWidth: '400px',
                        minWidth: "300px",
                        scrollbarWidth: "thin"
                    },
                }}
            >
                <MenuItem>
                    <Link sx={{ width: "100%", textDecoration: "none", display: "flex", alignItems: "center", color: theme.palette.primary.main }} href="https://www.simform.app/en/resources/?category=knowledge-base" target="_blank">
                        <SchoolIcon sx={{ marginRight: "10px" }}></SchoolIcon>
                        <Typography sx={{ whiteSpace: "normal" }} variant="body2">{t("Knowledge Base")}</Typography>
                    </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { setOpenAbout(true) }}>
                    <Link onClick={() => { handleCloseHelp() }} sx={{ width: "100%", textDecoration: "none", display: "flex", alignItems: "center", color: theme.palette.primary.main }}>
                        <InfoIcon sx={{ marginRight: "10px" }}></InfoIcon>
                        <Typography sx={{ whiteSpace: "normal" }} variant="body2">{t("About")}</Typography>
                    </Link>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <Link onClick={() => { handleCloseHelp() }} sx={{ width: "100%", textDecoration: "none", display: "flex", alignItems: "center", color: theme.palette.primary.main }} href="https://outlook.office365.com/owa/calendar/SimFormonboarding@mayahtt.com/bookings/" target="_blank">
                        <TourIcon sx={{ marginRight: "10px" }}></TourIcon>
                        <Typography sx={{ whiteSpace: "normal" }} variant="body2">{t("Book a free onboarding tour now!")}</Typography>
                    </Link>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <Link onClick={(e) => { window.open("mailto:support@simform.app", 'mail'); e.preventDefault(); handleCloseHelp() }} sx={{ width: "100%", textDecoration: "none", display: "flex", alignItems: "center", color: theme.palette.primary.main }}>
                        <EmailIcon sx={{ marginRight: "10px" }}></EmailIcon>
                        <Typography sx={{ whiteSpace: "normal" }} variant="body2">{t("Contact us...")}</Typography>
                    </Link>
                </MenuItem>
            </Menu>
            <AboutDialog
                open={openAbout}
                onClose={() => { setOpenAbout(false) }}
            ></AboutDialog>
        </>
    );
}

export default HelpMenu;