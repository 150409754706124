import { getFaceBoundingBox } from "../../../utils/hoops.utils";

export class SelectThroughCuttingPlaneOperator implements Communicator.Operator.Operator {
    protected readonly _hwv: Communicator.WebViewer;

    protected _selectedItems: Communicator.Selection.SelectionItem[] = [];

    constructor(hwv: Communicator.WebViewer) {
        this._hwv = hwv;
    }

    onActivate() {
        this._hwv.cuttingManager.setStandinGeometryPickable(true);
    }

    onDeactivate() {
        this._hwv.cuttingManager.setStandinGeometryPickable(false);
    }

    async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        const cuttingManager = this._hwv.cuttingManager;

        if (cuttingManager.getActiveCuttingSectionCount() > 0) {
            this._selectedItems = (await this._hwv.view.pickAllFromPoint(event.getPosition(), new Communicator.PickConfig(Communicator.SelectionMask.Face)));
        }
    }

    async onMouseMove(event: Communicator.Event.MouseInputEvent) {
        this._selectedItems = [];
    }

    async onMouseUp(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this._selectedItems.length) {
            const cuttingPlane = this.getCuttingPlane(this._selectedItems);

            if (cuttingPlane?.plane) {
                const candidates = this._selectedItems.filter(item => item.getNodeId() !== cuttingPlane.referenceNodeId)
                    .filter(item => item.isFaceSelection()) as Communicator.Selection.FaceSelectionItem[];

                const closestFace = await this.getClosestFaceToThePlane(candidates, cuttingPlane.plane);

                this._hwv.selectionManager.clear();
                this._hwv.selectionManager.add(closestFace);
            }

            this._selectedItems = [];

            event.setHandled(true);
        }
    }


    private getCuttingPlane(items: Communicator.Selection.SelectionItem[]): {
        plane: Communicator.Plane,
        referenceNodeId: number
    }|null {
        let plane: Communicator.Plane|null = null;

        for (const item of items) {
            const nodeId = item.getNodeId();

            if (nodeId === null) {
                continue;
            }

            const cuttingSection = this._hwv.cuttingManager.getCuttingSectionFromNodeId(nodeId);

            if (cuttingSection === null) {
                continue;
            }

            const planeIndex = cuttingSection.getPlaneIndexByNodeId(nodeId);

            if (planeIndex === null) {
                continue;
            }

            plane = cuttingSection.getPlane(planeIndex);

            if (plane) {
                return {
                    plane,
                    referenceNodeId: nodeId
                };
            }
        }

        return null;
    }

    private async getClosestFaceToThePlane(faces: Communicator.Selection.FaceSelectionItem[], plane: Communicator.Plane): Promise<Communicator.Selection.FaceSelectionItem> {
        const items: {
            face: Communicator.Selection.FaceSelectionItem,
            distanceToPlain: number
        }[] = [];

        for (const face of faces) {
            const box = await getFaceBoundingBox({nodeId: face.getNodeId(), faceIndex: face.getFaceEntity().getCadFaceIndex()}, this._hwv);

            items.push({face, distanceToPlain: Math.abs(plane.distanceToPoint(box.center()))});
        }

        const sortedItems = items.sort((i1, i2) => i1.distanceToPlain - i2.distanceToPlain);

        return sortedItems[0].face;
    }
}