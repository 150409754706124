import JobData from "../../../store/job/job-data";
import { computeInitialBasicMoldConfig, createRectangularMoldPartsAroundModel } from "../../../utils/hoops.utils";

export async function enableBasicMold (jobContext: JobData, hoopsWebViewer: Communicator.WebViewer) {
    jobContext.setBasicMoldEnabled(true);
    const size = jobContext.basicMold.size;
    if (size.x <= 0
        || size.y <= 0
        || size.z <= 0
        || !jobContext.basicMold.center) {
        const initialBasicMoldConfig = hoopsWebViewer ? await computeInitialBasicMoldConfig(hoopsWebViewer, jobContext.Categories.Part) : undefined;
        if (initialBasicMoldConfig) {
            jobContext.setBasicMoldCenter(initialBasicMoldConfig.center)
            jobContext.setBasicMoldSize(initialBasicMoldConfig.size);
        }
    } else {
        const nodesIds = hoopsWebViewer ? await createRectangularMoldPartsAroundModel(hoopsWebViewer, size, jobContext.basicMold.center) : undefined;
        if (nodesIds) {
            jobContext.setBasicMoldNodesIds(nodesIds);

        }
    }
}

export function disableBasicMold (jobContext: JobData, hoopsWebViewer: Communicator.WebViewer) {
    jobContext.setBasicMoldEnabled(false);
    removeRectangularMoldPartsAroundModel(jobContext, hoopsWebViewer).then(() => {
        jobContext.setBasicMoldSize({ x: 0, y: 0, z: 0 });
        jobContext.setBasicMoldNodesIds([]);
    });
}

async function removeRectangularMoldPartsAroundModel (jobContext: JobData, hoopsWebViewer: Communicator.WebViewer) {
    if (!hoopsWebViewer) { return; }
    jobContext.basicMold.nodesIds.forEach(ni => {
        try {
            hoopsWebViewer.model.deleteNode(ni); // TODO: investigate invalid node ids
        } catch {
            console.debug('basic mold node could not be deleted, node ids may already have been discarded');
        }
    });
}

export async function handleBasicMoldSizeChange (jobContext: JobData, hoopsWebViewer: Communicator.WebViewer) {
    if (!hoopsWebViewer?.model) { return; }
    const size = jobContext.basicMold.size;
    const center = jobContext.basicMold.center;
    if (size.x > 0 && size.y > 0 && size.z > 0 && center) { // skip when config is invalid
        await removeRectangularMoldPartsAroundModel(jobContext, hoopsWebViewer);
        const nodesIds = hoopsWebViewer ? await createRectangularMoldPartsAroundModel(hoopsWebViewer, size, center) : undefined;
        if (nodesIds) {
            jobContext.setBasicMoldNodesIds(nodesIds);
        }
    }
}