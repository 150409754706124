import { CollectionKinds } from "./Collection";
import { Index } from "./Index";
import { EOL, stringify, writeLine } from "./Utilities"; 

export interface CollectionSetItem {
    equals(other: CollectionSetItem) : boolean;
}

export class CollectionSet<T extends CollectionSetItem> {
    kind: CollectionKinds;
    values?: Set<T>;

    constructor(kind: CollectionKinds, values?: Set<T>) {
        this.kind = kind;
        this.values = values;
    }

    Values() : Set<T> {
        if (!this.values) this.values = new Set<T>();
        
        return this.values;
    }

    insert(value?: T) : Index | undefined {
        if (value === undefined || value === null) return undefined;

        const index = this.findMatch(value);

        return (index !== undefined)
               ? index
               : new Index(this.Values().add(value).size);
    }

    findMatch(value?: T) : Index | undefined {
        if (value === undefined || value === null || this.isEmpty()) return undefined;

        let index = 1;

        for (const item of this.values!) {
            if (item.equals(value)) {
                return new Index(index);
            }
            ++index;
        }

        return undefined;
    }

    isEmpty() : boolean {
        return (this.size() === 0);
    }

    size() : number {
        return this.values?.size ?? 0;
    }

    commentCount() : string {
        return this.isEmpty()
               ? ''
               : `There are ${this.size()} ${this.kind}.`;
    }

    join(separator: string = ',') : string {
        return this.isEmpty()
               ? ''
               : [...this.Values()].join(separator);
    }

    toString() : string {
        return this.isEmpty()
               ? ''
               : writeLine(this.join('')) + EOL;
    }

    static stringify<T extends CollectionSetItem>(collection?: CollectionSet<T>) : string {
        return stringify(collection?.toString());
    }

    static isEmpty<T extends CollectionSetItem>(collection?: CollectionSet<T>) : boolean {
        return (!collection || collection.isEmpty());
    }
}