import moment from 'moment';

export function compareDates(a: Date | null, b: Date | null) {
    if (a == null) {
        return (b == null) ? 0 : 1;
    }
    if (b == null) return -1;
    if (moment(a).isBefore(b)) {
        return -1;
    }
    return (moment(b).isBefore(a)) ? 1 : 0;
}
