import { TableContainer, Box, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Button } from "@mui/material"
import theme from "../../styles/main-theme"
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';

export enum InvitationStatus{
    Pending = "Pending",
    Cancelled = "Cancelled",
    Accepted = "Accepted"
}

export interface UserInvitation{
    id: string,
    userEmail: string,
    status: InvitationStatus,
    expiration: Date
}

export interface UserInvitationProps{
    invitations: Array<UserInvitation>
    onCancelInvitation: (cancelledInvitation: UserInvitation) => void,
    onResendInvitation: (invitationToResend: UserInvitation) => void
}

export const UserInvitations = (props: UserInvitationProps) => {
    const { t } = useTranslation();
    const [openRemoveUserDialog, setOpenRemoveUserDialog] = useState(false);
    const [invitationToCancel, setInvitationToCancel] = useState<UserInvitation | null>(null);
    
    const confirmRemoveInvitation = async (answer: boolean) => {
        setOpenRemoveUserDialog(false);
        if(answer == true && invitationToCancel){
            props.onCancelInvitation(invitationToCancel);
            setInvitationToCancel(null);
        }
    }

    const isExpired = (date: Date) => {
        const now = new Date().getTime();
        const expirationDate = new Date(date).getTime();

        const isExpired = now > expirationDate;
        return isExpired;
    }
    
    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" align="left" ><b>{t("Email")}</b></TableCell>
                            <TableCell component="th" align="center" width={"140"}><b>{t("Expiration Date")}</b></TableCell>
                            <TableCell component="th" align="center" width={"140"}><b>{t("Actions")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.invitations.map((invitation) => (
                        <TableRow
                            hover
                            key={invitation.userEmail}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row" align="left">{invitation.userEmail}</TableCell>
                            <TableCell scope="row" align="center" width={"140"}>
                                {isExpired(invitation.expiration) &&
                                    <Tooltip
                                        placement="top"
                                        title={t("Expired")}
                                    >
                                        <Box sx={{color: theme.palette.error.dark, display: "flex", alignItems: "center"}}>
                                            {new Date(invitation.expiration).toLocaleDateString()}<ErrorIcon sx={{ml: "5px", fontSize: "1.4em"}}></ErrorIcon>
                                        </Box>
                                    </Tooltip>
                                }
                                {!isExpired(invitation.expiration) &&
                                    <Box>
                                        {new Date(invitation.expiration).toLocaleDateString()}
                                    </Box>
                                }
                            </TableCell>
                            <TableCell scope="row" align="center" width={"140"}>
                                <Tooltip
                                    placement="top"
                                    title={t("Resend invitation")}
                                >
                                        <SendIcon onClick={() => {props.onResendInvitation(invitation)}} sx={{mr: "10px", color: theme.palette.primary.main, cursor: "pointer"}}></SendIcon>
                                </Tooltip>
                                <Tooltip
                                    placement="top"
                                    title={t("Cancel invitation")}
                                >
                                        <CancelIcon onClick={() => {setInvitationToCancel(invitation); setOpenRemoveUserDialog(true);}} sx={{color: theme.palette.error.dark, cursor: "pointer"}}></CancelIcon>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>  

            <ConfirmationModal 
                open={openRemoveUserDialog}
                title={t("Cancel Invitation Confirmation")}
                content={t("Are you sure you want to cancel this invitation to company account?")}
                onClose={confirmRemoveInvitation}
            />
        </Box> 
    )
}