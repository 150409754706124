import { Collection, CollectionKinds } from "./Collection";
import { Index } from "./Index";
import { Directives, composeDirective } from "./Directives";

export class Comment {
    readonly comment: string;

    constructor(comment: string) {
        this.comment = comment;
    }

    toString() : string {
        return composeDirective(Directives.Comment, this.comment, true);
    }
}

export class Comments extends Collection<Comment> {
    constructor(values? : Comment[]) {
        super(CollectionKinds.Comments, values);
    }

    add(comment: string) : Index | undefined {
        return this.push(new Comment(comment));
    }
}