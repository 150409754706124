import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import routes from '../router/routes';
import Iteration from '../store/project/iteration';
import Project from '../store/project/project';
import { Status, JobView } from '../store/job/job-data';
import moment from 'moment';
import { compareDates } from '../utils/date.utils'
import { ProjectType } from '../store/project/project-data';
import { lowerCaseProjectType } from '../utils/formatting';
import { ProjectFilters } from '../components/common/ProjectFilter';
import qs from 'qs';

export default class ProjectService {
    async cloneIteration(projectId: string, jobId: string, name: string, description: string, copyCadFiles: boolean): Promise<any> {
        try {
            const data = { name, projectId, id:jobId, description, copyCadFiles };
            const response = await axios.post(`/api/project/${projectId}/iteration/${jobId}/clone`, data);
            return response.data;
        }
        catch (error: any) {
            if(error.response == undefined){
                throw new Error("Error creating job");
            }
            throw new Error(error.response.data);
        }
    }

    async getProject(projectId: string, containerName: string): Promise<any> {
        try {
            const response = await axios.get(`/api/project/${projectId}/${containerName}`);
            return response.data;
        }
        catch (error) {
            throw new Error('Error getting project details');
        }
    }

    async updateProjectDetails(projectId: string, containerName: string, jsonPatch: object) {
        try {
            const response = await axios.patch(`/api/project/${projectId}/${containerName}`, jsonPatch);
            return response.data.patched;
        } catch (error) {
            throw new Error('Could not update project details');
        }
    }

    async removeCurrentIteration(projectId: string, jobId: string) {
        try {
            await axios.delete(`/api/project/${projectId}/iteration/${jobId}`);
        } catch (error) {
            throw new Error('Cannot delete current job');
        }
    }

    async getIterationParametersUrl(projectId: string, jobId: string, containerName: string): Promise<any> {
        try {
            const response = await axios.get(`/api/project/${projectId}/iteration/${jobId}/${containerName}/parameters`);
            return response.data;
        } catch (error) {
            throw new Error('Error getting job data');
        }
    }

    async createProject(name: string, description: string, client: string, type: string, tags?: string[]): Promise<Project> {
        try {
            const data = { name, description, client, type, tags };
            const response = await axios.post('/api/project', data);
            return response.data;
        } catch (error: any) {
            if(error.response == undefined){
                throw new Error("Error creating project");
            }
            throw new Error(error.response.data);
        }
    }

    async deleteProject(id: string): Promise<Project> {
        try {
            const response = await axios.delete(`/api/project/${id}`);
            return response.data;
        } catch (error) {
            throw new Error('Error deleting project data');
        }
    }

    async createIteration(name: string, projectId: string, description: string, type: string): Promise<Iteration> {
        try {
            const data = { name, projectId, description, type };
            const response = await axios.post(`/api/project/${projectId}/iteration`, data);
            return response.data;
        } catch (error: any) {
            if(error.response == undefined){
                throw new Error("Error creating job");
            }
            throw new Error(error.response.data);
        }
    } 

    async getProjects(includeIterations: boolean = false, projectFilters: ProjectFilters, signal: AbortSignal | undefined): Promise<Project[]> {
        try {
            let parameters = {
                includeIterations: includeIterations,
                inputSearch: projectFilters.inputSearch,
                filterType: projectFilters.filterType,
                selectedUsers: projectFilters.users.filter((u) => {return u.checked}).map((u) => { return u.id})
            };

            const response = await axios.get('/api/project', { signal, params: parameters, paramsSerializer: params => {
                return qs.stringify(params)
            }});
            
            return response.data;
        } catch (error) {
            throw new Error('Error getting projects');
        }
    }

    async getIterations(projectId: string, containerName: string): Promise<any[]> {
        try {
            const response = await axios.get(`/api/project/${projectId}/iteration/${containerName}`);
            return response.data;
        } catch {
            throw new Error('Error getting jobs');
        }
    }

    async getIteration(projectId: string, jobId: string, containerName: string, includeParameters: boolean = false): Promise<Iteration> {
        try {
            const response = await axios.get(`/api/project/${projectId}/iteration/${jobId}/includeParameters/${includeParameters}/iteration/${containerName}`);
            return response.data;
        } catch {
            throw new Error('Error getting job');
        }
    }

    public static getLastModifiedIteration(iterations: Iteration[] | undefined) {
        let lastModifiedIteration = iterations?.sort((a, b) => {
            if (a.lastModified == null || b.lastModified == null) {
                return 1
            }

            return moment(a.lastModified).isBefore(b.lastModified) ? 1 : -1
        })[0];

        return lastModifiedIteration;
    }

    public static getLastModified(project: Project) {
        const projectDate = project.lastModified;
        const lastModifiedIteration = ProjectService.getLastModifiedIteration(project.iterations);
        const iterationDate = lastModifiedIteration?.lastModified ?? projectDate;
        return moment(iterationDate).isAfter(projectDate) ? iterationDate : projectDate;
    }

    public static getLatestThumbnailBlobInfo(project: Project) {
        let latestBlobInfo = null;
        let latestDate: Date | null = null;
        project.iterations?.forEach(iteration => {
            if (iteration.thumbnailBlobInfo) {
                if (compareDates(iteration.lastModified, latestDate) < 0) {
                    latestBlobInfo = iteration.thumbnailBlobInfo;
                    latestDate = iteration.lastModified;
                }
            }
        });
        return latestBlobInfo;
    }

    async postViewJobResultsEvent(projectId: string, jobId: string, containerName: string) {
        try {
            await axios.post('/api/job/viewJobResultsEvent', {
                "JobId": jobId, "Project": projectId, containerName
            });
        } catch (error) {
            throw new Error('Error post event');
        }
    }

    async postViewJobEvent(projectId: string, jobId: string, containerName: string) {
        try {
            await axios.post('/api/job/ViewJobEvent', {
                "JobId": jobId, "Project": projectId, "containerName": containerName
            });
        } catch (error) {
            throw new Error('Error post event');
        }
    }

    public static async getCost(): Promise<any> {
        try {
            const response = await axios.get("/api/project/getCost");
            return response.data;
        } catch {
            throw new Error('Error getting project cost');
        }
    }

    navigateToLastModifiedIteration(project: Project, navigate: NavigateFunction, type: ProjectType = ProjectType.Design) {
        let lastModifiedIteration = ProjectService.getLastModifiedIteration(project.iterations);

        if (lastModifiedIteration != undefined) {
            this.navigateToIteration(project.id, lastModifiedIteration, project.containerName, navigate, null, type)
        } else {
            const route = routes.setups.replace(':id', project.id).replace(':type', lowerCaseProjectType(type)).replace(':container', project.containerName);
            navigate(route);
        }
    }

    navigateToIteration(projectId: string, job: Iteration | null | undefined, containerName: string, navigate: NavigateFunction, view: JobView | null = null, type: ProjectType = ProjectType.Design) {
        if (!job) {
            navigate(routes.setups.replace(':id', projectId).replace(':type', lowerCaseProjectType(type).replace(':container', containerName)));
            return;
        }

        const jobResultCriteria= view != null ? (view == JobView.Result) : true;

        ProjectService.navigateToIteration(projectId, job, containerName, jobResultCriteria, navigate, type);
    }

    public static navigateToIteration(projectId: string, job: Iteration, containerName: string, jobResultsCritera: boolean, navigate: NavigateFunction, type: ProjectType = ProjectType.Design){
        if (job.solveStatus === Status.Completed && jobResultsCritera) {
            new ProjectService().navigateToIterationResult(projectId, job.id, containerName, navigate, type);
        } else {
            new ProjectService().navigateToIterationSetup(projectId, job.id, containerName, navigate, type);
        }
    }

    navigateToIterationSetup(projectId: string, jobId: string | null | undefined, containerName: string, navigate: NavigateFunction, type: ProjectType = ProjectType.Design) {
        const lowerCaseType = lowerCaseProjectType(type);

        if (!jobId) {
            navigate(routes.setups.replace(':id', projectId).replace(':type', lowerCaseType).replace(':container', containerName));
        } else {
            navigate(routes.setup
                .replace(':id', projectId)
                .replace(':jobId', jobId || '').replace(':type', lowerCaseType).replace(':container', containerName));
        }
    }
    
    navigateToIterationResult(projectId: string, jobId: string, containerName: string, navigate: NavigateFunction, type: ProjectType = ProjectType.Design) {   
        this.postViewJobResultsEvent(projectId, jobId, containerName);
        navigate(routes.result
            .replace(':id', projectId)
            .replace(':jobId', jobId).replace(':type', lowerCaseProjectType(type)).replace(':container', containerName));
    }
}
