import { MoldCategory } from "../store/job/mold-categories";

export class ExportedItemGeometricalData {
    readonly id?: string;
    readonly exchangeId?: string | null;
    readonly name?: string;
    readonly fullName?: string;
    readonly format: string;
    readonly category: MoldCategory;
    readonly prefix: string;
    dataUrl: string;
    data: Blob;
    materialIndex: number;
    readonly cadFileName?: string | null;

    constructor(format: string, data: Blob, category: MoldCategory, prefix: string, id?: string, name?: string, fullName?: string, exchangeId?: string | null, materialIndex = 1, cadFileName?: string | null) {
        this.id = id;
        this.exchangeId = exchangeId;
        this.data = data;
        this.name = name;
        this.fullName = fullName;
        this.format = format;
        this.category = category;
        this.prefix = prefix;
        this.dataUrl = `${MoldCategory[category].toLowerCase()}-${this.prefix}.${this.format.toLowerCase()}`;
        this.materialIndex = materialIndex;
        this.cadFileName = cadFileName;
    }

    blobFilePath(project: string, jobId: string): string {
        // FIXME this should come from backend, otherwise it prevents handling common input files between jobs
        return `projects/${project}/jobs/${jobId}/solve/inputs/${this.dataUrl}`;
    }
}

export class ExportedJobGeometricalData {
    Molds: ExportedItemGeometricalData[] = [];
    Channels: ExportedItemGeometricalData[] = [];
    Parts: ExportedItemGeometricalData[] = [];
}