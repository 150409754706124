export default interface RangeMaterial {
    densityMin: number,
    densityMax: number,
    thermalConductivityMin: number,
    thermalConductivityMax: number,
    specificHeatMin: number,
    specificHeatMax: number,
    temperatureMin: number,
    temperatureMax: number,
    ejectionTemperatureMin?: number,
    ejectionTemperatureMax?: number,
    moldTemperatureMin?: number,
    moldTemperatureMax?: number
};

export const defaultPartRangeMaterial = {
    "densityMin": 500,
    "densityMax": 1500,
    "thermalConductivityMin": 0,
    "thermalConductivityMax": 0.5,
    "specificHeatMin": 1000,
    "specificHeatMax": 2500,
    "temperatureMin": 100,
    "temperatureMax": 300,
    "ejectionTemperatureMin": 50,
    "ejectionTemperatureMax": 200,
    "moldTemperatureMin": 20,
    "moldTemperatureMax": 100
};

export const defaultMoldRangeMaterial = {
    "densityMin": 2000,
    "densityMax": 9000,
    "thermalConductivityMin": 10,
    "thermalConductivityMax": 200,
    "specificHeatMin": 400,
    "specificHeatMax": 1000,
    "temperatureMin": 0,
    "temperatureMax": 50
};