import { Box, Button, Typography } from "@mui/material";
import { ToastPosition, toast } from "react-toastify";
import { TipsAndTricksService } from "./TipsAndTricksService";
import theme from "../styles/main-theme";
import { makeStyles } from "@mui/styles";
import { UiSettingsContext } from "../store/uiSettings/UiSettingsContext";
import { useContext, useEffect, useRef } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface TipsAndTricks{
    ShowNextTipsAndTricksWhilePromise: <PromiseReturnType>(promise: Promise<PromiseReturnType>, displayTipsAndTricks: boolean, toastPosition?: ToastPosition) => Promise<PromiseReturnType | undefined>
}

export interface TipAndTrickModel{
    title: string,
    text: string,
    icon: string
}

export interface TipsAndTricksProps{
    title: string,
    text: string,
    promise: Promise<any>,
    toastPosition: ToastPosition
}

const customToastId = "tipsAndTricksCustomToast"
let isDisabled = false;
let isClosed = false;

const close = () => {
    toast.dismiss(customToastId);
    isClosed = true;
}

const TipsAndTrickCustom = (props: TipsAndTricksProps) => {
    const uiSettingsContext = useContext(UiSettingsContext); 
    const location = useLocation();
    const lastLocation = useRef<Location | null>();

    //To make the tips and tricks toast close on page change...couldnt find a better way...
    useEffect(() => {
        if(lastLocation.current != undefined && lastLocation.current?.pathname != location.pathname){
            close();
        }
            
        lastLocation.current = location;
      }, [location]);

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        },
        titleTipsAndTricks: {
            marginTop: "10px"
        }
    })();

    const nextTip = () => {
        TipsAndTricksHelper.ShowNextTipsAndTricksWhilePromise(props.promise, true, props.toastPosition);
    }

    const disableTipsAndTricks = async () => {
        toast.dismiss(customToastId);
        isDisabled = true;

        const updatedSettings = {
            ...uiSettingsContext.uiSettings,
            displayTipsAndTricks: false
        };

        uiSettingsContext.setAndSaveConfig(updatedSettings);
    }
    const { t } = useTranslation();
    return (
        <Box sx={{}}>
            <Box sx={{mt: "0"}}>
                <h3 className={classes.titleTipsAndTricks}>{t(props.title)}</h3>
            </Box>
            <p>{t(props.text)}</p>
            <br/>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Button className={classes.btn} color="primary" onClick={nextTip}>{t("Next Tip")}</Button>
                <Box>
                    <Button className={classes.btn} sx={{mr: "8px"}} color="primary" onClick={disableTipsAndTricks}>{t("Disable")}</Button>
                    <Button className={classes.btn} color="primary" onClick={() => {close();}}>{t("Close")}</Button>
                </Box>
            </Box>
        </Box>
    )
}

export const TipsAndTricksHelper: TipsAndTricks = {
    ShowNextTipsAndTricksWhilePromise: async function <PromiseReturnType>(promise: Promise<PromiseReturnType>, displayTipsAndTricks: boolean, toastPosition: ToastPosition = "bottom-center"): Promise<PromiseReturnType | undefined> {
        if(!displayTipsAndTricks) return promise;

        isClosed = false;
        isDisabled = false;
        toast.dismiss(customToastId)

        let promiseResolved = false;

        //Fetch next tip
        const randomTip = await TipsAndTricksService.GetNextTipsAndTricks();
        if(!randomTip) return promise;

        //Display next tip
        const title = randomTip.title;
        const text = randomTip.text;

        toast(<TipsAndTrickCustom title={title} text={text} promise={promise} toastPosition={toastPosition}/>, {
            toastId: customToastId,
            containerId: "TipsAndTrickToastContainer",
            position: toastPosition,
            autoClose: 10000,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            type: "info",
            icon: false,
            closeButton: false,
            onClose: () => {!promiseResolved && !isDisabled && !isClosed && TipsAndTricksHelper.ShowNextTipsAndTricksWhilePromise(promise, true, toastPosition)}
        });

        const promiseDone = () => {
            //close tips and tricks
            promiseResolved = true;
            toast.dismiss(customToastId)
        }

        const promiseResult = await promise.finally(() => {promiseDone();});
        return promiseResult;
    }
}

