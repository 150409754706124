import React from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { FileWithErrors } from './FileWithErrors';

interface FileListProps { filesWithErrors: FileWithErrors[] | undefined };

const FileList = (props: FileListProps) => {
    if (props.filesWithErrors?.length === 0) return null;

    const hasErrors = props.filesWithErrors?.some(fileWithErrors => fileWithErrors.errors.length > 0);
    const fileInfoMessage = (name: string, size: number) => `${name} - ${size} bytes`;
    const fileErrorMessage = (name: string, error: string) => `${name} - ${error}`;

    if (hasErrors) {
        return <List dense>
            {props.filesWithErrors?.map(fileWithErrors =>
                fileWithErrors.errors.map((error, index) =>
                    <ListItem key={index} >
                        <ListItemText primary={fileErrorMessage(fileWithErrors.file.name, error.message)} />
                    </ListItem>
                ))}
        </List>
    }

    return <List dense>
        {props.filesWithErrors?.map((fileWithErrors, index) =>
            <ListItem key={index} >
                <ListItemAvatar>
                    <Avatar>
                        <UploadFile />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={fileInfoMessage(fileWithErrors.file.name, fileWithErrors.file.size)} />
            </ListItem>
        )}
    </List>
};

export default FileList;