import { MouseEvent, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IterationsList from './IterationsList';
import ActionCard from '../common/ActionCard';
import ProjectInfoComponent from './ProjectInfo.component';
import Project from '../../store/project/project';
import ProjectService from '../../services/ProjectsService';
import LinkTextButton from '../common/LinkTextButton';
import Iteration from '../../store/project/iteration';
import { ProjectType } from '../../store/project/project-data';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/main-theme';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AuthContext from '../../auth/AuthenticationContext';

export type ProjectCardProps = {
    project: Project;
    forceExpand?: boolean
    onDetailsClick: (event: MouseEvent<HTMLElement>) => void
    onCardClick?: (event: MouseEvent<HTMLElement>) => void
    onDeleteClick?: (event: MouseEvent<HTMLElement>) => void
    onExpand?: (project: Project) => void
    onJobDetailsClick?: (iteration: Iteration, projectId: string) => void,
    onJobCancel: (projectId: string, jobId: string) => void
}

const ProjectCard = (props: ProjectCardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    
    const expandProjectCard = () => {
        setExpanded(!expanded);

        if(props.onExpand && expanded == false)
            props.onExpand(props.project);
    }

    const onJobDetailClick = (iteration: Iteration) => {
        if(props.onJobDetailsClick)
            props.onJobDetailsClick(iteration, props.project.id);
    }

    const onJobCancel = (projectId: string, jobId: string) => {
        props.onJobCancel(projectId, jobId)
    }

    return <ActionCard
        data-testid="projectCardItem"
        onCardClick={props.onCardClick}
        onExpandToggle={expandProjectCard}
        expandDisabled={!props.project.iterations?.length}
        expanded={props.forceExpand || expanded || false}
        onDetailsClick={props.onDetailsClick}
        onDeleteClick={props.onDeleteClick}
        sx={{ margin: 'custom.margin.small', ':hover': { boxShadow: 10 }, maxHeight: 385, minWidth: 'inherit' }}
        top={<>
            <ProjectInfoComponent project={props.project} compact={false} />            
        </>}
        expandRow={            
            <Box sx={{background: theme.palette.background.paper, display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0.4rem 0.8rem 0.4rem 0.8rem"}}>
                {!expanded && (props.project.iterations?.length
                    ? <LinkTextButton sx={{padding:"0px !important"}} ariaLabel="expand-iterations" onClick={(e) => { e.stopPropagation(); expandProjectCard() }}>{`${props.project.iterations.length}`} {t("job(s)")}</LinkTextButton>
                    : <LinkTextButton sx={{ padding: "0px !important" }} ariaLabel="setup-iteration" onClick={() => new ProjectService().navigateToIterationSetup(props.project.id, null, props.project.containerName, navigate, props.project.type as ProjectType)}>{t("There are no jobs yet, add one.")}</LinkTextButton>)}
                {authContext.email != props.project.creatorEmail && <Box sx={{marginLeft: "auto", padding: "6px 8px", color: theme.palette.primary.main, display: "flex", alignItems: "center"}}>
                    <AccountCircleIcon sx={{marginRight: "5px", fontSize: "1.3em"}}></AccountCircleIcon>{props.project.creatorEmail}
                </Box>}
            </Box>}
        bottom={<>
            <Box sx={{ display: 'flex', position: 'relative' }}>
            </Box>
            {expanded && <Box>
                <IterationsList
                    compact={false}
                    iterations={props.project.iterations || []}
                    onJobDetailsClick={onJobDetailClick}
                    onJobClick={(iteration) => {
                        new ProjectService().navigateToIteration(props.project.id, iteration, props.project.containerName, navigate, null, props.project.type as ProjectType)
                    }}
                    onJobCancel={onJobCancel}
                />
            </Box>}
        </>}
    />
}

export default ProjectCard;