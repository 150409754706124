import { ProjectFilterType, ProjectFilters } from "../components/common/ProjectFilter";
import Project from "../store/project/project";

export const filterProjectList = (projectList: Project[], filter: ProjectFilters) => {
    let filteredProjects = projectList;

    if(filter.inputSearch){
        if(filter.filterType == ProjectFilterType.AllAttributes){
            filteredProjects = filterByAllAttributes(projectList, filter.inputSearch);
        }else if(filter.filterType == ProjectFilterType.Description){
            filteredProjects = filterByDescription(projectList, filter.inputSearch);
        }else if(filter.filterType == ProjectFilterType.Name){
            filteredProjects = filterByName(projectList, filter.inputSearch);
        }else if(filter.filterType == ProjectFilterType.Tag){
            filteredProjects = filterByTag(projectList, filter.inputSearch);
        }
    }

    if(filter.users){
        filteredProjects = filteredProjects.filter((p) => {return filter.users.map((u) => {return u.checked && u.email}).includes(p.creatorEmail)})
    }

    return filteredProjects;
}

export const filterByDescription = (projectList: Project[], inputSearch: string | null) => {
    if(!inputSearch) return projectList;

    return projectList.filter((p) => {return p.description.toLowerCase().includes(inputSearch!.toLocaleLowerCase())});
}

export const filterByName = (projectList: Project[], inputSearch: string | null) => {
    if(!inputSearch) return projectList;

    return projectList.filter((p) => {return p.name.toLowerCase().includes(inputSearch!.toLocaleLowerCase())});;
}

export const filterByTag = (projectList: Project[], inputSearch: string | null) => {
    if(!inputSearch) return projectList;

    return projectList.filter((p) => {
        if(!p.tags) return false;

        const lowerCaseTags = p.tags.map((t) => {return t.toLocaleLowerCase()});
        for(const tag of lowerCaseTags){
            if(tag.includes(inputSearch!.toLocaleLowerCase())) return true;
        }
    });
}

export const filterByAllAttributes = (projectList: Project[], inputSearch: string | null): Project[] => {
    if(!inputSearch) return projectList;
    
    const filteredByTag = filterByTag(projectList, inputSearch);
    const filteredByName = filterByName(projectList, inputSearch);
    const filteredByDescription = filterByDescription(projectList, inputSearch);

    const combinedList = [...filteredByTag, ...filteredByName, ...filteredByDescription];

    const distinctList = new Set<Project>();
    combinedList.forEach(obj => distinctList.add(obj));

    return Array.from(distinctList);;
}