import { Directives } from "./Directives";
import { BaseNode } from './Base';
import { Group, Groups } from './Group';
import { Index } from "./Index";
import { ObjectNode, ObjectNodes } from './ObjectNode';

export class Document extends BaseNode {
    objectNodes?: ObjectNodes;
    groups?: Groups;

    constructor(name?: string) {
        super(Directives.Document, name);
    }

    ObjectNodes() : ObjectNodes {
        if (!this.objectNodes) this.objectNodes = new ObjectNodes();

        return this.objectNodes;
    }

    Groups() : Groups {
        if (!this.groups) this.groups = new Groups();

        return this.groups;
    }

    pushObject(objectNode?: ObjectNode) : Index | undefined {
        return this.ObjectNodes().push(objectNode);
    }

    pushObjects(objectNodes?: ObjectNodes) : Index | undefined {
        return this.ObjectNodes().pushCollection(objectNodes);
    }

    pushGroup(group?: Group) : Index | undefined {
        return this.Groups().push(group);
    }

    pushGroups(groups?: Groups) : Index | undefined {
        return this.Groups().pushCollection(groups);
    }

    supplementalComments() {
        super.supplementalComments();
        
        if (this.groups) 
            this.addComment(this.groups.commentCount());

        if (this.objectNodes) 
            this.addComment(this.objectNodes.commentCount());
    }

    directiveLine() : string {
        return '';
    }

    supplementalSections() : string {
        return Groups.stringify(this.groups) +
               ObjectNodes.stringify(this.objectNodes);
    }

    export() : Blob {
        return new Blob([this.toString()], {type:'text/plain'});
    }
}