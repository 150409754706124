import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import theme from '../../styles/main-theme';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Grid } from "@mui/material";
import { useContext } from "react";
import { CeetronActionType, CeetronContext, DisplayPreset } from "../../store/job/ceetron-context";
import { useTranslation } from 'react-i18next';
import AuthContext from '../../auth/AuthenticationContext';
import { ProjectType } from "../../store/project/project-data";

const useStyles = makeStyles({
    axisContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        backgroundColor: theme.palette.info.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.info.dark
        }
    },
    buttonSelected: {
        backgroundColor: theme.palette.secondary.dark,
        "&:hover": {
            backgroundColor: theme.palette.info.dark
        }
    }
});

interface AxisSelectorProps {
    cuttingPlane: cee.ug.CuttingPlane;
    projectType: ProjectType;
    comparisonCuttingPlane?: cee.ug.CuttingPlane;
    handleClipPlasticPart: (isClipping: boolean) => void
}

export const AxisSelector = (props: AxisSelectorProps) => {
    const { t } = useTranslation();
    const context = useContext(CeetronContext);
    const classes = useStyles();
    const authContext = useContext(AuthContext);

    function isShowMoldTemperatures() {
        return !props.cuttingPlane.computeFromVisibleParts;
    }

    function isClipPlasticPart() {
        return props.cuttingPlane.clipping;
    }

    function isInverted() {
        const normal = context.ceetronState.cuttingPlaneNormal;
        return normal.x + normal.y + normal.z < 0;
    }

    function handleAxisChange(x: number, y: number, z: number) {
        const factor = isInverted() ? -1 : 1;
        emitVector(x, y, z, factor);
    }

    function handleAxisInvert() {
        const normal = context.ceetronState.cuttingPlaneNormal;
        emitVector(normal.x, normal.y, normal.z, -1);
    }

    function handleShowMoldTemperatures() {
        props.cuttingPlane.computeFromVisibleParts = !props.cuttingPlane.computeFromVisibleParts;
        if (props.comparisonCuttingPlane) {
            props.comparisonCuttingPlane.computeFromVisibleParts = !props.comparisonCuttingPlane.computeFromVisibleParts;
        }
    }

    function handleClipPlasticPart() {
        props.cuttingPlane.clipping = !props.cuttingPlane.clipping;

        if (props.comparisonCuttingPlane) {
            props.comparisonCuttingPlane.clipping = !props.comparisonCuttingPlane.clipping;
        }

        props.handleClipPlasticPart(props.cuttingPlane.clipping);
    }

    function emitVector(x: number, y: number, z: number, factor: number) {
        context.updateCeetronState({
            type: CeetronActionType.SetCuttingPlaneNormal, payload: {
                x: factor * x,
                y: factor * y,
                z: factor * z,
            }
        });
    }

    function isDisplayPresetPlastic() {
        return (context.ceetronState.displayPreset === DisplayPreset.PLASTIC_SURFACE ||
            context.ceetronState.displayPreset === DisplayPreset.PLASTIC_VOLUME);
    }

    return (
        <Grid container rowSpacing={1} sx={{ flexDirection: "row" }}>
            <Grid item xs={3}><Button sx={{ width: "1em", fontWeight: "bold", color: theme.palette.primary.dark }}
                variant="contained" className={context.ceetronState.cuttingPlaneNormal.x !== 0 ? classes.buttonSelected : classes.button} color="secondary" component="span" onClick={() => handleAxisChange(1, 0, 0)}>
                X
            </Button></Grid>
            <Grid item xs={3}> <Button sx={{ width: "1em", fontWeight: "bold", color: theme.palette.primary.dark }}
                variant="contained" className={context.ceetronState.cuttingPlaneNormal.y !== 0 ? classes.buttonSelected : classes.button} color="secondary" component="span" onClick={() => handleAxisChange(0, 1, 0)}>
                Y
            </Button></Grid>
            <Grid item xs={3}><Button sx={{ width: "1em", fontWeight: "bold", color: theme.palette.primary.dark }}
                variant="contained" className={context.ceetronState.cuttingPlaneNormal.z !== 0 ? classes.buttonSelected : classes.button} color="secondary" component="span" onClick={() => handleAxisChange(0, 0, 1)}>
                Z
            </Button></Grid>
            <Grid item xs={3}><FormControlLabel
                control={<Checkbox checked={isInverted()} onChange={handleAxisInvert} />}
                label={t("Invert")}
            /></Grid>
            {(props.projectType == ProjectType.Design || (authContext.isSupervisor && props.projectType == ProjectType.Feasibility))
                && isDisplayPresetPlastic()
                && <Grid item xs={12}><FormControlLabel id="axis-selector-mold-temperature"
                    control={<Checkbox checked={isShowMoldTemperatures()} onChange={handleShowMoldTemperatures} />}
                    label={t("Show Mold Temperatures")}
                /></Grid>}
            {isDisplayPresetPlastic()
                && <Grid item xs={12}><FormControlLabel id="axis-selector-clip"
                    control={<Checkbox checked={isClipPlasticPart()} onChange={handleClipPlasticPart} />}
                    label={t("Clip Plastic Part")}
                /></Grid>}
        </Grid>
    );
};
