import { useMutation, useQuery } from 'react-query';
import { queryClient } from '../../../react-query/queryClient';
import { queryKeys } from '../../../react-query/queryKeys';
import JobService from '../../../services/JobService';
import ProjectService from '../../../services/ProjectsService';
import Iteration from '../../../store/project/iteration';

export const useJobsQuery = (projectId: string, containerName: string) => {
    const queryData = useQuery<Iteration[], Error>([queryKeys.jobs, projectId], async () => {

        const iterations = await new ProjectService().getIterations(projectId, containerName);

        return iterations;
    }, {
        refetchInterval: 15000,
        refetchOnWindowFocus: false,
        select: data => data.sort((a: Iteration, b: Iteration) => !b.lastModified || !a.lastModified ? -1 : new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime())
    })

    return queryData;
}

export const useJobMutation = (projectId: string) => {
    const jobMutation = useMutation(JobService.editJobInfo, {
        onSuccess: (updated) => {

            queryClient.setQueryData<Iteration[]>([queryKeys.jobs, projectId], (old) => {
                const found: Iteration[] | undefined = old?.filter(item => item.id === updated.id);
                if (found == null || found?.length === 0) return [];

                const currentJob = found[0];
                const updatedJob: Iteration = {
                    ...currentJob,
                    name: updated?.name,
                    description: updated?.description,
                    customSolverSettings: updated?.customSolverSettings
                };

                return old ? [...old?.filter(item => item.id !== updated.id), updatedJob] : [];
            });
        }
    });

    return jobMutation;
}