import { Box, FormGroup, FormControlLabel, Checkbox, useTheme } from "@mui/material"
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react"
import { AccountUsersConfiguration } from "../../store/creditsAccount/CreditsAccountContext"
import { useTranslation } from 'react-i18next';

export interface AccountConfigurationProps {
    AccountUsersConfiguration: AccountUsersConfiguration,
    onChangeUsersConfig: (updatedConfig: AccountUsersConfiguration) => void
}

export const AccountConfiguration = (props: AccountConfigurationProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const handleUsersConfigChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const isChecked = event.target.checked;

        const updatedUsersConfig = {...props.AccountUsersConfiguration};
        updatedUsersConfig[field] = isChecked;

        props.onChangeUsersConfig(updatedUsersConfig);
    }

    return (
        <Box sx={{ position: "relative", display: "flex", justifyContent: "flex-start", flexWrap: "nowrap", overflowX: "auto", scrollbarColor: theme.palette.info.contrastText, padding: "10px"}}>
            <>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={props.AccountUsersConfiguration.DisplayCreditsToInvitedUsers} 
                        onChange={((e: React.ChangeEvent<HTMLInputElement>) => {handleUsersConfigChange(e, "DisplayCreditsToInvitedUsers")})}/>} 
                        label={t("Display Credits for Invited Users")} />
                </FormGroup>
            </>
        </Box>
    )
}