import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions, Button, useTheme, FormGroup, Alert, AlertTitle, Typography } from '@mui/material';
import { t } from 'i18next';
import { makeStyles } from "@mui/styles";
import { ImportJobPathCategory, ImportJobResult } from '../../store/job/job-data';


export type JobImportResultDialogProps = {
    open: boolean;
    errors: ImportJobResult[] | undefined;
    warnings: ImportJobResult[] | undefined;
    pathResultCategories: ImportJobPathCategory[] | undefined;
    jobImported: boolean;
    onClose: () => void;
}

const JobImportResultDialog = (props: JobImportResultDialogProps) => {
    const theme = useTheme();

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return (
        <Dialog id="job-import-result-dialog" open={props.open} onClose={() => props.onClose()} aria-label={t("Import Job Results")}>
            <DialogTitle sx={{ color: theme.palette.primary.dark }}>{props.jobImported ?
                t("Job partially imported with exception(s)") :
                t("Job import interrupted by critical error(s)")}</DialogTitle>
            <DialogContent> <FormGroup>
                {props.warnings?.map((warning, index) => {
                    return (<Alert severity="warning" key={"wrr-" + index} sx={{ maxWidth: "99%" }}>
                        <AlertTitle>{t(warning.message) + (warning.detail ? (": " + warning.detail) : "")}</AlertTitle>
                    </Alert>);
                })}
                {props.errors?.map((error, index) => {
                    return (<Alert severity="error" key={"err-" + index} sx={{ maxWidth: "99%" }}>
                        <AlertTitle>{t(error.message) + (error.detail ? (": " + error.detail) : "")}</AlertTitle>
                    </Alert>);
                })}
                {props.pathResultCategories && props.pathResultCategories?.length > 0 &&
                    <Typography sx={{ fontSize: '1.05em', textTransform: 'none' }}>
                        {t("The body(s) specified are not found")}:
                    </Typography>
                }
                {props.pathResultCategories?.map((cat, index) => {
                    return (
                        <Alert severity="error" key={"pat-" + index} sx={{ maxWidth: "99%" }}>
                            <AlertTitle>
                                <b>{t(cat.category)}</b>
                                {cat.paths?.map((path) => <div>
                                    {cat.category == "Molds" ? `${t("Mold Group")} ${path.name.replace(/[^\d\.]*/g, '')}`: path.name}
                                    {path?.values?.map((value) => <div>
                                        {value}
                                    </div>)}
                                </div>)}
                            </AlertTitle>
                        </Alert>)
                })}
            </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button className={classes.btn} onClick={(e) => {
                    e.stopPropagation();
                    props.onClose();
                }}>{t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default JobImportResultDialog;