import { toPrecision } from "./Utilities";

export class Ratio {
    readonly ratio: number;

    constructor(ratio: number) {
        this.ratio = ratio;
    }

    equals(other: Ratio) {
        return (this.ratio === other.ratio);
    }

    toString(): string {
        return toPrecision(this.ratio);
    }
}