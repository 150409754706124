import { IterationNameMinimumLength, ProjectNameMinimumLength } from "../components/projects/ProjectsConfig";

const tagNameMaxCharacters = 256;

function hasValidNameCharacters(value: string){
  if(value == undefined || value == "") return true;
  let regex = new RegExp('^[A-Za-zÀ-ÖØ-öø-ÿ0-9. _ĉ-]+$', 'gi');
  return regex.test(value);
}

function hasValidTagCharacters(value: string | undefined){
  if(value == undefined || value == "") return true;

  let regex = new RegExp('^[0-9a-zA-Z .+-/:=_]+$', 'g');
  return regex.test(value);
}

function validateProjectName(value: string){
  let rule1 = value.trim() !== '' && value.length >= ProjectNameMinimumLength;
  let rule2 = hasValidNameCharacters(value);
  
  return rule1 && rule2;
}

function validateJobName(value: string){
  let rule1 = value.trim() !== '' && value.length >= IterationNameMinimumLength;
  let rule2 = hasValidNameCharacters(value);
  
  return rule1 && rule2;
}

function validateTagName(value: string){
  return hasValidTagCharacters(value) && value.length <= tagNameMaxCharacters
}

export {validateProjectName, validateJobName, validateTagName}