import { Channel } from "../../../store/job/channel";
import { ColorGroup, ColorStateActionType, HoopsColorContextType } from "../../../store/job/hoops-color-context";
import { MoldCategory } from "../../../store/job/mold-categories";
import { baffleColor, categoryColors, channelSelectionModeColorDefault, channelSelectionModeColorDefaultSelected, channelSelectionModeColorInletDone, channelSelectionModeColorInletDoneSelected, channelSelectionModeColorInletOutletDone, channelSelectionModeColorInletOutletDoneSelected, channelSelectionModeColorNoInlet, channelSelectionModeColorNoInletSelected, inletSelectionModeColor, outletSelectionModeColor } from "../../../store/uiSettings/communicatorColors";
import { makeFaceKey } from "../../../utils/hoops.utils";

export function setChannelsColors(channels: Channel[], hoopsColorContext: HoopsColorContextType, selectedChannel?: Channel) {
    const groups: ColorGroup[] = [];

    for (const channel of channels) {
        if (channel.inlets.length > 0 && channel.outlets.length === 0) {
            groups.push((selectedChannel && channel.id === selectedChannel.id) ? colorInletDoneSelected(channel) : colorInletDone(channel));
        } else if (channel.inlets.length === 0 && channel.outlets.length > 0) {
            groups.push((selectedChannel && channel.id === selectedChannel.id) ? colorNoInletSelected(channel) : colorNoInlet(channel));
        } else if (channel.inlets.length > 0 && channel.outlets.length > 0) {
            groups.push((selectedChannel && channel.id === selectedChannel.id) ? colorInletOutletDoneSelected(channel) : colorInletOutletDone(channel));
        } else {
            groups.push((selectedChannel && channel.id === selectedChannel.id) ? colorOnlyBodiesSelected(channel) : colorOnlyBodies(channel));
        }
    }

    hoopsColorContext.updateColor({
        type: ColorStateActionType.SET_CATEGORY_GROUP_COLOR,
        category: MoldCategory.Channel,
        groups
    });
}

export function resetChannelsColors(hoopsColorContext: HoopsColorContextType) {
    hoopsColorContext.updateColor({
        type: ColorStateActionType.CLEAR_CATEGORY,
        category: MoldCategory.Channel
    });
}

function makeColorGroup(channel: Channel, color: Communicator.Color = categoryColors[MoldCategory.Channel]): ColorGroup {
    let group: ColorGroup = {
        bodies: {
            nodes: channel.getBodyParts().flatMap(part => part.nodesIds).map(nodeId => ({
                nodeId,
                color
            })),
            faces: channel.getBodyFaceGroups().flatMap(fg => fg.config).map(face => ({
                faceKey: makeFaceKey(face),
                color
            }))
        },
        inlets: channel.inlets.filter(i => Channel.isSyntheticFace(i.face) === false).map(i => ({
            faceKey: makeFaceKey(i.face),
            color: inletSelectionModeColor
        })),
        outlets: channel.outlets.filter(o => Channel.isSyntheticFace(o.face) === false).map(o => ({
            faceKey: makeFaceKey(o.face),
            color: outletSelectionModeColor
        })),
        baffles: channel.baffles.flatMap(fg => fg.config).filter(face => Channel.isSyntheticFace(face) === false).map(face => ({
            faceKey: makeFaceKey(face),
            color: baffleColor
        }))
    }

    return group;
}

function colorInletOutletDoneSelected(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorInletOutletDoneSelected);
}

function colorInletOutletDone(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorInletOutletDone);
}

function colorInletDoneSelected(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorInletDoneSelected);
}

function colorInletDone(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorInletDone);
}

function colorNoInletSelected(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorNoInletSelected);
}

function colorNoInlet(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorNoInlet);
}

function colorOnlyBodiesSelected(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorDefaultSelected);
}

function colorOnlyBodies(channel: Channel): ColorGroup {
    return makeColorGroup(channel, channelSelectionModeColorDefault);
}

