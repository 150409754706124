import { CategoriesData, BasicMold, CategoriesDataForSummary } from '../../store/job/job-data';
import BlobInfo from '../job/blob-info';

export default interface Iteration {
    id: string,
    name: string,
    userId: string,
    projectId: string,
    description: string,
    estimatedCoolingTime: number,
    avatar: string,
    type: string,
    status: string,
    solveStatus: number,
    solveInfoEjectionTime: number,
    solveCurrentProgress: number,
    solveInfo?: SolveInfo,
    parameters?: Parameters,
    errorInfo?: ErrorInfo,
    versionId: string,
    lastModified: Date | null,
    thumbnailData: string | null,
    thumbnailBlobInfo: BlobInfo | null,
    cugBundleBinaryBlobInfo: BlobInfo | null,
    containerName: string,
    customSolverSettings: string | null
}

export interface JobThumbnailData {
    jobId: string,
    thumbnailData: string | null
}

export interface ErrorInfo {
    code: number,
    message: string
}

export interface SolveInfo {
    ejectionTime: number,
    ejectionTimeLowerBound?: number | null,
    ejectionTimeUpperBound?: number | null,
    isTimeMaxReached?: boolean | null,
    isReportEjectionTimeRange?: boolean | null,
    channelInfo?: ChannelInfo[]
}

export interface Parameters {
    categories: CategoriesDataForSummary,
    basicMold?: BasicMold
}

export interface ChannelInfo {
    id: string,
    inlets: TerminalInfo[],
    outlets: TerminalInfo[]
}

export interface TerminalInfo {
    id: string,
    flowRate?: number
    temperature: number
}

export function getIterationMaterialEjectionTemperature(iteration?: Iteration) {
    return iteration?.parameters?.categories?.materials?.parts?.[0]?.ejectionTemperature ?? null
}