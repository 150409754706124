import { MutableRefObject } from 'react';
import { Id, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryErrorHandler = (error: unknown) => {
    const title = error instanceof Error ? error.message : 'error connecting to server';
    toast.error(title, {
        containerId: "InfoToastContainer",
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const vizServerErrorHandler = (error: unknown, toastId: MutableRefObject<Id | undefined>) => {
    const title = error instanceof Error ? error.message : 'error connecting to visualization server';
    return toast.error(title, {
        containerId: "InfoToastContainer",
        position: "bottom-left",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
            if (toastId.current) {
                window.location.reload();
            }
        }
    });
}

export const successToast = (msg: string) => {
    toast.success(msg, {
        containerId: "InfoToastContainer",
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export default queryErrorHandler;