import { Upload } from '@mui/icons-material';
import { Paper, Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileWithErrors } from './FileWithErrors';
import FileList from './FileList';
import { useTranslation } from 'react-i18next';

export interface ModelPickerInfo {
    selected: Array<File> | undefined
}

export interface ModelPickerProps {
    setModelPickerInfo: (info: ModelPickerInfo) => void
}

const ModelPicker = (props: ModelPickerProps) => {

    const theme = useTheme();
    const { t } = useTranslation();
    // As of summer 2022, Firefox doesn't implement the File System Access API,
    // and support in Chrome is troublesome (underscore are not accepted in file extensions).
    // Set useFsAccessApi to false (falls back to legacy <input type="file">).
    const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
        useFsAccessApi: false,
        accept: {
            'model/step': [".step", ".stp"],
            'model/stl': [".stl"],
            'model/step+xml': [".stpx"],
            'model/step+zip': [".stpz"],
            'model/step-xml+zip': [".stpxz"],
            'model/vnd.parasolid.transmit.text': [".x_t", ".xmt_txt"],
            'model/vnd.parasolid.transmit.binary': [".x_b", ".xmt_bin"]
        }
    });

    useEffect(() => {
        props.setModelPickerInfo({
            selected: acceptedFiles.length > 0 ? acceptedFiles : undefined });
    }, [fileRejections, acceptedFiles]);

    const rejectedFilesWithErrors: FileWithErrors[] = fileRejections.map(fileRejection => {
        return { ...fileRejection };
    });

    const acceptedFilesWithErrors: FileWithErrors[] = acceptedFiles.map(file => {
        return { file, errors: [] };
    });

    const { ref, ...rootProps } = getRootProps();

    return <>
        <Paper {...rootProps} ref={ref} sx={{ cursor: 'pointer', ':hover': { 'border': `1px solid ${theme.palette.primary.dark}` }, 'border': '1px solid transparent' }}>
            <input data-testid="fileDropzone" id="cad-file-input" {...getInputProps()} />
            <Box id="file-chooser-section-dialog" textAlign='center' sx={{padding: "10px"}}>
                <IconButton>
                    <Upload color='primary'></Upload>
                </IconButton>
                <Typography variant='h6' component='div' color='primary'>{t("Select file")}</Typography>
                <Typography variant='caption' component='div'>{t("STEP, STL and Parasolid files are allowed")}</Typography>
                <Typography variant='caption' component='div'>{t("Maximum 512mb per file")}</Typography>
            </Box>
        </Paper>
        <Box>
            <FileList filesWithErrors={rejectedFilesWithErrors} />
        </Box>
    </>;
}

export default ModelPicker;