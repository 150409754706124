import { useContext, useEffect, useRef, useState } from "react";
import { CeetronActionType, CeetronContext, DisplayPreset, VtfxScalarResults } from "../../../store/job/ceetron-context";
import { TemperatureUnit, UiSettingsContext } from "../../../store/uiSettings/UiSettingsContext";
import { makeStyles } from "@mui/styles";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, Input, InputAdornment, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertTemperature, getUnit } from "../../../utils/units.utils";
import CloseIcon from '@mui/icons-material/Close';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';


export interface ColorLegendSettings {
    min: number;
    max: number
}

interface ColorLegendSettingsProps {
    viewer: cee.Viewer,
    model: cee.ug.RemoteModel,
    precision: number,
    ejectionTemperature: number | null,
    displayPreset: DisplayPreset
}

export const ColorLegendSettingsDialog = (props: ColorLegendSettingsProps) => {
    const ceetronContext = useContext(CeetronContext);
    const uiSettingsContext = useContext(UiSettingsContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const [min, setMin] = useState<number>(0);
    const [max, setMax] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [displayedUnit, setDisplayedUnit] = useState<string>('');
    const initialDialogSettings = useRef<ColorLegendSettings | null>(null);
    const scalarSettings = useRef<cee.ug.ScalarSettings>();
    const scalarResults = useRef<cee.ug.ResultInfo>();

    const classes = makeStyles({
        icon: {
            position: "relative",
            padding: "5px 10px 5px 10px",
            color: theme.palette.primary.dark,
            marginLeft: "10px"
        },
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    const initDlialog = (model: cee.ug.RemoteModel, resultId: number) => {
        const settings = model.getScalarSettingsById(resultId);

        if (settings) {
            const max = isNaN(settings.rangeMaximum) ? model.getScalarResultMaximumValue(resultId) : settings.rangeMaximum;
            const min = isNaN(settings.rangeMinimum) ? model.getScalarResultMinimumValue(resultId) : settings.rangeMinimum;
            const scalarResultInfo = model.modelDirectory.scalarResultArray.find(sra => sra.id === resultId);

            if (scalarResultInfo) {
                const unit = getUnit(scalarResultInfo);

                if (scalarResultInfo.name === VtfxScalarResults.TEMPERATURE_RESULT) {
                    const convertedMax = convertTemperature({
                        value: max,
                        unit: unit as TemperatureUnit
                    }, uiSettingsContext.uiSettings.temperatureUnit);
                    const convertedMin = convertTemperature({
                        value: min,
                        unit: unit as TemperatureUnit
                    }, uiSettingsContext.uiSettings.temperatureUnit);

                    setMax(parseFloat(convertedMax.value.toFixed(props.precision)));
                    setMin(parseFloat(convertedMin.value.toFixed(props.precision)));
                    setDisplayedUnit(convertedMax.unit);
                } else {
                    setMin(parseFloat(min.toFixed(props.precision)));
                    setMax(parseFloat(max.toFixed(props.precision)));
                    setDisplayedUnit(unit);
                }

                scalarSettings.current = settings;
                scalarResults.current = scalarResultInfo;

                if (!initialDialogSettings.current) {
                    initialDialogSettings.current = { min, max };
                }
                setOpen(true);
            }
        }

    };

    const onSave = () => {
        let maxValue = max;
        let minValue = min;
        if (scalarResults.current?.name === VtfxScalarResults.TEMPERATURE_RESULT) {
            maxValue = convertTemperature({
                value: max, unit: uiSettingsContext.uiSettings.temperatureUnit
            }, getUnit(scalarResults.current!) as TemperatureUnit).value;
            minValue = convertTemperature({
                value: min, unit: uiSettingsContext.uiSettings.temperatureUnit
            }, getUnit(scalarResults.current!) as TemperatureUnit).value;
        }
        scalarSettings.current?.setRange(minValue, maxValue);
        ceetronContext.updateCeetronState({ type: CeetronActionType.SetScalarResultsRange, payload: { min: minValue, max: maxValue } });
        setOpen(false);
    }

    const onReset = () => {
        if (initialDialogSettings.current) {
            ceetronContext.updateCeetronState({ type: CeetronActionType.SetScalarResultsRange, payload: initialDialogSettings.current });
            scalarSettings.current?.setRange(initialDialogSettings.current?.min, initialDialogSettings.current.max);
        }
        setOpen(false);
    };


    useEffect(() => {
        props.viewer.setColorLegendClickHandler((resultId: number) => initDlialog(props.model, resultId));
    }, [props.viewer, props.model]);

    useEffect(() => {
        initialDialogSettings.current = null
    }, [ceetronContext.ceetronState.displayPreset]);

    return (
        <Dialog open={open} BackdropProps={{
            invisible: true
        }} id="legend_settings_panel_dlg" sx={{
            '.MuiDialog-paper': {
                backgroundColor: theme.palette.info.dark,
                position: 'absolute',
                top: '7em',
                left: '21em',
                borderRadius: '8px',
                boxShadow: `0px 2px 1px -1px ${theme.palette.primary.dark}, 0px 1px 1px 0px ${theme.palette.primary.dark}, 0px 1px 3px 0px rgba(0,0,0,0.12)`,
                border: '1px solid transparent',
                color: theme.palette.primary.contrastText,
                maxWidth: '16.5em'
            }
        }}>
            <DialogTitle sx={{
                '&': {
                    width: '100%',
                    fontWeight: 700,
                    fontSize: '1.2em',
                    padding: '0.5em 1em 0.5em 1em',
                    color: theme.palette.primary.main
                }
            }}>
                <CloseIcon sx={{
                    width: '0.75em',
                    height: '0.75em',
                    marginLeft: '1.5em',
                    marginRight: '-0.2em',
                    marginTop: '-0.2em',
                    borderRadius: '0.75em',
                    position: 'relative',
                    cursor: 'pointer',
                    float: 'right',
                    color: theme.palette.error.contrastText,
                    backgroundColor: theme.palette.primary.dark,
                }} onClick={() => setOpen(false)} />
                {t("Legend Settings")}
            </DialogTitle>
            <DialogContent sx={{
                '&': {
                    padding: '0.5em 1em 0.5em 1em',
                }
            }}>
                <Grid container>
                    <Grid item>
                        <Stack direction="column">
                            {t("Max")}:
                            <Stack direction="row">
                                <Input
                                    sx={{
                                        width: '150px',
                                        padding: '0em 0.5em 0em 0.5em',
                                        backgroundColor: theme.palette.info.contrastText,
                                        color: theme.palette.primary.dark
                                    }}
                                    value={max}
                                    id='legend_settings_panel_max_input'
                                    onChange={(event: any) => setMax(parseFloat(event.target.value))}
                                    inputProps={{
                                        type: 'number'
                                    }}
                                    endAdornment={<InputAdornment position="end">{displayedUnit}</InputAdornment>}
                                />

                                {props.ejectionTemperature &&
                                    (props.displayPreset == DisplayPreset.PLASTIC_SURFACE ||
                                        props.displayPreset == DisplayPreset.PLASTIC_VOLUME) &&
                                    <Tooltip title={t("Set Safe Ejection Temperature")}>
                                        <Fab id="thermostat-icon-id" className={classes.icon} variant="extended" size="small" color="default" >
                                            <DeviceThermostatOutlinedIcon id="legend_settings_safe_ejection_icon" onClick={() => { setMax(Number(props.ejectionTemperature)); }} />
                                        </Fab>
                                    </Tooltip>}

                            </Stack>
                            {t("Min")}: <Input
                                sx={{
                                    width: '150px',
                                    padding: '0em 0.5em 0em 0.5em',
                                    backgroundColor: theme.palette.info.contrastText,
                                    color: theme.palette.primary.dark
                                }}
                                value={min}
                                id='legend_settings_panel_min_input'
                                onChange={(event: any) => setMin(parseFloat(event.target.value))}
                                inputProps={{
                                    type: 'number'
                                }}
                                endAdornment={<InputAdornment position="end">{displayedUnit}</InputAdornment>} />
                            <Typography sx={{
                                width: '100%',
                                marginBotton: '1em',
                                fontSize: '0.95em',
                                marginTop: '1em'
                            }}>{t("Values above the range are shown in dark red, below in purple.")}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onReset()}
                    className={classes.btn} variant={'contained'} size="small">{t("Reset")}</Button>
                <Button onClick={() => onSave()}
                    className={classes.btn} variant={'contained'} size="small">{t("Save")}</Button>
            </DialogActions>
        </Dialog>)
}