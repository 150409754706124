import { Collection, CollectionKinds } from "./Collection";
import { Directives, composeDirective } from "./Directives";
import { Index } from "./Index";

function checkVertices(vertexIndexes: Index[]) : boolean {
    if (vertexIndexes.length < 2) {
        console.warn("An insufficient number of vertrices were provided to compose a line. vertices: " + vertexIndexes.length);
        return false;
    }

    return true;
}

export class Line {
    vertexIndexes?: Index[]

    constructor(vertexIndexes: Index[]) {
        if (!checkVertices(vertexIndexes)) return;

        this.vertexIndexes = vertexIndexes;
    }

    toString() : string {
        return composeDirective(Directives.Line, this.vertexIndexes?.join(' '));
    }
}

export class Lines extends Collection<Line> {
    constructor(values? : Line[]) {
        super(CollectionKinds.Lines, values);
    }

    add(vertexIndexes?: Index[]) : Index | undefined {
        if (!vertexIndexes || vertexIndexes.length === 0) return undefined;

        return this.push(new Line(vertexIndexes));
    }
}