import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormGroup, FormControl, TextField, Paper, Table, TableBody, TableCell, TableContainer, IconButton, TableRow, Alert, Box, CircularProgress  } from "@mui/material";
import theme from "../../styles/main-theme";
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Reducer, useEffect, useReducer, useState } from "react";
import { makeStyles } from '@mui/styles';
import queryErrorHandler from "../../react-query/queryErrorHandler";
import { UserInvitation } from "../users/UserInvitations";
import CompanyInvitationService from "../../services/CompanyInvitationService";
import { useTranslation } from 'react-i18next';

export interface UserInvitationDialogProps{
    title: string,
    open: boolean,
    onClose: () => void,
    onUsersInvited: (invitedUsers: Array<UserInvitation>) => void
}

export interface EmailFormState{
    emailInput: string,
    emailInputError: boolean,
    emailList: Array<string>,
    atLeastOneValidEmailError: boolean,
    submitInvitations: boolean
}

export interface UserInvitationEmailFormAction{
    type: string,
    email?: string | undefined,
    emailIndexToRemove?: number
}

const reducerFunction: Reducer<EmailFormState, UserInvitationEmailFormAction> = (state: EmailFormState, action: UserInvitationEmailFormAction) => {
    if(action.type == "ChangeEmailAddressNoValidation" && action.email != undefined){
        return {
            ...state,
            emailInput: action.email
        }
    }else if(action.type == "SetEmailAddressWithValidation" && action.email){
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(action.email)){
            return {
                ...state,
                emailInput: action.email,
                emailInputError: true
            }
        }else{
            return {
                ...state,
                emailInput: action.email,
                emailInputError: false
            } 
        }
    }else if(action.type == "AddInputEmailToList"){
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.emailInput)){
            return {
                ...state,
                emailInputError: true
            }
        }else{
            const emailInput = state.emailInput;
            return {
                ...state,
                emailInput: "",
                emailInputError: false,
                emailList: [...state.emailList.filter((email) => {return email != emailInput}), emailInput],
                atLeastOneValidEmailError: false
            }
        }
    }else if(action.type == "ResetState"){
        return {
            ...state,
            emailInput: "",
            emailInputError: false,
            emailList: [],
            atLeastOneValidEmailError: false,
            submitInvitations: false
        } 
    }else if(action.type == "RemoveEmail" && action.emailIndexToRemove != undefined){
        let newEmailList = [...state.emailList]
        newEmailList.splice(action.emailIndexToRemove, 1);
        return {
            ...state,
            emailList: newEmailList
        } 
    }else if(action.type == "InviteEmailList"){
        if(state.emailList.length > 0){
            return {
                ...state,
                submitInvitations: true
            }
        }else{
            return {
                ...state,
                atLeastOneValidEmailError: true
            }
        }
    }

    return {...state};
}

const initialEmailFormState: EmailFormState = {
    emailInput: '',
    emailInputError: false,
    emailList: [],
    atLeastOneValidEmailError: false,
    submitInvitations: false
}

export const UserInvitationDialog = (props: UserInvitationDialogProps) => {
    const { t } = useTranslation();
    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    const [isLoading, setIsLoading] = useState(false);

    const [state, dispatchEmailForm] = useReducer(reducerFunction, initialEmailFormState)

    const submitInvitations = async() => {
        if(state.submitInvitations == true && state.atLeastOneValidEmailError == false && state.emailList.length > 0){
            try{
                setIsLoading(true);
                const invitedUsers = await CompanyInvitationService.InviteUsers(state.emailList);
                props.onUsersInvited(invitedUsers);
            }catch(error){
                queryErrorHandler(error);
            }finally{
                setIsLoading(false);
                resetState()
                props.onClose();
            }
        }
    }

    useEffect(() => {
        submitInvitations();
    }, [state.submitInvitations])

    const addEmailToList = () => {
        const data ={
            type: "AddInputEmailToList",
            email: undefined
        };

        dispatchEmailForm(data);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            addEmailToList();
        }
    }

    const handleInputChange = (event: any) => {
        const value = event.target.value;
        setEmail(value, "ChangeEmailAddressNoValidation");
    }

    const setEmail = (email: string, type: string) => {
        const data ={
            type: type,
            email: email
        };

        dispatchEmailForm(data);
    }

    const resetState = () => {
        dispatchEmailForm({type: "ResetState", email: undefined})
    }

    const onCloseDialog = () => {
        if(isLoading)return;

        resetState();

        props.onClose();
    }

    const removeEmailFromList = (index: number) => {
        const data ={
            type: "RemoveEmail",
            emailIndexToRemove: index
        };
        dispatchEmailForm(data)
    }

    const invite = () => {
        const data ={
            type: "InviteEmailList"
        };
        dispatchEmailForm(data)
    }

    return (
        <Dialog id="info-dialog" open={props.open} onClose={onCloseDialog} aria-label="error dialog" maxWidth="sm" fullWidth>
            <DialogTitle sx={{ color: theme.palette.primary.dark, mb: "5px"}}>{props.title}</DialogTitle>
            <DialogContent sx={{position: "relative"}}>
                {isLoading == true && <Box sx={{position: "absolute", top: 0, bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress />
                </Box>}
                <br/>
                <FormGroup sx={{mb: "25px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <FormControl sx={{flexGrow: "1"}}>
                        <TextField
                            data-testid="InviteEmailInputField"
                            disabled={isLoading}
                            error={state.emailInputError}
                            size="small"
                            id="outlined-error"
                            label={t("Email")}
                            placeholder={t("Enter user email")}
                            value={state.emailInput}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                    </FormControl>
                    <IconButton data-testid="addEmailButton" disabled={state.submitInvitations} onClick={() => {addEmailToList();}} 
                        sx={{color: theme.palette.primary.main, ml: "10px"}} >
                        <AddBoxIcon sx={{fontSize: "1.2em"}}/>
                    </IconButton>
                </FormGroup>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                        {state.emailList.map((inviteEmail, index) => (
                            <TableRow
                                hover
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell scope="row" align="left" sx={{width:"20%"}}>
                                    {inviteEmail}
                                </TableCell>
                                <TableCell scope="row" align="right" sx={{width:"10%"}}>
                                    <IconButton disabled={state.submitInvitations} onClick={() => {removeEmailFromList(index)}} sx={{padding: 0, color: theme.palette.error.main, cursor: "pointer", verticalAlign: 'middle', fontSize:"1.5em"}}>
                                        <CancelIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>  
                {state.atLeastOneValidEmailError && 
                    <Alert severity="error" sx={{mt: "15px"}}>
                       {t("You need to have at least one valid email in the list.")} {t("Use the '+' button to add the email in the list.")}
                    </Alert>
                }                
            </DialogContent>
            <DialogActions>                
                <Button data-testid="UserInviteButton" disabled={state.submitInvitations} className={classes.btn} onClick={invite}>{t("Invite")}</Button>
                <Button disabled={state.submitInvitations} className={classes.btn} onClick={onCloseDialog}>{t("Cancel")}</Button>
            </DialogActions>
        </Dialog>
    )
}