import { useContext, useState, useEffect, PropsWithChildren } from "react";
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import JobContext, { serializeJobContextState } from "../../store/job/job-context";
import JobService from "../../services/JobService";
import SendOutlined from "@mui/icons-material/SendOutlined";
import SolverParameters from "../../store/job/solver-parameters";
import { exportJobGeometricalData } from "../../geometry-exporter/ExportJobGeometricalData";
import JobData, { CanJobBeCancelled, Status, StatusLabel, ViewSelection } from "../../store/job/job-data";
import { MoldCategory } from "../../store/job/mold-categories";
import FileManagementService from "../../services/FileManagementService";
import { PartType } from "../../store/job/ceetron-context";
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import AuthContext from '../../auth/AuthenticationContext';
import { Box, CircularProgress, ToggleButton } from "@mui/material";
import { CancelSubmittedJob } from "./CancelSubmittedJob";
import { makeStyles } from '@mui/styles';
import { Channel } from "../../store/job/channel";
import { useTranslation } from 'react-i18next';
import { ProjectType } from '../../store/project/project-data';
import { useParams } from "react-router-dom";

interface Props {
    projectId: string;
    jobId: string;
    onClick: Function;
    onSubmitted: Function;
    Hwv: Communicator.WebViewer;
    label?: string;
    className?: string;
    selected: ViewSelection;
    onJobCancel: () => void;
}

interface ToolipInfo {
    label: string;
    section: string;
    isValid: Boolean;
}
let toolipInfoArray: ToolipInfo[] = [];
let isJobValid = false;
const SubmitJobButton = (props: Props) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const jobContext = useContext(JobContext);
    const [jobValid, setJobValid] = useState(false);
    const [toolipInfo, setToolipInfo] = useState(toolipInfoArray);
    const isChannelValid = (c: Channel) => c.inlets.length === 1 && c.outlets.length > 0 && c.bodies.length > 0;
    const [cancelJobSubmit, setCancelJobSubmit] = useState<boolean>(false);
    const [submittedJobDataUploaded, setSubmittedJobDataUploaded] = useState<boolean>(false);
    const [submittingJob, setSubmittingJob] = useState<boolean>(false);
    const { container: containerNameParam } = useParams();

    const [feasibilityProject, setFeasibilityProject] = useState<boolean>(false);

    useEffect(() => {
        setFeasibilityProject(jobContext?.ProjectType == ProjectType.Feasibility);
    }, [jobContext?.ProjectType]);

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.dark,
            border: theme.palette.primary.contrastText,
            maxHeight: '50vh!important',
            overflowY: 'scroll'
        },
    }));

    const theme = useTheme();
    const classes = makeStyles({
        button: {
            borderRadius: '1em',
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            paddingLeft: "1em",
            margin: 0,
            textTransform: 'none',
        },
        submitButton: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            '&.selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText
            },
            '&.completed': {
                color: theme.palette.success.main
            },
            '&.completed.selected': {
                backgroundColor: theme.palette.success.main,
                color: theme.palette.primary.light
            }
        }
    })();

    const hasAllMoldGroupsWithBodiesAMaterial = (jobContext: JobData): boolean => {
        const moldGroupsWithBodies = jobContext.Categories.Mold.filter(m => m.bodies.length > 0);
        if (moldGroupsWithBodies.length === 0) {
            return true;
        }
        return moldGroupsWithBodies.every(mg => jobContext.Categories.Materials.Molds.find(m => m.moldGroupId === mg.id));
    }

    useEffect(() => {
        //TO Do: when implement delete mold group, at least one mold group should be defined completely
        const moldGroupElements = (jobContext.Categories.Mold[0]?.bodies.length > 0);
        const moldGroupMaterial = (Boolean(jobContext.Categories.Materials.Molds.length > 0 && hasAllMoldGroupsWithBodiesAMaterial(jobContext) && jobContext.Categories.Materials.Molds.find(m => m.moldGroupId == "0")));
        const plasticPartElements = (jobContext.Categories.Part.length > 0);
        const plasticPartMaterial = (Boolean(jobContext.Categories.Materials.Parts.length > 0));
        const basicMoldElement = jobContext.basicMold.enabled;
        const basicMoldSize = Boolean(jobContext.basicMold.size.x > 0 &&
            jobContext.basicMold.size.y > 0 &&
            jobContext.basicMold.size.z > 0);
        const basicMoldMaterial = Boolean(jobContext.Categories.Materials.BasicMold?.length > 0 &&
            jobContext.Categories.Materials.BasicMold[0].name &&
            jobContext.Categories.Materials.BasicMold[0].name.trim().length > 0);
        const coolingElements = (jobContext.Categories.Cooling !== null);

        const wallThicknessElement =
            (jobContext.Categories.Cooling.frozenWallThickness >= 20
                && jobContext.Categories.Cooling.frozenWallThickness <= 100);

        isJobValid = jobContext.IsTreeLoaded && jobContext?.CadInfos?.length > 0
            && plasticPartElements && plasticPartMaterial
            && ((!feasibilityProject && !basicMoldElement && moldGroupElements && moldGroupMaterial)
                || (!feasibilityProject && basicMoldElement && basicMoldSize && basicMoldMaterial)
                || (feasibilityProject && basicMoldMaterial)
            )
            && coolingElements
            && wallThicknessElement
            && (jobContext.Categories.Channel.length === 0 ||
                jobContext.Categories.Channel.every(isChannelValid) === true);

        setJobValid(isJobValid);

        toolipInfoArray = [];

        if (!feasibilityProject) {
            if (!basicMoldElement) {
                toolipInfoArray.push({ section: "mold-group-elements", label: `${t("Mold Group")} : ${t("Elements")}`, isValid: moldGroupElements });
                toolipInfoArray.push({ section: "mold-group-material", label: `${t("Mold Group")} : ${t("Material")}`, isValid: moldGroupMaterial });
            }
            else {
                toolipInfoArray.push({ section: "basic-mold-size", label: t("Basic Mold Size"), isValid: basicMoldSize });
                toolipInfoArray.push({ section: "basic-mold-material",label: t("Basic Mold Material"), isValid: basicMoldMaterial });
            }
        } else {
            toolipInfoArray.push({ section: "mold-material", label: t("Mold Material"), isValid: basicMoldMaterial });
        }
        toolipInfoArray.push({ section: "plastic-part-elements", label: `${t("Plastic Part")}: ${t("Elements")}`, isValid: plasticPartElements });
        toolipInfoArray.push({ section: "plastic-part-material", label: `${t("Plastic Part")}: ${t("Material")}`, isValid: plasticPartMaterial });
        toolipInfoArray.push({ section: "cooling-elements", label: t("Cooling: Elements"), isValid: coolingElements });
        { authContext.isSupervisor && toolipInfoArray.push({ section: "frozen-wall-thickness", label: t("Frozen wall thickness"), isValid: wallThicknessElement }); }
        jobContext.Categories.Channel.forEach((c) => {
            if (!(c.inlets.length === 1 && c.outlets.length > 0 && c.bodies.length > 0)) {
                if (c.inlets.length != 1) {
                    toolipInfoArray.push({ section: `${c.id}-missed-inlets`, label: `${c.name}: ${t("missed inlets")}`, isValid: false });
                }
                if (c.outlets.length == 0) {
                    toolipInfoArray.push({ section: `${c.id}-missed-outlets`, label: `${c.name}: ${t("missed outlets")}`, isValid: false });
                }
                if (c.bodies.length == 0) {
                    toolipInfoArray.push({ section: `${c.id}-missed-body`, label: `${c.name}: ${t("missed a body")}`, isValid: false });
                }
            }
        });
        jobContext.Categories.Channel.length > 0
            && jobContext.Categories.Channel.every(isChannelValid) === true
            && toolipInfoArray.push({ section: 'channels-valid', label: 'Channels valid', isValid: true });


        setToolipInfo(toolipInfoArray);

    }, [jobContext, feasibilityProject]);

    const cancelJob = async () => {
        //set status to default
        jobContext.setStatus(Status.None);
        setCancelJobSubmit(false);
        setSubmittedJobDataUploaded(false);

        props.onJobCancel();
    }

    useEffect(() => {
        if (submittedJobDataUploaded && cancelJobSubmit) {
            cancelJob();
        } else if (submittedJobDataUploaded && !cancelJobSubmit) {
            props.onSubmitted();
        }
    }, [submittedJobDataUploaded])

    const submitClickHandler = async () => {
        try {
            setSubmittingJob(true);
            props.onClick();

            jobContext.setStatus(Status.Submitted);

            console.log(`Preparing a record in the jobs table for the project of ${jobContext.Project}.`);
            await freezeJob();

            await uploadJobGeometricalData(props.jobId, containerNameParam!);

            setSubmittedJobDataUploaded(true);
        } catch (error) {
            console.error(`Failed to submit the job. Reason: ${error}`);
        } finally {
            setSubmittingJob(false);
        }
    };

    const freezeJob = async () => {
        const jobService = new JobService();
        const serializedState = serializeJobContextState(jobContext);
        await jobService.submit(props.projectId, props.jobId, containerNameParam!, serializedState);
    }

    const uploadJobGeometricalData = async (solveId: string, containerName: string) => {

        const geometricalDetails = await exportJobGeometricalData(jobContext, props.Hwv.model);
        const allGeometricalDetails = [
            ...geometricalDetails.Molds,
            ...geometricalDetails.Channels,
            ...geometricalDetails.Parts
        ];

        console.log(`Uploading the geometrical information that is required for the solve.`);
        const formData = new FormData();
        allGeometricalDetails.forEach(geometry => {
            if (geometry.category == MoldCategory.Channel && (geometry.name?.includes("Body")
                || geometry.name?.includes("Inlet")
                || geometry.name?.includes("Outlet")
                || geometry.name?.includes("Baffle"))
                || geometry.fullName?.includes('auto-' + PartType.CAVITY)
                || geometry.fullName?.includes('auto-' + PartType.CORE)
            ) {
                formData.append('geometry', geometry.data, geometry.blobFilePath(jobContext.Project, solveId))
            }
        });
        formData.append('containerName', containerName);

        const fileService = new FileManagementService();
        await fileService.uploadGeometries(formData);

        console.log(`Submitting a requesting to solve.`);

        const solverParameters = SolverParameters.create(jobContext, props.jobId, geometricalDetails);

        const jobService = new JobService();
        await jobService.updateGeometricalDetails(props.projectId, props.jobId, JSON.stringify(solverParameters), containerName, jobContext.CustomSolverSettings ?? '');

        console.log(`A solve request has been submitted.`);
    }

    const onJobCancel = async () => {
        setCancelJobSubmit(true);

        if (CanJobBeCancelled(jobContext.Status) && !submittingJob) {
            await cancelJob();
        }
    }

    const InfoTooltip = (props: { toolipInfoArray: ToolipInfo[], children: React.ReactElement }) => {
        return (
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Grid container rowSpacing={1} sx={{ padding: '1em', width: '100%' }} role="list">
                            {toolipInfoArray.map((info) => (
                                <Grid container rowSpacing={1} key={"toolip-info-array" + info.section} id={info.section}  >
                                    <Grid item xs={11} >
                                        <Typography noWrap sx={{
                                            color: theme.palette.primary.dark
                                        }}>
                                            {info.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>{info.isValid ?
                                        <DoneIcon color="success" /> :
                                        <ClearIcon color="error" />}</Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                }
            >
                {props.children}
            </HtmlTooltip>
        )
    }

    const invalidJobButton = () => {
        return (
            <InfoTooltip toolipInfoArray={toolipInfo}>
                <Button variant="outlined" sx={{
                    marginLeft: '2em',
                    color: theme.palette.action.disabled,
                    border: '1px solid theme.palette.disabledBackground'
                }}
                    color="primary" startIcon={<SendOutlined />} className={props.className}
                >
                    {t("Submit Job")}
                </Button>
            </InfoTooltip>
        )
    }

    const validJobButton = () => {
        return (
            <Box sx={{ display: "flex" }}>
                {jobContext.Status == Status.None &&
                    <InfoTooltip toolipInfoArray={toolipInfo}>
                        <Button
                            id="submitJobButton"
                            className={`${classes.button} ${classes.submitButton}`}
                            variant="outlined"
                            sx={{ marginLeft: '2em', color: theme.palette.primary.dark }}
                            color="primary"
                            onClick={() => {
                                submitClickHandler()
                            }}
                            startIcon={<SendOutlined />}
                        >
                            {t("Submit Job")}
                        </Button>
                    </InfoTooltip>
                }
                {jobContext.Status !== Status.Completed && jobContext.Status !== Status.None &&
                    <ToggleButton
                        className={`${classes.button} ${classes.submitButton}`}
                        value="progress"
                        disabled={true}
                        sx={{
                            color: theme.palette.action.disabled,
                            border: '1px solid theme.palette.disabledBackground'
                        }}
                    >
                        <CircularProgress size={'1em'} sx={{ mr: 1 }} />
                        {!cancelJobSubmit ? t(StatusLabel[jobContext.Status]) : t("Cancelling")}
                    </ToggleButton>
                }

                {CanJobBeCancelled(jobContext.Status) &&
                    <Box sx={{ ml: "5px" }}>
                        <CancelSubmittedJob
                            listItem={false}
                            onCancel={onJobCancel}
                        ></CancelSubmittedJob>
                    </Box>
                }
            </Box>
        )
    }

    return (<>
        {!jobValid ? invalidJobButton() : validJobButton()}
    </>);
};

export default SubmitJobButton;