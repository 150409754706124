import axios from "axios";
import SubscriptionOptions from "../models/subscriptionOptions";

const ConfigurationOptionsService = {
    GetSupportEmail: async (): Promise<string> => {
        try {
            const response = await axios.get('/api/ConfigurationOptions/supportEmail');
            return response.data;
        } catch {
            throw new Error('Error getting support email');
        }
    },
    GetBuildNumber: async (): Promise<string> => {
        try {
            const response = await axios.get('/api/ConfigurationOptions/buildNumber');
            return response.data;
        } catch {
            throw new Error('Error getting build number');
        }
    },
    GetSubscriptionOptions: async (): Promise<SubscriptionOptions> => {
        try {
            const response = await axios.get('/api/ConfigurationOptions/subscriptionOptions');
            return response.data;
        } catch {
            throw new Error('Error getting subscription options');
        }
    }
}

export default ConfigurationOptionsService;