import { Dialog, DialogTitle, DialogContent, TableHead, Table, Paper, TableBody, Grid, DialogActions, Button, Typography, TextField, Tabs, Tab, Stack, Divider, FormControl, InputLabel, OutlinedInput, MenuItem, Tooltip, TableCell, TableContainer, IconButton, TableRow, Alert, Box, CircularProgress } from "@mui/material";
import theme from "../../styles/main-theme";
import * as React from 'react';
import { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import MetricsService from "../../services/MetricsService";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

export interface UsersMetricsDialogProps {
    open: boolean,
    onClose: () => void
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const UsersMetricsDialog = (props: UsersMetricsDialogProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const initialUsersValue = {
        items: [
            { userName: 'userName', projectsCount: 0, jobsCount: 0 }]
    };
    const initialTagsValue = {
        items: [
            { userTag: 'userTag', projectsCount: 0 }]
    };
    const [UsersMetrics, SetUsersMetrics] = useState(initialUsersValue);
    const [TagsMetrics, SetTagsMetrics] = useState(initialTagsValue);

    const loadData = async () => {
        setLoading(true);
        const usersMetrics = await MetricsService.UsersAggregateMetrics(
            valueFrom ? valueFrom.toDate() : new Date(),
            valueTo ? valueTo.toDate() : new Date());
        SetUsersMetrics(usersMetrics);

        const tagsMetrics = await MetricsService.UsersTagsAggregateMetrics(
            valueFrom ? valueFrom.toDate() : new Date(),
            valueTo ? valueTo.toDate() : new Date());
        SetTagsMetrics(tagsMetrics);

        setLoading(false);
    }

    const classes = makeStyles({
        select: {
            borderRadius: "2em"
        },
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    const onCloseDialog = () => {
        props.onClose();
    }

    const usersHeaders = [
        { label: t("User Name"), key: "userName" },
        { label: t("Projects Count"), key: "projectsCount" },
        { label: t("Jobs Count"), key: "jobsCount" },
    ];
    const tagsHeaders = [
        { label: t("Tag"), key: "userTag" },
        { label: t("Projects Count"), key: "projectsCount" },
    ];
    const [valueFrom, setValueFrom] = useState<Dayjs | null>(
        // default: last 30 days
        dayjs(new Date().setDate(new Date().getDate() - 30)),
    );

    const [valueTo, setValueTo] = useState<Dayjs | null>(
        dayjs(new Date()),
    );

    const handleChangeFrom = (newValue: Dayjs | null) => {
        setValueFrom(newValue);
        loadData();
    };
    const [value, setValue] = useState(0);
    const handleChangeTo = (newValue: Dayjs | null) => {
        setValueTo(newValue);
        loadData();
    };
    const isValid = (dateFrom: Dayjs | null, dateTo: Dayjs | null) => {
        if (dateFrom && dateTo && dateFrom <= dateTo) {
            return true;
        }
        return false;
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        loadData();
    }, []);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (
        <Dialog id="info-dialog" open={props.open} aria-label="error dialog" onClose={onCloseDialog} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ color: theme.palette.primary.dark, mb: "5px" }}>{t("User Statistics")}</DialogTitle>
            <DialogContent sx={{ position: "relative" }}>
                {loading == true && <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </Box>}
                <Grid container rowSpacing={1} >
                    <Grid item xs={6}>
                        <FormControl sx={{ m: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Stack >
                                    <DesktopDatePicker
                                        label={t("From")}
                                        inputFormat="MM/DD/YYYY"
                                        value={valueFrom}
                                        onChange={handleChangeFrom}
                                        renderInput={(params) => <TextField sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: !isValid(valueFrom, valueTo) ?
                                                        theme.palette.error.main : theme.palette.primary.light,
                                                }
                                            },
                                        }} {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </FormControl></Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ m: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack >
                                    <DesktopDatePicker
                                        label={t("To")}
                                        inputFormat="MM/DD/YYYY"
                                        value={valueTo}
                                        onChange={handleChangeTo}
                                        renderInput={(params) => <TextField {...params} sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: !isValid(valueFrom, valueTo) ?
                                                        theme.palette.error.main : theme.palette.primary.light,
                                                }
                                            },
                                        }} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', '& .MuiBox-root': { padding: '15px' } }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                        <Tab label={t("Users Projects")} {...a11yProps(0)} sx={{ textTransform: 'none', fontSize: '1.2em' }} />
                        <Tab label={t("Tags Projects")} {...a11yProps(1)} sx={{ textTransform: 'none', fontSize: '1.2em' }} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CSVLink
                        target="_blank"
                        data={UsersMetrics.items}
                        headers={usersHeaders}
                        filename={"usersmetrics.csv"}>
                        <Button
                            sx={{
                                fontSize: '0.9em',
                                textTransform: 'none',
                                color: theme.palette.primary.contrastText,
                                fontWeight: 'bold', padding: '0',
                                paddingBottom: '15px'
                            }} startIcon={<DownloadIcon sx={{ color: theme.palette.primary.contrastText }} />}
                        >{t("Export to CSV")}</Button>
                    </CSVLink>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" align="left"><b>{t("User Name")}</b></TableCell>
                                    <TableCell component="th" align="left"><b>{t("Projects Count")}</b></TableCell>
                                    <TableCell component="th" align="left"><b>{t("Jobs Count")}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UsersMetrics.items.map((item) => (
                                    <TableRow
                                        hover
                                        key={item.userName}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell scope="row" align="left">
                                            <Typography noWrap
                                                sx={{ color: theme.palette.primary.dark }}>
                                                {item.userName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" align="left">
                                            <Typography noWrap
                                                sx={{ color: theme.palette.primary.dark }}>
                                                {item.projectsCount}
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" align="left">
                                            <Typography noWrap
                                                sx={{ color: theme.palette.primary.dark }}>
                                                {item.jobsCount}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    {TagsMetrics.items.length != 0 && <CSVLink
                        target="_blank"
                        data={TagsMetrics.items}
                        headers={tagsHeaders}
                        filename={"tagsmetrics.csv"}>
                        <Button
                            sx={{
                                fontSize: '0.9em',
                                textTransform: 'none',
                                color: theme.palette.primary.contrastText,
                                fontWeight: 'bold', padding: '0',
                                paddingBottom: '15px'
                            }} startIcon={<DownloadIcon sx={{ color: theme.palette.primary.contrastText }} />}
                        >{t("Export to CSV")}</Button>
                    </CSVLink>}
                    {TagsMetrics.items.length != 0 ? <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" align="left"><b>{t("Tag")}</b></TableCell>
                                    <TableCell component="th" align="left"><b>{t("Projects Count")}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {TagsMetrics.items.map((item) => (
                                    <TableRow
                                        hover
                                        key={item.userTag}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell scope="row" align="left">
                                            <Typography noWrap
                                                sx={{ color: theme.palette.primary.dark }}>
                                                {item.userTag}
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" align="left">
                                            <Typography noWrap
                                                sx={{ color: theme.palette.primary.dark }}>
                                                {item.projectsCount}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> : <Typography noWrap
                        sx={{ color: theme.palette.grey[400] }}>
                        {t("No data to show..")}
                    </Typography>}
                </TabPanel>
            </DialogContent>
            <Divider sx={{ mb: "15px" }}></Divider>
            <DialogActions>
                <Button data-testid="UsersMetricsCloseButton" onClick={onCloseDialog} className={classes.btn}>{t("Close")}</Button>
            </DialogActions>
        </Dialog>
    )
}