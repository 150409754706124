import CoolingFluid from './cooling-fluid';

export default class CoolingProperties {
    radius: number = 5;
    flow: number = 20;
    fluid: CoolingFluid = { id: 0, name: "Water"};
    temperature: number = 25;
    frozenWallThickness: number = 20;
}

