/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import { PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import JobContext from '../../store/job/job-context';
import Typography from '@mui/material/Typography';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import JobData, { Part } from '../../store/job/job-data';
import { MoldCategory } from '../../store/job/mold-categories';
import Fab from '@mui/material/Fab';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { mapToPart } from '../../services/TreeviewFunctions';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Collapse, IconButton, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import queryErrorHandler from "../../react-query/queryErrorHandler";
import { FrozenWallThickness } from './FrozenWallThickness';
import { ColorStateActionType, HoopsColorContext } from '../../store/job/hoops-color-context';
import AuthContext from '../../auth/AuthenticationContext';
import { HoopsVisibilityContext } from '../../store/job/hoops-visibility-context';
import { useTranslation } from 'react-i18next';
import { VisibilityStateActionType } from '../../store/job/hoops-visibility-reducer';

type PartCategory = MoldCategory.Mold | MoldCategory.Part | MoldCategory.Runner;

export interface CustomListProps {
    title: string,
    parts: Part[],
    moldGroupId: string,
    moldGroupName?: string,
    category: PartCategory
    secondarySectionTitle?: string,
    secondarySectionCategory?: PartCategory,
    secondarySectionParts?: Part[],
    isLoading: boolean,
    disabled: boolean,
    secondarySectionConfigButton?: ReactElement
}

export default function MoldCategoryDisplay(props: PropsWithChildren<CustomListProps>) {
    interface CategoriesMap {
        [key: string]: Part[]
    }
    const authContext = useContext(AuthContext);
    const { title, parts, category, moldGroupId } = props;
    const { t } = useTranslation();
    const jobContext = useContext(JobContext);
    const [isVisible, setIsVisible] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isSecondarySectionOpen, setIsSecondarySectionOpen] = useState(false);
    const { updateVisibility, mode: visibilityMode, getVisibility } = useContext(HoopsVisibilityContext);
    const { updateColor } = useContext(HoopsColorContext);
    const partNodeIdsStr = props.parts.flatMap(p => p.nodesIds).join('-');
    const secondaryPartNodeIdsStr = (props.secondarySectionParts ?? []).flatMap(p => p.nodesIds).join('-');

    useEffect(() => {
        updateColor({
            type: ColorStateActionType.SET_CATEGORY_NODE_COLOR,
            nodeIds: partNodeIdsStr ? partNodeIdsStr.split('-').map(n => parseInt(n)) : [],
            category
        });

    }, [partNodeIdsStr]);

    useEffect(() => {
        if (parts.length > 0 || (props.secondarySectionParts && props.secondarySectionParts.length > 0)) {
            const arePartsHidden = parts.length > 0 && parts.every(p => p.nodesIds && getVisibility(p) === false);
            const areSecondaryPartsHidden = props.secondarySectionParts &&
                props.secondarySectionParts.length > 0 &&
                props.secondarySectionParts.every(p => p.nodesIds && getVisibility(p) === false);
            const isHidden = arePartsHidden === true || areSecondaryPartsHidden === true;

            setIsVisible(!isHidden);
        }
    }, [parts, props.secondarySectionParts, getVisibility]);

    useEffect(() => {
        if (props.secondarySectionCategory) {
            updateColor({
                type: ColorStateActionType.SET_CATEGORY_NODE_COLOR,
                nodeIds: secondaryPartNodeIdsStr ? secondaryPartNodeIdsStr.split('-').map(n => parseInt(n)) : [],
                category: props.secondarySectionCategory
            });
        }
    }, [secondaryPartNodeIdsStr, props.secondarySectionCategory]);

    const removeClickHandler = (partId: string) => {
        const parts = mapToPart([partId], jobContext.Tree);

        updateVisibility({
            type: VisibilityStateActionType.SET_PART_VISIBILITY,
            items: parts,
            visibility: true
        });

        jobContext.removeFromCategory(partId, category, moldGroupId);

        setIsVisible(props.parts.length === 1);
    };

    const removeSecondaryPartClickHandler = (partId: string) => {
        const parts = mapToPart([partId], jobContext.Tree);
        updateVisibility({
            type: VisibilityStateActionType.SET_PART_VISIBILITY,
            items: parts,
            visibility: true
        });
        jobContext.removeFromCategory(partId, category, moldGroupId);
        setIsVisible(props.secondarySectionParts !== undefined && props.secondarySectionParts.length === 1);
    };

    const toogleVisibility = () => {
        const newState = !isVisible;
        setIsVisible(newState);

        setCategoryPartsVisibility(category, newState);

        if (props.secondarySectionCategory) {
            setCategoryPartsVisibility(props.secondarySectionCategory, newState);
        }
    };

    const getCategoryNodes = (categoryName: string): Part[] => {
        const moldGroupIndex = jobContext.Categories.Mold.findIndex(m => m.id === moldGroupId);
        const categoriesMap: CategoriesMap = {
            Part: jobContext.Categories.Part,
            Mold: jobContext.Categories.Mold[moldGroupIndex]?.bodies,
            Runner: jobContext.Categories.Runner ?? []
        };

        return categoriesMap[categoryName];
    };

    const setCategoryPartsVisibility = (category: MoldCategory, visibility: boolean): void => {
        const categoryNodes = getCategoryNodes(MoldCategory[category]);

        updateVisibility({
            type: VisibilityStateActionType.SET_PART_VISIBILITY,
            items: categoryNodes,
            visibility
        });
    };

    const theme = useTheme();

    const classes = makeStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: "1.3em"
        },
        listHeader: {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "end",
            position: "relative",
            width: "100%"
        },
        icon: {
            position: "relative",
            padding: "5px 15px 5px 15px",
            color: theme.palette.primary.dark,
            marginLeft: "10px"
        },
        iconAdd: {
            position: "relative",
            marginRight: "10px",
            color: theme.palette.primary.dark,
        },
        paper: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1em 1em 1.4em 1em"
        },
        materialSelectorContainer: {
            width: "100%",
            marginBottom: "20px"
        },
        list: {

        },
        collapse: {
            width: "100%"
        },
        dividerTitle: {
            margin: "20px 0 5px 0",
            width: "100%",
            borderBottom: "none",
            borderLeft: "none",
            borderRight: "none",
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            borderTopWidth: "1px",
            paddingBottom: "5px"
        },
        elementsTitle: {
            fontSize: "0.95em",
            color: theme.palette.primary.dark,
            cursor: "pointer"
        },
        expandIcon: {
            cursor: "pointer",
            color: theme.palette.primary.dark,
        }
    })();

    const handleOpenClick = () => {
        setIsOpen(!isOpen);
    }

    const handleOpenSecondarySectionClick = () => {
        setIsSecondarySectionOpen(!isSecondarySectionOpen);
    }

    const categoryClickHandler = (moldCategory: PartCategory) => {
        if (jobContext.Categories.Selected.length == 0) {
            queryErrorHandler(new Error(t("Before adding please select element for Category: ") + MoldCategory[props.category]));
        }
        else {
            const parts = mapToPart(jobContext.Categories.Selected.map(s => s.path), jobContext.Tree);

            updateVisibility({
                type: VisibilityStateActionType.SET_PART_VISIBILITY,
                items: parts,
                visibility: isVisible
            });
            jobContext.addToCategory(moldCategory, jobContext.Categories.Selected, moldGroupId, props.moldGroupName);
        }
    }

    return (
        <Box id={"section-" + MoldCategory[props.category]} sx={{ position: "relative" }}>
            {props.isLoading &&
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", width: "100%", height: "100%" }}>
                    <CircularProgress sx={{ color: theme.palette.primary.main }} />
                </Box>
            }
            <Paper elevation={1} className={classes.paper}>
                <div className={classes.header}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.dark }}>{title}</Typography>
                    <div>
                        <Fab id={"visibility-icon-" + MoldCategory[props.category] + ((category == MoldCategory.Mold) ? '-' + moldGroupId : '')} className={classes.icon} variant="extended" size="small" color="default" aria-label="toggle visibility" onClick={toogleVisibility}>
                            {isVisible ? <Visibility /> : <VisibilityOff />}
                        </Fab>
                    </div>
                </div>
                <div id={"material-select-" + MoldCategory[props.category] + ((category == MoldCategory.Mold) ? '-' + moldGroupId : '')} className={classes.materialSelectorContainer}>
                    {props.children}
                </div>
                <div id={"items-expand-" + MoldCategory[props.category]} className={classes.listHeader}>
                    <Fab disabled={props.disabled} id={"add-icon-" + MoldCategory[props.category] + ((category == MoldCategory.Mold) ? '-' + moldGroupId : '')} className={classes.iconAdd} color="default" size="small" aria-label="add" onClick={() => { categoryClickHandler(props.category) }}>
                        <AddIcon />
                    </Fab>
                    {isOpen ? <ExpandLess className={classes.expandIcon} onClick={handleOpenClick} /> : <ExpandMore className={classes.expandIcon} onClick={handleOpenClick} />}
                    <Typography className={classes.elementsTitle} onClick={handleOpenClick}>{parts.length + " " + t("element(s)")} </Typography>
                </div>
                <Collapse id={"items-list-" + MoldCategory[props.category]} in={isOpen} className={classes.collapse}>
                    <List className={classes.list} dense component="div" role="list">
                        {
                            parts.map((part: Part) => {
                                const labelId = `transfer-list-item-${part.id}-label`;
                                return (
                                    <ListItem key={part.id} role="listitem" button >
                                        <ListItemText id={labelId} primary={`${part.name}`} />
                                        <IconButton disabled={props.disabled} onClick={() => removeClickHandler(part.id)} sx={{ padding: 0, color: theme.palette.common.black }}>
                                            <RemoveCircleTwoToneIcon />
                                        </IconButton>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </Collapse>

                {/* //Secondary section */}
                {props.secondarySectionCategory && props.secondarySectionParts &&
                    <Box id={"secondary-section-" + MoldCategory[props.secondarySectionCategory]} sx={{ width: "100%" }}>
                        <fieldset className={classes.dividerTitle}>
                            <legend><Typography sx={{ fontSize: "1em" }}>{props.secondarySectionTitle}</Typography></legend>
                        </fieldset>

                        {props.secondarySectionConfigButton &&
                            <Box sx={{ mb: "15px", marginLeft: "auto" }}>
                                {props.secondarySectionConfigButton}
                            </Box>
                        }

                        <div id={"secondary-section-items-expand-" + MoldCategory[props.secondarySectionCategory]} className={classes.listHeader}>
                            <Fab disabled={props.disabled} id={"secondary-section-add-icon-" + MoldCategory[props.secondarySectionCategory]} className={classes.iconAdd} color="default" size="small" aria-label="add" onClick={() => { if (props.secondarySectionCategory) categoryClickHandler(props.secondarySectionCategory) }}>
                                <AddIcon />
                            </Fab>
                            {isSecondarySectionOpen ? <ExpandLess className={classes.expandIcon} onClick={handleOpenSecondarySectionClick} /> : <ExpandMore className={classes.expandIcon} onClick={handleOpenSecondarySectionClick} />}
                            <Typography className={classes.elementsTitle} onClick={handleOpenSecondarySectionClick}>{props.secondarySectionParts.length + " " + t("element(s)")} </Typography>
                        </div>
                        <Collapse id={"secondary-section-items-list-" + MoldCategory[props.secondarySectionCategory]} in={isSecondarySectionOpen} className={classes.collapse}>
                            <List className={classes.list} dense component="div" role="list">
                                {
                                    props.secondarySectionParts.map((part: Part) => {
                                        const labelId = `transfer-list-item-${part.id}-label`;
                                        return (
                                            <ListItem key={part.id} role="listitem" button >
                                                <ListItemText id={labelId} primary={`${part.name}`} />
                                                <IconButton disabled={props.disabled} onClick={() => removeSecondaryPartClickHandler(part.id)} sx={{ padding: 0, color: theme.palette.common.black }}>
                                                    <RemoveCircleTwoToneIcon />
                                                </IconButton>
                                            </ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Collapse>
                        {authContext.isSupervisor && <Box sx={{ marginLeft: "auto" }}>
                            <FrozenWallThickness disabled={props.disabled} />
                        </Box>}
                    </Box>}
            </Paper>
        </Box>
    );
}