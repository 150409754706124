import axios from "axios";
import { ProductResponseModel } from "../components/account/models/productModel";

const AccountProductService = {
    GetAllProducts: async (): Promise<ProductResponseModel> => {
        try {
            const response = await axios.get('/api/accountProduct/products');
            return response.data;
        } catch {
            throw new Error('Error getting mayaHTT transaction products');
        }
    }
}

export default AccountProductService;