import { AxiosResponse } from "axios";

const CACHE_NAME = "SimForm Data Cache";

export default class ResourceCacheService {
    readonly disabled: boolean;

    constructor() {
        this.disabled = !('caches' in global);
    }

    async tryGetAsBlob(url: URL): Promise<Blob | undefined> {
        const response = await this.tryGet(url);
        return response?.blob();
    }

    async put(url: URL, response: Response | AxiosResponse) {
        if (this.disabled) {
            return;
        }
        const cache = await caches.open(CACHE_NAME);
        if (!(response instanceof Response)) {
            response = axiosResponseToResponse(response);
        }
        await cache.put(url, response);
    }

    async remove(url: URL): Promise<boolean> {
        if (this.disabled) {
            return false;
        }
        const cache = await caches.open(CACHE_NAME);
        return cache.delete(url);
    }

    async purge(): Promise<boolean> {
        if (this.disabled) {
            return false;
        }
        return caches.delete(CACHE_NAME);
    }

    private async tryGet(url: URL): Promise<Response | undefined> {
        if (this.disabled) {
            return;
        }
        const cache = await caches.open(CACHE_NAME);
        return cache.match(url);
    }
}

function axiosResponseToResponse(axiosResponse: AxiosResponse): Response {
    return new Response(axiosResponse.data, {
        status: axiosResponse.status,
        statusText: axiosResponse.statusText,
        headers: axiosResponse.headers,
    });
}
