import { Reducer, useReducer } from "react";

type InputState = {
    value: string,
    isTouched: boolean
}

const initialInputState: InputState = { value: '', isTouched: false };

type Action = { type: 'BLUR' }
    | { type: 'INPUT', value: string }
    | { type: 'RESET' };

interface IPredicate {
    (...args: any[]): boolean;
}

const inputStateReducer: Reducer<InputState, Action> = (state, action) => {
    switch (action.type) {
        case 'BLUR':
            return { value: state.value, isTouched: true };
        case 'INPUT':
            return { value: action.value, isTouched: state.isTouched };
        case 'RESET':
            return initialInputState;
    }
}

const useInput = (validateValue: IPredicate, defaultErrorMessage:string) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, initialInputState);

    const valueIsValid = validateValue(inputState.value);
    const hasError = !valueIsValid && inputState.isTouched;

    const setValue = (newValue:string)=>{
        dispatch({ type: 'INPUT', value: newValue });
    }

    const valueChangedHandler = (event: any) => {
        dispatch({ type: 'INPUT', value: event.target.value });
    }

    const inputBlurHandler = (event: any) => {
        dispatch({ type: 'BLUR' });
    }

    const reset = () => {
        dispatch({ type: 'RESET' });
    }

    return {
        value: inputState.value,
        isValid: valueIsValid,
        hasError,
        errorMessage: hasError ? defaultErrorMessage : '',
        valueChangedHandler,
        inputBlurHandler,
        reset,
        setValue
    }
}

export default useInput;