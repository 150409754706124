import { TreeviewItemModel } from '../components/job/TreeviewNodeModel';
import JobData, { Face, Part, Tree } from '../store/job/job-data'

function GetPartNodeIds(partId: string, jobContext: JobData): number[] {
    let nodeIds = jobContext.Tree[partId]?.nodeIds || [];
    let treeNodeIds = jobContext.Tree[partId]?.children || [];

    while (treeNodeIds.length) {
        const treeNodeId = treeNodeIds.pop();

        nodeIds = [...nodeIds, ...(jobContext.Tree[treeNodeId!]?.nodeIds || [])];
        treeNodeIds = [...treeNodeIds, ...(jobContext.Tree[treeNodeId!]?.children || [])];
    }

    return nodeIds;
}

export function mapToPart(paths: string[], tree: Tree): Part[] {
    return paths.map(path => {
        return {
            id: path,
            name: tree[path].name,
            path: tree[path].path,
            nodesIds: tree[path].nodeIds,
            cadFileName: tree[path].cadFileName
        }
    });
}

export function GetNodeFromId(nodeId: number, jobContext: JobData): TreeviewItemModel | undefined {
    return Object.values(jobContext.Tree).find(item => item.nodeIds.includes(nodeId));
}

export function GetAllRelatedNodeIds(nodeIds: number[], hwv: Communicator.WebViewer): number[] {
    let result: number[] = [];
    let nodesToDiscover: number[] = [...nodeIds];

    while (nodesToDiscover.length) {
        const currentNodeId = nodesToDiscover.pop()!;
        const childNodeIds = hwv.model.getNodeChildren(currentNodeId);

        nodesToDiscover = [...nodesToDiscover, ...childNodeIds];
        result.push(currentNodeId);
    }

    return result.filter(nodeId => hwv.model.getNodeType(nodeId) === Communicator.NodeType.BodyInstance);
}

export function GetAllNodeIds(jobContext: JobData): number[] {
    return Object.values(jobContext.Tree).map(v => v.nodeIds).flat()
}

export function setPartVisibility(parts: Part[], visibilty: boolean, jobContext: JobData, hwv: Communicator.WebViewer): void {
    const nodeIds = parts.flatMap(p => GetPartNodeIds(p.id, jobContext));
    const treeItems = parts.map(p => jobContext.Tree[p.id]).filter(item => item);

    treeItems.forEach(item => item.isVisible = visibilty);
    hwv.model.setNodesVisibility(nodeIds, visibilty);
}

export async function GetSceneVisibilityMap(model: Communicator.Model, jobContext: JobData)
    : Promise<Map<number, boolean>> {
    const rootNodeId = model.getAbsoluteRootNode();
    const visiblityState = await model.getVisibilityState(rootNodeId);
    const allNodeIds = GetAllNodeIds(jobContext);
    const visibilityMap = new Map<number, boolean>();

    allNodeIds.forEach(nodeId => {
        visibilityMap.set(nodeId,
            visiblityState.visibilityExceptions.has(nodeId)
                ? !visiblityState.defaultVisibility : visiblityState.defaultVisibility);
    });

    if (jobContext.basicMold.enabled) {
        jobContext.basicMold.nodesIds.forEach(n => {
            visibilityMap.set(n, model.getNodeVisibility(n));
        });
    }
    return visibilityMap;
}

export function getChannelName(channelId: number) {
    return `Channel ${channelId + 1}`;
}

export function getChannelId(channelId: number) {
    return `channel-${channelId}`;
}

export function getBasicMoldPart(nodesIds: number[]): Part {
    return { id: '/basic-mold', name: 'Basic mold', path: '/basic-mold', cadFileName: '', nodesIds };
}

export const mapToParts = (paths: string[], tree: Tree): Part[] => {
    return paths.map(path => {
        return {
            id: path,
            name: tree[path].name,
            path: tree[path].path,
            nodesIds: tree[path].nodeIds,
            cadFileName: tree[path].cadFileName ?? ""
        }
    });
}

export const getPartFromNodeIds = (nodeId: number, jobContext: JobData): Part => {
    let treeItemsPath: Array<string> = [];
    Object.values(jobContext.Tree).forEach(treeItem => {
        if (treeItem.nodeIds.includes(nodeId)) {
            treeItemsPath.push(treeItem.path)
        }
    });

    var parts = mapToParts(treeItemsPath, jobContext.Tree);

    return parts[0];
}

export const GetSelectedWithExchangeId = (selected: Face[], hwv: Communicator.WebViewer): Face[] => {
    const result: Face[] = [];

    for (const s of selected) {
        const exchangeId = hwv.model.getNodeExchangeId(s.nodeId);

        if (exchangeId === null) {
            const childrenList = hwv.model.getNodeChildren(s.nodeId);

            for (const childId of childrenList) {
                result.push({
                    nodeId: childId,
                    faceIndex: 0
                })
            }

        } else {
            result.push(s);
        }
    }

    return result;
}

