import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useContext } from "react";
import { ChannelEnd, ChannelEndType } from "../../store/job/job-data";
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import JobContext from "../../store/job/job-context";
import theme from "../../styles/main-theme";
import { Channel } from "../../store/job/channel";
import { HoopsEntitiesContext } from "../../store/job/hoops-entities-context";
import { useTranslation } from 'react-i18next';

export interface ChannelTerminalProps {
    channel: Channel,
    Hwv: Communicator.WebViewer,
    terminals: ChannelEnd[],
    disableControl: boolean,
    disableHighlighting: boolean
}

export function ChannelTerminals(props: ChannelTerminalProps) {
    const jobContext = useContext(JobContext);
    const { getNodeIdFromMesh, unregisterMeshes } = useContext(HoopsEntitiesContext);
    const { t } = useTranslation();

    const removeChannelTerminal = async (channelId: string, terminalId: string, type: ChannelEndType) => {
        const channel = jobContext.Categories.Channel.find(c => c.id === channelId);
        const terminalEnd = channel?.[type === ChannelEndType.Inlet ? 'inlets' : 'outlets'].find(terminal => terminal.id === terminalId);

        if (channel && terminalEnd) {
            Channel.isSyntheticFace(terminalEnd.face) && unregisterMeshes([terminalEnd.face.config]);
            jobContext.removeChannelEnd(channelId, terminalId, type);
        }

    }

    const highlightFace = (channelId: string, terminalId: string, type: ChannelEndType, shouldHighlight: boolean) => {
        if (props.disableHighlighting) {
            return;
        }

        const channel = jobContext.Categories.Channel.find(c => c.id === channelId);
        if (!channel) {
            return;
        }

        const terminalEnd = channel[type === ChannelEndType.Inlet ? 'inlets' : 'outlets']
            .find(terminal => terminal.id === terminalId);
        if (terminalEnd) {
            props.Hwv.model.resetModelHighlight();

            if (Channel.isSyntheticFace(terminalEnd.face)) {
                const nodeId = getNodeIdFromMesh(terminalEnd.face.config);

                nodeId && props.Hwv.model.setNodeFaceHighlighted(nodeId, 0, shouldHighlight);
            } else {
                props.Hwv.model.setNodeFaceHighlighted(terminalEnd.face.nodeId, terminalEnd.face.faceIndex, shouldHighlight);
            }
        }
    }

    return (<List sx={{ padding: 0, width: "100%" }}>
        {props.terminals.map(te => (
            <ListItem id={"channel-terminal-list-item-" + te.id} key={te.id} sx={{ padding: "0 0 0 15px", '& .MuiListItemSecondaryAction-root': { right: 0 } }} secondaryAction={
                <IconButton disabled={props.disableControl || props.disableHighlighting} onClick={() => removeChannelTerminal(props.channel.id, te.id, te.type)}
                    sx={{ padding: 0, color: theme.palette.common.black }}>
                    <RemoveCircleTwoToneIcon id={"remove-channel-terminal-list-item-" + te.id} sx={{ right: 0 }}
                        titleAccess={t("Remove body")} />
                </IconButton>
            }
                onMouseEnter={() => highlightFace(props.channel.id, te.id, te.type, true)}
                onMouseLeave={() => highlightFace(props.channel.id, te.id, te.type, false)}>
                <ListItemText
                    primary={te.name}
                    primaryTypographyProps={{
                        variant: 'caption',
                    }}
                />
            </ListItem>))}
    </List>)
}