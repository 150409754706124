import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserService from "../../services/UserService";
import ConfigurationContext from "../../store/config/ConfigurationContext";
import theme from "../../styles/main-theme";
import { makeStyles } from "@mui/styles";
import { useTranslation } from 'react-i18next';

interface ErrorDialogProps {
    open: boolean;
    showEmailLink: boolean;
    title: string;
    message: string;
    jobId: string,
    projectId: string
    onClose: () => void
}

const ErrorDialog = (props: ErrorDialogProps) => {
    const { t } = useTranslation();
    const [emailLink, setEmailLink] = useState<string>("");
    const [containerName, setContainerName] = useState<string>("");
    const globalConfigContext = useContext(ConfigurationContext);

    useEffect(() => {
        if (!containerName || !globalConfigContext?.supportEmail || !props.projectId || !props.jobId) return;

        const lineBreak = "%0D%0A";
        const mailTo = globalConfigContext?.supportEmail;
        const subject = `${t("Problem found with Job")} ${props.jobId}`;
        const body = `${t("Container")}: ${containerName}${lineBreak}${lineBreak} ${t("Project")}: ${props.projectId}${lineBreak}${lineBreak}Job: ${props.jobId}${lineBreak}${lineBreak}Description: ${props.message}${lineBreak}${lineBreak} ${t("Please enter any comments you have below and then send the email")}: ${lineBreak}${lineBreak}${lineBreak}`;

        let emailLink = `mailto:${mailTo}?subject=${subject}&body=${body}`;
        setEmailLink(emailLink);
    }, [containerName, props.projectId, props.jobId])

    const GetData = async () => {
        UserService.GetBlobContainerName().then((response) => {
            setContainerName(response);
        });
    }

    useEffect(() => {
        if (props.open) {
            GetData();
        }
    }, [props.open]);

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return (
        <Dialog open={props.open} onClose={() => props.onClose()} aria-label={t("Error Dialog")}>
            <DialogTitle sx={{ color: theme.palette.primary.dark }}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.message}
                    {props.showEmailLink && <><br /><br /><a onClick={(e) => { window.open(emailLink, 'mail'); e.preventDefault(); }} href={emailLink}>{t("Send an email to report this problem")}...</a></>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.btn} onClick={(e) => {
                    e.stopPropagation();
                    props.onClose();
                }}>{t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialog;