///
/// Wavefront OBJ uses a index position of 1 rather than 0.
///
export class Index {
    readonly index: number;

    constructor(index: number) {
        this.index = index;
    }

    toString(): string {
        return this.index.toFixed(0);
    }
}