import axios from 'axios';
import { PostProcessingConfiguration } from '../store/postProcessing/postProcessingConfig';

export default class PostprocessingConfigService {
    async getConfig(voxelsCount: number): Promise<PostProcessingConfiguration> {
        try {
            const data = { voxelsCount };
            const response = await axios.post('/api/PostProcessingConfig', data, 
            {
                headers: { "Content-Type": "application/json" },
                params:{
                    voxelsCount
                }
            });
            return response.data;
        } catch (error) {
            throw new Error('Cannot get postprocessing configuration');
        }
    }
}