import { FC } from 'react';
import { PropsWithChildren } from 'react';

import { Route, Routes } from 'react-router-dom';
import routes from '../router/routes';

const LoginRouter: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Routes>
    <Route path={routes.login} element={null} />
    <Route path='*' element={children} />
  </Routes>
);

export default LoginRouter;
