import { createContext, useCallback, useEffect, useRef } from "react";
import JobData, { ChannelEndType, SyntheticMeshInfo } from "./job-data";
import { areSyntheticMeshesEqual, drawSyntheticNode } from "../../utils/hoops.utils";
import { Channel } from "./channel";
import { inletSelectionModeColor, outletSelectionModeColor } from "../uiSettings/communicatorColors";
import { useParams } from "react-router-dom";

type MeshInstance = {
    nodeId: number;
    meshId: Communicator.MeshId;
    mesh: SyntheticMeshInfo
};

export type ColoredMesh = {
    mesh: SyntheticMeshInfo
    color: Communicator.Color
}

export type HoopsEntitiesContextType = {
    registerColoredMesh: (mesh: ColoredMesh) => Promise<number>,
    registerChannelMeshes: () => Promise<void>,
    unregisterMeshes: (meshes: SyntheticMeshInfo[]) => void,
    getNodeIdFromMesh: (mesh: SyntheticMeshInfo) => number | null,
    getMeshForNodeId: (nodeId: number) => SyntheticMeshInfo | null,
};

export const HoopsEntitiesContext = createContext<HoopsEntitiesContextType>({
    registerColoredMesh: async (mesh: ColoredMesh) => Promise.resolve(-1),
    registerChannelMeshes: async () => Promise.resolve(),
    unregisterMeshes: () => null,
    getNodeIdFromMesh: () => null,
    getMeshForNodeId: () => null,
});

export const useEntitiesContext = (jobContext: JobData, hwv: Communicator.WebViewer | null) => {
    const { id: projectId, jobId } = useParams();
    
    const instances = useRef<MeshInstance[]>([]);

    const getNodeIdFromMesh = useCallback((mesh: SyntheticMeshInfo) => {
        const savedMesh = instances.current.find(m => areSyntheticMeshesEqual(m.mesh, mesh));

        if (savedMesh) {
            return savedMesh.nodeId;
        }
        return null;
    }, [instances]);

    const getMeshForNodeId = useCallback((nodeId: number) => {
        const savedMesh = instances.current.find(m => m.nodeId === nodeId);

        if (savedMesh) {
            return savedMesh.mesh;
        }
        return null;
    }, [instances]);

    const registerColoredMesh = useCallback(async (instance: ColoredMesh) => {
        const existingInstance = instances.current.find(i => areSyntheticMeshesEqual(i.mesh, instance.mesh));

        if (existingInstance) {
            return existingInstance.nodeId;
        }

        const { nodeId, meshId } = await drawSyntheticNode(instance.mesh.vertexes, instance.mesh.center, instance.color, hwv);

        instances.current.push({
            nodeId,
            meshId,
            mesh: instance.mesh
        });

        return nodeId;
    }, [instances, hwv]);

    const unregisterMeshes = useCallback(async (meshes: SyntheticMeshInfo[]) => {
        if (hwv) {
            const instancesToRemove: MeshInstance[] = instances.current.filter(i => meshes.find(m => areSyntheticMeshesEqual(m, i.mesh)) !== undefined);
            const nodeIdsToRemove = instancesToRemove.map(mi => mi.nodeId);
            const meshIdsToRemove = instancesToRemove.map(mi => mi.meshId);

            await hwv.model.deleteMeshInstances(nodeIdsToRemove);
            await hwv.model.deleteMeshes(meshIdsToRemove);
            instances.current = instances.current.filter(i => instancesToRemove.includes(i) === false);
        }

    }, [instances, hwv]);

    const registerChannelMeshes = useCallback(async () => {
        for (const channel of jobContext.Categories.Channel) {
            const terminals = [...channel.inlets, ...channel.outlets];
            
            for (const terminal of terminals) {
                if (Channel.isSyntheticFace(terminal.face) && getNodeIdFromMesh(terminal.face.config) === null) {
                    const nodeId = await registerColoredMesh({
                        mesh: terminal.face.config,
                        color: terminal.type === ChannelEndType.Inlet ? inletSelectionModeColor : outletSelectionModeColor
                    });

                    jobContext.editChannelEnd(channel.id, terminal.id, {
                        ...terminal.face,
                        nodeId,
                        faceIndex: 0
                    }, terminal.type);
                }
            }

            for (const baffle of channel.baffles) {
                let needsUpdate = false;

                for (const face of baffle.config) {
                    if (Channel.isSyntheticFace(face) && getNodeIdFromMesh(face.config) === null) {
                        const nodeId = await registerColoredMesh({
                            mesh: face.config,
                            color: Communicator.Color.blue()
                        });
                        face.nodeId = nodeId;
                        face.faceIndex = 0;
                        needsUpdate = true;
                    }
                } 

                if (needsUpdate) {
                    jobContext.editChannelBaffle(channel.id, baffle.id, baffle.config);
                }
            }
        }
    }, [getNodeIdFromMesh, registerColoredMesh, jobContext]);

    useEffect(() => {
        instances.current = [];
    }, [instances, projectId, jobId]);

    return {
        meshes: instances.current,
        unregisterMeshes,
        registerColoredMesh,
        registerChannelMeshes,
        getMeshForNodeId,
        getNodeIdFromMesh
    }
}