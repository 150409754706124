import { useEffect, useState, useContext, MouseEvent, useRef, MutableRefObject } from 'react'
import { Button, Grid, Typography, useTheme, MenuItem, FormControl, Menu } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Project from '../../store/project/project';
import ProjectService from '../../services/ProjectsService';
import Layout from '../ui/Layout';
import ProjectDialog from './ProjectDialog';
import AuthContext from '../../auth/AuthenticationContext';
import ProjectCard from './ProjectCard';
import { useNavigate } from 'react-router-dom';
import { iterationDialogReturnData, updateJobInfo } from '../../store/job/job-data';
import { ProjectType, projectTypes, ProjectName } from '../../store/project/project-data';
import './ProjectsListPage.css';
import { useProjects, useCreateProjectMutation, useDeleteProjectMutation, useUpdateProjectMutation, useLoadJobsThumbnail, useUpdateJobMutation, resetJobStatus } from './hooks/useProjects';
import IterationDialog from './IterationDialog';
import Iteration from '../../store/project/iteration';
import { OriginalJob } from './IterationDialogProps';
import JobService from '../../services/JobService';
import makeStyles from '@mui/styles/makeStyles';
import CreditsAccountContext, { CompanyUsersContext } from '../../store/creditsAccount/CreditsAccountContext';
import routes from '../../router/routes';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { UiSettingsService } from "../../services/UiSettingsService";
import { UiSettingsContext } from "../../store/uiSettings/UiSettingsContext";
import { DEFAULT_TYPE } from '../../store/project/project-data';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CanSeeCredits } from '../../utils/visibilityConfig.utils';
import { useTranslation } from 'react-i18next';
import { ProjectFilter, ProjectFilterType, ProjectFilters, SelectedUserFilterModel } from '../common/ProjectFilter';
import { RefetchOptions } from 'react-query';
import Loading from '../common/Loading';
import { UserListModel, UserRole } from '../users/UserList';
import { localStorageUtil } from '../../utils/localStorage.util';
import { filterProjectList } from '../../utils/project.util';

const ProjectsListPage = () => {
    const [isLoadingFilteredProjects, setIsLoadingFilteredProjects] = useState<boolean>(false);
    const [expandAll, setExpandAll] = useState(false);
    const authContext = useContext(AuthContext);

    const initialProjectFilter = { inputSearch: null, filterType: ProjectFilterType.AllAttributes, users: [{ id: authContext.id, name: authContext.username, email: authContext.email, checked: true, isDeleted: false }] };
    const [projectFilters, setProjectFilters] = useState<ProjectFilters>(initialProjectFilter);
    const previousProjectFilters = useRef<ProjectFilters>(initialProjectFilter);

    const [editedProject, setEditedProject] = useState<Project>();
    const [jobData, setJobData] = useState<OriginalJob | undefined>();
    const [isEditingJob, setIsEditingJob] = useState<boolean>(false);
    const [jobDialogOpen, setJobDialogOpen] = useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string>("");
    const [costPerProject, setCostPerProject] = useState<number>(0);
    const [costFeasibilityPerProject, setFeasibilityCostPerProject] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [newProjectDialogOpen, setNewProjectDialogOpen] = useState<boolean>();
    const theme = useTheme();
    const creditsAccountContext = useContext(CreditsAccountContext);
    const projectsQuery = useProjects(projectFilters);
    const projects: Project[] = projectsQuery.data ?? [];

    const localFilteredProjects = filterProjectList(projects, projectFilters);

    const uiSettingsContext = useContext(UiSettingsContext);
    const createProjectMutation = useCreateProjectMutation();
    const deleteProjectMutation = useDeleteProjectMutation();
    const updateProjectMutation = useUpdateProjectMutation();
    const loadJobsThumbnail = useLoadJobsThumbnail();
    const updateJobMutation = useUpdateJobMutation();
    const [type, setType] = useState<string>(DEFAULT_TYPE);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const companyUsersContext = useContext(CompanyUsersContext);

    useEffect(() => {
        const setCompanyUsers = async () => {
            let usersModel: SelectedUserFilterModel[] = [];

            if (authContext.role == UserRole.Admin) {
                const companyUsers: UserListModel[] = companyUsersContext.users;

                companyUsers.forEach(user => {
                    if (user.b2cOid == authContext.id) return;

                    usersModel.push({
                        id: user.b2cOid,
                        name: user.name,
                        email: user.email,
                        checked: false,
                        isDeleted: user.isDeleted
                    })
                });
            }

            //set current user with checked
            usersModel.push({
                id: authContext.id,
                name: authContext.username,
                email: authContext.email,
                checked: true,
                isDeleted: false
            });

            //check in localstorage if user checked
            const selectedUsers = localStorageUtil.getSelectedUsers();
            if (selectedUsers) {
                const selectedUserParsed: string[] = JSON.parse(selectedUsers);
                for (const selectedUserId of selectedUserParsed) {
                    let user = usersModel.find((u) => { return u.id == selectedUserId });
                    if (user)
                        user.checked = true;
                }
            }

            setProjectFilters({ ...projectFilters, users: usersModel });
        }

        setCompanyUsers();
    }, []);

    const columnsBreakpoints: Record<string, number[]> = {
        '(max-width: 800px)': [0],
        '(min-width: 800px) and (max-width: 1200px)': [0, 1],
        '(min-width: 1200px)': [0, 1, 2]
    };
    const { t } = useTranslation();
    const [columns, setColumns] = useState<number[]>(() => {
        const matchingQuery = Object.keys(columnsBreakpoints).find(mediaQuery => window.matchMedia(mediaQuery).matches)
        return matchingQuery ? columnsBreakpoints[matchingQuery] : [0]
    });
    const classes = makeStyles({
        iconAdd: {
            textAlign: 'left',
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            },
        },
        iconArrow: {
            backgroundColor: 'none',
            "&&:after": {
                backgroundColor: 'transparent',
            },
            "&:hover": {
                backgroundColor: 'transparent',
            },
            "&:focused": {
                backgroundColor: "transparent"
            }
        },
        form: {
            margin: '0', width: "100%",
        },
        iconCredits: {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.secondary.dark,
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            },
            ml: 1,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            marginLeft: '5px'
        },
        underline: {
            transition: "opacity 0",
            "&&&:before": {
                backgroundColor: 'transparent',
            },
            "&&:after": {
                backgroundColor: 'transparent',
            },
            "&:hover": {
                backgroundColor: 'transparent',
            }
        }
    })();

    useEffect(() => {
        const loadProjectTypeUiSettingsData = async () => {
            setLoading(true);
            let data = await UiSettingsService.Get();
            data.projectType && setType(data.projectType);
            setLoading(false);
        }
        loadProjectTypeUiSettingsData();
    }, []);

    useEffect(() => {
        const loadProjectCost = async () => {
            const projectCreationOptions = await ProjectService.getCost();
            setCostPerProject(projectCreationOptions.cost);
            setFeasibilityCostPerProject(projectCreationOptions.feasibilityCost);
        }

        loadProjectCost();
    }, []);

    useEffect(() => {
        Object.entries(columnsBreakpoints).forEach(([mediaQuery, columns]) => {
            window.matchMedia(mediaQuery).addEventListener('change', event => {
                if (event.matches) {
                    setColumns(columns)
                }
            })
        })
    }, [setColumns])

    const editProject = async (id: string, name: string, description: string, containerName: string, tags?: string[]) => {
        const patch = [
            {
                "op": "replace",
                "path": "/name",
                "value": name
            },
            {
                "op": "replace",
                "path": '/description',
                "value": description
            },

        ];
        if (tags) {
            patch.push({
                "op": "replace",
                "path": '/tags',
                "value": tags as never
            })
        }

        updateProjectMutation({ id, containerName, patch });
    };

    const addProject = async (name: string, description: string, type: string, tags?: string[]) => {
        try {
            const newProjectInfo = await createProjectMutation({ name, description, type, tags });
            creditsAccountContext?.refreshCreditsBalance();
            openNewProjectPage(newProjectInfo.project);
        } catch (error) {
            //note. explicitly doing nothing, this is already handled by react-query... price to pay for using mutateAsync
        }
    };

    const navigateToLastModifiedIteration = (project: Project) => {
        const projectService = new ProjectService();
        projectService.navigateToLastModifiedIteration(project, navigate, project.type as ProjectType);
    }

    const openNewProjectPage = (project: Project) => {
        const projectService = new ProjectService();
        projectService.navigateToIterationSetup(project.id, null, project.containerName, navigate, project.type as ProjectType);
    };

    const deleteProject = async (id: string) => {
        deleteProjectMutation({ id });
    };

    const topRight: SxProps = {
        position: 'absolute',
        top: 'auto',
        right: 35
    };

    const onExpandProjectCard = (project: Project) => {
        loadJobsThumbnail(project);
    }

    const onJobDetailsClick = async (iteration: Iteration, projectId: string) => {
        setIsLoading(true);
        const jobDetails = await JobService.getJobDetails(projectId, iteration.id, iteration.containerName);
        setIsLoading(false);

        if (!jobDetails) return;

        const job: OriginalJob = {
            name: iteration.name,
            description: iteration.description,
            jobId: iteration.id,
            cadInfos: jobDetails.CadInfos,
            solveStatus: +iteration.solveStatus,
            containerName: iteration.containerName
        };

        setSelectedProjectId(projectId)
        setIsEditingJob(true);
        setJobData(job);
        setJobDialogOpen(true);
    }

    const onSetJobDetails = async (returnData: iterationDialogReturnData, mode?: string) => {
        if (mode == "edit" && returnData.projectId && returnData.id) {
            const updateJobInfo: updateJobInfo = {
                projectId: returnData.projectId,
                id: returnData.id,
                name: returnData.name,
                description: returnData.description,
                existingCadInfos: returnData.existingCadInfos,
                cadFilesToAdd: returnData.cadFilesToAdd,
                updateOnCadFiles: returnData.updateOnCadFiles,
                containerName: returnData.containerName,
                customSolverSettings: returnData.customSolverSettings

            }
            updateJobMutation(
                updateJobInfo);
        }
    }

    const hasEnoughCredits = () => {
        if (creditsAccountContext?.balance == null || creditsAccountContext?.balance == undefined)
            return false;

        return (creditsAccountContext?.balance >= costPerProject ||
            creditsAccountContext?.balance >= costFeasibilityPerProject);
    }

    const getCostPerProject = (type: string) => {
        if (type == ProjectType.Feasibility) { return costFeasibilityPerProject; }
        else { return costPerProject; }
    }

    const onJobCancel = (projectId: string, jobId: string) => {
        //reload job status
        resetJobStatus(projectId, jobId);
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const CostPerProject = (type: string) => {
        const currentCostPerProject = getCostPerProject(type);

        const canSeeCredits = CanSeeCredits(creditsAccountContext.accountConfiguration.AccountUsersConfiguration, authContext.role);

        if (canSeeCredits) {
            return <Box component="span" sx={{ ml: "5px", display: "inline" }}><b>({currentCostPerProject} {t("Credits")})</b></Box>
        }

        return <></>
    }

    const onFilterProjects = (text: string | null, filterType: ProjectFilterType, selectedUsers: SelectedUserFilterModel[]) => {
        setProjectFilters({
            ...projectFilters,
            inputSearch: text,
            filterType: filterType,
            users: selectedUsers
        })
    }

    const sameSelectedUsers = (newFilter: ProjectFilters, oldFilter: MutableRefObject<ProjectFilters>) => {
        const newSelected = newFilter.users.filter((u) => { return u.checked });
        const oldSelected = oldFilter.current.users.filter((u) => { return u.checked });

        return newSelected.length == oldSelected.length
    }

    const inputStayEmpty = (newFilter: ProjectFilters, oldFilter: MutableRefObject<ProjectFilters>) => {
        return !projectFilters.inputSearch && !previousProjectFilters.current.inputSearch
    }

    useEffect(() => {

        const reloadProjects = async () => {
            //Important!
            //For now, we dont want to reload when changing only the input filter
            //Backend filtering is still too slow until we improve that and until we need paging.
            //Unless selected users change, dont reload for now
            //When ready for backend filtering, remove next line
            if (sameSelectedUsers(projectFilters, previousProjectFilters)) return;

            //No reload if condition
            if (sameSelectedUsers(projectFilters, previousProjectFilters) && inputStayEmpty(projectFilters, previousProjectFilters)) {
                return;
            }

            //save selected users in local storage
            localStorageUtil.saveSelectedUsers(projectFilters.users);

            //Reload projects on filter change
            //if during query, cancel it
            setIsLoadingFilteredProjects(true);
            const refretchOption: RefetchOptions = { cancelRefetch: true };
            projectsQuery.refetch(refretchOption);

            previousProjectFilters.current = projectFilters;
        }

        reloadProjects();
    }, [projectFilters]);

    useEffect(() => {
        setIsLoadingFilteredProjects(false);
    }, [projectsQuery.dataUpdatedAt]);

    return <>
        <ProjectDialog
            project={undefined}
            edit={false}
            open={newProjectDialogOpen || false}
            buttonText={t("Add")}
            OkHandler={() => setNewProjectDialogOpen(false)}
            CancelHandler={() => setNewProjectDialogOpen(false)}
            numberOfCredits={getCostPerProject(type)}
            type={type}
            title={t("Add") + " " + t(ProjectName[type as keyof typeof ProjectName])}
            setDetails={addProject}
        />
        {editedProject && <ProjectDialog
            project={editedProject}
            edit={true}
            open={editedProject !== undefined || false}
            OkHandler={() => setEditedProject(undefined)}
            CancelHandler={() => setEditedProject(undefined)}
            title={t("Edit") + " " + t(ProjectName[type as keyof typeof ProjectName])}
            type={type}
            setDetails={(name, description, type, tags) => {
                editProject(editedProject.id, name, description, editedProject.containerName, tags)
            }}
        />}

        <IterationDialog
            originalJob={jobData}
            projectId={selectedProjectId}
            open={jobDialogOpen || false}
            buttonText='Confirm'
            title={t("Edit Job")}
            edit={isEditingJob}
            OkHandler={() => setJobDialogOpen(false)}
            CancelHandler={() => setJobDialogOpen(false)}
            setDetails={onSetJobDetails}
        />

        <Layout>
            <Loading isLoading={isLoading || !projectsQuery.isFetched} />
            <Grid container direction='column' wrap='nowrap' sx={{ p: 2, height: '100%' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" component={'div'} sx={{ fontWeight: 'bold', color: theme.palette.primary.dark, ml: 1, mt: 0.5 }}>
                        {t('Projects')}
                    </Typography>
                </Box>

                {/* projectsnavbar */}
                <Grid container direction='row' sx={{ m: 1 }}>
                    <Grid item xs={12} lg={6}>
                        <Grid item xs={12} sx={{ display: "flex", height: "100%", alignItems: "center" }}>
                            <Typography variant="h6" component={'span'}
                                sx={{ fontWeight: 'bold', color: theme.palette.primary.contrastText, marginRight: "15px" }}>
                                {localFilteredProjects.length} {t('Projects')}
                            </Typography>

                            {!hasEnoughCredits() ? 
                                <Button id="add-project-button" className={classes.iconAdd}  onClick={() => {setType(ProjectType.Feasibility); setNewProjectDialogOpen(true)}}
                                    startIcon={<AddBoxIcon />} size='small' variant='contained' data-project-cost={getCostPerProject(ProjectType.Feasibility)}>
                                    {t(ProjectName[ProjectType.Feasibility])} {CostPerProject(ProjectType.Feasibility)}
                                </Button> : ""}
                            {hasEnoughCredits() &&
                                <Button id="add-project-button" className={classes.iconAdd} data-project-cost={getCostPerProject(ProjectType.Design)}
                                    startIcon={<AddBoxIcon onClick={() => setNewProjectDialogOpen(true)}/>}
                                    size='small' variant='contained'>
                                    <FormControl variant="standard" sx={{ m: 1 }} className={classes.form}>
                                        <Grid container wrap='nowrap' direction='row' sx={{ width: '100%', display: "flex", alignItems: "center" }}>
                                            <Grid item xs={11}>
                                                <Typography sx={{ color: theme.palette.primary.main }}
                                                    onClick={() => setNewProjectDialogOpen(true)}>{t(ProjectName[type as keyof typeof ProjectName])} {CostPerProject(type)}</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Box id="project-arrow-dropdown" className={classes.underline} onClick={handleClick} sx={{ minWidth: '15px', cursor: 'pointer', margin: '0', padding: '0', display: "flex", alignItems: "center" }}>
                                                    {openMenu && <ArrowDropUpIcon />}
                                                    {!openMenu && <ArrowDropDownIcon />}
                                                </Box>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    id="account-menu"
                                                    open={openMenu}
                                                    onClose={handleClose}
                                                    onClick={handleClose}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible',
                                                            boxShadow: '0px 5px 15px',
                                                            shadowOffset: { width: -2, height: 4 },
                                                            boxShadowColor: theme.palette.grey[700],
                                                            boxShadowOpacity: 0.1,
                                                            color: theme.palette.primary.main,
                                                            mt: 1.5,
                                                            '&:before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    }}>
                                                    {projectTypes.map((type, index) => {
                                                        return (
                                                            <MenuItem
                                                                id={`project-button-${type.toLowerCase()}`}
                                                                value={type}
                                                                onClick={(e) => {
                                                                    setType(type);
                                                                    const data = {
                                                                        temperatureUnit: uiSettingsContext.uiSettings.temperatureUnit,
                                                                        projectType: type,
                                                                        language: uiSettingsContext.uiSettings.language,
                                                                        displayTipsAndTricks: uiSettingsContext.uiSettings.displayTipsAndTricks
                                                                    };
                                                                    UiSettingsService.Set(data);
                                                                    setAnchorEl(null);
                                                                }}
                                                                key={type + '-' + index}>
                                                                {t(ProjectName[type])} {CostPerProject(type)}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box id="projectFilterSection" sx={{ maxWidth: "fit-content" }}>
                            <ProjectFilter
                                filters={projectFilters}
                                onSearchChange={onFilterProjects}
                                debounceTime={500}
                                isLoading={isLoadingFilteredProjects}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box className="container">
                    <Box className="scrollArea">
                        <Box className="columnContainer">
                            {columns.map(columnIndex => (
                                <Box className="column" key={columnIndex} role="feed" aria-label="project-list">
                                    {localFilteredProjects.filter((_, index) => index % columns.length === columnIndex)
                                        .map((project) =>
                                            <Box className="project-card" padding={1} key={project.id} >
                                                <ProjectCard
                                                    project={project}
                                                    onCardClick={(event: MouseEvent<HTMLElement>) => {
                                                        event.stopPropagation();
                                                        navigateToLastModifiedIteration(project)
                                                    }}
                                                    onDetailsClick={(event: MouseEvent<HTMLElement>) => {
                                                        event.stopPropagation();
                                                        setEditedProject(project);
                                                    }}
                                                    onDeleteClick={(event: MouseEvent<HTMLElement>) => {
                                                        event.stopPropagation();
                                                        deleteProject(project.id)
                                                    }}
                                                    onExpand={onExpandProjectCard}
                                                    onJobDetailsClick={onJobDetailsClick}
                                                    forceExpand={expandAll}
                                                    onJobCancel={(projectId, jobId) => {
                                                        onJobCancel(projectId, jobId);
                                                    }}
                                                />
                                            </Box >
                                        )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Layout>
    </>
}

export default ProjectsListPage;
