import Layout from '../ui/Layout';
import JobResultsWrapper from './JobResultsWrapper';
import JobRouteGuard from './JobRouteGuard'

const JobResultsComponent = () => {
    return (
        <JobRouteGuard>
            <JobResultsWrapper />
        </JobRouteGuard>
    )
};

export default JobResultsComponent;