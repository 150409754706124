export default class HideContextMenuOperator implements Communicator.Operator.Operator {
    private _ui: Communicator.Ui.Desktop.DesktopUi;

    constructor(ui: Communicator.Ui.Desktop.DesktopUi) {
        this._ui = ui;
    }

    onMouseUp(event: Communicator.Event.MouseInputEvent) {
        if (event.getButton() === Communicator.Button.Left) {
            this._ui._getContextMenu().hide();
        }
    }
}