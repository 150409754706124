import axios from "axios";

const FeatureService = {
    UpcomingFeature: async (featureName: string): Promise<any> => {
        try {
            const response = await axios.get('/api/feature/upcomingFeature', {params: {
                featureName
            }});
            return;
        } catch {
            throw new Error('Error upcoming feature');
        }
    }
}

export default FeatureService;