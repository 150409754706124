import { useState, useEffect, useContext, useMemo } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CeetronActionType, CeetronContext, DisplayPreset, PartType, VisibilityState } from "../../store/job/ceetron-context";
import { JobSolveInputsParameters } from "../../store/job/job-data";
import { useTranslation } from 'react-i18next';
import { setVisibilityByName } from "./utils/ceetron.utils";
interface PartModel {
    visible: boolean,
    name: string
}

interface PartListProps {
    model: cee.ug.RemoteModel;
    parameters?: JobSolveInputsParameters;
    partsVisibility: VisibilityState;
    coolingChannelsModel?: cee.geo.GeometryModel;
    comparisonCoolingChannelsModel?: cee.geo.GeometryModel;
    isLoadingGeometry?: boolean;
}

export const PartList = (props: PartListProps) => {
    const {model, parameters, partsVisibility, coolingChannelsModel, comparisonCoolingChannelsModel, isLoadingGeometry} = props;
    const [parts, setParts] = useState<PartModel[]>(mapVisibilityStateToPartModelArray(partsVisibility));
    const [moldGroup, setMoldGroup] = useState<PartModel[]>([]);
    const [coolingChannelVisibility, setCoolingChannelVisibility] = useState<boolean>(false);
    const [showCoolingChannelCheckbox, setShowCoolingChannelCheckbox] = useState<boolean>(false); 
    const context = useContext(CeetronContext);
    const { t } = useTranslation();
    useEffect(() => {
        const ccmPartCount = coolingChannelsModel?.partCount;
        const cccmPartCount = comparisonCoolingChannelsModel?.partCount;
        setShowCoolingChannelCheckbox(!!(ccmPartCount || cccmPartCount));
    }, [coolingChannelsModel, comparisonCoolingChannelsModel]);

    useEffect(() => {
        // handle reset of cooling channels visibility when changing comparison model
        coolingChannelsModel?.getPartArray().forEach(p => {
            p.settings.visible = false;
        });
        setCoolingChannelVisibility(false);
    }, [comparisonCoolingChannelsModel]);

    function toggleCoolingChannelsModel() {
        coolingChannelsModel?.getPartArray().forEach(p => {
            p.settings.visible = !coolingChannelVisibility;
        });
        comparisonCoolingChannelsModel?.getPartArray().forEach(p => {
            p.settings.visible = !coolingChannelVisibility;
        });
        setCoolingChannelVisibility(!coolingChannelVisibility);
    }

    function mapVisibilityStateToPartModelArray(vs: VisibilityState): PartModel[] {
        return Object.entries(vs.state).filter(item => item[1].type === PartType.MOLD).map(o => {
            return {
                name: o[0],
                visible: o[1].value
            };
        });
    }

    useEffect(() => {
        const parts = mapVisibilityStateToPartModelArray(context.ceetronState.visibility)
        setParts(parts);
    }, [context.ceetronState.visibility]);

    const handleToggle = (p: PartModel) => () => {
        const newVisibilityState = setVisibilityByName(context.ceetronState.visibility, p.name, !p.visible);
        context.updateCeetronState({type: CeetronActionType.SetVisibilityState, payload: newVisibilityState});
        context.updateCeetronState({type: CeetronActionType.SetScaleUpToDateState, payload: false});
    };

    const getLabelId = (partName: string) => {
        return `part-list-item-${partName}`
    }

    return (
        <div>
           <List component="div" role="list" style={{ display: 'flex', flexDirection: 'column', padding: 0, maxHeight: "250px", overflowY: "auto" }}>
                {   
                    showCoolingChannelCheckbox && props.isLoadingGeometry == false &&
                    <ListItem key={'channels'} role="listitem" dense button onClick={toggleCoolingChannelsModel} sx={{flex: 1, width: "100%"}}>
                        <ListItemIcon>
                            <Checkbox
                                sx={{paddingTop: "5px", paddingBottom: "5px"}}
                                edge="start"
                                checked={coolingChannelVisibility}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': getLabelId('channels') }}
                            />
                        </ListItemIcon>
                        <ListItemText id={getLabelId('channels')} primary={t("Channels")}
                            primaryTypographyProps={{
                                fontSize: '1rem',
                                fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif"
                            }} />
                    </ListItem>
                } 
                {parts.map(mg =>
                    (context.ceetronState.displayPreset === DisplayPreset.MOLD) && 
                    <ListItem key={mg.name} role="listitem" button dense onClick={handleToggle(mg)} sx={{flex: 1, width: "100%"}}>
                        <ListItemIcon >
                            <Checkbox
                                sx={{paddingTop: "5px", paddingBottom: "5px"}}
                                edge="start"
                                checked={mg.visible}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': getLabelId(mg.name) }}
                            />
                        </ListItemIcon>
                        <ListItemText id={getLabelId(mg.name)} primary={mg.name}
                            primaryTypographyProps={{
                                fontSize: '1rem',
                                fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif"
                            }} />
                    </ListItem>
                )}

            </List>
        </div>
    )
}