import { createContext, useCallback, useEffect, useReducer, useState, Dispatch } from "react";
import { GetAllRelatedNodeIds } from "../../services/TreeviewFunctions";
import clone from "../../utils/clone";
import { makeFaceKey, parseFaceKey } from "../../utils/hoops.utils";
import { categoryColors } from "../uiSettings/communicatorColors";
import { Channel } from "./channel";
import JobData, { Face, Part } from "./job-data";
import { MoldCategory } from "./mold-categories";

export type HoopsColorContextType = {
    getFaceOriginalColor: (nodeId: number, faceIndex: number) => Promise<Communicator.Color | null>,
    updateColor: Dispatch<ColorStateAction>
};

export const HoopsColorContext = createContext<HoopsColorContextType>({
    getFaceOriginalColor: (nodeId: number, faceIndex: number): Promise<Communicator.Color | null> => Promise.resolve(Communicator.Color.white()),
    updateColor: (action: ColorStateAction) => null
});

export enum ColorStateActionType {
    SET_CATEGORY_NODE_COLOR,
    SET_CATEGORY_FACE_COLOR,
    SET_CATEGORY_GROUP_COLOR,
    CLEAR_CATEGORY
}

export type NodeColorMap = { [nodeId: number]: Communicator.Color };

export type FaceColorMap = { [key: string]: Communicator.Color };

export type ColorGroup = {
    bodies: {
        nodes: { nodeId: number, color: Communicator.Color }[],
        faces: { faceKey: string, color: Communicator.Color }[]
    },
    inlets: { faceKey: string, color: Communicator.Color }[],
    outlets: { faceKey: string, color: Communicator.Color }[],
    baffles: { faceKey: string, color: Communicator.Color }[]
}

export type ColorState = {
    [category in MoldCategory]: {
        nodeColorMap: NodeColorMap
        faceColorMap: FaceColorMap
    }
}

export type ColorStateAction = {
    type: ColorStateActionType.SET_CATEGORY_NODE_COLOR,
    category: MoldCategory,
    nodeIds: number[],
    color?: Communicator.Color,
} | {
    type: ColorStateActionType.CLEAR_CATEGORY,
    category: MoldCategory
} | {
    type: ColorStateActionType.SET_CATEGORY_GROUP_COLOR,
    category: MoldCategory,
    groups: ColorGroup[]
}

type NodeAccessFn = (nodeIds: number[]) => number[];

function reducer(state: ColorState, action: ColorStateAction, nodeAccessFn: NodeAccessFn) {
    if (action.type === ColorStateActionType.SET_CATEGORY_NODE_COLOR) {
        const nodeColorMap: NodeColorMap = {};
        const color = action.color || categoryColors[action.category];

        action.nodeIds.forEach(nodeId => nodeColorMap[nodeId] = color);

        return {
            ...state,
            [action.category]: {
                ...state[action.category],
                nodeColorMap
            }
        }
    }
    if (action.type === ColorStateActionType.SET_CATEGORY_GROUP_COLOR) {
        const nodeColorMap: NodeColorMap = {};
        const faceColorMap: FaceColorMap = {};

        for (const group of action.groups) {
            group.bodies.nodes.forEach(node => nodeColorMap[node.nodeId] = node.color);
            [
                ...group.bodies.faces,
                ...group.inlets,
                ...group.outlets,
                ...group.baffles
            ].forEach(face => faceColorMap[face.faceKey] = face.color);
        }

        return {
            ...state,
            [action.category]: {
                ...state[action.category],
                nodeColorMap,
                faceColorMap
            }
        }
    }
    if (action.type === ColorStateActionType.CLEAR_CATEGORY) {
        return {
            ...state,
            [action.category]: {
                nodeColorMap: {},
                faceColorMap: {}
            }
        }
    }
    return state;
}

export function useColorContext(jobContex: JobData, hwv: Communicator.WebViewer | null) {
    const [nodeOriginalColorMap, setNodeOriginalColorMap] = useState<{ [nodeId: number]: Communicator.Color }>({});
    const [faceOriginalColorMap, setFaceOriginalColorMap] = useState<{ [key: string]: Communicator.Color }>({});
    const getFaceOriginalColor = useCallback(async (nodeId: number, faceIndex: number) => {
        if (hwv) {
            const key = makeFaceKey({ nodeId, faceIndex });

            return faceOriginalColorMap[key] || nodeOriginalColorMap[nodeId] || await hwv.model.getNodeEffectiveFaceColor(nodeId, faceIndex);
        }
        return null;
    }, [nodeOriginalColorMap, faceOriginalColorMap, hwv]);
    const getRelatedNodes = useCallback((nodeIds: number[]) => hwv !== null ? GetAllRelatedNodeIds(nodeIds, hwv) : nodeIds, [hwv])

    function createInitialState() {
        return {
            [MoldCategory.Mold]: { nodeColorMap: {}, faceColorMap: {} },
            [MoldCategory.BasicMold]: { nodeColorMap: {}, faceColorMap: {} },
            [MoldCategory.Channel]: { nodeColorMap: {}, faceColorMap: {} },
            [MoldCategory.Core]: { nodeColorMap: {}, faceColorMap: {} },
            [MoldCategory.Cavity]: { nodeColorMap: {}, faceColorMap: {} },
            [MoldCategory.Part]: { nodeColorMap: {}, faceColorMap: {} },
            [MoldCategory.Runner]: { nodeColorMap: {}, faceColorMap: {} },
        }
    }

    const [state, dispatch] = useReducer((state: ColorState, action: ColorStateAction) => reducer(state, action, getRelatedNodes), createInitialState());


    useEffect(() => {
        async function exec(state: ColorState, hwv: Communicator.WebViewer) {
            await saveOriginalColors(state, hwv);
            await updateNodeColors(state, hwv);
        }

        async function saveOriginalColors(state: ColorState, hwv: Communicator.WebViewer) {
            const newOriginalNodeColorMap: NodeColorMap = {};
            const newOriginalFaceColorMap: FaceColorMap = {};
            const nodeIds = Object.values(state).flatMap(category => Object.keys(category.nodeColorMap)).map(nodeId => parseInt(nodeId));
            const faceKeys = Object.values(state).flatMap(category => Object.keys(category.faceColorMap));
            const relatedNodeIds = GetAllRelatedNodeIds(nodeIds, hwv);

            for (const nodeId of relatedNodeIds) {
                const nodeColorMap = await hwv.model.getNodeColorMap(nodeId, Communicator.ElementType.Faces);
                const faceCount = await hwv.model.getFaceCount(nodeId);

                for (const entry of nodeColorMap.entries()) {
                    newOriginalNodeColorMap[entry[0]] = entry[1];
                }

                for (let faceIndex = 0; faceIndex < faceCount; faceIndex++) {
                    const key = makeFaceKey({ nodeId, faceIndex });
                    const color = await hwv.model.getNodeEffectiveFaceColor(nodeId, faceIndex);


                    if (color) {
                        newOriginalFaceColorMap[key] = color;
                    }
                }

            }

            for (const key of faceKeys) {
                const { nodeId, faceIndex } = parseFaceKey(key);
                const color = await hwv.model.getNodeEffectiveFaceColor(nodeId, faceIndex);

                if (color) {
                    newOriginalFaceColorMap[key] = color;
                }
            }


            setNodeOriginalColorMap(prevOriginalColorMap => {
                Object.keys(newOriginalNodeColorMap).forEach(key => {
                    const intKey = parseInt(key);
                    if (prevOriginalColorMap[intKey]) {
                        newOriginalNodeColorMap[intKey] = prevOriginalColorMap[intKey].copy();
                    }
                })
                return newOriginalNodeColorMap;
            });

            setFaceOriginalColorMap(prevOriginalColorMap => {
                Object.keys(newOriginalFaceColorMap).forEach(key => {
                    if (prevOriginalColorMap[key]) {
                        newOriginalFaceColorMap[key] = prevOriginalColorMap[key].copy();
                    }
                })
                return newOriginalFaceColorMap;
            });
        }

        async function updateNodeColors(state: ColorState, hwv: Communicator.WebViewer) {
            hwv.model.resetNodesColor();

            for (const category of Object.values(state)) {
                await hwv.model.setNodesColors(category.nodeColorMap);

                const faceKeys = Object.keys(category.faceColorMap);
                for (const key of faceKeys) {
                    const { nodeId, faceIndex } = parseFaceKey(key);

                    await hwv.model.setNodeFaceColor(nodeId, faceIndex, category.faceColorMap[key]);
                }
            }
        }

        if (hwv && jobContex.IsTreeLoaded) {
            exec(state, hwv);
        }

    }, [state, hwv, jobContex.IsTreeLoaded]);

    return {
        updateColor: dispatch,
        getFaceOriginalColor
    }
}