import React, { ErrorInfo, ReactElement } from 'react';

type ErrorBoundaryProps = {
  children: ReactElement
  message?: string
};

type ErrorBoundaryState = {
  error?: Error,
  hasError?: boolean
};

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    if(error.message.includes("AADB2C90091")){
      //user clicked on cancel button in signup page
      window.location.reload();
      return { hasError: false };
    }

    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error);
    console.error(errorInfo);
  }

  render(): ReactElement {
    if (this.state.error) {
      return <div className={'error-boundary'} style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      }}>
        <div><i className={'mdi mdi-alert-circle error'} style={{
          fontSize: 'xx-large',
        }} /></div>
        <div>{this.props.message || 'Something went wrong, this component cannot be loaded.'}</div>
      </div>;
    }

    return this.props.children;
  }
}
