import Iteration from "../../store/project/iteration";
import { DialogContent, Button, useTheme, DialogActions, Box, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import IterationMoldCategory from './IterationMoldCategory';
import { useTranslation } from 'react-i18next';
import { ProjectType } from "../../store/project/project-data";
import JobContext from '../../store/job/job-context';
import { useContext } from 'react';
interface IterationSummaryProps {
    open: boolean,
    onClose: () => void
    iteration: Iteration | undefined
}

const IterationSummaryDialog = (props: IterationSummaryProps) => {
    const { t } = useTranslation();
    const categories = props.iteration?.parameters?.categories;
    const theme = useTheme();
    const jobContext = useContext(JobContext);

    const classes = makeStyles({
        dividerTitle: {
            margin: "20px 0 5px 0",
            width: "100%",
            borderBottom: "none",
            borderLeft: "none",
            borderRight: "none",
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
            borderTopWidth: "1px",
            paddingBottom: "5px"
        },
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return (<Dialog open={props.open} maxWidth='xs' sx={{ m: 0, p: 2 }} onClose={props.onClose}>
        <DialogContent>
            {props.iteration && props.iteration.parameters && <div>
                {!props.iteration?.parameters?.basicMold?.enabled && categories?.mold && <>
                    {categories.mold.map(c =>
                        <IterationMoldCategory
                            elements={jobContext.ProjectType == ProjectType.Feasibility ? undefined : (c ? c.bodies : [])}
                            material={jobContext.ProjectType == ProjectType.Feasibility ?
                                categories.materials.basicMold.find(m => m.moldGroupId === c.id) :
                                categories.materials.molds.find(m => m.moldGroupId === c.id)}
                            basicMold={undefined}
                            title={jobContext.ProjectType == ProjectType.Feasibility ? "Mold Material" : c.name} />
                    )}
                </>}
                {categories?.materials.basicMold && props.iteration?.parameters?.basicMold?.enabled &&
                    <IterationMoldCategory
                        elements={undefined}
                        material={categories ? categories.materials.basicMold[0] : undefined}
                        basicMold={props.iteration?.parameters?.basicMold}
                        title={t("Basic Mold")} />}
                <IterationMoldCategory
                    elements={categories ? categories.part : []}
                    material={categories ? categories.materials.parts[0] : undefined}
                    basicMold={undefined}
                    title={t("Plastic Part")} />
                <fieldset className={classes.dividerTitle}>
                    <legend><Typography sx={{ fontSize: "1em" }}>{t("Runners")}</Typography></legend>
                </fieldset>
                <IterationMoldCategory
                    elements={categories ? categories.runner : []}
                    material={undefined}
                    basicMold={undefined}
                    title='' />
                {categories && categories.channel.length > 0 &&
                    <Typography variant="h6"
                        sx={{
                            fontWeight: 'bold', color: theme.palette.primary.dark,
                            "& .MuiTypography-root": { width: '0.5em' }
                        }}>{t("Cooling")}</Typography>}
                {categories && categories.channel.length > 0 && <TextField
                    disabled
                    variant="outlined"
                    fullWidth
                    key='runner-item-temperature'
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.primary.dark,
                        },
                    }}
                    label={t("Temperature")}
                    value={categories ? categories.cooling.temperature : undefined}
                    size="small"
                    margin="normal"
                    InputProps={{
                        endAdornment: <Box sx={{ whiteSpace: "nowrap", width: '15px' }}>
                            <Typography variant="caption" color="GrayText">&#8451;</Typography></Box>
                    }}
                />}</div>}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} className={classes.btn}>{t("Ok")}</Button>
        </DialogActions>
    </Dialog>);

}
export default IterationSummaryDialog;