import * as cee from "@ceetron/common/CeeEnvisionWebComponents";
import FileManagementService from "../services/FileManagementService";
import { JobChannelsBodiesData } from "../store/job/job-data";
import { channelSelectionModeColorInletOutletDone } from "../store/uiSettings/communicatorColors";
import { objGeometryScalingFactor, parseObjToGeometryData } from "./obj.utils";

export async function generateGeoModelFromChannelBodiesData(channelsBodiesData: JobChannelsBodiesData[], containerName: string): Promise<{
    result: cee.geo.GeometryModel,
    errors: Error[]
}> {
    const promises: Promise<void>[] = [];
    const errors:Error[] = [];
    const geoModel = new cee.geo.GeometryModel();
    const partColor = new cee.Color3(
        channelSelectionModeColorInletOutletDone.r/255,
        channelSelectionModeColorInletOutletDone.g/255,
        channelSelectionModeColorInletOutletDone.b/255);
    const dataUrls = channelsBodiesData.flatMap(cbd => cbd.bodiesDataUrl) ?? [];
    dataUrls?.forEach(async url => {
        promises.push(new FileManagementService().downloadFile(url, containerName).then(async rawData => {
            const data = parseObjToGeometryData(await rawData.text());
            data.e.forEach(error => {
                errors.push(new Error('In file "' + url.slice(url.lastIndexOf('/') + 1) + ', ' + error.message));
            });
            data.m.forEach(m => {
                m.forEach(face => {
                    const part = geoModel.addPart();
                    part.mesh = new cee.geo.MeshIndexedTriangles(face.vertices, [0, 1, 2], face.vertexNormals.length ? { vertexNormals: face.vertexNormals } : undefined);
                    part.settings.color = partColor
                    part.settings.opacity = 0.5;
                    part.settings.visible = false;
                    part.transformationMatrix = cee.Mat4.fromScaling({x: objGeometryScalingFactor, y: objGeometryScalingFactor, z: objGeometryScalingFactor});
                });
            });
        }, () => {
            errors.push(new Error('Data from missing OBJ file "' + url.slice(url.lastIndexOf('/') + 1) + '" were ignored.'));
        }));
    });
    return Promise.all(promises).then(() => {
        return {
            result: geoModel,
            errors
        }
    });
};