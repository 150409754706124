import { Index } from "./Index";
import { EOL, stringify, writeLine } from "./Utilities"; 

export enum CollectionKinds {
    Comments = 'comments',
    FaceElements = 'faces_elements',        
    Faces = 'faces',    
    Groups = 'groups',
    Lines = 'lines',
    ObjectNodes = 'objects',
    Vertices = 'vertices',
    NormalizedVertices = 'normalized vertices',
    Textures = 'textures'
}

export class Collection<T> {
    kind: CollectionKinds;
    values?: T[];

    constructor(kind: CollectionKinds, values? : T[]) {
        this.kind = kind;
        this.values = values;
    }

    Values() : T[] {
        if (!this.values) this.values = [];
        
        return this.values;
    }

    push(value?: T) : Index | undefined {
        if (!value) return undefined;

        this.Values().push(value);

        return this.lastIndex();
    }

    pushArray(items?: Array<T>) : Index | undefined {
        if (!items || items.length === 0) return undefined;

        let values = this.Values();

        for (const item of items) values.push(item);

        return this.lastIndex();        
    }

    pushCollection(collection?: Collection<T>) : Index | undefined {
        return this.pushArray(collection?.values);
    }

    findMatch(value?: T) : Index | undefined {
        const index = this.values?.findIndex((current) => current === value);

        return (index === undefined || index === -1)
               ? undefined
               : new Index(index + 1);
    }

    findMatches(collection?: Collection<T>) : Index[] | undefined {
        if (!collection?.values) return undefined;

        let foundIndexes: Index[] = [];

        for(const value of collection.values!) {
            const foundIndex = this.findMatch(value);

            if (foundIndex !== undefined) {
                foundIndexes.push(foundIndex);
            }
        }

        return (foundIndexes.length === 0)
               ? undefined
               : foundIndexes;
    }

    isEmpty() : boolean {
        return (this.length() === 0);
    }

    length() : number {
        return this.values?.length ?? 0;
    }

    lastIndex() : Index | undefined {
        const count = this.length();

        return (count === 0)
               ? undefined
               : new Index(count);
    }

    commentCount() : string {
        return this.isEmpty()
               ? ''
               : `There are ${this.length()} ${this.kind}.`;
    }

    join(separator: string = ',') {
        return this.isEmpty()
               ? ''
               : this.values?.join(separator);
    }

    toString() : string {
        return this.isEmpty()
               ? ''
               : writeLine(this.join('')) + EOL;
    }

    static stringify<T>(collection?: Collection<T>) : string {
        return stringify(collection?.toString());        
    }

    static isEmpty<T>(collection?: Collection<T>) : boolean {
        return (!collection || collection.isEmpty());
    }
}