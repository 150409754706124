import { Coordinates, MoldSize, Part } from "../store/job/job-data";
import { moldGroup1Color } from "../store/uiSettings/communicatorColors";

let markupIds: string[] = []

class DebugMarkupItem extends Communicator.Markup.MarkupItem {
    private _hwv: Communicator.WebViewer;

    private _isFinalized: boolean = false;

    private _point: Communicator.Point3;

    private _text: string;

    private _color: Communicator.Color;

    constructor(hwv: Communicator.WebViewer, point: Communicator.Point3, text: string, color: Communicator.Color = Communicator.Color.green()) {
        super();
        this._hwv = hwv;
        this._point = point;
        this._color = color;
        this._isFinalized = false;
        this._text = text;
    }

    draw() {
        let point3d = this._hwv.view.projectPoint(this._point);
        let point2d = Communicator.Point2.fromPoint3(point3d);

        let text = new Communicator.Markup.Shape.Text(this._text, point2d);
        let bullet = new Communicator.Markup.Shape.Circle()
        bullet.setCenter(point2d);
        bullet.setFillColor(this._color);
        bullet.setRadius(5);


        this._hwv.markupManager.getRenderer().drawText(text);
        this._hwv.markupManager.getRenderer().drawCircle(bullet);
    }

    finalize() {
        this._isFinalized = true;
        this._hwv.markupManager.refreshMarkup();
    }
}

class DebugLineMarkupItem extends Communicator.Markup.MarkupItem {
    private _hwv: Communicator.WebViewer;

    private _isFinalized: boolean = false;

    private _startPoint: Communicator.Point3;

    private _endPoint: Communicator.Point3;

    private _color: Communicator.Color;

    constructor(hwv: Communicator.WebViewer, startPoint: Communicator.Point3, endPoint: Communicator.Point3, color: Communicator.Color = Communicator.Color.green()) {
        super();
        this._hwv = hwv;
        this._startPoint = startPoint;
        this._endPoint = endPoint;
        this._color = color;
        this._isFinalized = false;
    }

    draw() {
        const startPoint = this._hwv.view.projectPoint(this._startPoint);
        const startPoint2d = Communicator.Point2.fromPoint3(startPoint);

        const endPoint = this._hwv.view.projectPoint(this._endPoint);
        const endPoint2d = Communicator.Point2.fromPoint3(endPoint);

        const line = new Communicator.Markup.Shape.Line(startPoint2d, endPoint2d);
        line.setStrokeColor(this._color);

        this._hwv.markupManager.getRenderer().drawLine(line);
    }

    finalize() {
        this._isFinalized = true;
        this._hwv.markupManager.refreshMarkup();
    }
}

class FaceInfo {
    nodeId: number;

    faceIndex: number;

    vertices: Map<string, Communicator.Point3>;

    normal: Communicator.Point3;

    props: Communicator.SubentityProperties.Face;

    get type(): Communicator.SubentityProperties.FaceType {
        return this.props.type();
    }

    get key(): string {
        return `${this.nodeId}-${this.faceIndex}`;
    }

    constructor(nodeId: number, faceIndex: number, vertices: Communicator.Point3[], normal: Communicator.Point3, props: Communicator.SubentityProperties.Face) {
        this.nodeId = nodeId;
        this.faceIndex = faceIndex;
        this.normal = normal;
        this.props = props;

        this.vertices = new Map<string, Communicator.Point3>();
        vertices.forEach(v => {
            this.vertices.set(`${v.x}-${v.y}-${v.z}`, v);
        })
    }

    public static isEqual(face1: FaceInfo, face2: FaceInfo): boolean {
        return face1.key === face2.key;
    }

    public static areConnected(face1: FaceInfo, face2: FaceInfo): boolean {
        for (const key1 of face1.vertices.keys()) {
            if (face2.vertices.has(key1)) {
                return true;
            }
        }

        return false;
    }
}

function buildConnectionMap(faces: FaceInfo[]): Map<FaceInfo, FaceInfo[]> {
    const connectionMap: Map<FaceInfo, FaceInfo[]> = new Map();

    faces.forEach(currentFace => {
        for (const f of faces) {
            if (FaceInfo.isEqual(f, currentFace) === false && FaceInfo.areConnected(f, currentFace)) {
                if (connectionMap.has(currentFace)) {
                    connectionMap.get(currentFace)!.push(f);
                } else {
                    connectionMap.set(currentFace, [f]);
                }
            }
        }
    });

    return connectionMap;
}

function getBaffleFaces(startFace: FaceInfo, restrictedFaces: FaceInfo[], connectionMap: Map<FaceInfo, FaceInfo[]>): FaceInfo[] {
    const result: FaceInfo[] = [];
    let stack = [startFace];

    while (stack.length) {
        const currentFace = stack.pop()!;

        if (result.includes(currentFace) === false) {
            result.push(currentFace);
        }

        const connectedFaces = (connectionMap.get(currentFace) ?? []).filter(f => restrictedFaces.includes(f) === false
            && result.includes(f) === false);
        stack = [...stack, ...connectedFaces];
    }

    return result;
}

async function detectBaffles(faces: FaceInfo[]): Promise<Face[][]> {
    const connectionMap = buildConnectionMap(faces);

    const cylinderFaces = faces.filter(f => f.type === Communicator.SubentityProperties.FaceType.Cylinder);

    const baffles: Face[][] = [];

    for (const baffleBody of cylinderFaces) {
        const baffleBodyConnectedFaces = connectionMap.get(baffleBody) ?? [];

        const isOrthogonal = (f: FaceInfo): boolean => {
            const angle = Communicator.Util.computeAngleBetweenVector(f.normal, baffleBody.normal);
            return f.type === Communicator.SubentityProperties.FaceType.Cylinder && (angle > 80 && angle < 110)
        }

        const connectedOnlyToBaffleBody = (f: FaceInfo): boolean => {
            const potentialChannelConnectedFaces = connectionMap.get(f) ?? [];
            return potentialChannelConnectedFaces.every(pccf => baffleBodyConnectedFaces.includes(pccf) === false);
        }

        const hasSmallerRadius = (f: FaceInfo): boolean => {
            return (baffleBody.props as Communicator.SubentityProperties.CylinderElement).radius > (f.props as Communicator.SubentityProperties.CylinderElement).radius * 1.05;
        }

        const potentialChannels = baffleBodyConnectedFaces.filter(face => face.normal !== undefined).filter(isOrthogonal).filter(connectedOnlyToBaffleBody);

        const allPotentialChannelsHaveSmallerRadius = potentialChannels.every(hasSmallerRadius);

        if (allPotentialChannelsHaveSmallerRadius && potentialChannels.length > 1 && baffleBodyConnectedFaces.length > potentialChannels.length) {

            const baffle: Face[] = getBaffleFaces(baffleBody, potentialChannels, connectionMap).map(b => {
                return {
                    faceIndex: b.faceIndex,
                    nodeId: b.nodeId
                }
            });
            baffles.push(baffle);
        }
    }

    return baffles;
}

async function getNodeCenterTranlsation(nodeId: number, hwv: Communicator.WebViewer) {
    const initialNodeBounding = await hwv.model.getNodesBounding([nodeId]);
    const currentNodeBounding = await hwv.model.getNodeRealBounding(nodeId);
    const initialCenter = initialNodeBounding.center();
    const currentCenter = currentNodeBounding.center();

    return new Communicator.Point3(
        currentCenter.x - initialCenter.x,
        currentCenter.y - initialCenter.y,
        currentCenter.z - initialCenter.z
    )
}

async function populateFaceInfoFromNodeId(nodeId: number, hwv: Communicator.WebViewer): Promise<FaceInfo[]> {
    const meshData = await hwv.model.getNodeMeshData(nodeId);
    const faceCount = await hwv.model.getFaceCount(nodeId);
    const result: FaceInfo[] = [];

    for (let i = 0; i < faceCount; i++) {
        const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(nodeId, i);

        if (props) {
            result.push(new FaceInfo(nodeId, i, await getFaceVertices({ nodeId, faceIndex: i }, meshData), (props as any).normal, props));
        }
    }

    return result;
}

async function populateFaceInfoFromFaces(faces: Face[], hwv: Communicator.WebViewer): Promise<FaceInfo[]> {
    const result: FaceInfo[] = [];
    const meshDataCache = new Map<number, Communicator.MeshDataCopy>();

    for (const face of faces) {
        const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(face.nodeId, face.faceIndex);

        if (props) {
            let meshData = meshDataCache.get(face.nodeId);

            if (!meshData) {
                meshData = await hwv.model.getNodeMeshData(face.nodeId);
                meshDataCache.set(face.nodeId, meshData);
            }

            result.push(new FaceInfo(face.nodeId, face.faceIndex, await getFaceVertices(face, meshData), (props as any).normal, props));
        }
    }

    return result;
}

function isPointOnPlane(point: Communicator.Point3, plane: Communicator.Plane): boolean {
    const distance = plane.distanceToPoint(point);
    return Math.round(distance) === 0;
}

export function getBoundingBox(points: Communicator.Point3[]): Communicator.Box {
    const box = new Communicator.Box(points[0], points[1]);
    points.forEach(v => box.addPoint(v));

    return box;
}

function getUniquePoints(points: Communicator.Point3[]): Communicator.Point3[] {
    return [...new Set(points.map(makePointKey)).values()].map(parsePointKey)
}

export class VertexGroup {
    public readonly nodeId: number;

    public edges: Edge[];

    constructor(nodeId: number, edges: Edge[]) {
        this.nodeId = nodeId;
        this.edges = edges;
    }

    get key(): string {
        return this.edges.map(e => e.key).join('+');
    }

    get vertices(): Communicator.Point3[] {
        return this.edges.flatMap(e => e.vertices);
    }

    get bb(): Communicator.Box {
        return getBoundingBox(this.vertices);
    }

    get plane(): Communicator.Plane {
        const av = arrangeVertices(this.vertices);
        return Communicator.Plane.createFromPoints(av[0], av[1], av[2]);
    }

    addEdge(edge: Edge) {
        this.edges = [...this.edges, edge];
    }

    clone(): VertexGroup {
        return new VertexGroup(this.nodeId, [...this.edges]);
    }

    isEdgeConnected(edge: Edge, hwv: Communicator.WebViewer): boolean {
        return this.vertices.some(v => edge.hasVertex(v));
    }

    static areConnected(v1: VertexGroup, v2: VertexGroup): boolean {
        const edge1Vkeys = new Set<string>(v1.vertices.map(makePointKey));
        const edge2Vkeys = new Set<string>(v2.vertices.map(makePointKey));
        let sharedVerticeCount = 0;

        edge1Vkeys.forEach(v1k => {
            if (edge2Vkeys.has(v1k)) {
                sharedVerticeCount++;
            }
        })

        return sharedVerticeCount > 1;
    }
}

export class Edge {
    public readonly nodeId: number;

    public readonly edgeIndex: number;

    public readonly vertices: Communicator.Point3[];

    private props: Communicator.SubentityProperties.Edge | null = null;

    constructor(nodeId: number, edgeIndex: number, vertices: Communicator.Point3[]) {
        this.nodeId = nodeId;
        this.edgeIndex = edgeIndex;
        this.vertices = vertices;
    }

    get key() {
        return `${this.nodeId}-${this.edgeIndex}`;
    }

    get bb(): Communicator.Box {
        return getBoundingBox(this.vertices);
    }

    setProps(p: Communicator.SubentityProperties.Edge | null) {
        this.props = p;
    }

    hasVertex(vertex: Communicator.Point3): boolean {
        return this.vertices.length > 0 && this.vertices.some(v => v.equals(vertex));
    }

    minDistance(refPoint: Communicator.Point3): number {
        return this.vertices.map(v => ({ ...v, d: Communicator.Point3.distance(v, refPoint) })).sort((v1, v2) => v1.d - v2.d)[0].d;
    }

    isLine(): boolean {
        const isNarrow = (): boolean => {
            const bb = getPlaneAreaBox(this.vertices);

            if (bb) {
                const extents = bb.extents();
                return [extents.x, extents.y, extents.z].filter(coord => coord < 2).length > 1;
            }

            return false;
        }


        if (this.props?.type() === Communicator.SubentityProperties.EdgeType.Line) {
            return true;
        } else {
            return isNarrow();
        }
    }

    public static sameSpace(edge1: Edge, edge2: Edge): boolean {
        const bb1 = getBoundingBox(edge1.vertices);
        const bb2 = getBoundingBox(edge2.vertices);

        return bb1.extents().equals(bb2.extents());
    }

    public static isEqual(edge1: Edge, edge2: Edge): boolean {
        return edge1.nodeId === edge2.nodeId && edge1.edgeIndex === edge2.edgeIndex;
    }

    public static distance(edge1: Edge, edge2: Edge): number {
        const bb1 = getBoundingBox(edge1.vertices);
        const bb2 = getBoundingBox(edge2.vertices);

        return Communicator.Point3.distance(bb1.center(), bb2.center());
    }

    public static areOnSamePlane(edge1: Edge | VertexGroup, edge2: Edge | VertexGroup): boolean {
        const allVertices = getUniquePoints([...edge1.vertices, ...edge2.vertices]);
        const bb = getBoundingBox(allVertices);
        const plane = Communicator.Plane.createFromPoints(bb.center(), allVertices[0], allVertices[1]);


        return allVertices.every(v => isPointOnPlane(v, plane));
    }

    public static areConnected(edge1: Edge | VertexGroup, edge2: Edge | VertexGroup): boolean {
        const edge1Vkeys = new Set<string>(edge1.vertices.map(makePointKey));
        const edge2Vkeys = new Set<string>(edge2.vertices.map(makePointKey));
        const diff = Communicator.Util.setSubtraction(edge1Vkeys, edge2Vkeys);

        return diff.size !== edge1Vkeys.size;
    }
}

export type Face = { nodeId: number, faceIndex: number };

export type GetColorFn = (nodeId: number, faceIndex: number) => Promise<Communicator.Color | null>

export async function getFaceFromSelectionItem(selectionItem: Communicator.Selection.NodeSelectionItem, hwv: Communicator.WebViewer): Promise<Communicator.Selection.FaceEntity | null> {
    if (selectionItem.isFaceSelection()) {
        return selectionItem.getFaceEntity();
    } else {
        const position = selectionItem.getPosition();
        const screenPosition = position ? Communicator.Point2.fromPoint3(hwv.view.projectPoint(position, hwv.view.getCamera())) : null;

        if (screenPosition) {
            const itemFromPosition = await hwv.view.pickFromPoint(screenPosition, new Communicator.PickConfig(Communicator.SelectionMask.Face));

            return itemFromPosition.getFaceEntity();
        }

        return null;
    }
}

export async function isChannelFace(face: Face, hwv: Communicator.WebViewer): Promise<boolean> {
    const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(face.nodeId, face.faceIndex);
    const allowedFaceTypes = [Communicator.SubentityProperties.FaceType.Cone,
    Communicator.SubentityProperties.FaceType.Cylinder,
    Communicator.SubentityProperties.FaceType.Sphere,
    ];
    const type = props?.type();

    if (type && allowedFaceTypes.includes(type) === true) {
        return true;
    } else {
        const nodeBox = await hwv.model.getNodesBounding([face.nodeId]);
        const meshData = await hwv.model.getNodeMeshData(face.nodeId);
        const vertices = await getFaceVertices(face, meshData);
        const faceBox = new Communicator.Box(vertices[0], vertices[1]);

        vertices.forEach(v => faceBox.addPoint(v));

        const isValidX = faceBox.max.x - faceBox.min.x < 0.3 * (nodeBox.max.x - nodeBox.min.x);
        const isValidY = faceBox.max.y - faceBox.min.y < 0.3 * (nodeBox.max.y - nodeBox.min.y);
        const isValidZ = faceBox.max.z - faceBox.min.z < 0.3 * (nodeBox.max.z - nodeBox.min.z);

        return isValidX && isValidY && isValidZ;
    }
}

export async function detectBafflesFromNodeId(nodeId: number, hwv: Communicator.WebViewer): Promise<Face[][]> {
    const faceInfo = await populateFaceInfoFromNodeId(nodeId, hwv);

    return await detectBaffles(faceInfo);
}

export async function detectBafflesFromFaces(faces: Face[], hwv: Communicator.WebViewer): Promise<Face[][]> {
    const faceInfo = await populateFaceInfoFromFaces(faces, hwv);

    return await detectBaffles(faceInfo);
}

export async function getChannelFromFaceColor(face: Face, hwv: Communicator.WebViewer, getColorFn: GetColorFn): Promise<Face[]> {
    async function populateFaces(nodeIds: number[], color: Communicator.Color): Promise<FaceInfo[]> {
        const meshDataCache = new Map<number, Communicator.MeshDataCopy>();
        const result: Map<string, FaceInfo> = new Map();

        for (const nodeId of nodeIds) {
            let meshData = meshDataCache.get(face.nodeId);
            const faceCount = await hwv.model.getFaceCount(nodeId);

            if (!meshData) {
                meshData = await hwv.model.getNodeMeshData(face.nodeId);
                meshDataCache.set(face.nodeId, meshData);
            }

            for (let i = 0; i < faceCount; i++) {
                const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(nodeId, i);
                const faceColor = await getColorFn(nodeId, i);

                if (props && faceColor?.equals(color)) {
                    const fi = new FaceInfo(nodeId, i, await getFaceVertices({ nodeId, faceIndex: i }, meshData), (props as any).normal, props);

                    if (!result.has(fi.key)) {
                        result.set(fi.key, fi);
                    }
                }
            }
        }

        return [...result.values()];
    }

    const selectedColor = await getColorFn(face.nodeId, face.faceIndex);

    if (!selectedColor) {
        return [];
    }

    const faces = await populateFaces([face.nodeId], selectedColor);
    const connectionMap = buildConnectionMap(faces);
    const startFace = faces.find(f => f.key === `${face.nodeId}-${face.faceIndex}`);

    const isValidFace = (startFace: FaceInfo, face: FaceInfo): boolean => {
        const startFaceVertices = [...startFace.vertices.values()];
        const startFaceBox = new Communicator.Box(startFaceVertices[0], startFaceVertices[1]);
        startFaceVertices.forEach(v => startFaceBox.addPoint(v));

        const faceVertices = [...face.vertices.values()];
        const faceBox = new Communicator.Box(faceVertices[0], faceVertices[1]);
        faceVertices.forEach(v => faceBox.addPoint(v));

        const allowedFaceTypes = [Communicator.SubentityProperties.FaceType.Cone,
        Communicator.SubentityProperties.FaceType.Cylinder,
        Communicator.SubentityProperties.FaceType.Sphere,
        ];

        if (!allowedFaceTypes.includes(face.type)) {
            const isValidX = faceBox.extents().x < 1.3 * startFaceBox.extents().x;
            const isValidY = faceBox.extents().y < 1.3 * startFaceBox.extents().y;
            const isValidZ = faceBox.extents().z < 1.3 * startFaceBox.extents().z;

            return isValidX && isValidY && isValidZ;
        }

        return true;
    }


    if (startFace) {
        let stack: FaceInfo[] = [startFace];
        const result: FaceInfo[] = [];

        while (stack.length) {
            const currentFace = stack.pop();

            if (currentFace) {

                const nextFaces = (connectionMap.get(currentFace) ?? [])
                    .filter(f => isValidFace(startFace, f))
                    .filter(f => result.find(r => r.nodeId === f.nodeId && r.faceIndex === f.faceIndex) === undefined);

                stack = [...stack, ...nextFaces];

                if (!result.find(f => f.nodeId === currentFace.nodeId && f.faceIndex === currentFace.faceIndex)) {
                    result.push(currentFace);
                }
            }
        }

        return result.map(f => {
            return {
                nodeId: f.nodeId,
                faceIndex: f.faceIndex
            }
        });
    }

    return [];
}

async function getFaceVertices(face: Face, md: Communicator.MeshDataCopy): Promise<Communicator.Point3[]> {
    const el = md.faces.element(face.faceIndex);
    const result: Communicator.Point3[] = [];

    const faceIter = el.iterate();

    while (!faceIter.done()) {
        const mesh = faceIter.next();
        result.push(Communicator.Point3.createFromArray(mesh.position));
    }

    return result;
}

async function getPlane(vertices: Communicator.Point3[], hwv: Communicator.WebViewer): Promise<Communicator.Plane> {
    const box = getBoundingBox(vertices);
    const center = box.center();
    const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);

    pickConfig.respectVisibility = true;
    pickConfig.oneEntityPerTypePerInstance = false;
    pickConfig.enableProximityFaces = true;

    let points: { v: Communicator.Point3, neighbours: number, fromCenter: number }[] = vertices.map(v => {
        const d = Communicator.Point3.distance(v, center);

        return {
            v,
            neighbours: 0,
            fromCenter: d
        }
    });

    points = points.sort((p1, p2) => p2.fromCenter - p1.fromCenter).slice(0, 50);

    for (const point of points) {
        const direction = point.v.copy().subtract(center);
        const ray = new Communicator.Ray(center, direction);
        const selectionKeys = (await hwv.view.pickAllFromRay(ray, pickConfig)).map(s => ({
            nodeId: s.getNodeId(),
            faceIndex: s.getFaceEntity()?.getCadFaceIndex()
        })).filter(f => f.faceIndex !== undefined).map(f => makeFaceKey(f as Face));
        const selectionKeysSet = new Set(selectionKeys);

        point.neighbours = selectionKeysSet.size;
    }

    points = points.sort((p1, p2) => p1.neighbours - p2.neighbours);

    let minEdgeLimit = 1;

    for (const point of points) {
        const hasMoreThenOnePoint = points.filter(p => p.neighbours > 0 && p.neighbours === point.neighbours).length > 2;

        if (hasMoreThenOnePoint) {
            minEdgeLimit = point.neighbours;
            break;
        }
    }

    points = points.filter(p => p.neighbours === minEdgeLimit);
    points = points.sort((p1, p2) => p2.fromCenter - p1.fromCenter);

    let planes: { plane: Communicator.Plane, count: number }[] = [];

    const vs = points.map(p => p.v);

    for (const point of vs) {
        const neighbours = vs.filter(p => p.equals(point) === false)
            .sort((p1, p2) => Communicator.Point3.distance(point, p1) - Communicator.Point3.distance(point, p2)).slice(0, 2);


        const plane = Communicator.Plane.createFromPoints(point, neighbours[0], neighbours[1]);
        const initalPlanePoints = [point, ...neighbours];
        const otherPoints = points.filter(p => initalPlanePoints.includes(p.v) === false);
        const count = otherPoints.filter(p => isPointOnPlane(p.v, plane) === true).length;

        planes.push({ plane, count: count + initalPlanePoints.length });
    }

    planes = planes.sort((p1, p2) => p2.count - p1.count);

    return planes.length ? planes[0].plane : new Communicator.Plane();
}

export type SyntheticMeshInfo = {
    center: number[], //extra info for indicator and comparisons 
    vertexes: number[] //already meshed (uses center)
}

export async function createMesh(vertices: Communicator.Point3[], hwv: Communicator.WebViewer): Promise<SyntheticMeshInfo> {
    const box = new Communicator.Box(vertices[0], vertices[1]);
    vertices.slice(2).forEach(v => box.addPoint(v));

    const center = box.center();

    const plane = new Communicator.Plane();
    plane.setFromPoints(vertices[0], vertices[1], vertices[2]);

    // Trying to arrange vertices clockwise
    const upPlane = new Communicator.Plane();
    const upVector = center.copy().add(hwv.view.getCamera().getUp().scale(5));
    upPlane.setFromPoints(upVector, plane.normal, center);

    const rightSideVertices = vertices
        .filter(v => upPlane.determineSide(v) === false);

    const sortedRightSideVertices = rightSideVertices.sort((v1, v2) => Communicator.Point3.distance(v1, upVector) - Communicator.Point3.distance(v2, upVector));

    const edges: Communicator.Point3[][] = [[sortedRightSideVertices[0], sortedRightSideVertices[1]]];

    if (sortedRightSideVertices.length < 2) {
        const leftSideVertices = vertices
            .filter(v => upPlane.determineSide(v) === true);
        const sortedLeftSideVertices = leftSideVertices.sort((v1, v2) => Communicator.Point3.distance(v2, upVector) - Communicator.Point3.distance(v1, upVector));

        edges[0][1] = sortedLeftSideVertices[0];
    }

    while (edges.length < vertices.length - 1) {

        const startPoint = edges[edges.length - 1][1];
        const processed = edges.flat();

        const sorted = vertices
            .filter(v => processed.find(p => p.equals(v)) === undefined)
            .sort((v1, v2) => Communicator.Point3.distance(v1, startPoint) - Communicator.Point3.distance(v2, startPoint))

        if (!sorted.length) {
            break;
        }

        const endPoint = sorted[0];
        edges.push([startPoint, endPoint]);
    }

    edges.push([edges[edges.length - 1][1], edges[0][0]]);


    //will be using this array, along with the center point to save the info.
    let vertexData: number[] = [];
    const v3 = [center.x, center.y, center.z];
    for (const points of edges) {
        for (let i = 0; i < points.length - 1; i++) {
            const v1 = [points[i].x, points[i].y, points[i].z];
            const v2 = [points[i + 1].x, points[i + 1].y, points[i + 1].z];

            vertexData = [...vertexData, ...v1, ...v2, ...v3];
        }
    }

    return { center: v3, vertexes: vertexData };
}

export async function drawSyntheticNode(vertexData: number[], center: number[], color: Communicator.Color, hwv: Communicator.WebViewer | null) {
    const meshData = new Communicator.MeshData();
    const extractPolyline = (center: number[], vertexData: number[]): number[] => {
        const centerPoint = makePointKey(Communicator.Point3.createFromArray(center));
        const edgeVertexData = new Set<string>();

        for (let i = 0; i < vertexData.length - 3; i += 3) {
            const vertice = makePointKey(new Communicator.Point3(vertexData[i], vertexData[i + 1], vertexData[i + 2]));
            edgeVertexData.add(vertice);
        }
        edgeVertexData.delete(centerPoint);

        let polyline: number[] = [];

        for (const ev of edgeVertexData.values()) {
            const vertice = parsePointKey(ev);
            polyline = [...polyline, vertice.x, vertice.y, vertice.z];
        }

        polyline = [...polyline, polyline[0], polyline[1], polyline[2]];

        return polyline;
    }

    meshData.setBackfacesEnabled(true);
    meshData.addFaces(vertexData);
    meshData.addPolyline(extractPolyline(center, vertexData));

    const meshId = await hwv?.model.createMesh(meshData)!;
    const meshInstanceData = new Communicator.MeshInstanceData(meshId);
    meshInstanceData.setFaceColor(color);
    meshInstanceData.setLineColor(color);

    const nodeId = await hwv?.model.createMeshInstance(meshInstanceData)!;

    return { nodeId, meshId };
}

function arrangeVertices(vertices: Communicator.Point3[]): Communicator.Point3[] {
    const uniqueVertices = getUniquePoints(vertices);
    const box = getBoundingBox(uniqueVertices);
    const orderedPoints: Communicator.Point3[] = [uniqueVertices[0]];
    const c1 = uniqueVertices[0].copy().subtract(box.center());
    const c2 = uniqueVertices[1].copy().subtract(box.center());
    const normalToSurface = Communicator.Point3.cross(c1, c2).negate().add(box.center());

    while (orderedPoints.length < uniqueVertices.length) {
        const currentPoint = orderedPoints[orderedPoints.length - 1];
        const plane = Communicator.Plane.createFromPoints(currentPoint, box.center(), normalToSurface);
        const rightSidePoints = uniqueVertices.filter(v => plane.determineSide(v) === true).sort((v1, v2) => plane.distanceToPoint(v1) - plane.distanceToPoint(v2));
        let nextPointDetected: boolean = false;

        for (let i = 0; i < rightSidePoints.length; i++) {
            const nextRightPoint = rightSidePoints[i];
            const newPlane = Communicator.Plane.createFromPoints(nextRightPoint, box.center(), normalToSurface);
            const updatedRightSidePoints = rightSidePoints.filter(v => newPlane.determineSide(v) === true);

            if (rightSidePoints.length - updatedRightSidePoints.length === 1) {
                orderedPoints.push(nextRightPoint);
                nextPointDetected = true;
                break;
            }
        }

        if (!nextPointDetected) {
            console.warn('Unable to arrange points');
            return [];
        }
    }

    return orderedPoints;
}

export function drawFace(vertices: Communicator.Point3[], hwv?: Communicator.WebViewer): SyntheticMeshInfo | null {
    const orderedPoints = arrangeVertices(vertices);

    if (!orderedPoints.length) {
        console.warn('Unable to generate surface');
        return null;
    }
    const box = getBoundingBox(orderedPoints);
    const v3 = [box.center().x, box.center().y, box.center().z];
    const startPoint = orderedPoints[orderedPoints.length - 1];
    const endPoint = orderedPoints[0];
    let vertexData: number[] = [];

    for (let i = 0; i < orderedPoints.length - 1; i++) {
        const v1 = [orderedPoints[i].x, orderedPoints[i].y, orderedPoints[i].z];
        const v2 = [orderedPoints[i + 1].x, orderedPoints[i + 1].y, orderedPoints[i + 1].z];

        vertexData = [...vertexData, ...v1, ...v2, ...v3];
    }

    vertexData = [...vertexData, ...[startPoint.x, startPoint.y, startPoint.z], ...[endPoint.x, endPoint.y, endPoint.z], ...v3];

    return { center: v3, vertexes: vertexData };
}

export function createRectangleMeshData(center: Communicator.Point3, sizeOfRectangle: { x: number, y: number, z: number }): {
    positions: number[], normals: number[], uvs: number[]
} {
    const p0 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p1 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p2 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p3 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p4 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);
    const p5 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);
    const p6 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);
    const p7 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);

    const positions = [
        p0.x, p0.y, p0.z, p2.x, p2.y, p2.z, p1.x, p1.y, p1.z, p0.x, p0.y, p0.z, p3.x, p3.y, p3.z, p2.x, p2.y, p2.z,
        p2.x, p2.y, p2.z, p3.x, p3.y, p3.z, p4.x, p4.y, p4.z, p2.x, p2.y, p2.z, p4.x, p4.y, p4.z, p5.x, p5.y, p5.z,
        p1.x, p1.y, p1.z, p2.x, p2.y, p2.z, p5.x, p5.y, p5.z, p1.x, p1.y, p1.z, p5.x, p5.y, p5.z, p6.x, p6.y, p6.z,
        p0.x, p0.y, p0.z, p7.x, p7.y, p7.z, p4.x, p4.y, p4.z, p0.x, p0.y, p0.z, p4.x, p4.y, p4.z, p3.x, p3.y, p3.z,
        p5.x, p5.y, p5.z, p4.x, p4.y, p4.z, p7.x, p7.y, p7.z, p5.x, p5.y, p5.z, p7.x, p7.y, p7.z, p6.x, p6.y, p6.z,
        p0.x, p0.y, p0.z, p6.x, p6.y, p6.z, p7.x, p7.y, p7.z, p0.x, p0.y, p0.z, p1.x, p1.y, p1.z, p6.x, p6.y, p6.z
    ];
    const uvs = [
        0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1,
    ];
    const normals = [
        0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1,
        0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0,
        1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0,
        -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0,
        0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1,
        0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,
    ];
    return {
        positions, normals, uvs
    };
}

export async function createRectangularMoldPartsAroundModel(hwv: Communicator.WebViewer, size: MoldSize, center: Coordinates) {
    let sizeAsPoint3 = new Communicator.Point3(
        size.x / 2,
        size.y,
        size.z
    );
    const center1 = new Communicator.Point3(center.x - size.x / 4, center.y, center.z);
    const center2 = new Communicator.Point3(center.x + size.x / 4, center.y, center.z);
    const nodeId1 = await createRectangularMoldPart(hwv, center1, sizeAsPoint3, moldGroup1Color);
    const nodeId2 = await createRectangularMoldPart(hwv, center2, sizeAsPoint3, moldGroup1Color);
    hwv.model.setNodesOpacity([nodeId1, nodeId2], 0.5);
    return [nodeId1, nodeId2];
}

export async function computeInitialBasicMoldConfig(hwv: Communicator.WebViewer, plasticPart: Part[]) {
    let boundingBox;
    if (plasticPart.length > 0) {
        const nodesIds = plasticPart.flatMap(p => p.nodesIds);
        boundingBox = await hwv.model.getNodesBounding(nodesIds);
    } else {
        boundingBox = await hwv.model.getModelBounding(true, false);
    }
    const fullMoldInitialSize = computeBasicMoldInitialSize(boundingBox);
    const center = boundingBox.center();
    return { size: { x: fullMoldInitialSize.x, y: fullMoldInitialSize.y, z: fullMoldInitialSize.z }, center: { x: center.x, y: center.y, z: center.z } };
}

export function computeBasicMoldInitialSize(boundingBox: Communicator.Box) {
    const extents = boundingBox.extents();
    const longestExtends = Math.max(extents.x, extents.y, extents.z);
    const factor = 0.5;
    return {
        x: parseFloat((extents.x + factor * longestExtends).toFixed(2)),
        y: parseFloat((extents.y + factor * longestExtends).toFixed(2)),
        z: parseFloat((extents.z + factor * longestExtends).toFixed(2))
    }
}

export async function createRectangularMoldPart(hwv: Communicator.WebViewer, center: Communicator.Point3, size: Communicator.Point3, color: Communicator.Color) {
    const meshData = new Communicator.MeshData();
    const rectangleMeshData = createRectangleMeshData(center, {
        x: size.x,
        y: size.y,
        z: size.z
    });
    meshData.addFaces(rectangleMeshData.positions, rectangleMeshData.normals, undefined, rectangleMeshData.uvs);
    meshData.setFaceWinding(Communicator.FaceWinding.CounterClockwise);
    const meshId = await hwv.model.createMesh(meshData);
    const meshInstanceData = new Communicator.MeshInstanceData(
        meshId,
        new Communicator.Matrix(),
        "texture-cube",
        color
    );
    return await hwv.model.createMeshInstance(meshInstanceData);
}

export function drawPoint(point: Communicator.Point3, text: string, hwv: Communicator.WebViewer, color: Communicator.Color = Communicator.Color.green()) {
    const markupItem = new DebugMarkupItem(hwv, point, text, color);
    markupIds.push(hwv.markupManager.registerMarkup(markupItem));
}

export function drawLine(startPoint: Communicator.Point3, endPoint: Communicator.Point3, hwv: Communicator.WebViewer, color: Communicator.Color = Communicator.Color.green()) {
    const markupItem = new DebugLineMarkupItem(hwv, startPoint, endPoint, color);
    markupIds.push(hwv.markupManager.registerMarkup(markupItem));
}

export function clearPoints(hwv: Communicator.WebViewer) {
    markupIds.forEach(id => hwv.markupManager.unregisterMarkup(id));
    markupIds = [];
}

export function makeFaceKey(face: { nodeId: number, faceIndex: number }): string {
    return `${face.nodeId}-${face.faceIndex}`;
}

const faceKeyRegexp = new RegExp(/([0-9\-]+)\-([0-9]+)/);

export function parseFaceKey(key: string): Face {
    const parts = key.match(faceKeyRegexp);

    if (!parts) {
        throw new Error('Wrong face key format');
    }

    const nodeId = parseInt(parts[1]);
    const faceIndex = parseInt(parts[2]);

    return {
        nodeId,
        faceIndex
    }
}

export function makePointKey(point: Communicator.Point3): string {
    return JSON.stringify(point.toJson());
}

export function parsePointKey(key: string): Communicator.Point3 {
    return Communicator.Point3.fromJson(JSON.parse(key));
}

export function areSyntheticMeshesEqual(mesh1: SyntheticMeshInfo, mesh2: SyntheticMeshInfo): boolean {
    const vertexSet1 = new Set<number>(mesh1.vertexes);
    const vertexSet2 = new Set<number>(mesh2.vertexes);

    return Communicator.Util.setSubtraction(vertexSet1, vertexSet2).size === 0;
}

export const getCadFileNameProperty = async (nodeId: number, webViewer: Communicator.WebViewer) => {
    const properties = await webViewer.model.getNodeProperties(nodeId);
    const cadFileName = properties && properties["cadFileName"] ? properties["cadFileName"] : null;

    return cadFileName;
}

export async function getFaceBoundingBox(face: Face, hwv: Communicator.WebViewer): Promise<Communicator.Box> {
    const meshData = await hwv.model.getNodeMeshData(face.nodeId);
    const vertices = await getFaceVertices(face, meshData);
    const box = new Communicator.Box(vertices[0], vertices[1]);

    vertices.slice(2).forEach(v => box.addPoint(v));

    return box;
}

export function getPlaneAreaBox(vertices: Communicator.Point3[]): Communicator.Box | null {
    if (vertices.length < 3) {
        return null;
    }

    const originBox = getBoundingBox(vertices);
    const plane = Communicator.Plane.createFromPoints(originBox.center(), vertices[0], vertices[1]);

    if (isNaN(plane.d)) {
        return null;
    }

    const mainAxis = Math.max(Math.abs(plane.normal.x), Math.abs(plane.normal.y), Math.abs(plane.normal.z));
    const normal = new Communicator.Point3(
        Math.abs(plane.normal.x) === mainAxis ? 1 : 0,
        Math.abs(plane.normal.y) === mainAxis ? 1 : 0,
        Math.abs(plane.normal.z) === mainAxis ? 1 : 0
    );
    const project = (p: Communicator.Point3) => {
        const d = Communicator.Point3.dot(normal.copy(), p);
        const dn = normal.copy().scale(d);
        return p.copy().subtract(dn);
    };
    const projectedVertices = vertices.map(project);

    return getBoundingBox(projectedVertices);
}
export interface OperatorIndex {
    indexOf: number,
    operatorId: Communicator.OperatorId | null
}

export function getCurrentSelectionOperator(hwv: Communicator.WebViewer): OperatorIndex | null {
    //select
    const selectOperator = Communicator.OperatorId.Select;
    //area select
    const areaSelectOperator = Communicator.OperatorId.AreaSelect;
    //Measure edges
    const measureEdgeOperator = Communicator.OperatorId.MeasureEdgeLength;
    //Measure point to point
    const measurePointToPointOperator = Communicator.OperatorId.MeasurePointPointDistance;
    //Measure angle between faces
    const measureAngleBetweenFacesOperator = Communicator.OperatorId.MeasureFaceFaceAngle;
    //Measure distance between faces
    const measureDistanceBetweenFacesOperator = Communicator.OperatorId.MeasureFaceFaceDistance;

    const availableOperators = [selectOperator, areaSelectOperator, measureEdgeOperator, measurePointToPointOperator, measureAngleBetweenFacesOperator, measureDistanceBetweenFacesOperator];

    for (const operatorId of availableOperators) {
        const indexOf = hwv.operatorManager.indexOf(operatorId);
        if (indexOf != -1) {
            return {
                indexOf: indexOf,
                operatorId: operatorId
            }
        }
    }

    return null;
}

export type MeshDataCopyElementIterable = Communicator.MeshDataCopyElement & Iterable<Communicator.MeshDataCopyVertex>;

export type MeshDataCopyElementGroupIterable = Communicator.MeshDataCopyElementGroup & Iterable<Communicator.MeshDataCopyVertex>;