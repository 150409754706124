import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MoldMaterial from '../../store/job/mold-material';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, DialogContent, FormGroup, TextField, Box, DialogActions, Button, Typography, useTheme, Tooltip } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Parser from 'html-react-parser'
import { t } from 'i18next';

export type MaterialDetailDialogProps = {
    material: MoldMaterial,
    type: string
}

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
    material: MoldMaterial;
    type: string,
    title: string;
}

function SimpleDialog(props: SimpleDialogProps) {
    const { material, open, onClose } = props;
    const theme = useTheme();
    const handleClose = (e: any) => {
        e.stopPropagation();
        onClose();
    };
    let details = [];
    if (material) {
        details.push({ label: t("Name"), value: material.name, units: '' });
        if (material.density) {
            details.push({ label: t("Density"), value: material.density, units: 'kg/m<sup>3</sup>' });
        }
        if (material.thermalConductivity) {
            details.push({ label: t("Thermal Conductivity"), value: material.thermalConductivity, units: 'W/(m &#8451;)' });
        }
        if (material.specificHeat) {
            details.push({ label: t("Specific Heat"), value: material.specificHeat, units: 'J/(kg &#8451;)' });
        }
        if (material.temperature) {
            details.push({ label: t("Temperature"), value: material.temperature, units: '&#8451;' });
        }
        if (material.ejectionTemperature) {
            details.push({ label: t("Ejection Temperature"), value: material.ejectionTemperature, units: '&#8451;' });
        }
        if (material.moldTemperature) {
            details.push({ label: t("Target Mold Temperature"), value: material.moldTemperature, units: '&#8451;' });
        }
    }

    const classes = makeStyles({
        btn: {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
            textTransform: 'none',
            fontSize: '1.1em',
            cursor: 'pointer',
            borderRadius: "20px",
            margin: "5px",
            "&:hover": {
                backgroundColor: theme.palette.info.dark
            }
        }
    })();

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
            <DialogTitle sx={{ color: theme.palette.primary.dark }}>{props.type} {t("Material")}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    {details.map((detail, index) => (
                        <Tooltip placement="left" title={detail.value} key={index}>
                            <TextField
                                fullWidth
                                disabled
                                variant="outlined"
                                key={'material-detail-item-' + index}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: theme.palette.primary.dark,
                                    },
                                }}
                                label={detail.label}
                                value={detail.value}
                                size="small"
                                margin="normal"
                                InputProps={{
                                    endAdornment: <Box sx={{ whiteSpace: "nowrap" }}>
                                        <Typography variant="caption" color="GrayText">{Parser(detail.units)}</Typography></Box>
                                }}
                            /></Tooltip>
                    ))}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.btn}>{t("Ok")}</Button>
            </DialogActions>
        </Dialog>
    );
}

const MaterialDetailDialog = (props: MaterialDetailDialogProps) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (<Box className="userMaterialIconBox" sx={{ marginLeft: "auto", pl: "30px", display: "flex", alignItems: "center" }}>
        <Tooltip
            placement="left"
            title='View details'>
            <IconButton onClick={(e) => { e.stopPropagation(); handleClickOpen(); }} size="small">
                <InfoIcon sx={{ cursor: "pointer", fontSize: "1.1em", color: theme.palette.grey[500] }}></InfoIcon>
            </IconButton>
        </Tooltip>
        <SimpleDialog
            open={open}
            onClose={handleClose}
            title={props.material?.name}
            type={props.type}
            material={props.material}
        /></Box>
    )
}

export default MaterialDetailDialog;