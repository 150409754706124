import { useQuery, useMutation, UseMutateFunction, UseMutateAsyncFunction, UseQueryResult } from 'react-query';
import Project from '../../../store/project/project';
import ProjectService from '../../../services/ProjectsService';
import { queryKeys } from '../../../react-query/queryKeys';
import { queryClient } from '../../../react-query/queryClient';
import Iteration, {JobThumbnailData} from '../../../store/project/iteration'
import FileManagementService from '../../../services/FileManagementService';
import { blobToBase64 } from '../../../services/HelperService';
import { updateJobInfo } from '../../../store/job/job-data';
import JobService from '../../../services/JobService';
import { ProjectFilters } from '../../common/ProjectFilter';

const projectService = new ProjectService();

export const useProjects = (projectFilters: ProjectFilters): UseQueryResult<Project[]> => {
    return useQuery<Project[], Error>([queryKeys.projects],
        async ({signal}) => {      
            let projects = await projectService.getProjects(true, projectFilters, signal);

            await fetchProjectsThumbnail(projects)

            setJobsThumbnailData(projects);
            return projects;
        },
        {
            refetchInterval: 15000,
            select: data => data.sort((a: Project, b: Project) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime()),
            initialData: []
        }
    );
}

async function fetchProjectsThumbnail(projects: Project[]){
    let promises = [];

    for(let project of projects){
        promises.push(getProjectThumbnail(project));
    }

    await Promise.all(promises);
}

async function getProjectThumbnail(project: Project) {
    const blobInfo = ProjectService.getLatestThumbnailBlobInfo(project);
    if (blobInfo) {
        let blob = await new FileManagementService().downloadBlob(blobInfo, project.containerName);

        let base64 = await blobToBase64(blob) as string;
        project.thumbnailData = base64.split(",")[1];
    }
}

function setJobsThumbnailData(projects: Project[]){
    let oldProjects: Project[] | undefined = queryClient.getQueryData([queryKeys.projects]);

    //set already fetched thumbnails for iterations
    for(let project of projects){
        let projectAssoc = oldProjects?.filter((oldProject: Project) => {return oldProject.id == project.id;})[0];

        project.iterations?.map((iteration: Iteration) => {
            let iterationAssoc = projectAssoc?.iterations?.filter((it: Iteration) => { return it.id == iteration.id})[0];
            let thumbnailData = iterationAssoc?.thumbnailData;

            iteration.thumbnailData = thumbnailData == undefined ? null : thumbnailData;
        });
    }
}

type projectInfo = { name: string, description: string, type: string, tags?: string[] };
type newProjectInfo = { project: Project };

const createProject = async (project: projectInfo) => {
    const newProject = await projectService.createProject(project.name, project.description, '', project.type, project.tags);
    return { project: newProject };
}

export const useCreateProjectMutation = (): UseMutateAsyncFunction<newProjectInfo, unknown, projectInfo, unknown> => {

    const { mutateAsync } = useMutation(createProject, {
        onSuccess: (newProjectInfo) => {
            queryClient.setQueryData<Project[]>([queryKeys.projects], (old) => {
                return old ? [...old, newProjectInfo.project] : [newProjectInfo.project];
            });
        }
    });

    return mutateAsync;
};

const deleteProject = async (deletedProjectInfo: deletedProjectInfo) => {
    await projectService.deleteProject(deletedProjectInfo.id);
    return { id: deletedProjectInfo.id };
}

type deletedProjectInfo = { id: string };

export const useDeleteProjectMutation = (): UseMutateFunction<deletedProjectInfo, unknown, deletedProjectInfo, unknown> => {

    const { mutate } = useMutation(deleteProject, {
        onSuccess: (deleted) => {

            queryClient.setQueryData<Project[]>([queryKeys.projects], (old) => {
                return old ? old?.filter(p => p.id !== deleted.id) : [];
            });
        }
    });

    return mutate;
}

type updateProjectInfo = { id: string, containerName: string, patch: object };

const updateProject = async (updateProjectInfo: updateProjectInfo) => {
    const patchedProject: Project = await projectService.updateProjectDetails(updateProjectInfo.id, updateProjectInfo.containerName, updateProjectInfo.patch);
    return { project: patchedProject, id: patchedProject.id }
}

export const useUpdateProjectMutation = (): UseMutateFunction<unknown, unknown, updateProjectInfo, unknown> => {
    const { mutate } = useMutation(updateProject, {
        onSuccess: (updated) => {
            queryClient.setQueryData<Project[]>([queryKeys.projects], (old) => {
                const found: Project[] | undefined = old?.filter(item => item.id === updated.id);
                if (found == null || found?.length === 0) return [];

                const currentProject = found[0];
                const updatedProject: Project = {
                    ...currentProject,
                    name: updated?.project?.name,
                    description: updated?.project.description,
                    tags: updated?.project?.tags
                };

                return old ? [...old?.filter(item => item.id !== updated.id), updatedProject] : [];
            });
        }
    });

    return mutate;
}

const updateJob = async (updateJobInfo: updateJobInfo) => {
    await JobService.editJobInfo(updateJobInfo);

    return { ...updateJobInfo }
}

export const useUpdateJobMutation = (): UseMutateFunction<unknown, unknown, updateJobInfo, unknown> => {
    const { mutate } = useMutation(updateJob, {
        onSuccess: (updated) => {
            queryClient.setQueryData<Project[]>([queryKeys.projects], (old) => {
                const found: Project[] | undefined = old?.filter(item => item.id === updated.projectId);
                if (found == null || found?.length === 0) return [];

                const currentProject = found[0];

                const foundJob = currentProject.iterations?.filter(item => item.id === updated.id)[0];
                if (!foundJob) return [];

                const updatedJob: Iteration = {
                    ...foundJob,
                    name: updated?.name,
                    description: updated?.description,
                    lastModified: new Date(),
                };

                const updatedProject = {
                    ...currentProject,
                    lastModified: new Date(),
                    iterations: currentProject.iterations ? [...currentProject.iterations?.filter(item => item.id !== updated.id), updatedJob] : [],
                }

                if(updated.updateOnCadFiles){
                    updatedJob.thumbnailData = null;
                    updatedJob.thumbnailBlobInfo = null;

                    updatedProject.thumbnailData = null;
                }

                return old ? [...old?.filter(item => item.id !== updated.projectId), updatedProject] : [];
            });
        }
    });

    return mutate;
}

export const resetJobStatus = (projectId: string, jobId: string) => {
    queryClient.setQueryData<Project[]>([queryKeys.projects], (old) => {
        if(old == undefined) return [];

        const project: Project | undefined = old?.filter(item => item.id === projectId)[0] as Project;
        if(!project.iterations) return old;

        const jobToResetStatus = project.iterations?.find((job) => {return job.id == jobId})
        if(!jobToResetStatus) return old;

        const updatedJob = {...jobToResetStatus};
        updatedJob.status = "0";
        updatedJob.solveStatus = 0;

        const updatedProject: Project = {
            ...project,
            iterations: [...project.iterations.filter(item => item.id !== jobId), updatedJob]
        }

        return [...old?.filter(item => item.id !== projectId), updatedProject];
    });    
}

async function fetchJobThumbnail(job: Iteration){
    let blobInfo = job.thumbnailBlobInfo;

    let returnValue = {
        jobId: job.id,
        thumbnailData: ""
    }

    if (blobInfo) {
        let blob = await new FileManagementService().downloadBlob(blobInfo, job.containerName);

        let base64 = await blobToBase64(blob) as string;
        returnValue.thumbnailData = base64.split(",")[1];
    }

    return returnValue;
}

export const loadJobsThumbnail = async (project: Project) => {
    let promises = [];

    let iterations = project.iterations ? project.iterations : [];

    for(let iteration of iterations){
        promises.push(fetchJobThumbnail(iteration));
    }

    let thumbnailsData: JobThumbnailData[] = await Promise.all(promises);

    return {projectId: project.id, thumbnailsData};
}

export const useLoadJobsThumbnail = (): UseMutateAsyncFunction<unknown, unknown, Project, unknown> => {
    const { mutateAsync } = useMutation(loadJobsThumbnail, {
        onSuccess: (data) => {
            queryClient.setQueryData<Project[]>([queryKeys.projects], (projectsList) => {
                if(projectsList == undefined) return []

                let updatedJobsList = [];
                var currentProject = projectsList?.filter((project) => {return project.id == data.projectId})[0];
                if(currentProject && currentProject.iterations){
                    for(let job of currentProject.iterations){
                        let newJob = {
                            ...job
                        }

                        let thumbnailAssoc = data.thumbnailsData.filter((data: JobThumbnailData) => {return data.jobId == job.id})[0];
                        if(thumbnailAssoc){
                            newJob.thumbnailData = thumbnailAssoc.thumbnailData;
                        }

                        updatedJobsList.push(newJob);
                    }
                }

                const updatedProject: Project = {
                    ...currentProject,
                    iterations: updatedJobsList
                };

                return projectsList ? [...projectsList?.filter(item => item.id !== data.projectId), updatedProject] : [];
            });
        }
    });

    return mutateAsync;
};