import { SelectOperator } from "./SelectOperator";

export class SelectDisplayGroupOperator extends SelectOperator {
    private selectedEntities: { nodeId: number, faceIndex: number }[] = [];

    async onActivate() {
        await super.onActivate();

        if (this._highlight) {
            this.selectedEntities.forEach(face => this._hwv.model.setNodeFaceHighlighted(face.nodeId, face.faceIndex, true));
        }
    };

    onSelect?: (selection: { nodeId: number, faceIndex: number }[]) => void;

    setSelectedEntities(entities: { nodeId: number, faceIndex: number }[]) {
        this.selectedEntities = entities;
    }

    async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        if (event.getButton() === Communicator.Button.Left) { 
            this._selectedItem = await this._hwv.view.pickFromPoint(event.getPosition(), new Communicator.PickConfig(Communicator.SelectionMask.Face));
        }
        if (event.getButton() === Communicator.Button.Right) {
            this.onSelect?.(this.selectedEntities);
        }
    }

    async onMouseUp(event: Communicator.Event.MouseInputEvent) {
        if (this._selectedItem) {
            const nodeId = this._selectedItem.getNodeId();
            const face = this._selectedItem.getFaceEntity();

            if (nodeId && face) {
                const selectedItem = { nodeId, faceIndex: face.getCadFaceIndex() };
                const alreadyAdded = this.selectedEntities.find(se => se.nodeId === selectedItem.nodeId && se.faceIndex === selectedItem.faceIndex);

                if (alreadyAdded) {
                    this.selectedEntities = this.selectedEntities.filter(se => se !== alreadyAdded);
                } else if (event.getModifiers() === Communicator.KeyModifiers.Control) {
                    this.selectedEntities.push(selectedItem);
                } else {
                    this.selectedEntities = [selectedItem];
                }

                this._hwv.model.resetModelHighlight();
                this.selectedEntities.forEach(se => this._hwv.model.setNodeFaceHighlighted(se.nodeId, se.faceIndex, true));
            } 
        }
        event.setHandled(true)
    }

}