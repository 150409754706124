import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { createTheme } from '@mui/material';

interface LoadingProp{
    isLoading?: boolean | null
}
const Loading = (props: LoadingProp) => {

    // Creates custom theme, page required more colors
    const customTheme = createTheme({
        palette: {
            primary: {
                main: '#fff'
            }
        }
    });

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const isFetching = useIsFetching({ predicate: query => query.state.status === 'loading' });
    const isMutating = useIsMutating();

    useEffect(() => {
        setOpen((isMutating || isFetching || props.isLoading == true) > 0);
    }, [isMutating || isFetching || props.isLoading]);

    return <Backdrop
        sx={{ color: customTheme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}>
        <CircularProgress />
    </Backdrop>
};

export default Loading;
