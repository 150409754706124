import { createTheme, Theme } from "@mui/material";

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    custom: CustomThemeVariables
  }
  interface ThemeOptions {
    custom?: Partial<CustomThemeVariables>
  }
}

type CustomThemeVariables = Theme & {
  padding: {
    small: string;
    medium: string;
    large: string;
  };
  margin: {
    small: string;
    medium: string;
    large: string;
  };
}

const theme = createTheme({
  custom: {
    margin: {
      small: '0.5rem',
      medium: '1rem',
      large: '1.5rem',
    },
    padding: {
      small: '0.2rem',
      medium: '0.8rem',
      large: '1.2rem',
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: { color: '#005087' }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: { fontSize: '1rem', fontFamily: "-apple-system,BlinkMacSystemFont,'DM Sans','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif" }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: { paddingLeft: '0' }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: { color: '#FFFFFF' }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: { color: '#FFFFFF' }
        }
      },
    }
  } as CustomThemeVariables,
  palette: {
    primary: {
      main: '#0c414b',
      light: '#d2d2d2',
      dark: '#0c414b',
      contrastText: '#005087'
    },
    secondary: {
      main: '#4C84AB',
      dark: '#e0e0e0',
      light: '#E5E5E5',
      contrastText: '#6e6e6e'
    },
    success: {
      main: "#2e7d32",
      light: "#d1f0e5",
      dark: "#D0EFF5"
    },
    info: {
      main: "#1880F5",
      light: "#17afce",
      dark: "#f5f5f5",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: '#ffcd00',
    },
    error: {
      main: '#ff4b00',
    },
    tonalOffset: 0.2,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#FFFFFF",
      default: "#f5f9fa"
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
  shape: {
    //button borderRadius for all app
    borderRadius: 8
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px)": {
        "@media (orientation: landscape)": {
          "minHeight": 48
        }
      },
      "@media (min-width:600px)": {
        "minHeight": 64
      }
    }
  },
  typography: {
    htmlFontSize: 16,
    "fontFamily": " -apple-system, BlinkMacSystemFont, 'DM Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold": 700
  },
  "transitions": {
    "easing": {
      "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
      "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
      "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
      "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
    },
    "duration": {
      "shortest": 150,
      "shorter": 200,
      "short": 250,
      "standard": 300,
      "complex": 375,
      "enteringScreen": 225,
      "leavingScreen": 195
    }
  },
  "zIndex": {
    "mobileStepper": 1000,
    "fab": 1050,
    "speedDial": 1050,
    "drawer": 1200,
    "modal": 1300,
    "snackbar": 1400,
    "tooltip": 1500,
    "appBar": 2000,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#0c414b',
          color: '#FFFFFF'
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '0 0.8rem',
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
          scrollbarColor: '#e0e0e0 white',
          htmlFontSize: 16,
          "fontFamily": " -apple-system, BlinkMacSystemFont, 'DM Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
          "fontSize": 14,
          "fontWeightLight": 300,
          "fontWeightRegular": 400,
          "fontWeightMedium": 500,
          "fontWeightBold": 700
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
          scrollbarColor: '#e0e0e0 white',
        }
      }
    }
  },
});

export default theme;
