import { Props } from "html-react-parser/lib/attributes-to-props"
import { ToastContainer } from "react-toastify"

export const ToastsContainers = () => {

    return (
        <>
            <ToastContainer
                enableMultiContainer
                containerId="InfoToastContainer"
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            
            <ToastContainer
                enableMultiContainer
                containerId="TipsAndTrickToastContainer"
                limit={1}
                position="bottom-center"
                style={{ width: "500px" }} />
        </>
    )
}

